<template>
  <PageTable :loading="loading">
    <template v-slot:thead>
      <th>
        <span
          class="controll date"
          :class="[
            createdAt,
            { active: $queryParams.parse(this.$route.query.sort).createdAt },
          ]"
          @click="$emit('sort', { createdAt: createdAt || 'asc' })"
        >
          {{ $translate("date") }}
          <ArrowSVG />
        </span>
      </th>
      <th>
        <span
          class="controll instruction"
          :class="[
            number,
            { active: $queryParams.parse(this.$route.query.sort).number },
          ]"
          @click="$emit('sort', { number: number || 'asc' })"
        >
          {{ $translate("transport_instructions", "orders") }}
          <ArrowSVG />
        </span>
      </th>
      <th>
        <span
          class="controll"
          :class="[
            order.number,
            {
              active: $queryParams.parse(this.$route.query.sort).order
                ?.number,
            },
          ]"
          @click="
            $emit('sort', {
              order: order.number ? order : { number: 'asc' },
            })"
        >
          {{ $translate("order", "orders") }}
          <ArrowSVG />
        </span>
      </th>
      <th>
        <span
          class="controll"
          :class="[
            quantity,
            { active: $queryParams.parse(this.$route.query.sort).quantity },
          ]"
          @click="$emit('sort', { quantity: quantity || 'asc' })"
        >
          {{ $translate("volume", "orders") }}
          <ArrowSVG />
        </span>
        <!-- {{ $translate("volume", "orders") }} -->
      </th>
      <th>{{ $translate("terms_of_delivery", "orders") }}</th>
      <th>{{ $translate("ti_destination", "orders") }}</th>
      <th>{{ $translate("delivery_status", "orders") }}</th>
      <th></th>
    </template>
    <template v-slot:default>
      <RowItem
        v-for="(item, index) in list"
        :key="item.id"
        :row="item"
        :index="index"
      />
    </template>
  </PageTable>
</template>

<script>
import RowItem from "./Item";
import PageTable from "../PageTable";
import ArrowSVG from "@/assets/img/arrow_top.svg";
/**
 * The PackagesTable component.
 */
export default {
  name: "PackagesTable",
  components: { RowItem, ArrowSVG, PageTable },
  props: {
    list: { default: () => [], type: Array },
    loading: { default: false, type: Boolean },
    createdAt: { default: "asc", type: String },
    number: { default: "asc", type: String },
    quantity: { default: "asc", type: String },
    order: {
      default: () => {
        return { number: undefined };
      },
      type: Object,
    },
  },
};
</script>

<style lang="scss" scoped src="./component.scss"></style>
