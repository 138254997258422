<template>
  <router-view v-if="isChildrenPage"></router-view>
  <LayoutDashboard name="payments" v-else>
    <DashboardTitleRow :title="$translate('my_payments', 'orders')" />
    <PageTabs :list="pageTabsList" />
    <PageFilter
      :loading="loading"
      :list="filterList"
      @submit="submitFilter"
      @reset="resetFilter"
      @remove="removeFilterTag"
    >
      <Label
        :title="$translate('extreme_term', 'orders')"
        name="documentValidToDate"
        inputType="datepicker"
        @onInput="onInputFilterDate"
        ref="filterDate"
        :value="filterCurrent.documentValidToDate"
        :upperLimit="false"
        calendarStartingView="months"
        :key="formKey"
      />
      <LabelDropdown
        name="order_number"
        :title="$translate('order_number', 'orders')"
        :list="filter.order.number"
        :active="filterCurrent.order.number"
        @change="changeDropdown"
      />
    </PageFilter>
    <PaymentsTable
      :list="list"
      v-if="list.length"
      :loading="loading"
      :suAmountVat="sort.suAmountVat"
      :documentValidToDate="sort.documentValidToDate"
      :order="sort.order"
      @sort="sortTable"
    />
    <LoadingIcon v-if="loading && !list.length" />
    <template v-if="!loading && !list.length">
      <p class="no_data">{{ $translate("no_data") }}</p>
    </template>
    <Pagination
      @paginate="paginate"
      :pageCurrent="pageCurrent"
      :pageCount="pageCount"
      :perPage="PAGE_SIZE"
      :class="{ disabledElem: loading }"
    />
  </LayoutDashboard>
</template>

<script>
const PAGE_SIZE = 2;

import LayoutDashboard from "@/layouts/Dashboard.vue";
import DashboardTitleRow from "@/components/DashboardTitleRow";
import PageTabs from "@/components/PageTabs";
import PageFilter from "@/components/PageFilter";
import PaymentsTable from "@/components/Table/Payments";
import Pagination from "@/components/Pagination";
/**
 * The payments-page component.
 */
export default {
  name: "PaymentsPage",
  components: {
    LayoutDashboard,
    DashboardTitleRow,
    PageTabs,
    PageFilter,
    PaymentsTable,
    Pagination,
  },
  data() {
    const filter = this.$queryParams.parse(this.$route.query.filter);
    const sort = this.$queryParams.parse(this.$route.query.sort);
    return {
      PAGE_SIZE: PAGE_SIZE,
      title: this.$translate("my_orders", "orders"),
      loading: true,
      preList: [],
      list: [],
      pageCurrent: parseInt(this.$route.query.page) || 1,
      pageCount: 1,
      sort: {
        suAmountVat: Object.keys(sort).length > 0 ? sort?.suAmountVat : 'desc',
        documentValidToDate: sort?.documentValidToDate || undefined,
        order: {
          number: sort?.order?.number || undefined,
        }
      },
      filterCurrentCopy: null,
      filterCurrent: {
        documentValidToDate: filter.documentValidToDate
          ? this.$moment(filter.documentValidToDate, "YYYY-MM-DD").format(
              "DD/MM/YYYY"
            )
          : null,

        order: {
          number: filter.order?.number || null,
        },
      },
      pageTabsList: [
        {
          id: 1,
          text: this.$translate("list_of_payments", "orders"),
          to: { name: "payments" },
          active: true,
        },
        {
          id: 2,
          text: this.$translate("payment_schedule", "orders"),
          to: { name: "payments-schedule" },
          active: false,
        },
      ],
      formKey: "form_0",
    };
  },
  computed: {
    isChildrenPage() {
      return this.$route.name !== "payments";
    },
    filter() {
      const data = {
        documentValidToDate: [],
        order: {
          number: [],
        },
      };
      this.preList.forEach((item) => {
        // data.documentValidToDate.push(
        //   this.$moment(item.documentValidToDate).format("DD.MM.YY")
        // );
        data.order.number.push(item.order?.number);
      });
      // get unique values of array
      // data.documentValidToDate = [...new Set(data.documentValidToDate)];
      data.order.number = [...new Set(data.order.number)].filter(
        (item) => item
      );
      return data;
    },
    filterList() {
      const list = [];

      // documentValidToDate
      if (this.filterCurrentCopy.documentValidToDate) {
        list.push({
          id: "documentValidToDate",
          type: this.$translate("extreme_term", "orders"),
          text: this.$moment(
            this.filterCurrentCopy.documentValidToDate,
            "DD/MM/YYYY"
          ).format("DD.MM.YY"),
        });
      }
      // order_number
      if (this.filterCurrentCopy.order?.number) {
        list.push({
          id: "order_number",
          type: this.$translate("order", "orders"),
          text: this.filterCurrentCopy.order.number,
        });
      }

      return list;
    },
  },
  methods: {
    paginate(page) {
      this.getPayments(page);
    },
    async getPreList() {
      const { list, success } = await this.$api.getPayments({
        populate: ["documentValidToDate", "order.number"],
      });
      if (success) {
        this.preList = list;
      }
      this.loading = false;
    },
    async getPayments(
      page,
      sort = this.sort,
      filter = this.filterCurrent,
      params = {}
    ) {
      const clearSort = this.$queryParams.getClear(sort);
      const clearFilter = this.$queryParams.getClear(filter, true, true);
      if (this.loading) return;
      this.loading = true;
      const { list, pagination, success } = await this.$api.getPayments({
        pagination: { pageSize: PAGE_SIZE, page: page || this.pageCurrent },
        sort: clearSort,
        filters: clearFilter,
        ...params,
      });
      if (success) {
        this.filterCurrentCopy = this.$copyObject(this.filterCurrent);
        this.list = list;
        this.pageCurrent = pagination.page;
        this.pageCount = pagination.pageCount;

        this.sort = {
          ...this.$queryParams.resetParams(this.sort),
          ...clearSort,
        };

        this.$router.push({
          query: {
            page: this.pageCurrent,
            sort: this.$queryParams.stringify(clearSort),
            filter: this.$queryParams.stringify(clearFilter),
          },
        });
      }
      this.loading = false;
    },
    async sortTable(params) {
      this.$queryParams.reverseParams(params);
      await this.getPayments(this.pageCurrent, params);
    },
    changeDropdown(key, name) {
      switch (name) {
        case "order_number":
          this.filterCurrent.order.number = key;
          break;
        case "documentValidToDate":
          this.filterCurrent.documentValidToDate = key;
          break;
        default:
          break;
      }
    },
    onInputFilterDate(value) {
      this.filterCurrent.documentValidToDate = value;
    },
    async submitFilter() {
      await this.getPayments(1);
    },
    async resetFilter() {
      this.$queryParams.resetParams(this.filterCurrent);
      this.formKey = "form_" + this.$rnd(0, 100);
      await this.getPayments(1);
    },
    async removeFilterTag(id) {
      this.changeDropdown(null, id);
      await this.getPayments();
    },
  },
  async created() {
    this.filterCurrentCopy = this.$copyObject(this.filterCurrent);
    await this.getPreList();
    await this.getPayments();
  },
  mounted() {
    this.emitter.on("PageFilter:show", () => {
      this.filterCurrent = this.$copyObject(this.filterCurrentCopy);
    });
  },
};
</script>

<style lang="scss" scoped src="./component.scss"></style>
