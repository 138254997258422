<template>
  <div class="CheckBoxWrap" ref="parent" :class="{ toggle, disabled }">
    <label
      class="CheckBox noTap"
      :class="[{ error: errorNow, active: input, disabled }]"
    >
      <span
        class="icon"
        :class="['type_' + inputType, { error: errorNow, toggle }]"
      >
        <CheckSVG v-show="input" v-if="!toggle" />
      </span>
      <input
        v-model="input"
        :type="inputType"
        ref="input"
        v-bind:name="name"
        v-if="!disabled"
      />
      <span v-if="text" class="text">{{ text }}</span>
    </label>
    <span
      class="errorText"
      v-if="(error && errorText) || (error?.error && error?.text)"
      >{{ errorText }}</span
    >
  </div>
</template>

<script>
import CheckSVG from "@/assets/img/check.svg";
/**
 * The CheckBox component.
 */
export default {
  name: "CheckBox",
  components: { CheckSVG },
  props: {
    /**
     * Error state
     */
    error: { default: false },
    /**
     * Error text
     */
    errorText: { default: null, type: String },
    /**
     * Conponent name
     */
    name: { default: null, type: String },
    /**
     * Type of input element
     * @values radio, checkbox
     */
    inputType: { default: "checkbox" },
    /**
     * Checked state on init
     */
    checked: { default: false, type: Boolean },
    /**
     * The text for the label
     */
    text: { default: null, type: String },
    /**
     * iphone toggle view
     */
    toggle: { default: false, type: Boolean },
    /**
     * disabled state
     */
    disabled: { default: false, type: Boolean },
  },
  data() {
    return {
      input: this.$props.checked,
    };
  },
  computed: {
    errorNow() {
      return typeof this.error === "object" ? this.error.error : this.error;
    },
  },
  watch: {
    input(e) {
      /**
       * Emit input value.
       */
      console.log("input", this.input);
      this.$emit("onInput", this.input, e, this.$props.name);
    },
  },
};
</script>

<style lang="scss" scoped src="./component.scss"></style>
