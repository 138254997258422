<template>
  <tr @click="click">
    <td>
      <span class="document" v-if="number">{{ number }}</span>
    </td>
    <td>
      <span v-if="totalValue"
        >{{ totalValue }} {{ $translate('t', 'orders') }}</span
      >
    </td>
    <td>
      <span v-if="deliveryTerms">{{ deliveryTerms }}</span>
    </td>
    <td>
      <Tag
        v-if="status"
        :text="status"
        :style="statusColor ? `border-color:${statusColor}` : ''"
      />
    </td>
    <td>
      <ul v-if="quotas && quotas.length" class="list">
        <li
          class="flex alignCenter"
          :key="item.id || i"
          v-for="(item, i) in quotas"
        >
          <Tag class="primary small" :text="parseQuotTxt(item)" />
          <!-- <span class="option">+3</span> -->
          <Notice>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis
              maxime, ducimus ipsa accusamus perferendis dolor quis vel alias.
              Rem maxime exercitationem quisquam aliquid non vero magnam
              molestiae. Expedita, necessitatibus culpa.
            </p>
          </Notice>
        </li>
      </ul>
    </td>
    <td>
      <Dropdown ref="dropdown">
        <TableActions :data="row" />
      </Dropdown>
    </td>
  </tr>
</template>

<script>
import Tag from '@/components/Table/PageTable/Tag';
import Dropdown from '@/components/Table/PageTable/Dropdown';
import Notice from '@/components/Notice';
import TableActions from '@/components/OrderComponents/TableActions';
/**
 * The DocumentsTableItem component.
 */
export default {
  name: 'TransportInstructionsTableItem',
  components: { Tag, Dropdown, Notice, TableActions },
  props: {
    row: { default: () => {}, type: Object },
  },
  computed: {
    number() {
      return this.row?.number;
    },
    quantity() {
      return this.row?.quantity;
    },
    prodName() {
      return this.row?.order?.product?.name;
    },
    totalValue() {
      return [this.prodName, this.quantity].filter((item) => item).join(', ');
    },
    status() {
      return this.row?.status?.name;
    },
    statusColor() {
      return this.row?.status?.labelColor;
    },
    quotas() {
      return this.row?.quotas;
    },
    deliveryTerms() {
      const condition = this.row?.deliveryCondition?.name;
      const shipmentType = this.row?.shipmentType?.name;
      return [condition, shipmentType].filter((item) => item).join(', ');
    },
  },
  methods: {
    parseQuotTxt({ date = null, quantity = null }) {
      const dates = this.$moment(date).format('DD.MM.YY');
      const qan = quantity
        ? `${quantity} ${this.$translate('t', 'orders')}`
        : null;
      return [dates, qan].filter((item) => item).join(' - ');
    },
    click(e) {
      try {
        const cancelClass = this.$refs.dropdown
          ? this.$refs.dropdown.$el.classList.toString()
          : '.Dropdown';
        let cancelTarget = e.target.closest(`.${cancelClass}`);
        if (cancelTarget) return null;
        return this.goIn();
      } catch (e) {
        console.log(e);
      }
    },
    goIn() {
      const tiID = this.row?.id || null;
      if (!tiID) return null;
      return this.$router.push({
        name: 'my-order-transport-instruction',
        params: { tiID },
      });
    },
  },
};
</script>

<style lang="scss" scoped src="./component.scss"></style>
