<template>
  <div class="DisplayViewType">
    <div
      class="item grid"
      @click="$emit('change', 'grid')"
      :class="{ active: activeType === 'grid' }"
    >
      <GridSVG />
    </div>
    <div
      class="item list"
      @click="$emit('change', 'list')"
      :class="{ active: activeType === 'list' }"
    >
      <ListSVG />
    </div>
  </div>
</template>

<script>
import GridSVG from "@/assets/img/grid.svg";
import ListSVG from "@/assets/img/list.svg";
/**
 * The DisplayViewType component.
 */
export default {
  name: "DisplayViewType",
  components: { GridSVG, ListSVG },
  props: {
    activeType: { default: null, type: String },
  },
  data() {
    return {};
  },
  computed: {
    // ...mapGetters(['IS_Tablet']),
  },
  methods: {
    // ...mapActions(['GET_PaymentInfo']),
  },
};
</script>

<style lang="scss" scoped src="./component.scss"></style>
