<template>
  <div class="Manager" :class="{ minimized: GET_MinimizedSidebar }">
    <transition name="fade">
      <h6 v-if="!GET_MinimizedSidebar">{{ $translate("your_manager") }}</h6>
    </transition>
    <!-- <div class="icon"> -->
      <!-- <img src="@/assets/img/manager.jpg" :alt="name" /> -->
      <!-- <LogoSVG /> -->
    <!-- </div> -->
    <transition name="fade">
      <div class="wrap" v-if="!GET_MinimizedSidebar">
        <!-- <p class="name">{{ $translate("your_manager_name") }}</p> -->
        <div class="contacts">
          <a href="#" class="message simpleLink icon-link"><MessageSVG /></a>
          <a :href="'tel:' + supportPhone" v-if="supportPhone" class="phone simpleLink icon-link"
            ><PhoneSVG
          /></a>
          <a
            :href="$translate('your_manager_telegram')"
            target="_blank"
            v-if="$translate('your_manager_telegram')"
            class="phone simpleLink icon-link"
            ><TelegramSVG
          /></a>
        </div>
        <div class="content">
          <a
            :href="'mailto:' + supportEmail"
            v-if="supportEmail"
            class="email simpleLink"
            >{{ supportEmail }}</a
          >
          <p
            class="text"
            v-if="$translate('your_manager_text')"
            v-html="$translate('your_manager_text')"
          ></p>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
import { mapGetters } from "vuex";
// import LogoSVG from "@/assets/img/logo_simple.svg";
/**
 * The Manager component.
 */
export default {
  name: "ManagerComponent",
  components: {
    // LogoSVG,
    // eslint-disable-next-line
    PhoneSVG: defineAsyncComponent(() => import("@/assets/img/phone.svg")),
    // eslint-disable-next-line
    MessageSVG: defineAsyncComponent(() => import("@/assets/img/message.svg")),
    // eslint-disable-next-line
    TelegramSVG: defineAsyncComponent(() =>
      import("@/assets/img/telegram.svg")
    ),
  },
  props: {
    minimize: { default: false, type: Boolean },
  },
  computed: {
    ...mapGetters(["GET_MinimizedSidebar", "GET_SidebarContacts"]),
    supportEmail() {
      return this.GET_SidebarContacts?.supportEmail;
    },
    supportPhone() {
      return this.GET_SidebarContacts?.supportPhone;
    },
  },
  created() {

  },
};
</script>

<style lang="scss" scoped src="./component.scss"></style>
