<template>
  <div class="transport-instructions shadowX-box">
    <h4 class="small title">
      {{ $translate('transport_instructions', 'orders') }}
    </h4>
    <ul class="list">
      <!-- STATUS -->
      <li>
        <div class="name">
          <span>{{ $translate('status', 'companies') }}</span>
        </div>
        <ul v-if="instructions && instructions.length" class="val">
          <li class="marg--bot--6" :key="i" v-for="(item, i) in instructions">
            <router-link
              class="ti-link"
              :to="{
                name: 'my-order-transport-instructions',
                query: { filter: `number=${item.number}` },
              }"
            >
              <div class="ti marg--right--8" v-if="item.number">
                {{ item.number }}
              </div>
              <Tag
                v-if="item.status && item.status.name"
                class="small"
                :text="item.status.name"
                :style="
                  item.status.labelColor
                    ? `border-color:${item.status.labelColor}`
                    : ''
                "
              />
            </router-link>
          </li>
        </ul>
        <Tag
          v-else
          class="small gray"
          :text="$translate('awaiting_payment', 'orders')"
        />
      </li>
      <!-- total quantity -->
      <li v-if="totalQuantity">
        <div class="name">
          <span>{{ $translate('total_volume', 'orders') }}</span>
        </div>
        <div class="val">
          <b> {{ totalQuantity }} {{ $translate('t', 'orders') }}</b>
        </div>
      </li>
      <!-- Preferred delivery dates -->
      <li v-if="gquotas && gquotas.length" class="marg--bot--14">
        <div class="name">
          <span>{{ $translate('preferred_delivery_dates', 'orders') }}</span>
        </div>
        <div class="val flex alignCenter wrap">
          <Tag
            type="primary"
            :key="gquotas[0]?.id"
            class="small"
            :text="parseQuote(gquotas[0])"
          />
          <span v-show="gquotas.length > 1" class="small-txt">+{{ gquotas.length - 1 }}</span>
          <Tippy
            class="marg--left--3"
            :text="$translate('transportInstruction_gquotas', 'orders')"
          />
        </div>
      </li>
      <li v-if="canEditQuota">
        <MyButton
          class="w100"
          type="outline-yellow"
          tag="button"
          :text="$translate('edit_delivery_dates', 'orders')"
          :dynamicSize="true"
          @click="$popup.show('DeliverySchedule')"
        />
      </li>
      <li class="marg--bot--14">
        <div class="name">
          <span>{{ $translate('shipment_status', 'orders') }}</span>
        </div>
        <div class="val">
          <DeliveryStatus
            v-if="gshipmentProgress && gshipmentProgress.quantity != 0"
            :quantity="gshipmentProgress.quantity"
            :quantityInTransit="gshipmentProgress.quantityInTransit"
            :quantityOnStation="gshipmentProgress.quantityOnStation"
            :quantityShipped="gshipmentProgress.quantityShipped"
            :quantityInStock="gshipmentProgress.quantityInStock"
          />
          <div class="mocprogress-bar" v-else>100%</div>
        </div>
      </li>
      <li>
        <MyButton
          class="w100"
          :class="{ gray_400: !instructions || !instructions.length }"
          type="primary"
          tag="router-link"
          :text="$translate('view_TI_details', 'orders')"
          :dynamicSize="true"
          :to="{ name: 'my-order-transport-instructions' }"
        />
      </li>
    </ul>
  </div>
</template>
<script>
import Tag from '@/components/Table/PageTable/Tag';
import Tippy from '@/components/Tippy';
import DeliveryStatus from '@/components/DeliveryStatus';

export default {
  name: 'TransportInstructions',
  components: {
    Tag,
    Tippy,
    DeliveryStatus,
  },
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    instructions() {
      return this.data?.transportInstructions;
    },
    totalQuantity() {
      return this.data?.shipmentProgress?.quantity;
    },
    gquotas() {
      return this.data?.quotas;
    },
    gshipmentProgress() {
      return this.data?.shipmentProgress;
    },
    canEditQuota() {
      return this.data?.actions?.editQuota;
    },
  },
  methods: {
    parseQuote({ date = null, quantity = null } = {}) {
      if (!date || !quantity) return '';
      return `${this.$moment(new Date(date)).format(
        'DD.MM.YY'
      )} - ${quantity} ${this.$translate('t', 'orders')}`;
    },
  },
};
</script>
<style scoped src="./style.scss" lang="scss"></style>
