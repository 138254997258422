<template>
  <div class="Accordion" :class="{ open }">
    <div class="content" ref="content">
      <div class="wrap">
        <slot></slot>
      </div>
    </div>
    <div class="top" @click="click">
      <span>{{ open ? titleActive : title }}</span>
      <ArrowSVG />
    </div>
  </div>
</template>

<script>
import ArrowSVG from "@/assets/img/arrow_bottom.svg";
/**
 * The CheckoutAccordion component.
 */
export default {
  name: "CheckoutAccordion",
  components: { ArrowSVG },
  props: {
    /**
     * Title of the accordion.
     */
    title: { default: null, type: String },
    /**
     * Title active state of the accordion.
     */
    titleActive: { default: null, type: String },
    openOnInit: { default: false, type: Boolean },
  },
  data() {
    return {
      open: false,
      disabled: false,
    };
  },
  methods: {
    click(e, time = 0.25) {
      if (this.disabled) return;
      this.disabled = true;
      this.open = !this.open;
      this.$slideAccordion(
        this.$refs.content,
        this.open,
        () => {
          this.disabled = false;
        },
        time
      );
    },
  },
  mounted() {
    if (this.openOnInit) this.click(null, 0);
  },
};
</script>

<style lang="scss" scoped src="./component.scss"></style>
