<template>
  <PageTable :loading="loading">
    <template v-slot:thead>
      <th>
        <span
          @click="emit('name')"
          class="controll"
          :class="[
            sortStatus && active == 'name' ? 'desc' : 'asc',
            active == 'name' ? 'active' : '',
          ]"
        >
          {{ $translate('farm_name', 'companies') }}
          <ArrowSVG />
        </span>
      </th>
      <th>
        <span
          @click="emit('region')"
          class="controll"
          :class="[
            sortStatus && active == 'region' ? 'desc' : 'asc',
            active == 'region' ? 'active' : '',
          ]"
        >
          {{ $translate('region', 'companies') }}
          <ArrowSVG />
        </span>
      </th>
      <th>
        <span
          @click="emit('cropArea')"
          class="controll"
          :class="[
            sortStatus && active == 'cropArea' ? 'desc' : 'asc',
            active == 'cropArea' ? 'active' : '',
          ]"
        >
          {{ $translate('area_ga', 'companies') }}
          <ArrowSVG />
        </span>
      </th>
      <th>
        <span
          @click="emit('company')"
          class="controll"
          :class="[
            sortStatus && active == 'company' ? 'desc' : 'asc',
            active == 'company' ? 'active' : '',
          ]"
        >
          {{ $translate('legal_entity', 'companies') }}
          <ArrowSVG />
        </span>
      </th>
      <th>
      </th>
    </template>
    <template v-slot:default>
      <RowItem
        v-for="item in list"
        @remove="$emit('removeItem', item.id)"
        :key="item.id"
        :row="item"
      />
    </template>
  </PageTable>
</template>

<script>
import RowItem from './Item';
import PageTable from '../PageTable';
import ArrowSVG from '@/assets/img/arrow_top.svg';
/**
 * The AreasTable component.
 */
export default {
  name: 'AreasTable',
  components: { RowItem, PageTable, ArrowSVG },
  props: {
    list: { default: () => [], type: Array },
    loading: { default: false, type: Boolean },
    sortStatus: {
      type: Boolean,
      default: false,
    },
    active: {
      type: String,
      default: '',
    },
  },
  methods: {
    emit(key) {
      this.$emit('toggleSort', key);
    },
  },
};
</script>

<style lang="scss" scoped src="./component.scss"></style>
