<template>
  <LayoutLogin>
    <LoginGrid
      :title="$translate('login_in_office', 'auth')"
      :text="$translate('login_in_office_text', 'auth')"
    >
      <h2>{{ $translate("login") }}</h2>
      <div class="formWrap">
        <LoadingIcon v-if="loading" :absolute="true" />
        <form
          novalidate
          @submit.prevent="submit"
          :class="{ loadingElem: loading }"
          :key="formKey"
        >
          <Label
            :title="$translate('phone_number', 'auth')"
            name="phone"
            placeholder="+38 093 000 00 00"
            inputType="tel"
            @onInput="onInputPhone"
            :error="phoneError"
            :errorText="phoneErrorText"
            ref="phone"
            autocomplete="tel"
            mask="+38 ### ### ## ##"
          />
          <Label
            :title="$translate('password', 'auth')"
            name="password"
            :placeholder="$translate('password', 'auth')"
            inputType="password"
            @onInput="onInputPassword"
            :error="passwordError"
            :errorText="passwordErrorText"
            ref="password"
          />
          <MyButton
            class="w100"
            :text="$translate('login_system', 'auth')"
            tag="button"
            :dynamicSize="true"
          />
        </form>
      </div>

      <div class="linkWrap">
        <router-link :to="{ name: 'password-recovery' }">{{
          $translate("forgot_password", "auth")
        }}</router-link>
      </div>
      <div class="linkWrap">
        <p>{{ $translate("dont_have_account", "auth") }}</p>
        <router-link :to="{ name: 'registration' }">
          {{ $translate("registration") }}
        </router-link>
      </div>
    </LoginGrid>
  </LayoutLogin>
</template>

<script>
import LayoutLogin from "@/layouts/Login.vue";
import LoginGrid from "@/components/LoginGrid";
/**
 * The login-page component.
 */
export default {
  name: "LoginPage",
  components: { LayoutLogin, LoginGrid },
  data() {
    return {
      formKey: "initForm",
      loading: false,
      phone: "",
      password: "",
      phoneError: false,
      phoneErrorText: "",
      passwordError: false,
      passwordErrorText: "",
    };
  },
  methods: {
    onInputPhone(value = this.phone, e, required = false) {
      this.phone = value;
      const phoneError = this.$simpleInputValidate(value, required, "tel");
      this.phoneError = phoneError.error;
      if (phoneError.error) {
        this.phoneErrorText = phoneError.text;
        this.$refs.phone.$refs.input.focus();
      }
      return phoneError.error;
    },
    onInputPassword(value = this.password, e, required = false) {
      this.password = value;
      const passwordError = this.$simpleInputValidate(
        value,
        required,
        "password"
      );
      this.passwordError = passwordError.error;
      if (passwordError.error) {
        this.passwordErrorText = passwordError.text;
        this.$refs.password.$refs.input.focus();
      }
      return passwordError.error;
    },
    resetForm() {
      this.loading = false;
      this.phone = "";
      this.password = "";
      this.phoneError = false;
      this.passwordError = false;
      this.formKey = "form_" + this.$rnd(0, 100);
    },
    success() {
      this.resetForm();
      this.$router.push({ name: "dashboard" });
    },
    async submit() {
      if (this.loading) return false;

      const error = (() => {
        const errorPhone = this.onInputPhone(undefined, undefined, true);
        const errorPassword = this.onInputPassword(undefined, undefined, true);
        return errorPhone || errorPassword;
      })();
      if (!error) {
        this.loading = true;

        const { phone, password } = this;
        const { success, message, name } = await this.$auth.login({
          phone: phone.replace(/ /g, ""),
          password,
        });
        this.loading = false;
        if (success) {
          this.success();
        } else {
          this.phoneError = true;
          this.passwordError = true;
          if (name === "ValidationError") {
            this.phoneErrorText = this.$translate(
              "invalid_phone_or_password",
              "auth"
            );
            this.passwordErrorText = this.$translate(
              "invalid_phone_or_password",
              "auth"
            );
          } else {
            this.phoneErrorText = message;
            this.passwordErrorText = message;
          }
          this.$refs.email.$refs.input.focus();
        }
      }
    },
  },
};
</script>
