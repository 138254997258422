<template>
  <div class="iconBox" :class="pos">
    <div class="icon" :class="name">
      <component :is="insertSvg" />
    </div>
  </div>
</template>
<script>
import FinishSVG from '@/assets/img/cargo/finish.svg';
import CarSVG from '@/assets/img/cargo/car.svg';
import WagonSVG from '@/assets/img/cargo/wagon.svg';
import DefaultSVG from '@/assets/img/cargo/default.svg';
export default {
  name: 'CargoIcon',
  components: {
    FinishSVG,
    CarSVG,
    WagonSVG,
    DefaultSVG,
  },
  props: {
    name: {
      type: String,
      default: '',
    },
    pos: {
      type: String,
      default: '',
    },
  },
  computed: {
    insertSvg() {
      switch (this.name) {
        case 'finish':
          return `FinishSVG`;
        case 'car':
          return `CarSVG`;
        case 'wagon':
          return `WagonSVG`;
        default:
          return `DefaultSVG`;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.iconBox {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  &::before {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: toRem(1);
    background: $orange;
    top: 50%;
    transform: translateY(-50%);
  }
  &.end {
    justify-content: flex-end;
  }
  &.center {
    justify-content: center;
  }
}
.icon {
  position: relative;
  width: toRem(34);
  height: toRem(34);
  border-radius: 50%;
  background: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 5px 5px 10px 0px rgba(166, 171, 189, 0.3),
    -5px -5px 20px 0px rgba(255, 255, 255, 0.8);

  ::v-deep(svg) {
    object-fit: contain;
    object-position: center;
    width: toRem(16);
    height: toRem(16);
  }
  &.finish {
    ::v-deep(svg) {
      width: toRem(13);
      height: toRem(16);
    }
  }
  &.wagon {
    ::v-deep(svg) {
      width: toRem(26);
      height: toRem(26);
    }
  }
  &.car {
    ::v-deep(svg) {
      width: toRem(19);
      height: toRem(16);
    }
  }
}
</style>
