import { createApp } from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';

import functions from './plugins/functions.js';

const app = createApp(App);
app.use(store);
app.use(router);
window.$app = app;

import { createHead, Head } from '@vueuse/head';
app.use(createHead());

app.component('MetaTags', Head);

import Button from '@/components/MyButton';
// eslint-disable-next-line
app.component('MyButton', Button); // global registration - can be used anywhere

import LoadingIcon from '@/components/LoadingIcon';
app.component('LoadingIcon', LoadingIcon); // global registration - can be used anywhere

import Label from '@/components/Label';
// eslint-disable-next-line
app.component('Label', Label); // global registration - can be used anywhere
import LabelDropdown from '@/components/LabelDropdown';
app.component('LabelDropdown', LabelDropdown); // global registration - can be used anywhere
import LabelDropdownMulty from '@/components/LabelDropdownMulty';
app.component('LabelDropdownMulty', LabelDropdownMulty); // global registration - can be used anywhere
// init custom plugins
functions(app, store, router);

store.dispatch('GET_Translations', app).then(() => {
  app.mount('#app');
});
