<template>
  <LayoutLogin>
    <LoginGrid
      :title="$translate('forgot_password', 'auth')"
      :text="$translate('lets_restore_access', 'auth')"
    >
      <h2>{{ $translate("password_recovery", "auth") }}</h2>
      <div class="formWrap">
        <LoadingIcon v-if="loading" :absolute="true" />
        <form
          novalidate
          @submit.prevent="submit"
          :class="{ loadingElem: loading }"
          :key="formKey"
        >
          <Label
            :title="$translate('phone_number', 'auth')"
            name="phone"
            placeholder="+38 093 000 00 00"
            inputType="tel"
            @onInput="onInputField"
            :error="inputError['phone']"
            ref="phone"
            autocomplete="tel"
            mask="+38 ### ### ## ##"
          />
          <MyButton
            class="w100"
            :text="$translate('recover_password', 'auth')"
            tag="button"
            :dynamicSize="true"
          />
        </form>
      </div>

      <!-- <div class="linkWrap">
        <router-link :to="{ name: 'password-recovery' }">{{
          $translate("forgot_password", "auth")
        }}</router-link>
      </div> -->
      <div class="linkWrap">
        <p>{{ $translate("dont_have_account", "auth") }}</p>
        <router-link :to="{ name: 'registration' }">
          {{ $translate("registration") }}
        </router-link>
      </div>
    </LoginGrid>
  </LayoutLogin>
</template>

<script>
import LayoutLogin from "@/layouts/Login.vue";
import LoginGrid from "@/components/LoginGrid";
/**
 * The password-recovery-page component.
 */
export default {
  name: "PasswordRecoveryPage",
  components: { LayoutLogin, LoginGrid },
  data() {
    return {
      formKey: "initForm",
      loading: false,
      inputError: {},
      phone: "",
    };
  },
  methods: {
    onInputEmail(value = this.email, e, required = false) {
      this.email = value;
      const emailError = this.$simpleInputValidate(value, required, "email");
      this.emailError = emailError.error;
      if (emailError.error) {
        this.emailErrorText = emailError.text;
        this.$refs.email.$refs.input.focus();
      }
      return emailError.error;
    },
    resetForm() {
      this.loading = false;
      this.phone = "";
      this.phoneError = false;
      this.formKey = "form_" + this.$rnd(0, 100);
    },
    success() {
      this.resetForm();
      localStorage.setItem("canPasswordRecovery", true);
      this.$router.push({ name: "password-recovery-create" });
    },
    async submit() {
      if (this.loading) return false;

      const error = (() => {
        const errorPhone = this.onInputField(
          undefined,
          undefined,
          "phone",
          true,
          "tel"
        );

        return errorPhone;
      })();
      if (!error) {
        this.loading = true;
        const { success, message } = await this.$auth.forgotPassword({
          phone: this.phone.replace(/ /g, ""),
        });
        this.loading = false;
        if (success) {
          this.success();
        } else {
          this.phoneError = true;
          this.phoneErrorText = message;
        }
      }
    },
    onInputField(value, $event, name, required = false, validateType = "name") {
      if (!name) throw new Error("onInputField: name is undefined");
      if (value != undefined) this[name] = value;
      const validateError = this.$simpleInputValidate(
        value || this[name],
        required,
        validateType
      );
      this.inputError[name] = validateError;
      if (validateError.error) {
        this.$refs[name].$refs.input.focus();
      }
      return validateError.error;
    },
  },
};
</script>
