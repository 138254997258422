import translate from './translate'
export default (key) => {
    let text = false;
    switch (key) {
        case "logistics_notice":
            text = translate("logistics_notice", "orders");
            break;
        case "delivery_volume_notice":
            text = translate("delivery_volume_notice", "orders");
            break;
        case "delivery_schedule_notice":
            text = translate("delivery_schedule_notice", "orders");
            break;
        case "payment_type_notice":
            text = translate("payment_type_notice", "orders");
            break;
        case "payment_form_notice":
            text = translate("payment_form_notice", "orders");
            break;
        default:
            break;
    }
    if (text === "Field not found" || text === 'Key not found') return null;
    return text;
}