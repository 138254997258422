<template>
  <LayoutDashboard name="documents">
    <LoadingIcon v-if="loading" :absolute="true" />
    <template v-if="document">
      <DocumentCard :document="document" />
      <div class="RelatedDocuments" v-if="list.length">
        <DashboardTitleRow
          :title="$translate('related_documents', 'documents')"
          titleTag="h2"
          titleTagClass="h4"
        />
        <RelatedDocumentsTable :list="list" />
      </div>
    </template>
  </LayoutDashboard>
</template>

<script>
import LayoutDashboard from '@/layouts/Dashboard.vue';
import DashboardTitleRow from '@/components/DashboardTitleRow';
import RelatedDocumentsTable from '@/components/Table/RelatedDocuments';
import DocumentCard from '@/components/DocumentCard';
/**
 * The documents-item-page component.
 */
export default {
  name: 'DocumentsItemPage',
  components: {
    LayoutDashboard,
    DashboardTitleRow,
    DocumentCard,
    RelatedDocumentsTable,
  },
  data() {
    return {
      list: [],
      id: this.$route.params.id,
      loading: false,
      document: null,
    };
  },
  methods: {
    async getDocument() {
      if (this.loading) return false;
      this.loading = true;
      const { success, data } = await this.$api.document.getByCRM(this.id);
      this.loading = false;
      if (success) {
        this.document = data;
        this.list = data?.relatedDocuments || [];
      } else {
        this.$router.push({ name: '404' });
      }
    },
  },
  async created() {
    await this.getDocument();
  },
};
</script>

<style lang="scss" scoped src="./component.scss"></style>
