<template>
  <LayoutLogin>
    <LoginGrid
      :title="$translate('register_in_office', 'auth')"
      :text="$translate('register_in_office_text', 'auth')"
      class="bigBox"
    >
      <h2>{{ $translate("registration") }}</h2>
      <p>* {{ $translate("required_fields") }}</p>

      <div class="formWrap">
        <LoadingIcon v-if="loading" :absolute="true" />
        <form
          novalidate
          @submit.prevent="submit"
          :class="{ loadingElem: loading }"
          :key="formKey"
        >
          <div class="row margin47">
            <div class="column w50 mobilew100">
              <Label
                :title="$translate('first_last_name', 'auth') + '*'"
                name="name"
                :placeholder="$translate('first_last_name_example', 'auth')"
                inputType="text"
                @onInput="onInputName"
                :error="nameError"
                :errorText="nameErrorText"
                ref="name"
                autocomplete="name"
              />
              <Label
                :title="$translate('phone_number', 'auth') + '*'"
                name="phone"
                placeholder="+38 093 000 00 00"
                inputType="tel"
                @onInput="onInputPhone"
                :error="phoneError"
                :errorText="phoneErrorText"
                ref="phone"
                autocomplete="tel"
                mask="+38 ### ### ## ##"
              />
              <Label
                :title="$translate('password', 'auth') + '*'"
                name="password"
                :placeholder="$translate('password', 'auth')"
                inputType="password"
                @onInput="onInputPassword"
                :error="passwordError"
                :errorText="passwordErrorText"
                ref="password"
                autocomplete="new-password"
              />
            </div>
            <div class="column w50 mobilew100">
              <Label
                :title="$translate('birth_date', 'auth')"
                name="birthDate"
                inputType="datepicker"
                @onInput="onInputBirthDate"
                ref="birthDate"
              />
              <Label
                :title="$translate('email', 'auth') + '*'"
                name="email"
                :placeholder="$translate('email_example', 'auth')"
                inputType="email"
                @onInput="onInputEmail"
                :error="emailError"
                :errorText="emailErrorText"
                ref="email"
              />
              <Label
                :title="$translate('enter_password_again', 'auth') + '*'"
                name="passwordConfirmation"
                :placeholder="$translate('password', 'auth')"
                inputType="password"
                @onInput="onInputPasswordConfirmation"
                :error="passwordConfirmationError"
                :errorText="passwordConfirmationErrorText"
                ref="passwordConfirmation"
                autocomplete="new-password"
              />
            </div>
          </div>
          <div class="CheckBoxWrap">
            <CheckBox
              name="agree_offer_agreement"
              :text="$translate('agree_offer_agreement', 'auth')"
              @onInput="onInputrulesConfirmation"
              :error="rulesConfirmationError"
              :errorText="rulesConfirmationErrorText"
              ref="agree_offer_agreement"
            />
            <CheckBox
              name="agree_notifications_email"
              :text="$translate('agree_notifications_email', 'auth')"
              @onInput="onInputemailNotifications"
              :error="emailNotificationsError"
              :errorText="emailNotificationsErrorText"
              ref="agree_notifications_email"
            />
            <CheckBox
              name="agree_notifications_phone"
              :text="$translate('agree_notifications_phone', 'auth')"
              @onInput="onInputphoneNotifications"
              :error="phoneNotificationsError"
              :errorText="phoneNotificationsErrorText"
              ref="agree_notifications_phone"
            />
          </div>
          <div class="row margin47 bottomRow">
            <div class="w50 mobilew100">
              <MyButton
                class="w100"
                :text="$translate('send')"
                tag="button"
                :dynamicSize="true"
              />
            </div>
          </div>
        </form>
      </div>
    </LoginGrid>
  </LayoutLogin>
</template>

<script>
import LayoutLogin from "@/layouts/Login.vue";
import LoginGrid from "@/components/LoginGrid";
import CheckBox from "@/components/CheckBox";
/**
 * The registration-page component.
 */
export default {
  name: "RegistrationPage",
  components: { LayoutLogin, LoginGrid, CheckBox },
  data() {
    return {
      formKey: "initForm",
      loading: false,
      name: "",
      nameError: false,
      nameErrorText: "",
      phone: "",
      phoneError: false,
      phoneErrorText: "",
      birthDate: "",
      email: "",
      emailError: false,
      emailErrorText: "",
      password: "",
      passwordError: false,
      passwordErrorText: "",
      passwordConfirmation: "",
      passwordConfirmationError: false,
      passwordConfirmationErrorText: "",
      rulesConfirmation: false,
      rulesConfirmationError: false,
      rulesConfirmationErrorText: "",
      emailNotifications: false,
      emailNotificationsError: false,
      emailNotificationsErrorText: "",
      phoneNotifications: false,
      phoneNotificationsError: false,
      phoneNotificationsErrorText: "",
      picked: "",
    };
  },
  methods: {
    onInputEmail(value = this.email, e, required = false) {
      this.email = value;
      const emailError = this.$simpleInputValidate(value, required, "email");
      this.emailError = emailError.error;
      if (emailError.error) {
        this.emailErrorText = emailError.text;
        this.$refs.email.$refs.input.focus();
      }
      return emailError.error;
    },
    onInputName(value = this.name, e, required = false) {
      this.name = value;
      const nameError = this.$simpleInputValidate(
        value,
        required,
        "name_surname"
      );
      this.nameError = nameError.error;
      if (nameError.error) {
        this.nameErrorText = nameError.text;
        this.$refs.name.$refs.input.focus();
      }
      return nameError.error;
    },
    onInputBirthDate(value = this.birthDate) {
      this.birthDate = value;
    },
    onInputPhone(value = this.phone, e, required = false) {
      this.phone = value;
      const phoneError = this.$simpleInputValidate(value, required, "tel");
      this.phoneError = phoneError.error;
      if (phoneError.error) {
        this.phoneErrorText = phoneError.text;
        this.$refs.phone.$refs.input.focus();
      }
      return phoneError.error;
    },
    onInputPassword(value = this.password, e, required = false) {
      this.password = value;
      const passwordError = this.$simpleInputValidate(
        value,
        required,
        "password"
      );
      this.passwordError = passwordError.error;
      if (passwordError.error) {
        this.passwordErrorText = passwordError.text;
        this.$refs.password.$refs.input.focus();
      }
      return passwordError.error;
    },
    onInputPasswordConfirmation(
      value = this.passwordConfirmation,
      e,
      required = false
    ) {
      this.passwordConfirmation = value;
      const passwordConfirmationError = this.$simpleInputValidate(
        value,
        required,
        "password"
      );
      this.passwordConfirmationError = passwordConfirmationError.error;
      if (passwordConfirmationError.error) {
        this.passwordConfirmationErrorText = passwordConfirmationError.text;
        this.$refs.passwordConfirmation.$refs.input.focus();
      }
      return passwordConfirmationError.error;
    },
    onInputrulesConfirmation(value = this.rulesConfirmation) {
      this.rulesConfirmation = value;
    },
    onInputemailNotifications(value = this.emailNotifications) {
      this.emailNotifications = value;
    },
    onInputphoneNotifications(value = this.phoneNotifications) {
      this.phoneNotifications = value;
    },
    isPasswordsEqual() {
      return this.password === this.passwordConfirmation;
    },
    validatePasswordsEqual() {
      const equals = this.isPasswordsEqual();
      if (!equals) {
        this.passwordError = true;
        this.passwordConfirmationError = true;
        this.passwordErrorText = this.$translate("equalPasswords", "auth");
        this.passwordConfirmationErrorText = this.$translate(
          "equalPasswords",
          "auth"
        );
        this.$refs.passwordConfirmation.$refs.input.focus();
      }
      return equals;
    },
    resetForm() {
      this.loading = false;
      this.name = "";
      this.nameError = false;
      this.email = "";
      this.emailError = false;
      this.phone = "";
      this.phoneError = false;
      this.birthDate = "";
      this.password = "";
      this.passwordError = false;
      this.passwordConfirmation = "";
      this.passwordConfirmationError = false;
      this.rulesConfirmation = false;
      this.rulesConfirmationError = false;
      this.emailNotifications = false;
      this.emailNotificationsError = false;
      this.phoneNotifications = false;
      this.phoneNotificationsError = false;

      this.formKey = "form_" + this.$rnd(0, 100);
    },
    success() {
      // this.resetForm();
      this.$router.push({
        name: "registration-send-otp",
        query: {
          phone: this.phone.replace(/ /g, ""),
        },
      });
    },
    async submit() {
      if (this.loading) return false;

      const error = (() => {
        const errorName = this.onInputName(undefined, undefined, true);
        const errorEmail = this.onInputEmail(undefined, undefined, true);
        const errorPhone = this.onInputPhone(undefined, undefined, true);

        this.rulesConfirmationError = !this.rulesConfirmation;
        if (this.rulesConfirmationError) {
          this.rulesConfirmationErrorText = this.$translate(
            "required_field",
            "auth"
          );
        }

        const errorPassword = this.onInputPassword(undefined, undefined, true);
        const errorpasswordConfirmation = this.onInputPasswordConfirmation(
          undefined,
          undefined,
          true
        );

        const mainValidate =
          errorName ||
          errorEmail ||
          errorPhone ||
          this.rulesConfirmationError ||
          errorPassword ||
          errorpasswordConfirmation;

        return (
          mainValidate || (!mainValidate && !this.validatePasswordsEqual())
        );
      })();
      if (!error) {
        this.loading = true;

        const {
          name,
          birthDate,
          phone,
          email,
          password,
          rulesConfirmation,
          emailNotifications,
          phoneNotifications,
        } = this;
        const { success, message, code } = await this.$api.auth.register({
          username: email,
          email: email,
          fullName: name,
          birthDate: (() =>
            birthDate ? this.$moment(birthDate).format("YYYY-MM-DD") : null)(),
          rulesConfirmation,
          emailNotifications,
          phoneNotifications,
          phone: phone.replace(/ /g, ""),
          password,
        });
        this.loading = false;
        if (success) {
          this.success();
        } else {
          if (code === 422) {
            this.phoneError = true;
            this.phoneErrorText = message;
            this.$refs.phone.$refs.input.focus();
          } else if (message.includes("Email")) {
            this.emailError = true;
            this.emailErrorText = this.$translate("email_exists", "auth");
            this.$refs.email.$refs.input.focus();
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.LoginGrid {
  :deep(form .Button) {
    margin-top: toRem(18);
  }
}
</style>
