<template>
  <Layout id="DeleteCarPopUp">
    <h4>{{ $translate('remove_car_ti', 'general') }}</h4>
    <p>{{ $translate('to_do_remove_car_ti', 'general') }}</p>
    <div class="formWrap" :class="{ loading: loading }">
      <MyButton
        @click="submit"
        class="w100"
        :text="$translate('delete', 'general')"
        tag="button"
        :dynamicSize="true"
      />
      <MyButton
        @click="() => $popup.hide()"
        class="w100"
        :text="$translate('go_back', 'general')"
        tag="button"
        tagType="reset"
        type="cancel"
        :dynamicSize="true"
      />
    </div>
    <transition>
      <LoadingIcon v-if="loading" :absolute="true" />
    </transition>
  </Layout>
</template>
<script>
import { mapGetters } from 'vuex';
import Layout from '../../PopupLayout';
export default {
  name: 'DeleteCarPopUp',
  components: {
    Layout,
  },
  data: () => ({
    loading: false,
  }),
  computed: {
    ...mapGetters(['IS_Mobile', 'GET_CurrentPopup', 'GET_PopupData']),
    gData() {
      return this.GET_PopupData?.[this.GET_CurrentPopup];
    },
    TIID() {
      return this.gData?.TIID;
    },
    carID() {
      return this.gData?.id;
    },
    carsList() {
      return this.gData?.cars;
    },
  },
  methods: {
    async submit() {
      try {
        if (this.loading) return null;
        if (this.TIID == null) throw Error('TIID is null');
        if (this.carID == null) throw Error('carID is null');
        this.loading = true;
        const PUT = {};
        const newList = this.carsList.filter((item) => item.id !== this.carID);
        PUT.carInfo = newList;
        const { success = false } = await this.$api.document.putInstruction(
          this.TIID,
          PUT
        );
        if (success) {
          this.$notice(this.$translate('deleted'));
          document.dispatchEvent(new CustomEvent('update-instruction'));
        } else {
          this.$notice(this.$translate('request_error'), undefined, {
            type: 'error',
          });
        }
        this.loading = false;
        this.$popup.hide();
      } catch (e) {
        this.loading = false;
        console.log(e);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
#DeleteCarPopUp {
  .formWrap {
    button {
      margin-bottom: toRem(10);
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
