<template>
  <router-view v-if="isChildrenPage"></router-view>
  <LayoutDashboard name="accompanying-document" v-else>
    <DashboardTitleRow
      class="accompanying-document my-order"
      :title="dashboard_title"
      :titleTag="'h4'"
      :titleTagClass="'h4'"
      :goBack="{
        name: 'my-order-transport-instructions',
      }"
    >
      <template v-slot:title>
        <div class="top-info">
          <Transition mode="out-in">
            <div v-if="!loading">
              <Tag
                v-if="order_status_name"
                class="w100 small"
                :text="order_status_name"
              />
              <span v-if="amount" class="total-sum"
                >{{ $translate('sum', 'general') }}:
                <b>{{ $price(amount, undefined, true) }}</b></span
              >
            </div>
          </Transition>
        </div>
      </template>
    </DashboardTitleRow>
    <PageTabs
      class="marg-big"
      v-if="pageTabsList && pageTabsList.length"
      :list="pageTabsList"
    />
    <Transition mode="out-in">
      <LoadingIcon v-if="loadingTI" />
      <div v-else-if="!loadingTI && instruction" class="myOrder-field marg-bot">
        <div class="main">
          <OpenInstruction :data="instruction" :orderData="orderData" />
        </div>
        <div class="other">
          <VolumeData
            :data="instruction"
            :orderData="orderData"
            :quotas="quotas"
          />
        </div>
      </div>
      <p v-else class="no_data">{{ $translate('no_data') }}</p>
    </Transition>

    <div class="bot">
      <h4 class="marg-bot--20">
        {{ $translate('related_documents', 'documents') }}
      </h4>
      <Transition mode="out-in">
        <LoadingIcon v-if="loadingDocuments" />
        <div
          v-else-if="!loadingDocuments && documentList && documentList.length"
        >
          <AccompanyingDocumentsTable
            :list="documentList"
            :loading="loadingDocuments"
            :date="sort.date"
            @sort="sortTable"
          />
          <Pagination
            @paginate="paginate"
            :pageCurrent="page"
            :pageCount="pageCount"
            :perPage="pageSize"
            :class="{ disabledElem: loadingDocuments }"
          />
        </div>
        <p v-else class="no_data">{{ $translate('no_data') }}</p>
      </Transition>
    </div>
  </LayoutDashboard>
</template>
<script>
import { mapActions } from 'vuex';
import LayoutDashboard from '@/layouts/Dashboard.vue';
import DashboardTitleRow from '@/components/DashboardTitleRow';
import PageTabs from '@/components/PageTabs';
import Tag from '@/components/Table/PageTable/Tag';
import OpenInstruction from '@/components/OrderComponents/OpenInstruction';
import VolumeData from '@/components/OrderComponents/VolumeData';
import AccompanyingDocumentsTable from '@/components/Table/AccompanyingDocuments';
import Pagination from '@/components/Pagination';
//pageTabsList
import pageTabList from '../../pageTabList';

export default {
  name: 'TransportInstruction',
  components: {
    LayoutDashboard,
    DashboardTitleRow,
    PageTabs,
    Tag,
    OpenInstruction,
    VolumeData,
    AccompanyingDocumentsTable,
    Pagination,
  },
  data() {
    return {
      orderData: null,
      pageTabsList: pageTabList(this, 'my-order-transport-instructions'),
      loading: false,
      //TI info
      loadingTI: true,
      instruction: null,

      //documents
      loadingDocuments: true,
      documentList: [],
      filterCurrent: {},
      sort: null,
      pagination: null,
      pageSize: 9,
      page: 1,
      pageCount: null,
      quotas: [],
    };
  },
  computed: {
    isChildrenPage() {
      return this.$route.name !== 'my-order-transport-instruction';
    },
    instructionType() {
      let doc = this.$route.params.doc || '';
      switch (doc.toLocaleLowerCase()) {
        case 'exw':
        case 'exwno':
          return 'exw';
        case 'cptno':
          return 'cpt';
        default:
          return doc.toLocaleLowerCase();
      }
    },
    //new
    dashboard_title() {
      const number = this.orderData?.number || this.$route?.params?.id;
      return `${this.$translate('order', 'orders')} ${number}`;
    },
    order_status_name() {
      return this.orderData?.status?.name;
    },
    amount() {
      return this.orderData?.paymentData?.suAmountVat;
    },
  },
  methods: {
    ...mapActions('checkout', ['SET_Checkout']),
    async getOrder() {
      try {
        this.loading = true;
        const filters = {
          number: this.$route.params.id,
        };
        const { list = [], success = false } = await this.$api.getOrders({
          filters,
        });
        if (success && list.length) {
          const { id } = list.find(({ number }) => number);
          if (!id) throw new Error('Order id not found');
          const { data = {}, success: done = false } =
            await this.$api.checkout.getOrder(id);
          if (done && data) {
            this.orderData = await this.getOrderMoreData(data);
            this.orderDataEmpty = false;
            this.loading = false;
            await this.getTI();
            await this.getDocuments(id);
          }
        } else {
          this.orderDataEmpty = true;
          this.loading = false;
        }
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    async getTI() {
      try {
        this.loadingTI = true;
        const id = this.$route?.params?.tiID;
        if (!id) throw Error(`TI id not is null or undifined`);
        const {
          data: { data: res = {} },
          success = false,
        } = await this.$api.document.getInstruction(id);
        if (success && res) {
          this.instruction = await this.getInstructionMoreData(res);
        } else {
          throw Error(`TI request no success`);
        }
        this.loadingTI = false;
      } catch (e) {
        console.log(e);
        this.loadingTI = false;
      }
    },
    async getOrderMoreData(order = this.orderData) {
      console.log('order', order);
      const deliveryCondition = await this.$api.checkout.getDeliveryCondition(
        order.deliveryCondition.id
      );
      if (deliveryCondition.success) {
        order.deliveryCondition = deliveryCondition.data;
      }
      // set wagon data
      if (order.typeWagon) {
        const wagon = deliveryCondition?.data?.wagons
          ? deliveryCondition.data.wagons.find(
              ({ crmId }) => crmId == order?.typeWagon?.crmId
            )
          : {};
        if (wagon?.wagonNorm?.suWagonNorm)
          wagon.wagonNorm.wagonNorm = wagon.wagonNorm.suWagonNorm;
        order.typeWagon = wagon;
      }
      // get additional data for product
      if (order.product) {
        const { success, data } = await this.$api.product.getProductID(
          order.product.id
        );
        if (success) order.product = data;
      }
      await this.getQuotas(order);

      return order;
    },
    async getQuotas(order = this.orderData) {
      const { list = [] } = await this.$api.checkout.getQuotas({
        filters: {
          product: {
            id: order.product.suGoodWithoutPackaging.id,
          },
          deliveryCondition: {
            id: order.deliveryCondition?.id,
          },
          stock: {
            id: order.storage?.id,
          },
        },
      });
      this.quotas = list;
    },
    async getInstructionMoreData(instruction) {
      return instruction;
    },
    paginate(page) {
      try {
        this.page = page;
        this.getDocuments(this.orderData.id);
      } catch (e) {
        console.log(e);
      }
    },
    async sortTable({ date = null }) {
      try {
        if (date) {
          if (date == 'asc') date = 'desc';
          else if (date == 'desc') date = 'asc';
          this.sort.date = date;
          this.setQueryParams();
        }
        await this.getDocuments(this.orderData.id);
      } catch (e) {
        console.log(e);
      }
    },
    async getDocuments(id) {
      try {
        this.loadingDocuments = true;
        let params = {
          filters: {
            order: { id: id },
          },
          pagination: {
            pageSize: this.pageSize,
            page: this.page,
          },
        };
        if (this.sort) params.sort = this.sort;
        if (this.filterCurrent) {
          if (this.filterCurrent.date) {
            params.filters.date = this.filterCurrent.date
              .split('/')
              .reverse()
              .join('-');
          }
          if (this.filterCurrent.number)
            params.filters.documentNumber = this.filterCurrent.number;
        }
        const {
          list = [],
          success = false,
          pagination = {},
        } = await this.$api.document.getDocuments(params);
        // if (this.allDocuments == null) await this.getAllDocuments(id);
        if (success && list.length) {
          this.documentList = list;
          this.pageCount = pagination.pageCount;
          this.setQueryParams();
        }
        this.loadingDocuments = false;
      } catch (e) {
        console.log(e);
        this.loadingDocuments = false;
      }
    },
    setQueryParams() {
      let object = {};
      if (this.page != null) object.page = this.page;
      if (this.sort)
        object.sort = this.$queryParams.stringify(
          this.$queryParams.getClear(this.sort)
        );
      if (this.filterCurrent)
        object.filter = this.$queryParams.stringify(
          this.$queryParams.getClear(this.filterCurrent, true, true)
        );
      return this.$router.push({
        query: this.$queryParams.getClear(object),
      });
    },
    getQueryParams() {
      this.page = this.$route.query.page || 1;
      const filter = this.$queryParams.parse(this.$route.query.filter);
      const sort = this.$queryParams.parse(this.$route.query.sort);
      this.filterCurrent = {
        date: filter.date
          ? this.$moment(filter.date, 'YYYY-MM-DD').format('DD/MM/YYYY')
          : null,
        number: filter.number || null,
      };
      this.sort = {
        date: sort.date || 'asc',
      };
    },
    async updateOrderQuotas(list) {
      this.orderData.quotas = list;
      //temporary status
      this.orderData.status = 'new';
      //temporary status
      await this.updateOrder();
    },
    async updateOrder(newOrderData = undefined, callBack = () => {}) {
      this.loading = true;
      return setTimeout(async () => {
        this.orderData.deliveryCondition = this.orderData?.deliveryCondition.id;
        const { data, success } = await this.$api.checkout.updateOrder(
          this.orderData.id,
          this.orderData,
          newOrderData,
          false
        );
        if (success) {
          this.orderData = {
            ...this.orderData,
            ...data,
          };
          console.log('updateOrder', success, data);
          await this.$api.document.putInstruction(this.instruction.id, {
            data: {
              ...this.instruction,
              quotas: this.orderData?.quotas,
            },
          })
        }
        await this.getOrderMoreData();
        this.DispatchCheckout();
        this.loading = false;
        callBack();
      }, 250);
    },
    DispatchCheckout() {
      this.SET_Checkout({
        quotas: this.quotas || [],
        product: this.instruction?.order?.product || {},
        order: {
          data: {
            ...this.instruction?.order,
            quotas: this.orderData?.quotas,
          },
        },
        wagonTypes: this.orderData?.typeWagon,
        calcQuantity: (() => {
          if (
            this.instruction?.deliveryCondition?.name === 'EXW' ||
            !this.orderData?.typeWagon
          )
            return false;

          const typeName = this.orderData?.typeWagon.genitiveName || '';
          const packagingName =
            this.orderData?.product.packaging.genitiveName || '';

          const quantity = parseFloat(this.orderData.quantity) || 0;
          const { wagonNorm, numberBags = 1 } =
            this.orderData?.typeWagon.wagonNorm;

          console.log({ typeName, packagingName, wagonNorm, numberBags });

          const typeVolume = Math.ceil(quantity / wagonNorm);
          const packagingVolume = Math.ceil(numberBags * typeVolume);

          return {
            type: {
              genitiveName:
                this.$translate('count', 'orders') +
                (typeName ? ' ' + typeName.toLowerCase() : ''),
              value: typeVolume,
              wagonNorm,
            },
            packaging: {
              genitiveName:
                this.$translate('count', 'orders') +
                (packagingName ? ' ' + packagingName.toLowerCase() : ''),
              value: packagingVolume,
            },
          };
        })(),

        basis: this.instruction?.deliveryCondition?.name,
        shipmentType: this.instruction?.shipmentType,
      });
    },
    async distributeSupply(dateFrom) {
      let totalQuantityAvailable = this.orderData.quantity;
      const { wagonNorm = 1 } = this.orderData?.typeWagon.wagonNorm || {};
      console.log('distributeSupply dateFrom', dateFrom);
      const quotas = this.quotas
        .filter(({ date }) => {
          if (dateFrom) {
            return (
              this.$moment(
                this.$moment(dateFrom, 'DD/MM/YYYY').format('YYYY-MM-DD')
              ).unix() <= this.$moment(date).unix()
            );
          }
          return (
            this.$moment(this.$moment().format('YYYY-MM-DD'))
              .add(2, 'day')
              .unix() < this.$moment(date).unix()
          );
        })
        .map((el) => {
          let quantity = el.balance;
          // more than available on order
          if (quantity > totalQuantityAvailable) {
            quantity = totalQuantityAvailable;
          }
          // round to nearest multiple
          quantity = this.$roundToNearestMultiple(quantity, wagonNorm);
          // if more than available on quota
          if (quantity > el.balance) quantity = quantity - wagonNorm;
          totalQuantityAvailable -= quantity;
          return {
            date: el.date,
            quantity,
          };
        })
        .filter(({ quantity }) => quantity > 0);
      await this.updateOrderQuotas(quotas);
    },
  },
  watch: {
    loading(val) {
      console.log('emit loadingOrder', val);
      this.$store.commit('checkout/SET_LoadingOrder', val);
    },
  },
  mounted() {
    document.addEventListener('update-instruction', this.getTI);
  },
  async created() {
    this.getQueryParams();
    await this.getOrder();
    await this.DispatchCheckout();

    this.emitter.on('updateOrderQuotas', this.updateOrderQuotas);
    this.emitter.on('distributeSupply', this.distributeSupply);
  },
  beforeUnmount() {
    this.$store.commit('checkout/SET_LoadingOrder', false);
    document.removeEventListener('update-instruction', this.getTI);

    this.emitter.off('updateOrderQuotas', this.updateOrderQuotas);
    this.emitter.off('distributeSupply', this.distributeSupply);
  },
};
</script>
<style scoped src="../../style.scss" lang="scss"></style>
