<template>
  <div ref="pieInfoCenter" class="pieInfoCenter">
    <div v-html="html" class="content"></div>
  </div>
</template>
<script>
export default {
  name: 'PieCenterInfo',
  props: {
    html: {
      type: String,
      default: '',
    },
    parent: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  mounted() {
    this.inject();
    document.addEventListener('chart-update', this.updatePosition);
  },
  beforeUnmount() {
    document.removeEventListener('chart-update', this.updatePosition);
  },
  methods: {
    inject() {
      try {
        if (this.parent) {
          this.parent.append(this.$refs.pieInfoCenter);
          setTimeout(() => this.updatePosition(), 200);
        }
      } catch (e) {
        console.log(e);
      }
    },
    updatePosition() {
      try {
        const parentTop = this.parent.getBoundingClientRect().top;
        const workField = this.parent.querySelector('.apexcharts-pie');
        if (!workField) return null;
        const workTop = workField.getBoundingClientRect().top;
        const workHeight = workField.getBoundingClientRect().height;
        const topDifferent = workTop - parentTop;
        this.$refs.pieInfoCenter.style.top = topDifferent + 'px';
        this.$refs.pieInfoCenter.style.height = workHeight + 'px';
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>
<style scoped lang="scss">
.pieInfoCenter {
  pointer-events: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    ::v-deep(span) {
      &.total {
        color: $gray_500;
        opacity: 0.7;
        text-align: center;
        text-transform: uppercase;
        font-weight: 600;
        font-size: toRem(12);
        line-height: toRem(14);
        margin-bottom: toRem(4);
      }
      &.colorize {
        font-weight: 600;
        font-size: toRem(30);
        line-height: toRem(38);
        text-align: center;
        color: $blue;
      }
      font-weight: 450;
      font-size: toRem(16);
      line-height: toRem(21);
      text-align: center;
      color: $gray_500;
    }
  }
}
</style>
