<template>
  <PageTable :loading="loading">
    <template v-slot:thead>
      <th>
        {{ $translate("date", "documents") }}
      </th>
      <th>
        {{ $translate("document", "documents") }}
      </th>
      <th>
        {{ $translate("note", "orders") }}
      </th>
      <th>
        {{
          $translate(
            balanceType === "trade" ? "money_movement" : "volume",
            "orders"
          )
        }}
      </th>
      <th>
        {{ $translate("final_saldo", "orders") }}
      </th>
      <th></th>
    </template>
    <template v-slot:default>
      <RowItem
        v-for="item in list"
        :key="item.id"
        :row="item"
        :orderId="orderId"
        :balanceType="balanceType"
      />
    </template>
  </PageTable>
</template>

<script>
import RowItem from "./Item";
import PageTable from "../PageTable";
/**
 * The OrderDocumentsTable component.
 */
export default {
  name: "OrderDocumentsTable",
  components: { RowItem, PageTable },
  props: {
    list: { default: () => [], type: Array },
    loading: { default: false, type: Boolean },
    date: { default: "asc", type: String },
    orderId: { default: null, type: [String, Number] },
    balanceType: { default: null, type: String },
  },
};
</script>

<style lang="scss" scoped src="./component.scss"></style>
