<template>
  <div class="DeliveryStatusWrap">
    <div class="DeliveryStatus">
      <span
        v-if="quantityInStock"
        class="in_stock"
        :style="{ width: calcPercent(quantityInStock) + '%' }"
        >{{ calcPercent(quantityInStock) }}%</span
      >
      <span
        v-if="quantityOnStation"
        class="waiting"
        :style="{ width: calcPercent(quantityOnStation) + '%' }"
        >{{ calcPercent(quantityOnStation) }}%</span
      >
      <span
        v-if="quantityInTransit"
        class="in_way"
        :style="{ width: calcPercent(quantityInTransit) + '%' }"
        >{{ calcPercent(quantityInTransit) }}%</span
      >
      <span
        v-if="quantityShipped"
        class="delivered"
        :style="{ width: calcPercent(quantityShipped) + '%' }"
        >{{ calcPercent(quantityShipped) }}%</span
      >
    </div>
    <div
      class="DeliveryStatusPopup"
      v-if="
        quantityInStock ||
        quantityOnStation ||
        quantityInTransit ||
        quantityShipped
      "
    >
      <ul>
        <li v-if="quantityInStock">
          <span class="in_stock"></span>
          {{ $translate('in_stock', 'documents') }}, {{ quantityInStock }} т
        </li>
        <li v-if="quantityOnStation">
          <span class="waiting"></span>
          {{ $translate('waiting', 'documents') }}, {{ quantityOnStation }} т
        </li>
        <li v-if="quantityInTransit">
          <span class="in_way"></span>
          {{ $translate('in_transit', 'documents') }}, {{ quantityInTransit }} т
        </li>
        <li v-if="quantityShipped">
          <span class="delivered"></span>
          {{ $translate('delivered', 'documents') }}, {{ quantityShipped }} т
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
/**
 * The DeliveryStatus component.
 */
export default {
  name: 'DeliveryStatus',
  components: {},
  props: {
    quantity: { default: null, type: Number },
    quantityInTransit: { default: null, type: Number },
    quantityOnStation: { default: null, type: Number },
    quantityShipped: { default: null, type: Number },
    quantityInStock: { default: null, type: Number },
  },
  data() {
    return {
      showPopup: false,
    };
  },
  methods: {
    calcPercent(val) {
      const percent = (val / this.quantity) * 100;
      if (percent != parseInt(percent)) return percent.toFixed(1);
      return percent;
    },
  },
};
</script>

<style lang="scss" scoped src="./component.scss"></style>
