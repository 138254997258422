<template>
  <tr>
    <template v-if="template == 'bills'">
      <td>
        <template v-if="row.name">
          {{ row.name }}
        </template>
      </td>
      <td>
        <template v-if="row.bankCode">
          {{ row.bankCode }}
        </template>
      </td>
      <td>
        <template v-if="row.bankName">
          {{ row.bankName }}
        </template>
      </td>
      <td>
        <template v-if="row.iban">
          {{ row.iban }}
        </template>
      </td>
      <td>
        <Dropdown ref="dropdown" :contentClass="'padding--min'">
          <button class="dropdown-btn" type="button" @click="goEdit">
            <EditIcon class="EditIcon CloseIcon" />
            <span>{{ $translate("edit") }}</span>
          </button>
          <button
            @click="
              $store.dispatch('RemoveCompanyData', {
                type: 'bills',
                id: row.id,
              })
            "
            class="dropdown-btn"
            type="button"
          >
            <CloseIcon class="CloseIcon" />
            <span>{{ $translate("delete") }}</span>
          </button>
        </Dropdown>
      </td>
    </template>
    <template v-else-if="template == 'delivery-address'">
      <td>
        <template v-if="row.name">
          {{ row.name }}
        </template>
      </td>
      <td>
        <template v-if="row.region">
          {{ row.region }}
        </template>
      </td>
      <td>
        <template v-if="row.city">
          {{ row.city?.name }}
        </template>
      </td>
      <td>
        <template v-if="row.index">
          {{ row.index }}
        </template>
      </td>
      <td>
        <template v-if="row.address">
          {{ row.address }}
        </template>
      </td>
      <td>
        <Dropdown ref="dropdown" :contentClass="'padding--min'">
          <button class="dropdown-btn" type="button" @click="goEdit">
            <EditIcon class="EditIcon CloseIcon" />
            <span>{{ $translate("edit") }}</span>
          </button>
          <button
            @click="
              $store.dispatch('RemoveCompanyData', {
                type: 'delivery-address',
                id: row.id,
              })
            "
            class="dropdown-btn"
            type="button"
          >
            <CloseIcon class="CloseIcon" />
            <span>{{ $translate("delete") }}</span>
          </button>
        </Dropdown>
      </td>
    </template>
    <template v-else-if="template == 'company-contacts'">
      <td>
        <template v-if="row.name">
          {{ row.name }}
        </template>
      </td>
      <td>
        <template v-if="row.role">
          {{ row.role }}
        </template>
      </td>
      <td>
        <template v-if="row.phone">
          {{ row.phone }}
        </template>
      </td>
      <td>
        <template v-if="row.email">
          {{ row.email }}
        </template>
      </td>
      <td>
        <Dropdown ref="dropdown" :contentClass="'padding--min'">
          <button class="dropdown-btn" type="button" @click="goEdit">
            <EditIcon class="EditIcon CloseIcon" />
            <span>{{ $translate("edit") }}</span>
          </button>
          <button
            @click="
              $store.dispatch('RemoveCompanyData', {
                type: 'company-contacts',
                id: row.id,
              })
            "
            class="dropdown-btn"
            type="button"
          >
            <CloseIcon class="CloseIcon" />
            <span>{{ $translate("delete") }}</span>
          </button>
        </Dropdown>
      </td>
    </template>
  </tr>
</template>
<script>
import Dropdown from "@/components/Table/PageTable/Dropdown";
import CloseIcon from "@/assets/img/close.svg?.raw";
import EditIcon from "@/assets/img/edit.svg";

export default {
  name: "CompaniesTableItem",
  components: { Dropdown, CloseIcon, EditIcon },
  props: {
    row: { default: () => {}, type: Object },
    template: {
      default: "bills",
      type: String,
    },
  },
  methods: {
    goEdit() {
      const name = (() => {
        switch (this.template) {
          case "bills":
            return "edit-company-bills";
          case "delivery-address":
            return "edit-delivery-address";
          case "company-contacts":
            return "edit-company-contacts";
        }
      })();
      this.$router.push({
        name,
        params: { itemId: this.row.id, id: this.$route.params.id },
      });
    },
  },
};
</script>
<style scoped src="../style.scss" lang="scss"></style>
<style lang="scss">
.dropdown-btn .CloseIcon path {
  fill: $white;
}
</style>
