<template>
  <div class="SpecialDescription" v-if="special">
    <SpecialSliderItem :item="special" :single="true" />
    <Markdown
      class="SpecialText"
      :source="special.description"
      :html="true"
      v-if="special.description"
    />
    <div class="insctuctionsList" v-if="special.achievementSteps?.length">
      <template
        v-for="(item, index) in special.achievementSteps"
        :key="item.id"
      >
        <div class="insctuctionsListItem">
          <div class="number">
            <span>{{ $prefix(index + 1) }}</span>
          </div>
          <div class="text">
            <p v-html="item.description"></p>
          </div>
        </div>
        <div
          class="line"
          v-if="index + 1 < special.achievementSteps.length"
        ></div>
      </template>
    </div>
  </div>
</template>

<script>
import SpecialSliderItem from "@/components/SpecialSlider/Item";
import Markdown from "vue3-markdown-it";
/**
 * The SpecialDescription component.
 */
export default {
  name: "SpecialDescription",
  components: { SpecialSliderItem, Markdown },
  props: {
    special: { default: null, type: Object },
  },
  data() {
    return {};
  },
  computed: {
    // ...mapGetters(['IS_Tablet']),
  },
  methods: {
    // ...mapActions(['GET_PaymentInfo']),
  },
};
</script>

<style lang="scss" scoped src="./component.scss"></style>
