<template>
  <div v-if="getComponents" class="dashboardMainTemplate">
    <template :key="'component-' + i" v-for="(item, i) in getComponents">
      <component :is="item.component" :data="item" />
    </template>
  </div>
</template>
<script>
import Chart from '@/components/Dashboard/Chart';
import Cargo from '@/components/Dashboard/Cargo';
import Payable from '@/components/Dashboard/Payable';

export default {
  name: 'MainTemplate',
  components: {
    Chart,
    Cargo,
    Payable,
  },
  props: {
    data: {
      type: Array,
      default() {
        return [];
      },
      required: true,
    },
  },
  computed: {
    getComponents() {
      let data = this.data && this.data.length ? this.data : null;
      if (!data) return null;
      data = data.map((item) => {
        return { ...item };
      });
      return data.map((item) => {
        const { id } = item;
        switch (id) {
          case 2:
            item.component = 'Chart';

            break;
          case 3:
            item.component = 'Chart';
            break;
          case 6:
            item.component = 'Chart';
            break;
          case 7:
            item.component = 'Chart';
            break;
          case 8:
            item.component = 'Chart';
            break;
          case 4:
            item.component = 'Cargo';
            break;
          case 5:
            item.component = 'Payable';
            break;
          default:
            item.component = 'Chart';
            break;
        }
        return item;
      });
    },
  },
};
</script>
<style scoped lang="scss">
.dashboardMainTemplate {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin: 0 toRem(-8);
  .chartInfo {
    width: calc(33.33% - toRem(16));
    margin: toRem(8);
    @media (max-width: $notebook) {
      width: calc(50% - toRem(16));
    }
    @media (max-width: $tablet2) {
      width: calc(100% - toRem(16));
    }
  }
  .chartInfo,
  .cargoBlock,
  .payable {
    &:nth-child(1) {
      order: 1;
    }
    &:nth-child(2) {
      order: 2;
    }
    &:nth-child(3) {
      order: 3;
      @media (max-width: $notebook) {
        &.chartInfo {
          order: 5;
        }
      }
    }
    &:nth-child(4) {
      order: 4;
      @media (max-width: $notebook) {
        &.cargoBlock {
          order: 3;
        }
      }
    }
    &:nth-child(5) {
      order: 5;
      @media (max-width: $notebook) {
        &.payable {
          order: 4;
        }
      }
    }
    &:nth-child(6) {
      order: 6;
      @media (max-width: $notebook) {
        order: 7;
      }
    }
    &:nth-child(7) {
      order: 7;
      @media (max-width: $notebook) {
        order: 8;
      }
    }
    &:nth-child(8) {
      order: 8;
      @media (max-width: $notebook) {
        order: 9;
      }
    }
  }

  .cargoBlock,
  .payable {
    width: calc(100% - toRem(16));
    margin: toRem(8);
  }

  ::v-deep(.Notice) {
    .content {
      word-wrap: break-word;
    }
  }
}
</style>
