import gsap from 'gsap'

function slideAccordion(el, open = true, callBack = () => { }, time = 0.25, openCallBack = () => { },) {
    let hNow = el.clientHeight;
    let hOpen = hNow;
    if (open) {
        el.removeAttribute('style')
        gsap.set(el, { display: 'block', height: '' });
        hOpen = el.clientHeight;
        openCallBack()
    }
    let hNew = open ? hOpen : 0;
    gsap.fromTo(el, { height: hNow }, {
        display: !open ? 'none' : 'block',
        padding: open ? '' : 0,
        margin: open ? '' : 0,
        height: hNew,
        duration: time,
        ease: 'Power2.easeInOut',
        onComplete: () => {
            gsap.set(el, { height: '' });
            callBack();
        }
    });
    // gsap.set(el, { className: `${!open ? '+' : '-'}=hide` });
}

export default slideAccordion;