<template>
  <LayoutDashboard name="checkout">
    <LoadingIcon v-if="loading && !product" />
    <template v-else>
      <DashboardTitleRow
        :title="$translate('order', 'dashboard') + ' ' + product?.name"
        titleTagClass="h4"
        :goBack="goBack"
        :goBackText="$translate('back_goods', 'orders')"
      />
      <div class="chechoutRow row margin0 nowrap">
        <div class="column columnLeft">
          <DashboardCard class="p40 witdh-auto overflow checkoutCard">
            <!-- Basis - <b>{{ basis }}</b> -->
            <!-- <br /> -->
            <NoticeInfo
              :notice="GET_CostCalculation"
              :text="GET_CostCalculation"
            >
              <MyButton
                :text="$translate('check_price', 'orders')"
                type="transparent-primary"
                class="no-padding min-height"
                tag="a"
                :link="'/dashboard/price-list'"
                target="_blank"
              />
            </NoticeInfo>

            <div class="formWrap">
              <LoadingIcon v-if="loading" :absolute="true" />
              <form
                novalidate
                @submit.prevent="submit"
                :class="{ loadingElem: loading }"
                :key="formKey"
              >
                <div class="formRow logisticsRow">
                  <p class="rowTitle">
                    {{ $translate("logistics", "orders") }}
                    <Notice
                      :title="$getNotice('logistics_notice')"
                      v-if="$getNotice('logistics_notice')"
                      class="gray"
                    />
                  </p>
                  <div class="row margin72">
                    <div
                      class="w50"
                      :class="{ marginBottom0: !basis && !IS_Mobile }"
                    >
                      <LabelDropdown
                        name="deliveryType"
                        :title="$translate('type_delivery', 'orders')"
                        :placeholder="$translate('get_value', 'orders')"
                        :list="deliveryTypeListComputed"
                        :active="deliveryTypeCurrent"
                        @change="changeDropdown"
                        class="deliveryTypeLabel darkPlaceholder marginBottom0"
                        :disabled="deliveryTypeListComputed.length <= 1"
                      />
                    </div>
                    <div class="w50" :class="{ marginBottom0: !basis }">
                      <template v-if="deliveryTypeCurrent">
                        <LabelDropdown
                          name="shipmentType"
                          :title="$translate('type_transportation', 'orders')"
                          :placeholder="$translate('get_value', 'orders')"
                          :list="shipmentTypeListComputed"
                          :active="shipmentTypeCurrent"
                          @change="changeDropdown"
                          class="shipmentTypeLabel darkPlaceholder marginBottom0"
                          :disabled="shipmentTypeListComputed.length <= 1"
                        />
                      </template>
                    </div>
                    <template v-if="deliveryTypeCurrent">
                      <template v-if="basis">
                        <div
                          v-if="basis === 'EXW' || basis === 'DDP'"
                          class="w50">
                          <LabelDropdown
                            name="regionType"
                            :title="$translate('choose_region', 'orders')"
                            :placeholder="$translate('get_value', 'orders')"
                            :list="regionTypeList"
                            :active="regionTypeCurrent"
                            @change="changeDropdown"
                            class="regionTypeLabel darkPlaceholder marginBottom0"
                            :disabled="regionTypeList.length <= 1"
                          />
                        </div>
                        <div class="w50">
                          <LabelDropdown
                            name="shippingWarehouseType"
                            :title="
                              $translate('select_shipping_warehouse', 'orders')
                            "
                            :placeholder="$translate('get_value', 'orders')"
                            :list="shippingWarehouseList"
                            :active="shippingWarehouseCurrent"
                            @change="changeDropdown"
                            class="shippingWarehouseLabel darkPlaceholder marginBottom0"
                            v-if="basis === 'EXW' || basis === 'DDP'"
                            :disabled="
                              shippingWarehouseList.length <= 1 ||
                              !regionTypeCurrent
                            "
                          />
                          <LabelSearchSelect
                            :getData="getStations"
                            :getDataParams="createStationSearchParams"
                            :value="deliveryStationCurrent"
                            :placeholder="$translate('get_value', 'orders')"
                            :title="$translate('choose_a_delivery_station', 'orders')"
                            @change="changeDropdown"
                            class="deliveryStationLabel darkPlaceholder marginBottom0"
                            displayProperty="name"
                            name="deliveryStation"
                            v-if="basis === 'CPT' || basis === 'FCA'"
                          >
                            <template v-slot:item="stationsSearchDropdownItemSlot">
                              <span>{{ stationsSearchDropdownItemSlot?.item?.name }} ({{ stationsSearchDropdownItemSlot?.item?.code }})</span>
                              <span v-if="stationsSearchDropdownItemSlot?.item?.longitude == null && stationsSearchDropdownItemSlot?.item?.latitude == null">&nbsp;⚠️</span>
                            </template>
                          </LabelSearchSelect>
                        </div>
                      </template>
                      <!-- choosePlaceDispatch -->
                      <template v-if="basis === 'CPT' || basis === 'FCA'">
                        <div class="w100">
                          <CheckBox
                            class="big marginBottom0"
                            name="choosePlaceDispatch"
                            :text="
                              $translate('choose_a_place_of_dispatch', 'orders')
                            "
                            @onInput="onInputCheckBox"
                            :error="inputError['choosePlaceDispatch']"
                            ref="choosePlaceDispatch"
                            :checked="choosePlaceDispatch"
                            :toggle="true"
                            :disabled="disabledChoosePlaceDispatch"
                            :key="choosePlaceDispatchKey"
                          />
                        </div>
                        <template v-if="choosePlaceDispatch"> <!--TODO: CPT change - add fields for CPT but not mandatory -->
                          <div
                            v-if="regionTypeList && regionTypeList.length"
                            class="w50"
                          >
                            <LabelDropdown
                              name="regionType"
                              :title="$translate('choose_region', 'orders')"
                              :placeholder="$translate('get_value', 'orders')"
                              :list="regionTypeList"
                              :active="regionTypeCurrent"
                              @change="changeDropdown"
                              class="regionTypeLabel darkPlaceholder w50 marginBottom0"
                            />
                          </div>
                          <div
                            v-if="
                              shippingWarehouseList &&
                              shippingWarehouseList.length
                            "
                            class="w50"
                          >
                            <LabelDropdown
                              name="shippingWarehouseType"
                              :title="
                                $translate(
                                  'select_shipping_warehouse',
                                  'orders'
                                )
                              "
                              :placeholder="$translate('get_value', 'orders')"
                              :list="shippingWarehouseList"
                              :active="shippingWarehouseCurrent"
                              @change="changeDropdown"
                              class="shippingWarehouseLabel darkPlaceholder w50 marginBottom0"
                              :disabled="
                                shippingWarehouseList.length <= 1 ||
                                !regionTypeCurrent
                              "
                            />
                          </div>
                        </template>
                      </template>
                      <template
                        v-if="
                          basis === 'CPT' || basis === 'FCA' || basis === 'DDP'
                        "
                      >
                        <div class="w50">
                          <LabelDropdown
                            name="consignee"
                            :title="$translate('consignee', 'orders')"
                            :placeholder="$translate('get_value', 'orders')"
                            :list="consigneeListComputed"
                            :active="consigneeCurrent"
                            @change="changeDropdown"
                            class="consigneeLabel darkPlaceholder marginBottom0"
                            v-if="basis === 'CPT' || basis === 'FCA'"
                          />
                          <LabelDropdown
                            name="carrier"
                            :title="$translate('carrier', 'orders')"
                            :placeholder="$translate('get_value', 'orders')"
                            :list="carrierList"
                            :active="carrierCurrent"
                            @change="changeDropdown"
                            class="carrierLabel darkPlaceholder marginBottom0"
                            v-if="basis === 'DDP'"
                            :disabled="carrierList.length <= 1"
                          />
                        </div>
                        <div class="w50">
                          <Label
                            :title="$translate('name_of_consignee', 'orders')"
                            name="consigneeName"
                            @onInput="onInputField"
                            :error="inputError['consigneeName']"
                            ref="consigneeName"
                            :value="consigneeName"
                            v-if="
                              (basis === 'FCA' || basis === 'CPT') &&
                              consigneeCurrent
                            "
                            :disabled="consigneeNameDisabled"
                            :key="consigneeKey"
                            @blur="labelBlur"
                          />
                        </div>

                        <template
                          v-if="
                            (basis === 'FCA' || basis === 'CPT') &&
                            consigneeCurrent
                          "
                        >
                          <div class="w50">
                            <Label
                              :title="
                                $translate('edrpou_of_the_consignee', 'orders')
                              "
                              name="consigneeEdrpou"
                              @onInput="onInputField"
                              :error="inputError['consigneeEdrpou']"
                              ref="consigneeEdrpou"
                              :value="consigneeEdrpou"
                              :disabled="consigneeEdrpouDisabled"
                              :key="consigneeKey"
                              @blur="labelBlur"
                              mask="##########"
                            />
                          </div>
                          <div class="w50">
                            <Label
                              :title="
                                $translate('consignee_s_license_code', 'orders')
                              "
                              name="consigneeCode"
                              @onInput="onInputField"
                              :error="inputError['consigneeCode']"
                              ref="consigneeCode"
                              :value="consigneeCode"
                              :disabled="consigneeCodeDisabled"
                              :key="consigneeKey"
                              @blur="labelBlur"
                            />
                          </div>
                        </template>
                      </template>

                      <!-- deliveryAddress -->
                      <template v-if="basis === 'DDP'">
                        <div
                          v-if="
                            deliveryAddressListComputed &&
                            deliveryAddressListComputed.length
                          "
                          class="w50"
                        >
                          <LabelDropdown
                            name="deliveryAddress"
                            :title="$translate('delivery_address', 'orders')"
                            :placeholder="$translate('get_value', 'orders')"
                            :list="deliveryAddressListComputed"
                            :active="deliveryAddressCurrent"
                            @change="changeDropdown"
                            class="deliveryAddressLabel darkPlaceholder marginBottom0"
                            :disabled="deliveryAddressListComputed.length <= 1"
                          />
                        </div>
                        <div class="w50">
                          <DashboardCard
                            v-if="order.data.deliveryDistance != null"
                            class="p20 width-auto deliveryDistanceCard">
                            <article class="deliveryDistanceCardContent">
                              <InfoIcon />
                              <p class="deliveryDistanceCardContentInner">
                                <span class="deliveryDistanceCardContentTitle">
                                  {{ $translate("delivery_distance", "orders") }}:
                                </span><strong>{{ order.data.deliveryDistance }} км</strong>.
                                <span class="deliveryDistanceCardContentTitle">
                                  {{ $translate("delivery_tariff", "orders") }}:
                                </span><span>{{ order.data.deliveryTariff }} грн/км</span>.
                              </p>
                            </article>
                          </DashboardCard>
                        </div>
                      </template>
                      <div
                        class="w100 marginBottom0"
                        v-if="
                          basis === 'CPT' || basis === 'FCA' || basis === 'DDP'
                        "
                      >
                        <LabelSearchSelect
                          v-if="basis === 'DDP' && deliveryAddressCurrent?.key === 'new_address'"
                          :getData="addressAutocomplete"
                          :getDataParams="createAddressSearchParams"
                          :value="newAddress"
                          :placeholder="$translate('get_value', 'orders')"
                          :title="$translate('delivery_address', 'orders')"
                          :error="inputError['newAddress']"
                          :sortResults="false"
                          inputType="textarea"
                          @change="changeDropdown"
                          class="deliveryStationLabel darkPlaceholder marginBottom0"
                          displayProperty="description"
                          name="newAddress"
                        >
                        </LabelSearchSelect>

                        <Accordion
                          v-if="basis === 'CPT' || basis === 'FCA'"
                          :title="
                            $translate(
                              'additional_data_for_the_railway',
                              'orders'
                            )
                          "
                          :titleActive="
                            $translate(
                              'hide_additional_data_for_the_railway',
                              'orders'
                            )
                          "
                          :openOnInit="additionalAccordionOpen"
                          :key="additionalAccordionKey"
                        >
                          <Label
                            :title="$translate('shipping_notes', 'orders')"
                            name="shippingNotes"
                            inputType="textarea"
                            @onInput="onInputField"
                            :error="inputError['shippingNotes']"
                            ref="shippingNotes"
                            :value="shippingNotes"
                            @blur="labelBlur"
                            :key="shippingNotesKey"
                          />
                          <Label
                            :title="
                              $translate('column_15_for_the_railway', 'orders')
                            "
                            name="columnRailway15"
                            inputType="textarea"
                            @onInput="onInputField"
                            :error="inputError['columnRailway15']"
                            ref="columnRailway15"
                            :value="columnRailway15"
                            @blur="labelBlur"
                            :key="columnRailway15Key"
                          />
                          <CheckBox
                            class="big"
                            name="notTreatAntiCakingAgent"
                            :text="
                              $translate(
                                'do_not_treat_with_an_anti_caking_agent',
                                'orders'
                              )
                            "
                            @onInput="onInputCheckBox"
                            :error="inputError['notTreatAntiCakingAgent']"
                            ref="notTreatAntiCakingAgent"
                            :checked="notTreatAntiCakingAgent"
                            :toggle="true"
                          />
                        </Accordion>
                      </div>
                    </template>
                  </div>
                </div>
                <div class="formRow quantityRow">
                  <p class="rowTitle">
                    {{ $translate("delivery_volume", "orders") }}
                    <Notice
                      class="gray"
                      :title="$getNotice('delivery_volume_notice')"
                      v-if="$getNotice('delivery_volume_notice')"
                    />
                  </p>
                  <div class="row margin72">
                    <div
                      class="w50"
                      v-if="
                        deliveryTypeCurrent &&
                        (basis === 'CPT' ||
                          basis === 'FCA' ||
                          basis === 'DDP') &&
                        wagonTypesListComputed &&
                        wagonTypesListComputed.length
                      "
                    >
                      <LabelDropdown
                        name="wagonTypes"
                        :title="
                          $translate(
                            basis === 'DDP' ? 'vehicle_type' : 'type_wagons',
                            'orders'
                          )
                        "
                        :placeholder="$translate('get_value', 'orders')"
                        :list="wagonTypesListComputed"
                        :active="wagonTypesCurrent"
                        @change="changeDropdown"
                        class="wagonTypesLabel darkPlaceholder marginBottom0"
                        :disabled="wagonTypesListComputed.length <= 1"
                      />
                    </div>
                    <div class="w50 marginBottom0">
                      <Label
                        :title="
                          $translate('volume_prefix', 'orders') +
                          (product?.unit?.name || 'т')
                        "
                        name="quantity"
                        :placeholder="
                          $translate('volume_prefix', 'orders') +
                          (product?.unit?.name || 'т')
                        "
                        :value="quantity"
                        @onInput="onInputField"
                        :error="inputError['quantity']"
                        ref="quantity"
                        mask="0.99"
                        mask-tokens="0:\d:multiple|9:\d"
                        @blur="labelBlur"
                        :key="quantityKey"
                        :disabled="quantityDisabled"
                      >
                        <template v-slot:title>
                          <MyButton
                            v-hide="quantityDisabled"
                            @click="openOrderRecommendedVolumePopup"
                            class="primaryIcon recommendedVolumeButton underline min-height no-padding"
                            iconAfter="arrow_right"
                            type="transparent-primary"
                            :text="$translate('recommended_volume', 'orders')"
                          />
                        </template>
                        <template v-slot:bottom v-if="calcQuantity">
                          <div class="calcQuantity">
                            <b class="calcQuantityItem"
                              >{{ calcQuantity.type.genitiveName }}:
                              {{ calcQuantity.type.value }}</b
                            >
                            <b class="calcQuantityItem"
                              >{{ calcQuantity.packaging.genitiveName }}:
                              {{ calcQuantity.packaging.value }}</b
                            >
                          </div>
                        </template>
                      </Label>
                    </div>
                  </div>
                </div>
                <div class="formRow deliveryScheduleRow">
                  <p class="rowTitle">
                    {{ $translate("delivery_schedule", "orders") }}
                    <Notice
                      class="gray"
                      :title="$getNotice('delivery_schedule_notice')"
                      v-if="$getNotice('delivery_schedule_notice')"
                    />
                    <ReserveTimer
                      :date="order.data.quotasUpdatedAt"
                      v-if="order?.data?.quotasUpdatedAt"
                    />
                  </p>
                  <div class="quotaList" v-if="orderQuotasSorted">
                    <PageFilterTag
                      v-for="item in orderQuotasSorted"
                      :key="item.id"
                      :text="getQuotaText(item)"
                      :textOnly="true"
                      class="primary"
                      @close="removeQuota(item.id)"
                    />
                  </div>

                  <p
                    v-if="order?.data?.quotas"
                    :class="[
                      'text',
                      {
                        reserve: order.data.quotas.length,
                        no_dates: !order.data.quotas.length,
                      },
                    ]"
                  >
                    {{
                      $translate(
                        order.data.quotas.length
                          ? "reserve_delivery_dates"
                          : "no_dates_available_select_dates_in_calendar",
                        "orders"
                      )
                    }}
                  </p>
                  <div class="row margin0 nowrap">
                    <MyButton
                      :text="$translate('select_date', 'orders')"
                      class="selectDateButton w288 bigIcon iconAbsolute"
                      iconAfter="calendar"
                      :disabled="disabledSelectDate"
                      @click="$popup.show('DeliverySchedule')"
                    />
                  </div>
                </div>
                <div class="formRow paymentRow">
                  <p class="rowTitle">
                    {{ $translate("payment_type", "orders") }}
                    <Notice
                      class="gray"
                      :title="$getNotice('payment_type_notice')"
                      v-if="$getNotice('payment_type_notice')"
                      :class="{
                        left_center: IS_Mobile,
                      }"
                    />
                  </p>
                  <div
                    v-if="paymentTypeList && paymentTypeList.length"
                    class="row margin72 alignStart nowrap"
                  >
                    <LabelDropdown
                      name="paymentType"
                      :title="$translate('payment_form', 'orders')"
                      :placeholder="$translate('get_value', 'orders')"
                      :list="paymentTypeList"
                      :active="paymentTypeCurrent"
                      @change="changeDropdown"
                      class="paymentTypeLabel darkPlaceholder w50 marginBottom0"
                    >
                      <Notice
                        class="gray"
                        :title="$getNotice('payment_form_notice')"
                        v-if="$getNotice('payment_form_notice')"
                        :class="{
                          left: IS_Mobile,
                        }"
                      />
                    </LabelDropdown>
                    <Label
                      v-if="showLoanCoveragePercentage"
                      class="w50 marginBottom0"
                      :title="$translate('loan_coverage_percentage', 'orders')"
                      name="loanCoveragePercentage"
                      @onInput="onInputField"
                      :error="inputError['loanCoveragePercentage']"
                      ref="loanCoveragePercentage"
                      :value="order.data.loanCoveragePercentage"
                      @blur="labelBlur"
                      numbersOnly
                      :min="0"
                      :max="100"
                      inputType="number"
                    />
                  </div>
                </div>
                <div class="formRow contractorRow">
                  <p class="rowTitle">
                    {{ $translate("counterparty", "orders") }}
                    <Notice
                      class="gray"
                      :title="$getNotice('counterparty_notice')"
                      v-if="$getNotice('counterparty_notice')"
                      :class="{
                        left_center: IS_Mobile,
                      }"
                    />
                  </p>
                  <div class="row margin72 alignStart">
                    <div
                      v-if="
                        counterpartyListComputed &&
                        counterpartyListComputed.length
                      "
                      class="w50"
                    >
                      <LabelDropdown
                        name="counterparty"
                        :title="$translate('counterparty_name', 'orders')"
                        :placeholder="$translate('get_value', 'orders')"
                        :list="counterpartyListComputed"
                        :active="counterpartyCurrent"
                        @change="changeDropdown"
                        class="counterpartyLabel darkPlaceholder marginBottom0"
                        :disabled="counterpartyListComputed.length < 1"
                      />
                    </div>
                    <div class="w50"></div>
                    <template v-if="counterpartyCurrent">
                      <div
                        class="w50"
                        :class="{
                          marginBottom0:
                            !showNewCounterparty && !showNewContactPerson,
                        }"
                      >
                        <template v-if="showNewCounterparty">
                          <Label
                            :title="$translate('name_legal_entity', 'orders')"
                            name="nameLegalEntity"
                            :placeholder="
                              $translate('name_legal_entity', 'orders')
                            "
                            inputType="text"
                            @onInput="onInputField"
                            :error="inputError['nameLegalEntity']"
                            ref="nameLegalEntity"
                            @blur="labelBlur"
                            :value="order.data.accountName"
                          />
                        </template>
                        <template v-else>
                          <LabelDropdown
                            v-if="
                              contactPersonListComputed &&
                              contactPersonListComputed.length
                            "
                            name="contactPerson"
                            :title="$translate('contact_person', 'orders')"
                            :placeholder="$translate('get_value', 'orders')"
                            :list="contactPersonListComputed"
                            :active="contactPersonCurrent"
                            @change="changeDropdown"
                            class="contactPersonLabel darkPlaceholder w50 marginBottom0"
                            :disabled="contactPersonListComputed.length === 1"
                          />
                        </template>
                      </div>
                      <div class="w50">
                        <template v-if="showNewCounterparty">
                          <Label
                            :title="$translate('edrpou_legal_entity', 'orders')"
                            name="edrpouLegalEntity"
                            :placeholder="
                              $translate('edrpou_legal_entity', 'orders')
                            "
                            inputType="text"
                            @onInput="onInputField"
                            :error="inputError['edrpouLegalEntity']"
                            ref="edrpouLegalEntity"
                            mask="##########"
                            @blur="labelBlur"
                            :value="order.data.accountEdrpou"
                          />
                        </template>
                      </div>
                      <div class="w50 marginBottom0">
                        <template
                          v-if="showNewCounterparty || showNewContactPerson"
                        >
                          <Label
                            :title="$translate('name_contact_person', 'orders')"
                            name="nameContactPerson"
                            :placeholder="
                              $translate('name_contact_person', 'orders')
                            "
                            inputType="text"
                            @onInput="onInputField"
                            :error="inputError['nameContactPerson']"
                            ref="nameContactPerson"
                            :key="counterpartyContentKey"
                            @blur="labelBlur"
                            :value="order.data.accountContactName"
                          />
                        </template>
                      </div>
                      <div class="w50 marginBottom0">
                        <template
                          v-if="showNewCounterparty || showNewContactPerson"
                        >
                          <Label
                            :title="
                              $translate('contact_person_phone', 'orders')
                            "
                            name="contactPersonPhone"
                            placeholder="+38 093 000 00 00"
                            inputType="tel"
                            @onInput="onInputField"
                            :error="inputError['contactPersonPhone']"
                            ref="contactPersonPhone"
                            autocomplete="tel"
                            mask="+38 ### ### ## ##"
                            :key="counterpartyContentKey"
                            @blur="labelBlur"
                            :value="order.data.accountContactPhone"
                          />
                        </template>
                      </div>
                    </template>
                  </div>
                </div>
                <div class="formRow notesRow">
                  <Label
                    :title="$translate('notes_to_order', 'orders')"
                    name="notes"
                    inputType="textarea"
                    @onInput="onInputField"
                    :error="notesError"
                    :errorText="notesErrorText"
                    ref="notes"
                    class="boldTitle notesLabel"
                    @blur="labelBlur"
                    :value="notes"
                    :key="notesKey"
                  />
                </div>
                <MyButton
                  class="w100"
                  :text="$translate('send')"
                  tag="button"
                  tagType="submit"
                  :dynamicSize="true"
                  v-show="false"
                />
              </form>
            </div>
          </DashboardCard>
        </div>
        <div class="column columnRight">
          <div class="wrap">
            <CheckoutOrderDetails
              :packagingName="product?.packaging?.name"
              :paymentType="paymentTypeCurrent"
              :unit="product?.unit"
              :basis="basisCurrent"
              :shippingWarehouse="shippingWarehouseCurrent"
              :order="order.data"
              :disabled="disabledOrderButton"
              @submit="submit"
            />
            <span></span>
          </div>
        </div>
      </div>
    </template>
  </LayoutDashboard>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import LayoutDashboard from "@/layouts/Dashboard.vue";
import DashboardTitleRow from "@/components/DashboardTitleRow";
import DashboardCard from "@/components/DashboardCard";
import NoticeInfo from "@/components/NoticeInfo";
import Notice from "@/components/Notice";
import CheckoutOrderDetails from "@/components/Checkout/OrderDetails";
import ReserveTimer from "@/components/Checkout/ReserveTimer";
import Accordion from "@/components/Checkout/Accordion";
import CheckBox from "@/components/CheckBox";
import LabelSearchSelect from "@/components/LabelSearchSelect";
import PageFilterTag from "@/components/PageFilter/Tag";
import InfoIcon from "@/assets/img/info.svg";
import uniqBy from 'lodash/uniqBy'

/**
 * The order-checkout-page component.
 */
export default {
  name: "OrderCheckoutPage",
  components: {
    LayoutDashboard,
    DashboardTitleRow,
    DashboardCard,
    NoticeInfo,
    Notice,
    CheckoutOrderDetails,
    Accordion,
    CheckBox,
    ReserveTimer,
    PageFilterTag,
    InfoIcon,
    LabelSearchSelect,
  },
  data() {
    return {
      order: {},
      deliveryConditions: [],
      // The form data.
      // delivery data
      deliveryType: "",
      deliveryTypeList: [],
      deliveryTypeCurrent: null,
      // transportation data
      shipmentType: "",
      shipmentTypeList: [],
      shipmentTypeCurrent: null,
      // region data
      regionType: "",
      regionTypeList: [],
      regionTypeCurrent: null,
      // consignee data
      consigneeList: [],
      consigneeCurrent: null,
      consigneeName: "1",
      consigneeNameDisabled: false,
      consigneeEdrpou: "2",
      consigneeEdrpouDisabled: false,
      consigneeCode: "3",
      consigneeCodeDisabled: false,
      consigneeKey: 0,
      // shippingWarehouse data
      shippingWarehouse: "",
      shippingWarehouseList: [],
      shippingWarehouseCurrent: null,
      // quantity
      quantity: "1.00",
      quantityKey: 0,
      // contractor inputs
      nameLegalEntity: "",
      edrpouLegalEntity: "",
      nameContactPerson: "",
      contactPersonPhone: "",
      loanCoveragePercentage: "",
      inputError: {},
      loading: true,
      product: null,

      // suGoodWithoutPackaging: this.$route.query.suGoodWithoutPackaging,
      // packagingId: this.$route.query.packagingId,
      // productId: this.$route.query.productId,

      formKey: "checkoutForm_0",
      notes: "",
      notesKey: 0,
      notesError: false,
      notesErrorText: "",
      counterpartyCurrent: null,
      counterpartyList: [],
      contactPersonCurrent: null,
      contactPersonList: [],
      // paymentType
      paymentTypeCurrent: null,
      paymentTypeList: [],
      counterpartyContentKey: 0,
      // carrier
      carrierCurrent: null,
      carrierList: [],
      // deliveryAddress
      deliveryAddressCurrent: null,
      deliveryAddressList: [],
      newAddress: null,
      newAddressKey: 0,
      deliveryStationCurrent: null,
      // additional railway data
      additionalAccordionKey: 0,
      additionalAccordionOpen: false,
      shippingNotes: "",
      shippingNotesKey: 0,
      columnRailway15: "",
      columnRailway15Key: 0,
      notTreatAntiCakingAgent: false,
      choosePlaceDispatch: false,
      watchChoosePlaceDispatch: true,
      disabledChoosePlaceDispatch: false,
      choosePlaceDispatchKey: 0,
      // choosePlaceDispatch
      regionDispatchList: [],
      regionDispatchCurrent: null,
      shippingWarehouseDispatchList: [],
      shippingWarehouseDispatchCurrent: null,
      // wagonTypes
      wagonTypesList: [],
      wagonTypesCurrent: null,
      disableWatch: true,
      setDataFromOrder: true,
      //
      quotas: [],
    };
  },
  computed: {
    ...mapGetters(["IS_Tablet", "IS_Mobile"]),
    ...mapGetters("checkout", ["GET_CostCalculation", "GET_Checkout"]),
    wagonTypesListComputed() {
      if (this.basis === "CPT" && this.CPT_Condition)
        return this.CPT_Condition.transportTypes;
      if (this.basis === "FCA" && this.FCA_Condition)
        return this.FCA_Condition.transportTypes;
      if (this.basis === "DDP" && this.DDP_Condition)
        return this.DDP_Condition.transportTypes;
      return [];
    },
    deliveryTypeListComputed() {
      const list = [];
      // PICK_UP
      if (this.EXW_Condition) {
        list.push({
          ...this.deliveryTypeList.find(
            ({ crmId }) => crmId === process.env.VUE_APP_DELIVERY_PICK_UP
          ),
        });
      }
      // DELIVERY
      if (this.CPT_Condition || this.FCA_Condition || this.DDP_Condition) {
        list.push({
          ...this.deliveryTypeList.find(
            ({ crmId }) => crmId === process.env.VUE_APP_DELIVERY_DELIVERY
          ),
        });
      }
      return list;
    },
    shipmentTypeListComputed() {
      const list = [];

      // PICK_UP
      if (
        this.deliveryTypeCurrent?.crmId === process.env.VUE_APP_DELIVERY_PICK_UP
      ) {
        list.push({
          ...this.shipmentTypeList.find(
            ({ crmId }) => crmId === process.env.VUE_APP_SHIPMENT_AUTO
          ),
        });
      }

      // DELIVERY
      if (
        this.deliveryTypeCurrent?.crmId ===
        process.env.VUE_APP_DELIVERY_DELIVERY
      ) {
        if (this.CPT_Condition || this.FCA_Condition) {
          list.push({
            ...this.shipmentTypeList.find(
              ({ crmId }) => crmId === process.env.VUE_APP_SHIPMENT_RAILWAY
            ),
          });
        }

        if (this.DDP_Condition) {
          list.push({
            ...this.shipmentTypeList.find(
              ({ crmId }) => crmId === process.env.VUE_APP_SHIPMENT_AUTO
            ),
          });
        }
      }

      return list;
    },

    EXW_Condition() {
      // return false;
      if (!this.product) return false;
      return this.product.deliveryConditions.find(
        ({ crmId }) => crmId === process.env.VUE_APP_CONDITION_EXW
      );
    },
    CPT_Condition() {
      // return false;
      if (!this.product) return false;
      return this.product.deliveryConditions.find(
        ({ crmId }) => crmId === process.env.VUE_APP_CONDITION_CPT
      );
    },
    FCA_Condition() {
      // return false;
      if (!this.product) return false;
      return this.product.deliveryConditions.find(
        ({ crmId }) => crmId === process.env.VUE_APP_CONDITION_FCA
      );
    },
    DDP_Condition() {
      // return false;
      if (!this.product) return false;
      return this.product.deliveryConditions.find(
        ({ crmId }) => crmId === process.env.VUE_APP_CONDITION_DDP
      );
    },

    basis() {
      const condition = this.deliveryConditions[0];
      // if (this.deliveryConditions.length > 1) {
      //   alert("deliveryConditions.length > 1");
      // }
      if (process.env.VUE_APP_BASIS_EXW === condition?.crmId) {
        return "EXW";
      } else if (process.env.VUE_APP_BASIS_CPT === condition?.crmId) {
        return "CPT";
      } else if (process.env.VUE_APP_BASIS_FCA === condition?.crmId) {
        return "FCA";
      } else if (process.env.VUE_APP_BASIS_DDP === condition?.crmId) {
        return "DDP";
      }
      return null;
      // const list = ["EXW", "CPT", "FCA", "DDP"];
      // return list[4];
    },
    basisCurrent() {
      if (!this.deliveryConditions) return null;
      return this.deliveryConditions.find((item) => item.name === this.basis);
    },
    goBack() {
      if (this.order?.routerNavigation) return this.order.routerNavigation;
      if (!this.order.category && !this.order.specials) {
        return { name: "order" };
      }
      return {
        name: this.order.category ? "order-category" : "order-specials",
        params: {
          category: this.order.category || this.order.specials,
        },
      };
    },
    showNewCounterparty() {
      return this.counterpartyCurrent?.id === "new_counterparty";
    },
    showNewContactPerson() {
      return this.contactPersonCurrent?.id === "new_contact_person";
    },
    counterpartyListComputed() {
      return [
        ...this.counterpartyList,
        {
          id: "new_counterparty",
          name: this.$translate("new_counterparty", "orders"),
        },
      ];
    },
    contactPersonListComputed() {
      return [
        ...this.contactPersonList,
        {
          id: "new_contact_person",
          name: this.$translate("new_contact_person", "orders"),
        },
      ];
    },
    deliveryAddressListComputed() {
      return [
        ...this.deliveryAddressList,
        {
          key: "new_address",
          name: this.$translate("new_address", "orders"),
        },
      ];
    },
    consigneeListComputed() {
      return [
        ...this.consigneeList,
        {
          id: "own_company",
          crmId: "own_company",
          name: this.$translate("own_company", "orders"),
        },
      ];
    },
    calcQuantity() {
      if (this.basis === "EXW" || !this.wagonTypesCurrent) return false;

      const typeName = this.wagonTypesCurrent.genitiveName || "";
      const packagingName = this.product.packaging.genitiveName || "";

      const quantity = parseFloat(this.quantity) || 0;
      const { wagonNorm, numberBags } = this.wagonTypesCurrent.wagonNorm;

      const typeVolume = Math.ceil(quantity / wagonNorm);
      const packagingVolume = Math.ceil(numberBags * typeVolume);

      return {
        type: {
          genitiveName:
            this.$translate("count", "orders") +
            (typeName ? " " + typeName.toLowerCase() : ""),
          value: typeVolume,
          wagonNorm,
        },
        packaging: {
          genitiveName:
            this.$translate("count", "orders") +
            (packagingName ? " " + packagingName.toLowerCase() : ""),
          value: packagingVolume,
        },
      };
    },
    quantityDisabled() {
      if (
        this.deliveryTypeCurrent &&
        (this.basis === "CPT" || this.basis === "FCA" || this.basis === "DDP")
      ) {
        return !this.wagonTypesCurrent;
      }
      return false;
    },
    isOwnCompany() {
      return this.consigneeCurrent?.crmId === "own_company";
    },
    disabledSelectDate() {
      if (this.basisCurrent) {
        if (
          (this.basis === "FCA" && !this.shippingWarehouseCurrent) ||
          (this.basis === "DDP" &&
            (!this.shippingWarehouseCurrent || !this.wagonTypesCurrent)) ||
          (this.basis === "CPT" &&
            (!this.deliveryStationCurrent || !this.wagonTypesCurrent)) ||
          (this.basis === "EXW" && !this.shippingWarehouseCurrent)
        )
          return true;

        return false;
      }
      return true;
    },
    orderQuotasSorted() {
      if (!this.order?.data?.quotas) return null;
      return [...this.order.data.quotas].sort((a, b) => {
        const dateA = this.$moment(a.date).unix();
        const dateB = this.$moment(b.date).unix();
        if (dateA > dateB) return 1;
        if (dateA < dateB) return -1;
        return 0;
      });
    },
    disabledOrderButton() {
      if (!this.paymentTypeCurrent) return true;
      if (!this.counterpartyCurrent) return true;
      if (
        this.counterpartyCurrent?.id !== "new_counterparty" &&
        !this.contactPersonCurrent
      )
        return true;

      if (!this.order.data.quotas.length) return true;

      if (this.basis === "EXW") {
        if (this.regionTypeCurrent && this.shippingWarehouseCurrent)
          return false;
      } else if (this.basis === "CPT" || this.basis === "FCA") {
        if (
          this.deliveryStationCurrent &&
          this.consigneeCurrent
        ) {
          if (
            this.basis === "FCA" &&
            (!this.shippingWarehouseCurrent || !this.wagonTypesCurrent)
          ) {
            return true;
          }
          return false;
        }
      } else if (this.basis === "DDP") {
        if (
          this.shippingWarehouseCurrent &&
          this.carrierCurrent &&
          this.deliveryAddressCurrent &&
          this.wagonTypesCurrent
        ) {
          return false;
        }
      }

      return true;
    },
    showLoanCoveragePercentage() {
      return (
        this.paymentTypeCurrent != null &&
        this.paymentTypeCurrent.setLoanCoveragePercentage
      )
    },
  },
  methods: {
    ...mapActions("checkout", ["SET_CostCalculation", "SET_Checkout"]),
    resetForm() {
      this.loading = false;
      this.notes = "";
      this.notesError = false;
      this.formKey = "checkoutForm_" + this.$rnd(0, 100);
      // clear fields
      this.nameLegalEntity = "";
      this.edrpouLegalEntity = "";
      this.nameContactPerson = "";
      this.contactPersonPhone = "";

      this.inputError = {};
    },
    async success() {
      await this.updateOrder({ status: "new" }, () => {
        this.$router.push({ name: "orders" });
        this.$order.remove();
      });

      // this.resetForm();
    },
    async submit() {
      if (this.loading) return false;
      console.log("submit");

      const error = (() => {
        const CPT_FCA_error = (() => {
          if (this.basis != "CPT" && this.basis != "FCA") return false;
          const required = this.consigneeCurrent?.id === "own_company";
          const consigneeNameError = this.onInputField(
            undefined,
            undefined,
            "consigneeName",
            required
          );
          const consigneeEdrpouError = this.onInputField(
            undefined,
            undefined,
            "consigneeEdrpou",
            required,
            "edrpou"
          );
          const consigneeCodeError = this.onInputField(
            undefined,
            undefined,
            "consigneeCode",
            required
          );

          return (
            consigneeNameError || consigneeEdrpouError || consigneeCodeError
          );
        })();

        const EXW_error = (() => {
          if (this.basis != "EXW") return false;
          return false;
        })();

        const DDP_error = (() => {
          if (this.basis != "DDP") return false;

          const newAddressError = this.onInputField(
            undefined,
            undefined,
            "newAddress",
            this.deliveryAddressCurrent?.key === "new_address",
            "select"
          );

          return newAddressError;
        })();

        let nameLegalEntityError,
          edrpouLegalEntityError,
          nameContactPersonError,
          contactPersonPhoneError;
        if (this.showNewCounterparty) {
          nameLegalEntityError = this.onInputField(
            undefined,
            undefined,
            "nameLegalEntity",
            true,
            "name"
          );
          edrpouLegalEntityError = this.onInputField(
            undefined,
            undefined,
            "edrpouLegalEntity",
            true,
            "edrpou"
          );
        }
        if (this.showNewContactPerson || this.showNewCounterparty) {
          nameContactPersonError = this.onInputField(
            undefined,
            undefined,
            "nameContactPerson",
            true,
            "name"
          );
          contactPersonPhoneError = this.onInputField(
            undefined,
            undefined,
            "contactPersonPhone",
            true,
            "tel"
          );
        }

        return (
          DDP_error ||
          CPT_FCA_error ||
          EXW_error ||
          this.disabledOrderButton ||
          //
          nameLegalEntityError ||
          edrpouLegalEntityError ||
          nameContactPersonError ||
          contactPersonPhoneError
        );
      })();

      if (!error) {
        this.success();
      }
    },
    onInputCheckBox(value, $event, name) {
      this.onInputField(value, $event, name, false, "checkbox");
      this.updateOrder();
    },
    onInputField(value, $event, name, required = false, validateType = "name") {
      if (!name) throw new Error("onInputField: name is undefined");
      if (value != undefined) this[name] = value;
      const validateError = this.$simpleInputValidate(
        value || this[name],
        required,
        validateType
      );
      this.inputError[name] = validateError;
      if (validateError.error) {
        this.$refs[name].$refs.input.focus();
      }
      return validateError.error;
    },
    changeDropdown(key, name, item) {
      console.log("changeDropdown", key, name, item);

      switch (name) {
        case "deliveryType":
          this.deliveryTypeCurrent = item;
          break;
        case "shipmentType":
          this.shipmentTypeCurrent = item;
          break;
        case "paymentType":
          this.paymentTypeCurrent = item;
          this.order.data.formOfRaisedFund = item;
          break;
        case "counterparty":
          this.counterpartyCurrent = item;
          this.contactPersonCurrent = null;

          break;
        case "contactPerson":
          this.contactPersonCurrent = item;
          break;
        case "deliveryAddress":
          this.deliveryAddressCurrent = item;
          break;
        case "carrier":
          this.carrierCurrent = item;
          break;
        case "regionType":
          this.regionTypeCurrent = item;
          break;
        case "shippingWarehouseType":
          this.shippingWarehouseCurrent = item;
          break;
        case "deliveryStation":
          this.deliveryStationCurrent = item;
          break;
        case "consignee":
          this.consigneeCurrent = item;
          break;
        case "wagonTypes":
          this.wagonTypesCurrent = item;
          break;
        case "newAddress":
          this.newAddress = item;
          break;

        default:
          return;
      }

      this.updateOrder();
    },
    async getDeliveryConditions(params = {}) {
      this.loading = true;

      const { success, list } = await this.$api.checkout.getDeliveryConditions({
        filters: {
          deliveryType: {
            id: this.deliveryTypeCurrent?.id,
          },
          shipmentType: {
            id: this.shipmentTypeCurrent?.id,
          },
          setStorage: (() => {
            if (
              this.deliveryTypeCurrent?.crmId ===
                process.env.VUE_APP_DELIVERY_DELIVERY && // DELIVERY
              this.shipmentTypeCurrent?.crmId ===
                process.env.VUE_APP_SHIPMENT_AUTO // AUTO
            )
              return true;
            if (this.basis === "CPT" || this.basis === "FCA")
              return this.choosePlaceDispatch;
            // default
            return false;
          })(),
          ...params,
        },
      });
      if (success) this.deliveryConditions = list;
      console.log("getDeliveryConditions", success, list);
      this.basisList = list;
      this.loading = false;
      return this.basis;
    },
    async getProduct(setData = false) {
      // const { success, data } = await this.$api.product.getProductCRM(
      //   this.order.productId
      // );
      console.log("getProduct this.order.id-----", this.order.id);
      const { success, data } = await this.$api.product.getProductID(
        this.order.id
      );
      if (setData) this.product = data;
      return { success, data };
    },
    async getPaymentTypes() {
      const { success, list } = await this.$api.checkout.getPaymentTypes();
      console.log("getPaymentTypes", success, list);
      if (success) this.paymentTypeList = list;
    },
    async getСompanies() {
      const { list = [] } = await this.$api.getСompanies();
      console.log("getСompanies", list);
      this.counterpartyList = list;
    },
    async getCompanyManagers() {
      this.loading = true;
      const { list } = await this.$api.company.getCompanyManagers({
        filters: {
          company: {
            id: this.counterpartyCurrent?.id,
          },
        },
      });
      console.log("getCompanyManagers", list);
      this.contactPersonList = list;
      this.loading = false;
    },
    async getRegions() {
      this.loading = true;
      const { list = [] } = await this.$api.checkout.getRegions();
      console.log("getRegions", list);
      this.regionTypeList = list;
      this.loading = false;
    },
    async getConsignees() {
      this.loading = true;
      const { list = [] } = await this.$api.checkout.getConsignees();
      console.log("getConsignees", list);
      this.consigneeList = list;
      this.loading = false;
    },
    createStationSearchParams(value) {
      const pagination = {
        pageSize: 100,
        withCount: false,
      }

      if (value == null) {
        return {
          sort: {
            name: 'asc',
          },
          pagination,
        }
      }

      const _value = value.trim();
      const containsNumber = /\d+/.test(_value);
      const isStationCode = /^\d{6}$/.test(_value);
      const filters =
        isStationCode
          ? { code: { $eq: _value } }
          : containsNumber
            ? {
              $or: [
                { code: { $containsi: _value } },
                { name: { $containsi: _value } }
              ]
            }
            // filter by name only by default
            : { name: { $containsi: _value } };
      const sort = containsNumber ? { code: 'desc' } : { name: 'asc' };

      return {
        filters,
        sort,
        pagination,
      }
    },
    async getStations(params = {}, abortSignal) {
      const { list = [] } = await this.$api.checkout.getStations(
        {
          deep: "railway",
          ...params,
        },
        abortSignal
      );
      return list;
    },
    createAddressSearchParams(value) {
      return { value };
    },
    async addressAutocomplete(params = {}) {
      if (params.value == null || params.value?.trim().length === 0) {
        return [];
      }

      const res = await this.$placesSearch.search(params.value);

      return res.map(({ place_id, ...item }) => ({
        ...item,
        place_id,
        id: place_id
      }));
    },
    async getLogisticCompanies() {
      this.loading = true;
      const { list = [] } = await this.$api.checkout.getLogisticCompanies();
      console.log("getLogisticCompanies", list);
      this.carrierList = list;
      this.loading = false;
    },
    async getDeliveryAddress() {
      this.loading = true;
      const { list = [] } = await this.$api.checkout.deliveryAddress();
      console.log("deliveryAddress", list);
      this.deliveryAddressList = list;
      this.loading = false;
    },
    labelBlur(val, e, name) {
      if (name === "quantity") {
        this.reCalcQuantity(val);
      }
      if (name === "notes") {
        this.order.data.notes = val;
      }
      // if (name === "quantity") {
      //   this.order.data.quantity = val;
      // }

      this.updateOrder();
      console.log("labelBlur", val, e, name);
    },
    reCalcQuantity(val = this.quantity) {
      if (val > 99999999.99) val = 99999999;

      const { wagonNorm = "1.00" } = this.wagonTypesCurrent?.wagonNorm || {};
      let newValue = this.$roundToNearestMultiple(val, wagonNorm);
      if (newValue < wagonNorm) newValue = wagonNorm;
      // clear quotas
      if (this.order.data.quantity != newValue) this.order.data.quotas = [];
      this.quantity = newValue;
      this.order.data.quantity = newValue;
      console.log(
        "reCalcQuantity ---- this.order.data.quantity ",
        this.order.data.quantity
      );
      this.quantityKey++;

      console.log("reCalcQuantity", val, newValue, wagonNorm, this.quantityKey);
    },
    async createOrder() {
      this.loading = true;
      const { data } = await this.$api.checkout.createOrder({
        data: {
          product: this.product.id,
          user: this.$auth.user,
          quantity: this.quantity,
        },
      });
      console.log("createOrder", data);
      this.order.data = data;
      this.$order.set(this.order);
      this.loading = false;
    },
    async getOrder(product) {
      if (!product.success) return false;

      const { success, data } = await this.$api.checkout.getOrder(this.order.data.id);
      console.log("getOrder", data);
      
      if (!success) {
        this.$notice(this.$translate("request_error"), undefined, {
          type: "error",
        });
        
        this.$router.push({ name: "orders" });
        return
      }

      this.paymentTypeCurrent = data.formOfRaisedFund;

      this.product = product.data;

      // new counterparty
      const {
        quantity,
        account,
        accountName,
        accountEdrpou,
        accountContactName,
        accountContactPhone,
        accountContact,
        deliveryType,
        shipmentType,
        storage,
        destinationStation,
        consignee,
        consigneeCode,
        consigneeEdrpou,
        consigneeName,
        shipmentAddress,
        shipmentAddressType,
        shipmentAddressPlaceId,
        railroadNote,
        railroadNote15,
        useAgent,
        logisticCompany,
        typeWagon,
        notes,
      } = data;
      this.counterpartyCurrent = account;
      this.quantity = quantity;
      this.quantityKey++;
      this.notes = notes;
      this.notesKey++;

      let currentBasis = null;
      if (deliveryType?.id && shipmentType?.id) {
        currentBasis = await this.getDeliveryConditions({
          deliveryType: deliveryType?.id,
          shipmentType: shipmentType?.id,
          setStorage: storage != null,
        });
      }

      if (account) {
        data.accountName = "";
        data.accountEdrpou = "";
        await this.getCompanyManagers();
        if (accountContact) {
          this.contactPersonCurrent = accountContact;
        } else if (accountContactName || accountContactPhone) {
          this.contactPersonCurrent = this.contactPersonListComputed.find(
            ({ id }) => id === "new_contact_person"
          );
        }
      }

      if (
        !account &&
        (accountName ||
          accountEdrpou ||
          accountContactName ||
          accountContactPhone)
      ) {
        this.counterpartyCurrent = this.counterpartyListComputed.find(
          ({ id }) => id === "new_counterparty"
        );
      }
      if (deliveryType) {
        await Promise.all([
          await this.getRegions(),
          await this.getConsignees(),
        ]).catch((err) => {
          console.error("getOrder", err);
        });

        // setTimeout(() => {
        this.deliveryTypeCurrent = {
          ...this.deliveryTypeList.find(({ id }) => id === deliveryType.id),
        };
        this.shipmentTypeList = this.deliveryTypeCurrent.shipmentTypes;

        if (shipmentType) {
          this.shipmentTypeCurrent =
            this.deliveryTypeCurrent.shipmentTypes.find(
              ({ id }) => id === shipmentType.id
            );
        }

        if (storage) {
          this.regionTypeCurrent = this.regionTypeList.find((el) => {
            return el.storages.find((el) => el.id === storage.id);
          });
          if (this.regionTypeCurrent) {
            this.shippingWarehouseCurrent =
              this.regionTypeCurrent.storages.find(
                ({ id }) => id === storage.id
              );
          }
        }

        if (destinationStation) {
          this.deliveryStationCurrent = destinationStation;
        } else if (
          this.shipmentTypeCurrent?.crmId ===
          process.env.VUE_APP_SHIPMENT_RAILWAY
        )

        if (consignee) {
          this.consigneeCurrent = consignee;
          this.consigneeName = consignee.name;
          this.consigneeEdrpou = consignee.edrpou;
          this.consigneeCode = consignee.railCode;
        } else if (consigneeCode || consigneeEdrpou || consigneeName) {
          this.consigneeCurrent = this.consigneeListComputed.find(
            ({ id }) => id === "own_company"
          );
          this.consigneeName = consigneeName;
          this.consigneeEdrpou = consigneeEdrpou;
          this.consigneeCode = consigneeCode;
        }
        if (
          storage &&
          shipmentType?.crmId === process.env.VUE_APP_SHIPMENT_RAILWAY
        ) {
          this.choosePlaceDispatch = true;
          this.choosePlaceDispatchKey++;
          await this.getDeliveryConditions();
          if (storage) {
            this.regionTypeCurrent = this.regionTypeList.find((el) => {
              return el.storages.find((el) => el.id === storage.id);
            });
            if (this.regionTypeCurrent) {
              this.shippingWarehouseCurrent =
                this.regionTypeCurrent.storages.find(
                  ({ id }) => id === storage.id
                );
            }
          }
        }
        if (currentBasis === "DDP") {
          await this.getDeliveryAddress();

          if (shipmentAddress) {
            const deliveryAddressCurrent = this.deliveryAddressListComputed.find(
              (el) => el.name === shipmentAddressType
            );

            if (deliveryAddressCurrent) {
              this.deliveryAddressCurrent = deliveryAddressCurrent;
            } else {
              this.deliveryAddressCurrent =
                this.deliveryAddressListComputed.find(
                  ({ key }) => key === "new_address"
                );
              if (shipmentAddressPlaceId != null) {
                this.newAddress = {
                  id: shipmentAddressPlaceId,
                  place_id: shipmentAddressPlaceId,
                  description: shipmentAddress,
                };
              } else {
                this.newAddress = shipmentAddress;
              }
            }
          }
        }

        // additionalAccordion
        this.shippingNotes = railroadNote;
        this.shippingNotesKey++;
        this.columnRailway15 = railroadNote15;
        this.columnRailway15Key++;
        this.notTreatAntiCakingAgent = !useAgent;
        if (railroadNote || railroadNote15 || !useAgent) {
          this.additionalAccordionOpen = true;
        }
        this.additionalAccordionKey++;
        if (currentBasis === "DDP") {
          await this.getLogisticCompanies();

          const find = this.carrierList.find(
            (el) => el.id === logisticCompany?.id
          );
          if (find) {
            this.carrierCurrent = find;
          } else if (this.carrierList.length === 1) {
            this.carrierCurrent = this.carrierList[0];
            await this.updateOrder();
          }
        }

        const wagonTypesListComputed = (() => {
          if (currentBasis === "CPT" && this.CPT_Condition)
            return this.CPT_Condition.transportTypes;
          if (currentBasis === "FCA" && this.FCA_Condition)
            return this.FCA_Condition.transportTypes;
          if (currentBasis === "DDP" && this.DDP_Condition)
            return this.DDP_Condition.transportTypes;
          return [];
        })();

        // wagonTypes
        this.wagonTypesCurrent =
          wagonTypesListComputed.find(({ id }) => id === typeWagon?.id) || null;

        // fix disabledChoosePlaceDispatch
        this.disabledChoosePlaceDispatch =
          !this.FCA_Condition || !this.CPT_Condition;
      }

      // save
      this.order.data = data;
      this.$order.set(this.order);
    },
    async updateOrder(newOrderData = undefined, callBack = () => {}) {
      this.loading = true;

      // await new Promise((resolve) => {
      return setTimeout(async () => {
        this.order.data.deliveryCondition = this.basisCurrent?.id;

        const { data, success } = await this.$api.checkout.updateOrder(
          this.order.data.id,
          this.order.data,
          newOrderData
        );
        if (success) {
          this.order.data = {
            ...this.order.data,
            ...data,
          };
          console.log("updateOrder", success, data);

          this.$order.set(this.order);
          // return resolve();
          this.loading = false;
          await this.getQuotas();
          if (this.shippingWarehouseCurrent) await this.getDeliveryConditions();
          this.DispatchCheckout();
        } else {
          await this.getOrder(await this.getProduct());
          this.loading = false;
        }
        callBack();
      }, 250);
      // });

      // this.loading = false;
    },
    async getDeliveryType() {
      const { list = [] } = await this.$api.checkout.getDeliveryType();
      this.deliveryTypeList = list;
      console.log("getDeliveryType", list);
    },
    getQuotaText(item) {
      const date = this.$moment(item.date).format("DD.MM.YY");
      return (
        this.$translate("supply", "orders") +
        " " +
        item.quantity +
        " " +
        (this.product?.unit?.name || "т") +
        ", " +
        date
      );
    },
    async getQuotas() {
      const { list = [] } = await this.$api.checkout.getQuotas({
        filters: {
          product: {
            id: this.product.suGoodWithoutPackaging.id,
          },
          deliveryCondition: {
            id: this.basisCurrent?.id,
          },
          stock: {
            id: this.shippingWarehouseCurrent?.id,
          },
        },
        sort: {
          date: "asc",
          id: "desc"
        },
        fields: [
          'date',
          'crmId',
          'balance',
        ]
      });
      this.quotas = uniqBy(list, 'date');
    },
    async updateOrderQuotas(list) {
      this.order.data.quotas = list;
      await this.updateOrder();
    },
    async resetQuotas(reset = false) {
      this.quotas = [];
      this.order.data.quotas = [];
      if (reset) {
        this.order.data.quotasUpdatedAt = null;
        await this.updateOrderQuotas([]);
      }
    },
    removeQuota(id) {
      const index = this.order.data.quotas.findIndex((el) => el.id === id);
      this.order.data.quotas.splice(index, 1);
      this.updateOrder();
    },
    DispatchCheckout() {
      this.SET_Checkout({
        quotas: this.quotas,
        product: this.product,
        order: this.order,
        wagonTypes: this.wagonTypesCurrent,
        calcQuantity: this.calcQuantity,
        basis: this.basis,
        shipmentType: this.shipmentTypeCurrent,
      });
    },
    async distributeSupply(dateFrom) {
      let totalQuantityAvailable = this.order.data.quantity;
      const { wagonNorm = 1 } = this?.wagonTypesCurrent?.wagonNorm || {};
      console.log("distributeSupply dateFrom", dateFrom);
      const quotas = this.quotas
        .filter(({ date }) => {
          if (dateFrom) {
            return (
              this.$moment(
                this.$moment(dateFrom, "DD/MM/YYYY").format("YYYY-MM-DD")
              ).unix() <= this.$moment(date).unix()
            );
          }
          return (
            this.$moment(this.$moment().format("YYYY-MM-DD"))
              .add(2, "day")
              .unix() < this.$moment(date).unix()
          );
        })
        .map((el) => {
          let quantity = el.balance;
          // more than available on order
          if (quantity > totalQuantityAvailable) {
            quantity = totalQuantityAvailable;
          }
          // round to nearest multiple
          quantity = this.$roundToNearestMultiple(quantity, wagonNorm);
          // if more than available on quota
          if (quantity > el.balance) quantity = quantity - wagonNorm;
          totalQuantityAvailable -= quantity;
          return {
            date: el.date,
            quantity,
          };
        })
        .filter(({ quantity }) => quantity > 0);
      await this.updateOrderQuotas(quotas);
    },
    async saveDrafts() {
      this.resetQuotas();
      this.order.data.quotasUpdatedAt = null;
      await this.updateOrder(undefined, () => {
        this.$router.push({ name: "orders" });
      });
    },
    openOrderRecommendedVolumePopup() {
      this.$popup.show('OrderRecommendedVolume', {
        product: {
          id: this.product.id,
          crmId: this.product.crmId,
          name: this.product.name,
        },
        wagonNorm: this.wagonTypesCurrent?.wagonNorm?.wagonNorm,
      })
    },
    async ConfigureMarketingProduct(config) {
      const find = this.order.data.marketingProducts.find(
        ({ marketingProduct }) =>
          marketingProduct.id === config.marketingProduct.id
      );
      console.log("ConfigureMarketingProduct", { config, find });

      if (find) {
        this.order.data.marketingProducts =
          this.order.data.marketingProducts.map((el) => {
            if (el.marketingProduct.id === config.marketingProduct.id)
              return config;
            return el;
          });
      } else {
        this.order.data.marketingProducts.push(config);
      }

      await this.updateOrder();
      this.emitter.emit("SuccessUpdateConfigureMarketingProduct", config);
    },
    async disableMarketingProduct(id) {
      const index = this.order.data.marketingProducts.findIndex(
        (item) => item.id === id
      );
      this.order.data.marketingProducts.splice(index, 1);
      await this.updateOrder();
    },
    async handleOrderRecommendedVolume({ quantity }) {
      this.quantity = quantity;
    },
  },
  watch: {
    loading(val) {
      console.log("emit loadingOrder", val);
      this.$store.commit("checkout/SET_LoadingOrder", val);
    },
    deliveryTypeListComputed(val) {
      if (val.length == 1) this.deliveryTypeCurrent = val[0];
    },
    deliveryTypeCurrent(item) {
      this.shipmentTypeList = item.shipmentTypes;
      console.log(
        "shipmentTypeList this.setDataFromOrder",
        this.setDataFromOrder
      );
      if (this.setDataFromOrder && this.order.data.shipmentType) {
        this.shipmentTypeCurrent = item.shipmentTypes.find(
          ({ id }) => id === this.order.data.shipmentType.id
        );
      } else if (this.shipmentTypeListComputed.length === 1) {
        this.shipmentTypeCurrent = this.shipmentTypeListComputed[0];
      } else {
        this.shipmentTypeCurrent = null;
      }
      this.deliveryConditions = [];

      // set order data
      this.order.data.deliveryType = item;
      this.order.data.shipmentType = this.shipmentTypeCurrent;

      if (this.disableWatch) return;
      // clear
      this.resetQuotas();
      this.shippingWarehouseCurrent = null;
      this.consigneeCurrent = null;
      this.order.data.consignee = null;
      this.order.data.consigneeName = null;
      this.order.data.consigneeEdrpou = null;
      this.order.data.consigneeCode = null;
    },
    async shipmentTypeCurrent(val) {
      // set order data
      this.order.data.shipmentType = val;

      this.watchChoosePlaceDispatch = false; // disable watch
      this.choosePlaceDispatch = false;

      if (
        !this.CPT_Condition &&
        this.FCA_Condition &&
        val.crmId === process.env.VUE_APP_SHIPMENT_RAILWAY
      ) {
        this.choosePlaceDispatch = true;
      }

      if (!this.disableWatch) {
        // clear
        this.regionTypeCurrent = null;
        this.deliveryStationCurrent = null;
        this.consigneeCurrent = null;
        this.deliveryAddressCurrent = null;

        if (this.basis === "EXW") {
          this.wagonTypesCurrent = null;
        }

        // additionalAccordion
        this.shippingNotes = "";
        this.columnRailway15 = "";
        this.notTreatAntiCakingAgent = false;
        this.additionalAccordionOpen = false;
        this.additionalAccordionKey++;
      }

      if (val) await this.getDeliveryConditions();
      this.watchChoosePlaceDispatch = true; // enable watch

      if (this.disableWatch) return;
      this.resetQuotas();

      this.disabledChoosePlaceDispatch =
        !this.FCA_Condition || !this.CPT_Condition;

      // get regions for EXW, FCA, DDP
      if (
        (val &&
          val.crmId === process.env.VUE_APP_SHIPMENT_AUTO &&
          this.deliveryTypeCurrent.crmId ===
            process.env.VUE_APP_DELIVERY_PICK_UP) ||
        (val &&
          val.crmId === process.env.VUE_APP_SHIPMENT_RAILWAY &&
          this.choosePlaceDispatch) ||
        (val &&
          val.crmId === process.env.VUE_APP_SHIPMENT_AUTO &&
          this.deliveryTypeCurrent.crmId ===
            process.env.VUE_APP_DELIVERY_DELIVERY)
      ) {
        if (!this.regionTypeList.length) await this.getRegions();
        this.regionTypeCurrent =
          this.regionTypeList.length === 1 ? this.regionTypeList[0] : null;
      }
      // clear
      this.shippingWarehouseCurrent = null;

      // get consignees for CPT and FCA
      if (this.CPT_Condition || this.FCA_Condition) {
        if (!this.consigneeList.length) await this.getConsignees();
      }

      // get logistic companies for DDP
      if (this.basis === "DDP") {
        if (!this.carrierList.length) await this.getLogisticCompanies();
        this.carrierCurrent =
          this.carrierList.length === 1 ? this.carrierList[0] : null;
      } else {
        this.carrierCurrent = null;
      }

      // get delivery address for DDP
      if (this.basis === "DDP") {
        if (!this.deliveryAddressList.length) await this.getDeliveryAddress();
        this.deliveryAddressCurrent =
          this.deliveryAddressListComputed.length === 1
            ? this.deliveryAddressListComputed[0]
            : null;
      } else {
        this.deliveryAddressCurrent = null;
      }

      await this.updateOrder();
    },
    async choosePlaceDispatch(val) {
      if (this.disableWatch) return;
      if (!this.watchChoosePlaceDispatch) return;
      await this.getDeliveryConditions();
      this.resetQuotas();
      // get regions for FCA
      if (val && !this.regionTypeList.length) await this.getRegions();
      this.regionTypeCurrent =
        this.regionTypeList.length === 1 ? this.regionTypeList[0] : null;

      this.regionTypeCurrent = null;
      await this.updateOrder();
    },
    async counterpartyCurrent(val) {
      if (this.disableWatch) return;

      if (val && val.id === "new_counterparty") {
        this.order.data.account = null;
      } else {
        this.order.data.account = val;
      }
      // clear fields
      this.nameLegalEntity = "";
      this.edrpouLegalEntity = "";
      this.nameContactPerson = "";
      this.contactPersonPhone = "";
      this.counterpartyContentKey++;
      if (val && val.id != "new_counterparty") await this.getCompanyManagers();
      this.counterpartyContentKey++;
    },
    contactPersonList(val) {
      console.log("watch contactPersonList", val);
      if (this.contactPersonListComputed.length === 1) {
        this.contactPersonCurrent = this.contactPersonListComputed[0];
      } else {
        this.contactPersonCurrent = null;
      }
    },
    contactPersonCurrent(val) {
      if (this.disableWatch) return;
      if (val && val.id === "new_contact_person") {
        this.order.data.accountContact = null;
      } else {
        this.order.data.accountContact = val;
      }

      // clear fields
      this.nameLegalEntity = "";
      this.edrpouLegalEntity = "";
      this.nameContactPerson = "";
      this.contactPersonPhone = "";
    },
    regionTypeCurrent(val) {
      if (val) {
        this.shippingWarehouseList = val.storages;
        if (this.shippingWarehouseList.length === 1) {
          this.shippingWarehouseCurrent = this.shippingWarehouseList[0];
        } else {
          this.shippingWarehouseCurrent = null;
        }
      } else {
        this.shippingWarehouseCurrent = null;
      }

      // clear
      if (this.disableWatch) return;
      this.order.data.storage = null;
    },
    shippingWarehouseCurrent(val) {
      this.order.data.storage = val;
      // clear
      if (this.disableWatch) return;
      this.resetQuotas();
    },
    deliveryStationCurrent(val) {
      this.order.data.destinationStation = val;
    },
    consigneeCurrent(val) {
      this.consigneeKey++;

      console.log("watch consigneeCurrent", val);

      this.consigneeNameDisabled = !this.isOwnCompany;
      this.consigneeEdrpouDisabled = !this.isOwnCompany;
      this.consigneeCodeDisabled = !this.isOwnCompany;

      if (this.disableWatch) return;

      this.consigneeName = this.isOwnCompany ? "" : val?.name;
      this.consigneeEdrpou = this.isOwnCompany ? "" : val?.edrpou;
      this.consigneeCode = this.isOwnCompany ? "" : val?.railCode;

      this.consigneeKey++;

      // set data
      this.order.data.consignee = this.isOwnCompany ? null : val;
      this.order.data.consigneeName = null;
      this.order.data.consigneeEdrpou = null;
      this.order.data.consigneeCode = null;
    },
    wagonTypesListComputed(val) {
      if (this.disableWatch) return;
      if (val && val.length === 1) {
        this.wagonTypesCurrent = val[0];
      } else {
        this.wagonTypesCurrent = null;
      }
    },
    wagonTypesCurrent(val) {
      if (this.disableWatch) return;
      console.log("watch wagonTypesCurrent", val);
      this.reCalcQuantity(0);
      this.order.data.typeWagon = val;
      // this.order.data.quantity = this.quantity;
    },
    counterpartyListComputed(val) {
      if (this.disableWatch) return;
      if (val.length === 1) {
        this.counterpartyCurrent = val[0];
      } else {
        this.counterpartyCurrent = null;
      }
    },
    nameLegalEntity(val) {
      console.log("watch nameLegalEntity", val);
      this.order.data.accountName = val;
    },
    edrpouLegalEntity(val) {
      console.log("watch edrpouLegalEntity", val);
      this.order.data.accountEdrpou = val;
    },
    nameContactPerson(val) {
      console.log("watch nameContactPerson", val);
      this.order.data.accountContactName = val;
    },
    contactPersonPhone(val) {
      console.log("watch contactPersonPhone", val);
      this.order.data.accountContactPhone = val;
    },
    consigneeName(val) {
      console.log("watch consigneeName", val);
      this.order.data.consigneeName = this.isOwnCompany ? val : null;
    },
    consigneeEdrpou(val) {
      console.log("watch consigneeEdrpou", val);
      this.order.data.consigneeEdrpou = this.isOwnCompany ? val : null;
    },
    consigneeCode(val) {
      console.log("watch consigneeCode", val);
      this.order.data.consigneeCode = this.isOwnCompany ? val : null;
    },
    carrierCurrent(val) {
      console.log("watch carrierCurrent", val);
      this.order.data.logisticCompany = val;
    },
    shippingNotes(val) {
      console.log("watch shippingNotes", val);
      this.order.data.railroadNote = val;
    },
    columnRailway15(val) {
      console.log("watch columnRailway15", val);
      this.order.data.railroadNote15 = val;
    },
    notTreatAntiCakingAgent(val) {
      console.log("watch notTreatAntiCakingAgent", val);
      this.order.data.useAgent = !val;
    },
    // quantity(val) {
    //   console.log("watch quantity", val);
    //   this.order.data.quantity = val;
    // },
    deliveryAddressCurrent(val) {
      if (this.disableWatch) return;
      console.log("watch deliveryAddressCurrent", val);
      if (val && val.key !== "new_address") {
        this.order.data.shipmentAddressType = val.name;
        this.order.data.shipmentAddress = val.address;
        this.order.data.shipmentAddressPlaceId = val.addressPlaceId;
      } else {
        this.order.data.shipmentAddressType = null;
        this.order.data.shipmentAddress = null;
        this.order.data.shipmentAddressPlaceId = null;
      }

      this.newAddress = null;
      this.newAddressKey++;
    },
    newAddress(val) {
      if (this.disableWatch) return;
      console.log("watch newAddress", val);
      if (val == null) {
        this.order.data.shipmentAddress = "";
        this.order.data.shipmentAddressPlaceId = null;
        return
      }
      if (typeof val === "string") {
        this.order.data.shipmentAddress = val;
        this.order.data.shipmentAddressPlaceId = null;
        return
      }
      if (val.place_id) {
        this.order.data.shipmentAddress = val.description;
        this.order.data.shipmentAddressPlaceId = val.place_id;
        return
      }
      // If it's company's address
      if (val.name) {
        this.order.data.shipmentAddress = val.address;
        this.order.data.shipmentAddressType = val.name;
        this.order.data.shipmentAddressPlaceId = val.addressPlaceId || null;
        return
      }
    },
  },
  async created() {
    this.SET_CostCalculation([
      "2022-04-09T14:02:30.210Z",
      "2022-04-16T14:02:30.210Z",
    ]);
    this.order = this.$order.get();
    console.log(this.order);
    this.loading = true;
    if (!this.order)
      return this.$store.dispatch("SET_ErrorPage", {
        code: 404,
        message: this.$translate("order_not_found", "orders"),
      });
    await this.getDeliveryType();
    if (this.order?.data?.id) {
      await this.getOrder(await this.getProduct());
    } else {
      await this.getProduct(true);
    }

    // check status new
    if (
      this.order?.data?.status?.slug &&
      this.order.data.status.slug !== "draft"
    ) {
      return this.$router.push({
        name: "my-order",
        params: { id: this.order.data.id },
      });
    }

    if (this.product) {
      if (!this.order?.data?.id) await this.createOrder();
      await this.getPaymentTypes();
      await this.getСompanies();
      await this.getQuotas();
      this.DispatchCheckout();
    }
    this.disableWatch = false;
    this.loading = false;
    this.setDataFromOrder = false;
    
    if (!this.$placesSearch.ready) {
      this.$placesSearch.init();
    }

    this.emitter.on("updateOrderQuotas", this.updateOrderQuotas);
    this.emitter.on("distributeSupply", this.distributeSupply);
    this.emitter.on("resetQuotas", this.resetQuotas);
    this.emitter.on("saveDrafts", this.saveDrafts);
    this.emitter.on(
      "ConfigureMarketingProduct",
      this.ConfigureMarketingProduct
    );
    this.emitter.on("disableMarketingProduct", this.disableMarketingProduct);
    this.emitter.on("orderRecommendedVolume", this.disableMarketingProduct);
    // this.$popup.show("DeliverySchedule");
  },
  beforeUnmount() {
    this.$store.commit("checkout/SET_LoadingOrder", false);
    this.emitter.off("updateOrderQuotas", this.updateOrderQuotas);
    this.emitter.off("distributeSupply", this.distributeSupply);
    this.emitter.off("resetQuotas", this.resetQuotas);
    this.emitter.off("saveDrafts", this.saveDrafts);
    this.emitter.off(
      "ConfigureMarketingProduct",
      this.ConfigureMarketingProduct
    );
    this.emitter.off("disableMarketingProduct", this.disableMarketingProduct);
    this.emitter.off("orderRecommendedVolume", this.disableMarketingProduct);
  },
};
</script>

<style lang="scss" scoped src="./component.scss"></style>

<style lang="scss">
// @include minDesk {
//   .wrapper.checkout-page {
//     padding-right: toRem(45);
//     & > .container {
//       padding-right: 0;
//     }
//   }
// }
@media (max-width: $mobile) {
  .wrapper.checkout-page {
    overflow: hidden;
  }
}
</style>
