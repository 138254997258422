<template>
  <div
    class="MarketingProductWrap"
    :class="{
      checked,
      disabled,
      configuredProduct: checked && isConfiguredProduct,
      notConfiguredProduct: checked && !disabled && configuredProductError,
      canEnableProduct,
    }"
  >
    <div class="MarketingProduct" :class="{ checked, disabled }">
      <div class="image" @click="showDetails">
        <img :src="iconLink" :alt="item.name" v-if="iconLink" />
      </div>
      <div class="content">
        <h6 @click="showDetails">
          {{ item.name }} <ArrowSVG class="arrow" v-if="!disabled" />
        </h6>
        <div class="additionslContent">
          <template v-if="disabled">
            <span>{{ $translate("not_available") }}</span>
            <Notice
              class="boldTitle gray bottom left_center"
              :title="$translate('conditions_promotion', 'orders')"
            >
              <ul>
                <li v-for="item in conditionsFiltered" :key="item.id">
                  <SuccessSVG v-if="item.unlocked" class="status" />
                  <CloseSVG v-else class="status error" />
                  <b>{{ item.name }}</b>
                  <span>{{ item.caption }}</span>
                </li>
              </ul>
            </Notice>
          </template>
          <template v-else-if="checked && isConfiguredProduct">
            {{ $translate("configured_product", "orders") }}
          </template>
          <template v-else-if="checked && configuredProductError">
            {{ $translate("not_configured_product", "orders") }}
          </template>
        </div>
        <!-- <u class="details" @click="showDetails" v-if="!disabled">{{
          $translate("details", "orders")
        }}</u> -->
        <Notice
          v-if="item.conditions == null"
          class="underline boldTitle gray bottom left_center"
          :text="$translate('details', 'orders')"
          :disableIcon="true"
          @click="showDetails"
        >
          {{ $translate('order_marketing_product_no_conditions', 'orders') }}
        </Notice>
        <Notice
          v-else-if="!disabled"
          class="underline boldTitle gray bottom left_center"
          :text="$translate('details', 'orders')"
          :disableIcon="true"
          @click="showDetails"
        >
          <ul>
            <li v-for="item in conditionsFiltered" :key="item.id">
              <SuccessSVG v-if="item.unlocked" class="status" />
              <CloseSVG v-else class="status error" />
              <b>{{ item.name }}</b>
              <span>{{ item.caption }}</span>
            </li>
          </ul>
        </Notice>
      </div>
    </div>
    <CheckBox
      class="big marginBottom0"
      name="CheckBox"
      @onInput="onInputCheckBox"
      ref="CheckBox"
      :checked="checked"
      :toggle="true"
      :disabled="disabled"
      :key="CheckBoxKey"
    />
  </div>
</template>

<script>
// import { mapGetters, mapActions } from 'vuex'
import CloseSVG from "@/assets/img/close.svg";
import SuccessSVG from "@/assets/img/success2.svg";
import CheckBox from "@/components/CheckBox";
import Notice from "@/components/Notice";
import ArrowSVG from "@/assets/img/arrow_right_long.svg";
/**
 * The MarketingProduct component.
 */
export default {
  name: "MarketingProduct",
  components: { CheckBox, Notice, ArrowSVG, CloseSVG, SuccessSVG },
  props: {
    /**
     * component data
     */
    item: { default: null, type: Object },
    currentMarketingProduct: { default: null, type: Object },
  },
  data() {
    return {
      checked: false,
      mounted: false,
      CheckBoxKey: 0,
      unsubscribeStoreAction: () => {},
    };
  },
  computed: {
    iconLink() {
      return process.env.VUE_APP_API_DOMAIN + this.item.icon.url;
    },
    disabled() {
      return !this.item.unlocked;
    },
    doesntRequireConfiguration() {
      return this.isEmptyFormData(this.item);
    },
    isConfiguredProduct() {
      if (!this.item.unlocked) return false;
      if (this.doesntRequireConfiguration) return true;

      return this.currentMarketingProduct?.formData?.find(({ type, value }) => {
        return type === "file" && value;
      });
    },
    configuredProductError() {
      if (!this.item.unlocked || !this.isConfiguredProduct) return true;
      if (this.doesntRequireConfiguration) return false;

      return !this.currentMarketingProduct?.formData?.find(({ type, value }) => {
        return type === "file" && value;
      });
    },
    conditionsFiltered() {
      return this.item.conditions;
    },
    canEnableProduct() {
      return (
        (this.item.unlocked && !this.isConfiguredProduct) ||
        this.isConfiguredProduct
      );
    },
  },
  methods: {
    // ...mapActions(['GET_PaymentInfo']),
    onInputCheckBox(value, $event, name) {
      this.checked = value;
      console.log("onInputCheckBox MarketingProduct", value, $event, name);
    },
    handleHideConfigureMarketingProduct() {
      const isNotFullyFilled = this.currentMarketingProduct?.formData?.some(
        ({ value }) => value == null || value === '' || (Array.isArray(value) && value.length == 0)
      );
      if (this.currentMarketingProduct == null || isNotFullyFilled) {
        this.checked = false;
        this.CheckBoxKey++;
      }

    },
    showDetails() {
      if (!this.canEnableProduct) return;
      if (!this.checked) {
        this.checked = true;
        this.CheckBoxKey++;
      }
      if (this.doesntRequireConfiguration) {
        this.emitter.emit("ConfigureMarketingProduct", {
          marketingProduct: {
            id: this.item.id,
          },
          formData: [],
        });
        return;
      }
      this.$popup.show("ConfigureMarketingProduct", {
        id: this.item.id,
        marketingProduct: this.item,
        currentMarketingProduct: this.currentMarketingProduct,
      });
    },
    removeCurrentMarketingProduct() {
      this.emitter.emit(
        "disableMarketingProduct",
        this.currentMarketingProduct.id
      );
      this.checked = false;
      this.CheckBoxKey++;
    },
    isEmptyFormData(product) {
      return (
        product?.formData == null ||
        (Array.isArray(product?.formData) && product?.formData?.length == 0)
      )
    },
  },
  watch: {
    checked(value) {
      if (!value && this.currentMarketingProduct) {
        this.emitter.emit(
          "disableMarketingProduct",
          this.currentMarketingProduct.id
        );
      } else if (this.mounted && value && !this.currentMarketingProduct) {
        this.showDetails();
      }
    },
  },
  created() {
    this.checked = (() => {
      if (this.item.unlocked) {
        return this.currentMarketingProduct?.formData.find(
          ({ type, value }) => {
            return type === "file" && value;
          }
        )
          ? true
          : false;
      }
      return false;
    })();
    if (!this.item.unlocked && this.currentMarketingProduct) {
      this.removeCurrentMarketingProduct();
    }
    this.unsubscribeStoreAction = this.$store.subscribeAction(({ type, payload }) => {
      // check if the action was called is an action relevant to the current instance of the component,
      // since these are intended to be placed on the same page
      if (
        (type === "HIDE_Popup" && payload.name === "ConfigureMarketingProduct") ||
        (
          Array.isArray(payload) &&
          payload.some(elem => elem instanceof Element && parseInt(elem.dataset.id) === this.item.id)
        )
      ) {
        this.handleHideConfigureMarketingProduct();
      }
    });
  },
  updated() {
    if (!this.item.unlocked && this.currentMarketingProduct) {
      this.removeCurrentMarketingProduct();
    }
  },
  mounted() {
    this.mounted = true;
  },
  beforeUnmount() {
    if (typeof this.unsubscribeStoreAction === "function") {
      this.unsubscribeStoreAction();
    }
  },
};
</script>

<style lang="scss" scoped src="./component.scss"></style>
