export default (context = false, active = '') => {
  if (!context) return [];
  return [
    {
      id: 1,
      text: context.$translate('order_details', 'orders'),
      to: { name: 'my-order', params: { id: context.$route.params.id } },
      active: active == 'my-order',
    },
    {
      id: 2,
      text: context.$translate('accompanying_documents', 'orders'),
      to: {
        name: 'accompanying-documents',
        params: { id: context.$route.params.id },
      },
      active: active == 'accompanying-documents',
    },
    {
      id: 3,
      text: context.$translate('transport_instructions', 'orders'),
      to: {
        name: 'my-order-transport-instructions',
        params: { id: context.$route.params.id },
      },
      active: active == 'my-order-transport-instructions',
    },
    // {
    //   id: 4,
    //   text: context.$translate("notification", "general"),
    //   to: { name: "my-order", params: { id: context.$route.params.id } },
    //   active: active == "default",
    // },
  ];
};
