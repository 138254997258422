<template>
  <div class="SwiperPagination">
    <div
      class="SwiperPagination__item"
      @click="() => this.$emit('change', index)"
      v-for="(item, index) in total"
      :key="index"
      :class="{ active: index === this.index }"
    >
      <div
        class="SwiperPagination__item__circle"
        :class="{ active: index === this.index }"
      ></div>
    </div>
  </div>
</template>

<script>
/**
 * The SwiperPagination component.
 */
export default {
  name: "SwiperPagination",
  components: {},
  props: {
    total: { default: 0, type: Number },
    index: { default: 0, type: Number },
  },
  data() {
    return {};
  },
  computed: {
    // ...mapGetters(['IS_Tablet']),
  },
  methods: {
    // ...mapActions(['GET_PaymentInfo']),
  },
};
</script>

<style lang="scss" scoped src="./component.scss"></style>