export default {
    state: {
        hasUnread: false,
    },
    getters: {
        GET_HasUnread: (state) => state.hasUnread,
    },
    mutations: {
        SET_HasUnread(state, hasUnread) {
            state.hasUnread = hasUnread;
        },
    },
    actions: {
        SET_MarkAsRead({ commit }) {
            commit('SET_HasUnread', false);
        },
        EFFECT_WatchNotifications({ commit }) {
            window.$app.config.globalProperties.$api.io.onAny((event, data) => {
                console.log('[io event]', event, data);
                commit('SET_HasUnread', true);
            });
        },
    }
}