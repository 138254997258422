<template>
  <div class="ChatMessageWrap">
    <div class="ChatMessage" :class="['role_' + message.role]">
      <div v-if="message.attachment" class="ChatMessageAttachment">
        <RequestCommentAttachmentSvg />
        <a
          :href="fullUrl"
          class="ChatMessageAttachmentFilename"
          :download="message.attachment.name">
          {{ message.attachment.name }}
        </a>
      </div>
      <div class="Tag">
        {{ message.message }}
      </div>
      <div class="infoRow">
        <div class="wrap">
          <span class="author">
            <b>{{ message.author }},</b>
          </span>
          <span class="date_time">{{ currentDate }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RequestCommentAttachmentSvg from "@/assets/img/request-comment-attachment.svg"

/**
 * The ChatMessage component.
 */
export default {
  name: "ChatMessage",
  components: {
    RequestCommentAttachmentSvg,
  },
  props: {
    /**
     * Component data
     */
    message: { default: () => {}, type: Object },
  },
  data() {
    return {};
  },
  computed: {
    currentDate() {
      if (!this.message.date) return false;
      return this.$moment(this.message.date).format("DD.MM.YY HH:mm");
    },
    fullUrl() {
      return this.message.attachment && this.$api.file.getFullUrl(this.message.attachment.url)
    }
  },
};
</script>

<style lang="scss" scoped src="./component.scss"></style>
