<template>
  <Layout id="OrderCancellationRequest">
    <h4 class="title">
      {{ $translate('submit_order_cancellation_request', 'orders') }}
    </h4>
    <div class="formWrap">
      <LoadingIcon v-if="loading" :absolute="true" />
      <form
        novalidate
        @submit.prevent="submit"
        :class="{ loadingElem: loading }"
        :key="formKey"
      >
        <Label
          :title="$translate('comment', 'general')"
          name="comment"
          inputType="textarea"
          @onInput="onInputComment"
          :error="commentError"
          :errorText="commentErrorText"
          :placeholder="
            $translate('you_can_specify_number_of_coupons', 'orders')
          "
          ref="comment"
        />
        <MyButton
          class="w100"
          :text="$translate('send', 'purchase')"
          tag="button"
          tagType="submit"
          :dynamicSize="true"
        />
      </form>
    </div>
  </Layout>
</template>

<script>
import { mapGetters } from 'vuex';
import Layout from '../../PopupLayout';
/**
 * The OrderCancellationRequest component.
 */
export default {
  name: 'OrderCancellationRequest',
  components: {
    Layout,
  },
  data() {
    return {
      formKey: 'faqForm_0',
      loading: false,
      comment: '',
      commentError: false,
      commentErrorText: '',
    };
  },
  computed: {
    ...mapGetters(['IS_Mobile', 'GET_CurrentPopup', 'GET_PopupData']),
    orderID() {
      return this.GET_PopupData?.[this.GET_CurrentPopup]?.id;
    },
  },
  methods: {
    onInputComment(value = this.comment, e, required = false) {
      this.comment = value;
      const validateError = this.$simpleInputValidate(value, required, 'name');
      this.commentError = validateError.error;
      if (validateError.error) {
        this.commentErrorText = validateError.text;
        this.$refs.comment.$refs.input.focus();
      }
      return validateError.error;
    },
    async submit() {
      try {
        if (this.loading) return false;
        if (!this.orderID) throw Error(`not found order id`);
        const error = this.onInputComment(undefined, undefined, true);
        if (!error) {
          this.loading = true;
          const { success } = await this.$api.document.askQuestionProfile(
            {
              data: {
                requestBody: this.comment,
                notificationType: 'notification',
                entityId: this.orderID,
                name: 'Видалення замовлення',
              },
            },
            'orders'
          );
          this.loading = false;
          if (success) {
            this.$popup.hide(undefined, () => this.resetForm());
            this.$notice(this.$translate('request_sent_'));
          } else {
            this.$notice(this.$translate('request_error'), undefined, {
              type: 'error',
            });
          }
        }
      } catch (e) {
        this.loading = false;
        this.$notice(this.$translate('request_error'), undefined, {
          type: 'error',
          text: e,
        });
      }
    },
    resetForm() {
      this.comment = '';
      this.commentError = false;
      this.commentErrorText = '';
      this.formKey = 'faqForm_' + this.$rnd(0, 100);
    },
  },
};
</script>
<style lang="scss" src="./style.scss"></style>
