export default new class Order {
    set(data = {}) {
        localStorage.setItem('order', JSON.stringify(data));
    }
    get() {
        return JSON.parse(localStorage.getItem('order')) || null;
    }
    remove() {
        localStorage.removeItem('order');
    }

}