<template>
  <div class="content" id="mobile-site-menu" ref="content">
    <MyButton
      :text="$translate('make_an_order')"
      :link="{ name: 'order' }"
      tag="router-link"
      :dynamicSize="true"
      class="p42 w100"
    />
    <div class="topRow">
      <h6>
        {{ $translate("management_services") }}
      </h6>
    </div>
    <SiteNav class="nav" />
    <div class="bottomRow">
      <div class="line"></div>
      <div class="logOutButton simpleLink" @click="$auth.logOut()">
        <LogOutSVG />
        <span>{{ $translate("log_out") }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import SiteNav from '@/components/SiteNav'
import LogOutSVG from "@/assets/img/log_out.svg";

export default {
  name: 'MobileMenu',
  components: {
    SiteNav,
    LogOutSVG
  },
  methods: {
  },
}
</script>
<style lang="scss" scoped src="./component.scss"></style>