<template>
  <router-view v-if="isChildrenPage"></router-view>
  <LayoutDashboard name="notifications" v-else>
    <DashboardTitleRow :title="$translate('my_messages')" />
    <PageFilter
      :loading="loading"
      :list="filterList"
      @submit="submitFilter"
      @reset="resetFilter"
      @remove="removeFilterTag"
    >
      <Label
        :title="$translate('calendar_select_date')"
        name="filterDate"
        inputType="datepicker"
        @onInput="onInputFilterDate"
        ref="filterDate"
        :value="filterCurrent.date"
        :upperLimit="false"
        calendarStartingView="months"
        :key="formKey"
      />
      <LabelDropdown
        name="type"
        :title="$translate('message_subject')"
        :list="filter.type"
        :active="filterCurrent.type"
        @change="changeDropdown"
      />
      <LabelDropdown
        name="notificationType"
        :title="$translate('notification_type')"
        :list="filter.notificationType"
        :active="filterCurrent.notificationType"
        @change="changeDropdown"
      />
    </PageFilter>
    <NotificationsTable
      :list="list"
      v-if="list.length"
      :loading="loading"
      :date="sort.date"
      :notificationType="sort.notificationType"
      @sort="sortTable"
      @rowClick="handleRowClick"
    />
    <LoadingIcon v-if="loading && !list.length" />
    <template v-if="!loading && !list.length">
      <p class="no_data">{{ $translate("no_data") }}</p>
    </template>
    <Pagination
      @paginate="paginate"
      :pageCurrent="pageCurrent"
      :pageCount="pageCount"
      :perPage="PAGE_SIZE"
      :class="{ disabledElem: loading }"
    />
  </LayoutDashboard>
</template>

<script>
const PAGE_SIZE = 9;
const FILTER_OPTIONS = {
  notificationType: [
    {
      id: "question",
      name: ["question"],
    },
    {
      id: "notification",
      name: ["notification"],
    },
  ],
  type: [
    {
      id: "documents",
      name: ["document", "documents"]
    },
    {
      id: "orders",
      name: ["order", "orders"]
    },
    // {
    //   id: "auth", 
    //   name: "Auth"
    // },
    // {
    //   id: "companies", 
    //   name: "Companies"
    // },
    // {
    //   id: "dashboard", 
    //   name: "Dashboard"
    // },
    // {
    //   id: "general", 
    //   name: "General"
    // },
    // {
    //   id: "payments", 
    //   name: "Payments"
    // },
    // {
    //   id: "profile", 
    //   name: "Profile"
    // },
    // {
    //   id: "transport-instructions", 
    //   name: "Transport Instructions"
    // }
  ]
}
import LayoutDashboard from "@/layouts/Dashboard.vue";
import DashboardTitleRow from "@/components/DashboardTitleRow";
import PageFilter from "@/components/PageFilter";
import NotificationsTable from "@/components/Table/Notifications";
import Pagination from "@/components/Pagination";

const RequestTypeRouteNameMapping = {
  "auth": {
    routeName: "profile",
  },
  "companies": {
    routeName: "company",
    getParams: ({ entityId }) => ({ id: entityId }),
  },
  "dashboard": {
    routeName: "dashboard",
  },
  "documents": {
    routeName: "documents-item",
    getParams: ({ entityId }) => ({ entityId }),
  },
  "general": {
    routeName: "dashboard",
  },
  "payments": {
    routeName: "payments",
  },
  "orders": {
    routeName: "my-order",
    getParams: ({ requestBody }) => {
      if (typeof requestBody !== 'string') {
        console.warn("Invalid request.requestBody");
        return {};
      }

      try {
        const { orderNumber } = JSON.parse(requestBody);
        return {
          id: typeof orderNumber === 'object' && 'number' in orderNumber
            ? orderNumber.number
            : orderNumber
        };
      } catch (error) {
        console.warn(error)
        return {};
      }
    },
  },
  "profile": {
    routeName: "profile",
  },
  "transport-instructions": {
    routeName: "my-order-transport-instruction",
    getParams: ({ entityId, requestBody }) => {
      if (typeof requestBody !== 'string') {
        console.warn("Invalid request.requestBody");
        return {};
      }

      try {
        const { orderNumber } = JSON.parse(requestBody);
        return {
          id: typeof orderNumber === 'object' && 'number' in orderNumber
            ? orderNumber.number
            : orderNumber,
          tiID: entityId,
        }
      } catch (error) {
        console.warn(error)
        return {};
      }
    },
  },
}

/**
 * The orders-page component.
 */
export default {
  name: "NotificationsPage",
  components: {
    LayoutDashboard,
    DashboardTitleRow,
    PageFilter,
    NotificationsTable,
    Pagination,
  },
  data() {
    const filter = this.$queryParams.parse(this.$route.query.filter);
    const sort = this.$queryParams.parse(this.$route.query.sort);
    const defaultSort = {
      date: Object.keys(sort).length === 0 ? "desc" : sort?.date,
      notificationType: sort?.notificationType || null,
    };

    return {
      title: this.$translate("my_orders", "orders"),
      PAGE_SIZE: PAGE_SIZE,
      loading: true,
      preList: [],
      list: [],
      pageCurrent: parseInt(this.$route.query.page) || 1,
      pageCount: 1,
      sort: defaultSort,
      filter: this.getFilterOptions(),
      filterCurrentCopy: null,
      filterCurrent: {
        date: filter.date
          ? this.$moment(filter.date, "YYYY-MM-DD").format("DD/MM/YYYY")
          : null,
        type: filter.type || null,
        notificationType: filter.notificationType || null,
      },
      formKey: "form_0",
    };
  },
  computed: {
    isChildrenPage() {
      return this.$route.name !== "notifications";
    },
    filterList() {
      const list = [];

      if (this.filterCurrentCopy.date) {
        list.push({
          id: "date",
          type: this.$translate("date"),
          text: this.$moment(this.filterCurrentCopy.date, "DD/MM/YYYY").format(
            "DD.MM.YY"
          ),
        });
      }

      if (this.filterCurrentCopy.notificationType) {
        list.push({
          id: "notificationType",
          type: this.$translate("notification_type"),
          text: this.$translate(this.filterCurrentCopy.notificationType.id),
        });
      }

      if (this.filterCurrentCopy.type) {
        list.push({
          id: "type",
          type: this.$translate("message_subject"),
          text: this.translateFilterType(this.filterCurrentCopy.type.id),
        });
      }

      return list;
    },
  },
  methods: {
    translateFilterType(type) {
      let keys = []

      switch (type) {
        case 'documents':
          keys = ['document', 'documents']
          break;
        case 'orders':
          keys = ['order', 'orders']
          break;
        default:
          break;
      }

      return this.$translate(...keys);
    },
    getFilterOptions() {
      {
        const filterOptions = Object.entries(FILTER_OPTIONS)
          .map(([key, options]) => {
            return [
              key,
              options.map((option) => ({
                ...option,
                name: this.$translate(...option.name),
              })),
            ]
          });
        return Object.fromEntries(filterOptions);
      }
    },
    paginate(page) {
      this.getRequests(page);
    },
    parseFilters(filters = {}) {
      return {
        ...filters,
        type: filters.type?.id != null ? filters.type.id : filters.type,
        notificationType:
          filters.notificationType?.id != null
            ? filters.notificationType.id
            : filters.notificationType,
      }
    },
    encodeFilters(filters = {}) {
      return {
        ...filters,
        type: filters.type != null ? { id: filters.type } : null,
        notificationType:
          filters.notificationType != null
            ? { id: filters.notificationType }
            : null,
      }
    },
    async getRequests(
      page,
      sort = this.sort,
      filter = this.filterCurrent,
      params = {}
    ) {
      const clearSort = this.$queryParams.getClear(sort);
      const clearFilter = this.$queryParams.getClear(
        filter,
        true,
        true
      );

      this.loading = true;

      const { list, pagination, success } = await this.$api.getRequests({
        pagination: { pageSize: PAGE_SIZE, page: page || this.pageCurrent },
        sort: clearSort,
        filters: clearFilter,
        ...params,
      });

      this.loading = false;

      if (!success) {
        return
      }
      
      this.filterCurrentCopy = this.$copyObject(this.filterCurrent);
      this.list = list;
      this.pageCurrent = pagination.page;
      this.pageCount = pagination.pageCount;

      this.sort = {
        ...this.$queryParams.resetParams(this.sort),
        ...clearSort,
      };

      this.$router.push({
        query: this.$queryParams.getClear({
          page: this.pageCurrent,
          sort: this.$queryParams.stringify(clearSort),
          filter: this.$queryParams.stringify(this.encodeFilters(clearFilter)),
        }),
      });
    },
    async sortTable(params) {
      this.$queryParams.reverseParams(params);
      await this.getRequests(this.pageCurrent, params);
    },
    changeDropdown(key, name) {
      switch (name) {
        case "type":
          this.filterCurrent = {
            ...this.filterCurrent,
            type: key == null ? null : { id: key }
          };
          break;
        case "notificationType":
          this.filterCurrent = {
            ...this.filterCurrent,
            notificationType: key == null ? null : { id: key }
          };
          break;
        default:
          break;
      }
    },
    onInputFilterDate(value) {
      this.filterCurrent.date = value;
    },
    async submitFilter() {
      await this.getRequests(1);
    },
    async resetFilter() {
      this.filterCurrent = {
        date: null,
        type: null,
        notificationType: null,
      }
      await this.getRequests(1);
    },
    async removeFilterTag(id) {
      this.changeDropdown(null, id);
      await this.getRequests();
    },
    handleRowClick(row) {
      void this.$api.markAsRead({ requestIds: [row.id] })
        .then(() => {
          this.list = this.list.map((item) => {
            if (item.id === row.id) {
              item.read = true;
            }
            return item;
          });
        })
        .catch(console.error);

      if (row.notificationType === 'question') {
        this.$router.push({
          name: "chat",
          params: {
            id: row.id,
          },
          query: {
            title: row.name,
          },
        });
        return
      }

      const routeConfig = RequestTypeRouteNameMapping[row.type];
      
      if (routeConfig == null) {
        console.warn(`Unknown request type: ${row.type}`);
        return;
      }

      this.$router.push({
        name: routeConfig.routeName,
        params: routeConfig.getParams?.(row) ?? {},
      })
      
    },
  },
  async created() {
    this.filterCurrentCopy = this.$copyObject(this.filterCurrent);
    await this.getRequests();
    this.loading = false;
    this.$store.dispatch("SET_MarkAsRead");
  },
  mounted() {
    this.emitter.on("PageFilter:show", () => {
      this.filterCurrent = this.$copyObject(this.filterCurrentCopy);
      this.formKey = "form_" + this.$rnd(0, 100);
    });
  },
  beforeUnmount() {
    this.emitter.off("PageFilter:show");
  },
};
</script>

<style lang="scss" scoped src="./component.scss"></style>
