<template>
  <LayoutDashboard name="documents">
    <DashboardTitleRow
      :title="title"
      :goBack="{ name: 'notifications' }"
      titleTagClass="h4"
    />
    <div v-if="filterTags.length > 0" class="filterTags">
      <Tag
        :text="tag"
        type="primary"
        class="small"
        v-for="(tag, index) in filterTags"
        :key="index"
      />
    </div>
    <Chat
      :loading="sendMessageLoading"
      :list="chatMessages"
      @message="handleMessage"
      ref="chat"
    />
  </LayoutDashboard>
</template>

<script>
import LayoutDashboard from "@/layouts/Dashboard.vue";
import DashboardTitleRow from "@/components/DashboardTitleRow";
import Tag from "@/components/Table/PageTable/Tag";
import Chat from "@/components/Chat";

export default {
  name: "NotificationsChatPage",
  components: {
    LayoutDashboard,
    DashboardTitleRow,
    Tag,
    Chat,
  },
  data() {
    return {
      filterTags: [],
      messagesList: [],
      sendMessageLoading: false,
      title: "",
    };
  },
  computed: {
    chatMessages() {
      return this.messagesList.map((message) => {
        return {
          id: message.id,
          message: message.comment,
          date: message.date,
          role: message.type,
          author: message.name == null && message.type === 'user'
            ? this.$translate('chat_sender_default_name', 'general')
            : message.name,
          attachment: message.attachment,
        }
      })
    }
  },
  methods: {
    async handleMessage({ text, file } = {}) {
      let attachment = {}
      this.sendMessageLoading = true;

      if (file != null) {
        try {
          const { success, data } = await this.$api.file.upload([file])
  
          if (success) {
            attachment = data[0].id
          }
        } catch (error) {
          console.error(error);
        }
      }
      
      try {
        const res = await this.$api.sendMessage({
          requestId: this.$route.params.id,
          payload: {
            type: "user",
            name: null,
            comment: text,
            date: new Date().toISOString(),
            attachment,
          }
        });
        
        if (!res.success) {
          return
        }

        this.messagesList = res.data.comments
        this.sendMessageLoading = false;
        this.$refs.chat.success()
      } catch (error) {
        this.sendMessageLoading = false;
        console.error(error);
      }
    },
  },
  async created() {
    if (this.$route.query.title) {
      this.title = this.$route.query.title;
    }

    try {
      const res = await this.$api.getRequestById(this.$route.params.id);

      if (res.success) {
        this.messagesList = res.data.comments;
        this.title = res.data.name;
      }
    } catch (error) {
      console.error(error);
    }
  },
};
</script>

<style lang="scss" scoped src="./component.scss"></style>
