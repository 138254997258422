import translate from './translate';

const simpleInputValidate = (
  val = '',
  required = false,
  type = 'text',
  config = {}
) => {
  let error = false;
  let text = null;

  if (typeof val == 'string') val = val.trim();

  // is empty
  if (
    required &&
    typeof val === 'string' &&
    !val.trim().length &&
    type === 'text'
  ) {
    error = true;
    // text = translate('empty_field', 'auth');
    text = '';
  }

  // name
  if (required && type == 'name') {
    if (!val || !val.trim().length) {
      error = true;
      text = translate('empty_field', 'auth');
    }
  }

  if (required && type == 'volume') {
    const reg = /^[0-9.]+$/;
    if (!val) {
      error = true;
      text = translate('empty_field', 'auth');
    } else if (!reg.test(val)) {
      error = true;
      text = translate('only_numbers_1', 'general');
    }
  }

  if (required && type == 'cropArea') {
    if (!val || !val.trim().length) {
      error = true;
      text = translate('empty_field', 'auth');
    } else if (!parseFloat(val)) {
      error = true;
      text = translate('enter_the_area', 'companies');
    }
  }

  // select
  if (required && type == 'select') {
    if (
      (typeof val === 'string' && !val.trim().length) ||
      val == undefined ||
      val == null
    ) {
      error = true;
      text = translate('empty_field', 'auth');
    }
  }

  // name_surname
  if (required && type == 'name_surname') {
    const test_name_surname = /([a-zA-Zа-яА-я])+ ([a-zA-Zа-яА-я])+/;
    if (!val.trim().length || !test_name_surname.test(val)) {
      error = true;
      if (!val.length || val == '') {
        text = translate('empty_field', 'auth');
      } else {
        text = translate('incorrect_field', 'auth');
      }
    }
  }

  // email
  if (required && type == 'email') {
    const test_email =
      /^([a-zA-Z0-9_.-])+@([a-zA-Z0-9_.-])+\.([a-zA-Z])+([a-zA-Z]$)+/;
    if (!val.length || val == '' || !test_email.test(val)) {
      error = true;
      if (!val.length || val == '') {
        text = translate('empty_field', 'auth');
      } else {
        text = translate('incorrect_email', 'auth');
      }
    }
  }

  // password
  if (required && type == 'password') {
    const { minLength = 8 } = config;
    if (!val.trim().length || val.trim().length < minLength) {
      error = true;
      if (!val.trim().length) {
        text = translate('empty_field', 'auth');
      } else {
        text = translate('password_min_characters', 'auth').replace(
          '##',
          minLength
        );
      }
    }

    if (!val.trim().length || (val.length < minLength && val.trim().length)) {
      error = true;
      if (!val.trim().length) {
        text = translate('empty_field', 'auth');
      } else {
        text = translate('min_characters', 'auth').replace('##', minLength);
      }
    }
  }
  // tel
  if (required && type == 'tel') {
    const { minLength = 12 } = config;
    if (
      !val.length ||
      val == '' ||
      val.replace(/ /g, '').replace('+', '').length !== minLength
    ) {
      error = true;
      if (!val.length || val == '') {
        text = translate('empty_field', 'auth');
      } else {
        text = translate('incorrect_field', 'auth');
      }
    }
  }

  // textarea
  if (required && type == 'textarea') {
    const { minLength = 10 } = config;
    if (!val.trim().length || (val.length < minLength && val.trim().length)) {
      error = true;
      if (!val.trim().length) {
        text = translate('empty_field', 'auth');
      } else {
        text = translate('min_characters', 'auth').replace('##', minLength);
      }
    }
  }

  // IBAN
  if (required && type == 'IBAN') {
    const { length = 29 } = config;
    if (!val || (typeof val === 'string' && !val.trim().length)) {
      error = true;
      text = translate('empty_field', 'auth');
    } else if (val.length != length) {
      error = true;
      text = translate('equal_characters', 'auth').replace('##', length);
    }
  }
  // MFO
  if (required && type == 'MFO') {
    const { length = 6 } = config;
    if (!val || (typeof val === 'string' && !val.trim().length)) {
      error = true;
      text = translate('empty_field', 'auth');
    } else if (val.length != length) {
      error = true;
      text = translate('equal_characters', 'auth').replace('##', length);
    }
  }

  // opt_key
  if (required && type == 'opt_key') {
    const { minLength = 4 } = config;
    if (val.length < minLength) {
      error = true;
      text = translate('min_characters', 'auth').replace('##', minLength);
    }
  }
  //TIN_YEDRPOU
  if (required && type == 'TIN_YEDRPOU') {
    const { minLength = 8, maxLength = 10 } = config;
    let validSymbols = /^[0-9]+$/;
    val = val.toString();
    if (
      !val ||
      !val.trim().length ||
      (val.trim().length !== minLength && val.trim().length !== maxLength)
    ) {
      error = true;
      text = translate('TIN_YEDRPOU_error', 'companies')
        .replace('<length1>', minLength)
        .replace('<length2>', maxLength);
    }
    if (val && !validSymbols.test(val)) {
      error = true;
      text = translate('validation_only_numbers', 'general');
    }
  }

  // edrpou
  if (required && type == 'edrpou') {
    const test_edrpou = /^[0-9]{10}$/;
    if (!val.length || val == '' || !test_edrpou.test(val)) {
      error = true;
      if (!val.length || val == '') {
        text = translate('empty_field', 'auth');
      } else {
        text = translate('incorrect_edrpou', 'auth');
      }
    }
    // const { minLength = 10 } = config;
    // if (val.length < minLength) {
    //     error = true;
    //     text = translate('min_characters', 'auth').replace('##', minLength)
    // } else if (val.length > minLength) {
    //     error = true;
    //     text = translate('max_characters', 'auth').replace('##', minLength)
    // } else {

    // }
  }

  // checkbox
  if (required && type == 'checkbox') {
    error = val != true;
    if (error) text = translate('required_field', 'auth');
  }

  // files
  if (required && type == 'files') {
    error = !val || !val.length;
    if (error) text = translate('required_field', 'auth');
  }

  // console.log({
  //     error, type, val, required
  // });

  return { error, text };
};

export default simpleInputValidate;
