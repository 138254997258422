<template>
  <PageTable :loading="loading">
    <template v-slot:thead>
      <th>
        <span
          class="controll"
          :class="[
            suAmountVat,
            { active: currentSort?.suAmountVat },
          ]"
          @click="$emit('sort', { suAmountVat: suAmountVat || 'asc' })"
        >
          {{ $translate("payment_amount", "orders") }}
          <ArrowSVG />
        </span>
      </th>
      <th>
        {{ $translate("invoice") }}
      </th>
      <th>
        {{ $translate("paid", "orders") }}
      </th>
      <th>
        <span
          class="controll"
          :class="[
            order?.number,
            { active: currentSort?.order?.number },
          ]"
          @click="$emit('sort', {
            order: order.number ? order : { number: 'asc' }
          })"
        >
          {{ $translate("order", "orders") }}
          <ArrowSVG />
        </span>
      </th>
      <th>
        <span
          class="controll"
          :class="[
            documentValidToDate,
            { active: currentSort?.documentValidToDate },
          ]"
          @click="
            $emit('sort', { documentValidToDate: documentValidToDate || 'asc' })
          "
        >
          {{ $translate("extreme_term", "orders") }}
          <ArrowSVG />
        </span>
      </th>
      <th>
        {{ $translate("change_in_case_of_non_payment", "orders") }}
      </th>
      <th></th>
    </template>
    <template v-slot:default>
      <RowItem v-for="item in list" :key="item.id" :row="item" />
    </template>
  </PageTable>
</template>

<script>
import RowItem from "./Item";
import PageTable from "../PageTable";
import ArrowSVG from "@/assets/img/arrow_top.svg";
/**
 * The PaymentsTable component.
 */
export default {
  name: "PaymentsTable",
  components: { RowItem, ArrowSVG, PageTable },
  props: {
    list: { default: () => [], type: Array },
    loading: { default: false, type: Boolean },
    suAmountVat: { default: "asc", type: String },
    documentValidToDate: { default: "asc", type: String },
    order: {
      default: () => ({ number: "asc" }),
      type: Object
    },
  },
  computed: {
    currentSort() {
      return this.$queryParams.parse(this.$route.query.sort);
    },
  },
};
</script>

<style lang="scss" scoped src="./component.scss"></style>
