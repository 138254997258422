<template>
  <div class="PageFilterTag">
    <template v-if="textOnly">
      {{ text }}
    </template>
    <template v-else>
      <template v-if="type">
        <span class="type">{{ type }}</span
        >:
      </template>
      {{ text }}
    </template>

    <div class="close" @click="closeClick">
      <CloseSVG />
    </div>
  </div>
</template>

<script>
import CloseSVG from "@/assets/img/close.svg";
/**
 * The PageFilterTag component.
 */
export default {
  name: "PageFilterTag",
  components: { CloseSVG },
  props: {
    id: { default: null, type: [String, Number] },
    type: { default: null, type: String },
    text: { default: null, type: String },
    textOnly: { default: false, type: Boolean },
  },
  data() {
    return {};
  },
  methods: {
    closeClick() {
      this.$emit("close", this.id);
    },
  },
};
</script>

<style lang="scss" scoped src="./component.scss"></style>
