<template>
  <Layout id="AskQuestion">
    <h4>{{ $translate('faq_modal_title', 'faq') }}</h4>
    <p>{{ $translate('faq_modal_text', 'faq') }}</p>
    <div class="formWrap">
      <LoadingIcon v-if="loading" :absolute="true" />
      <form
        novalidate
        @submit.prevent="submit"
        :class="{ loadingElem: loading }"
        :key="formKey"
      >
        <Label
          :title="$translate('your_question')"
          name="comment"
          inputType="textarea"
          @onInput="onInputComment"
          :error="commentError"
          :errorText="commentErrorText"
          ref="comment"
        />
        <MyButton
          class="w100"
          :text="$translate('ask_question')"
          tag="button"
          tagType="submit"
          :dynamicSize="true"
        />
      </form>
    </div>
  </Layout>
</template>

<script>
import { mapGetters } from 'vuex';
import Layout from '../../PopupLayout';
/**
 * The AskQuestionModal component.
 */
export default {
  name: 'AskQuestionModal',
  components: {
    Layout,
  },
  data() {
    return {
      formKey: 'faqForm_0',
      loading: false,
      comment: '',
      commentError: false,
      commentErrorText: '',
    };
  },
  computed: {
    ...mapGetters(['IS_Mobile', 'GET_CurrentPopup']),
  },
  methods: {
    onInputComment(value = this.comment, e, required = false) {
      this.comment = value;
      const validateError = this.$simpleInputValidate(value, required, 'name');
      this.commentError = validateError.error;
      if (validateError.error) {
        this.commentErrorText = validateError.text;
        this.$refs.comment.$refs.input.focus();
      }
      return validateError.error;
    },
    async submit() {
      if (this.loading) return false;

      const error = this.onInputComment(undefined, undefined, true);
      if (!error) {
        this.loading = true;
        const { success } = await this.$api.faq.askQuestion({
          data: {
            requestBody: this.comment,
          },
        });
        this.loading = false;
        if (success) {
          this.$popup.hide(undefined, () => this.resetForm());
          this.$notice(this.$translate('question_sent'));
        } else {
          this.$notice(this.$translate('request_error'), undefined, {
            type: 'error',
          });
        }
      }
    },
    resetForm() {
      this.formKey = 'faqForm_' + this.$rnd(0, 100);
      this.comment = '';
    },
  },
};
</script>
<style lang="scss" src="./component.scss"></style>
