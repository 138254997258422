<template>
  <div class="CounterpartiesSection">
    <DashboardTitleRow
      :title="$translate('list_of_counterparties', 'companies')"
      titleTag="h2"
    >
      <template v-slot:title>
        <div class="btnWrap">
          <MyButton
            class="width-auto"
            iconAfter="add_document"
            :text="$translate('add_counterparty', 'dashboard')"
            size="s"
            @click="addCounterparties"
          />
        </div>
      </template>
    </DashboardTitleRow>
    <Transition mode="out-in">
      <LoadingIcon v-if="loading" />
      <div v-else-if="counterpartyList.length && !loading" class="List">
        <CounterpartiesItem
          v-for="item in counterpartyList"
          :key="item.id"
          :item="item"
        />
      </div>
      <h5 v-else>{{ $translate("empty") }}</h5>
    </Transition>
  </div>
</template>

<script>
import DashboardTitleRow from "@/components/DashboardTitleRow";
import CounterpartiesItem from "./Item";
/**
 * The CounterpartiesSection component.
 */
export default {
  name: "CounterpartiesSection",
  components: { DashboardTitleRow, CounterpartiesItem },
  props: {
    /**
     * Title of the page
     */
    title: { default: null, type: String },
  },
  data() {
    return {
      counterpartyList: [],
      loading: false,
    };
  },
  computed: {
    // ...mapGetters(['IS_Tablet']),
  },
  methods: {
    // ...mapActions(['GET_PaymentInfo']),
    addCounterparties() {
      return this.$router.push({ name: "add-company" });
    },
    async getСompanies() {
      try {
        this.loading = true;
        const { list = [] } = await this.$api.getСompanies();
        console.log("getСompanies", list);
        if (list.length) this.counterpartyList = list;
        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
  },
  async created() {
    await this.getСompanies();
  },
};
</script>

<style lang="scss" scoped src="./component.scss"></style>
