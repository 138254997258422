<template>
  <router-view v-if="isChildrenPage"></router-view>
  <LayoutDashboard name="accompanying-documents" v-else>
    <DashboardTitleRow
      class="accompanying-documents my-order"
      v-if="dashboard_title"
      :title="dashboard_title"
      :titleTag="'h4'"
      :titleTagClass="'h4'"
      :goBack="{ name: 'orders' }"
    >
      <template v-slot:title>
        <div class="top-info">
          <Transition mode="out-in">
            <div v-if="!loading">
              <Tag
                v-if="order_status_name"
                class="w100 small"
                :text="order_status_name"
              />
              <span v-if="amount" class="total-sum"
                >{{ $translate('sum', 'general') }}:
                <b>{{ $price(amount, undefined, true) }}</b></span
              >
            </div>
          </Transition>
        </div>
      </template>
    </DashboardTitleRow>
    <PageTabs
      class="marg-big"
      v-if="pageTabsList && pageTabsList.length"
      :list="pageTabsList"
    />
    <PageFilter
      :loading="loadingDocuments"
      :list="filterList"
      @submit="submitFilter"
      @reset="resetFilter"
      @remove="removeFilterTag"
    >
      <LabelDropdown
        name="number"
        :title="$translate('TI_number_short', 'documents')"
        :list="documentNumbers"
        :active="filterCurrent?.number"
        @change="changeDropdown"
      />
      <LabelDropdown
        name="condition"
        :title="$translate('terms_of_delivery', 'orders')"
        :list="documentsTermsOfDelivery"
        :active="filterCurrent?.condition"
        @change="changeDropdown"
      />
      <LabelDropdown
        name="status"
        :title="$translate('TI_status', 'documents')"
        :list="documentsStatus"
        :active="filterCurrent?.status"
        @change="changeDropdown"
      />
    </PageFilter>
    <Transition mode="out-in">
      <LoadingIcon v-if="loadingDocuments" />
      <div v-else-if="!loadingDocuments && documentList && documentList.length">
        <TransportInstructionsTable
          :list="documentList"
          :loading="loadingDocuments"
          :date="sort.date"
          @sort="sortTable"
        />
        <Pagination
          @paginate="paginate"
          :pageCurrent="page"
          :pageCount="pageCount"
          :perPage="pageSize"
          :class="{ disabledElem: loadingDocuments }"
        />
      </div>
      <p v-else class="no_data">{{ $translate('no_data') }}</p>
    </Transition>
  </LayoutDashboard>
</template>
<script>
import LayoutDashboard from '@/layouts/Dashboard.vue';
import DashboardTitleRow from '@/components/DashboardTitleRow';
import PageTabs from '@/components/PageTabs';
import Tag from '@/components/Table/PageTable/Tag';
import PageFilter from '@/components/PageFilter';
import TransportInstructionsTable from '@/components/Table/TransportInstructions';
import Pagination from '@/components/Pagination';

//mock data
//pageTabsList
import pageTabList from '../pageTabList.js';
export default {
  name: 'TransportInstructions',
  components: {
    LayoutDashboard,
    DashboardTitleRow,
    PageTabs,
    Tag,
    PageFilter,
    TransportInstructionsTable,
    Pagination,
  },
  data() {
    return {
      loading: true,
      loadingDocuments: true,
      pageTabsList: pageTabList(this, 'my-order-transport-instructions'),
      orderData: {},
      orderDataEmpty: false,
      documentList: [],
      pagination: null,
      pageSize: 9,
      page: 1,
      pageCount: null,
      formKey: 'form_0',
      filterCurrent: {},
      sort: null,
      list: [],
      allDocuments: null,
    };
  },
  computed: {
    isChildrenPage() {
      return this.$route.name !== 'my-order-transport-instructions';
    },
    order_status_name() {
      return this.orderData?.status?.name;
    },
    dashboard_title() {
      const number = this.orderData?.number || this.$route?.params?.id;
      return `${this.$translate('order', 'orders')} ${number}`;
    },
    amount() {
      return this.orderData?.paymentData?.suAmountVat;
    },
    filterList() {
      const list = [];
      // number
      if (this.filterCurrent?.number) {
        list.push({
          id: 'number',
          type: this.$translate('document_number', 'documents'),
          text: this.filterCurrent.number,
        });
      }
      if (this.filterCurrent?.condition) {
        list.push({
          id: 'condition',
          type: this.$translate('terms_of_delivery', 'orders'),
          text: this.filterCurrent.condition,
        });
      }
      if (this.filterCurrent?.status) {
        list.push({
          id: 'status',
          type: this.$translate('TI_status', 'documents'),
          text: this.filterCurrent.status,
        });
      }
      return list;
    },
    documentNumbers() {
      return this.allDocuments && this.allDocuments.length
        ? this.allDocuments.map((item) => item.number)
        : [];
    },
    documentsTermsOfDelivery() {
      let res = [];
      const condition =
        this.allDocuments && this.allDocuments.length
          ? this.allDocuments
              .map((item) => item.deliveryCondition.name)
              .filter((item) => item)
          : [];
      condition.forEach((item) => {
        if (!res.includes(item)) res.push(item);
      });
      return res;
    },
    documentsStatus() {
      let res = [];
      const condition =
        this.allDocuments && this.allDocuments.length
          ? this.allDocuments
              .map((item) => item.status.name)
              .filter((item) => item)
          : [];
      condition.forEach((item) => {
        if (!res.includes(item)) res.push(item);
      });
      return res;
    },
  },
  methods: {
    paginate(page) {
      try {
        this.page = page;
        this.getDocuments(this.orderData.id);
      } catch (e) {
        console.log(e);
      }
    },
    async submitFilter() {
      try {
        this.page = 1;
        await this.getDocuments(this.orderData.id);
      } catch (e) {
        console.log(e);
      }
    },
    async resetFilter() {
      try {
        this.$queryParams.resetParams(this.filterCurrent);
        this.formKey = 'form_' + this.$rnd(0, 100);
        this.page = 1;
        await this.getDocuments(this.orderData.id);
      } catch (e) {
        console.log(e);
      }
    },
    async removeFilterTag(id) {
      try {
        this.changeDropdown(null, id);
        this.page = 1;
        await this.getDocuments(this.orderData.id);
      } catch (e) {
        console.log(e);
      }
    },
    changeDropdown(key, name) {
      try {
        switch (name) {
          case 'number':
            this.filterCurrent.number = key;
            break;
          case 'condition':
            this.filterCurrent.condition = key;
            break;
          case 'status':
            this.filterCurrent.status = key;
            break;
          default:
            break;
        }
      } catch (e) {
        console.log(e);
      }
    },
    async sortTable({ date = null }) {
      try {
        if (date) {
          if (date == 'asc') date = 'desc';
          else if (date == 'desc') date = 'asc';
          this.sort.date = date;
          this.setQueryParams();
        }
        await this.getDocuments(this.orderData.id);
      } catch (e) {
        console.log(e);
      }
    },

    async getOrder() {
      try {
        this.loading = true;
        const filters = {
          number: this.$route.params.id,
        };
        const { list = [], success = false } = await this.$api.getOrders({
          filters,
        });
        if (success && list.length) {
          const { id } = list.find(({ number }) => number);
          if (!id) throw new Error('Order id not found');
          const { data = {}, success: done = false } =
            await this.$api.checkout.getOrder(id);
          if (done && data) {
            this.orderData = data;
            this.orderDataEmpty = false;
            this.loading = false;
            await this.getDocuments(id);
          }
        } else {
          this.orderDataEmpty = true;
          this.loading = false;
        }
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    async getAllDocuments(id) {
      try {
        this.loadingDocuments = true;
        const params = {
          filters: {
            order: { id: id },
          },
        };
        const { list = [], success = false } =
          await this.$api.document.getInstructions(params);
        if (success && list.length) {
          this.allDocuments = list;
        }
        this.loadingDocuments = false;
      } catch (e) {
        console.log(e);
        this.loadingDocuments = false;
      }
    },
    async getDocuments(id) {
      try {
        if (!id) throw Error('id is null');
        this.loadingDocuments = true;
        let params = {
          filters: {
            order: { id: id },
          },
          pagination: {
            pageSize: this.pageSize,
            page: this.page,
          },
        };
        if (this.sort) params.sort = this.sort;
        if (this.filterCurrent) {
          if (this.filterCurrent.number)
            params.filters.number = this.filterCurrent.number;
          if (this.filterCurrent.condition)
            params.filters.deliveryCondition = {
              name: this.filterCurrent.condition,
            };
          if (this.filterCurrent.status)
            params.filters.status = {
              name: this.filterCurrent.status,
            };
        }
        const {
          list = [],
          success = false,
          pagination = {},
        } = await this.$api.document.getInstructions(params);
        if (this.allDocuments == null) await this.getAllDocuments(id);
        if (success && list.length) {
          this.documentList = list;
          this.pageCount = pagination.pageCount;
          this.setQueryParams();
        }
        this.loadingDocuments = false;
      } catch (e) {
        console.log(e);
        this.loadingDocuments = false;
      }
    },
    async getDocumentsBox() {
      return await this.getDocuments(this.orderData.id);
    },
    setQueryParams() {
      let object = {};
      if (this.page != null) object.page = this.page;
      if (this.sort)
        object.sort = this.$queryParams.stringify(
          this.$queryParams.getClear(this.sort)
        );
      if (this.filterCurrent)
        object.filter = this.$queryParams.stringify(
          this.$queryParams.getClear(this.filterCurrent, true, true)
        );
      return this.$router.push({
        query: this.$queryParams.getClear(object),
      });
    },
    getQueryParams() {
      this.page = this.$route.query.page || 1;
      const filter = this.$queryParams.parse(this.$route.query.filter);
      const sort = this.$queryParams.parse(this.$route.query.sort);
      this.filterCurrent = {
        number: filter.number || null,
        condition: filter.condition || null,
        status: filter.status || null,
      };
      this.sort = {
        date: sort.date || 'asc',
      };
    },
  },
  async created() {
    this.getQueryParams();
    await this.getOrder();
  },
  mounted() {
    this.emitter.on('PageFilter:show', () => {
      this.formKey = 'form_' + this.$rnd(0, 100);
    });
    document.addEventListener('update-instructions', this.getDocumentsBox);
  },
  beforeUnmount() {
    document.removeEventListener('update-instructions', this.getDocumentsBox);
  },
};
</script>
<style scoped src="../style.scss" lang="scss"></style>
