<template>
  <DashboardCard class="NoticeInfo p20 witdh-auto overflow">
    <div class="content">
      <Notice v-if="notice">
        {{ notice }}
      </Notice>
      <span>{{ text }}</span>
    </div>
    <div class="slot">
      <slot></slot>
    </div>
  </DashboardCard>
</template>

<script>
import Notice from "@/components/Notice";
import DashboardCard from "@/components/DashboardCard";
/**
 * The NoticeInfo component.
 */
export default {
  name: "NoticeInfo",
  components: { Notice, DashboardCard },
  props: {
    /**
     * notice text
     */
    notice: { default: null, type: String },
    /**
     * main text
     */
    text: { default: null, type: String },
  },
};
</script>

<style lang="scss" scoped src="./component.scss"></style>
