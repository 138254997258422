<template>
  <PageTable :loading="loading">
    <template v-slot:thead>
      <th>
        {{ $translate('TI_number_short', 'documents') }}
      </th>
      <th>
        {{ $translate('volume', 'orders') }}
      </th>
      <th>
        {{ $translate('terms_of_delivery', 'orders') }}
      </th>
      <th>
        {{ $translate('TI_status', 'documents') }}
      </th>
      <th>
        {{ $translate('selected_delivery_dates', 'orders') }}
      </th>
      <th>
        {{ $translate('actions', 'general') }}
      </th>
    </template>
    <template v-slot:default>
      <RowItem v-for="item in list" :key="item.id" :row="item" />
    </template>
  </PageTable>
</template>

<script>
import RowItem from './Item';
import PageTable from '../PageTable';
/**
 * The DocumentsTable component.
 */
export default {
  name: 'TransportInstructionsTable',
  components: { RowItem, PageTable },
  props: {
    list: { default: () => [], type: Array },
    loading: { default: false, type: Boolean },
  },
};
</script>

<style lang="scss" scoped src="./component.scss"></style>
