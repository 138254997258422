<template>
  <div class="payment-data shadowX-box">
    <h4 class="small title">{{ $translate('payment_data', 'orders') }}</h4>
    <ul>
      <li v-if="orderAmount">
        <div class="name">
          <span>{{ $translate('order_amount', 'orders') }}</span>
        </div>
        <div class="val">
          <div class="flex alignCenter">
            <span>{{ $price(orderAmount, undefined, true) }}</span>
            <Tippy :text="$translate('paymentData_orderAmount', 'orders')" />
          </div>
          <!-- <span v-if="data?.payment_data_mass" class="mass">{{
            data.payment_data_mass
          }}</span> -->
        </div>
      </li>
      <li v-if="balanceDue != null">
        <div class="name">
          <span>{{ $translate('balance_due', 'orders') }}</span>
        </div>
        <div class="val">
          <div class="flex alignCenter mob-justify-start">
            <span
              :class="{ red: balanceMoreThenZero, green: !balanceMoreThenZero }"
              >{{ $price(balanceDue, undefined, true) }}</span
            >
            <Tippy
              v-if="balanceMoreThenZero"
              :text="$translate('paymentData_balanceDue', 'orders')"
            />
          </div>
        </div>
      </li>
    </ul>
    <MyButton
      v-if="balanceMoreThenZero"
      tag="button"
      :text="$translate('view_bill', 'orders')"
      :dynamicSize="true"
      @click="goToPage"
    />
    <!-- <MyButton
      v-else
      type="outline-yellow"
      tag="button"
      :text="$translate('view_bill', 'orders')"
      :dynamicSize="true"
    /> -->

    <div v-if="bePaidBy" class="deadline">
      {{ $translate('payment_must_be', 'orders') }}
      <b>
        {{ $moment(new Date(bePaidBy), 'DD/MM/YYYY').format('DD.MM.YYYY') }}</b
      >
    </div>
  </div>
</template>
<script>
import Tippy from '@/components/Tippy';
export default {
  name: 'PaymentData',
  components: {
    Tippy,
  },
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
    id: {
      type: [String, Number],
      default: '',
    },
    numOrder: {
      type: [String, Number],
      default: '',
    },
  },
  computed: {
    orderAmount() {
      return this.data?.suAmountVat;
    },
    balanceDue() {
      return this.data?.orderPaymentAmount;
    },
    balanceMoreThenZero() {
      return this.balanceDue !== null ? this.balanceDue != 0 : false;
    },
    bePaidBy() {
      return this.data?.validToDate;
    },
  },
  methods: {
    goToPage() {
      if (!this.numOrder) return null;
      return this.$router.push({
        name: 'payments',
        query: { filter: `order_number=${this.numOrder}` },
      });
    },
  },
};
</script>
<style scoped src="./style.scss" lang="scss"></style>
