<template>
  <Layout id="OrderRecommendedVolume">
    <h4>
      {{ $translate('volume_recommendation_modal_title', 'orders') }}:
      <span class="product">{{ productName }}</span>
    </h4>
    <article class="formWrap">
      <LoadingIcon v-if="loading" :absolute="true" />
      <form
        novalidate
        @submit.prevent="submit"
        :class="{ loadingElem: loading }"
        :key="formKey">
        <LabelDropdown
          name="area"
          :list="areasList"
          :active="areaCurrent"
          :title="$translate('volume_recommendation_modal_area', 'orders')"
          :placeholder="$translate('get_value', 'orders')"
          :key="areaCurrent?.id"
          class="darkPlaceholder marginBottom0"
          @change="changeDropdown"
        />
        <template v-if="recommendedVolume != null">
          <p class="recommendation">
            {{ $translate('volume_recommendation_modal_volume', 'orders') }}
          </p>
          <p class="recommendation-value">
            {{ recommendedVolume }} т
          </p>
        </template>
        <footer class="form-footer">
          <MyButton
            :text="$translate('go_back')"
            class="w100"
            tag="button"
            tagType="button"
            type="transparent"
            @click="closeModal"
          />
          <MyButton
            :text="$translate('volume_recommendation_modal_apply', 'orders')"
            class="w100"
            tag="button"
            tagType="submit"
          />
        </footer>
      </form>
    </article>
  </Layout>
</template>

<script>
import { mapGetters } from 'vuex';
import Layout from '../../PopupLayout';
/**
 * The OrderRecommendedVolume component.
 */
export default {
  name: 'OrderRecommendedVolume',
  components: {
    Layout,
  },
  data() {
    return {
      formKey: 'faqForm_0',
      loading: false,
      areaCurrent: null,
      areasList: [],
      recommendedVolumeRaw: null,
    };
  },
  computed: {
    ...mapGetters('checkout', ['GET_Checkout']),
    ...mapGetters(['GET_PopupData']),
    popupData() {
      return this.GET_PopupData['OrderRecommendedVolume'];
    },
    productName() {
      return this.popupData?.product?.name;
    },
    wagonNorm() {
      return this.popupData?.wagonNorm;
    },
    areasListFiltered() {
      if (Array.isArray(this.areasList) && this.areasList.length > 0) {
        return this.areasList.map(({ name, crmId }) => {
          return { crmId, name };
        });
      }
      return [];
    },
    recommendedVolume() {
      if (this.recommendedVolumeRaw != null && this.wagonNorm != null) {
        return this.$roundToNearestMultiple(this.recommendedVolumeRaw, this.wagonNorm);
      }
      return null;
    },
  },
  methods: {
    async fetchAreasList(params) {
      this.loading = true;

      try {
        const { success, list } = await this.$api.cropArea.getCropArea({
          ...params,
          pagination: params.pagination || {
            withCount: false,
            pageSize: 200,
          },
        });
  
        if (success) {
          this.areasList = list;
        }
      } catch (error) {
        console.error(error);
        this.$notice(this.$translate("request_error"), undefined, {
          type: "error",
        });
      } finally {
        this.loading = false;
      }
    },
    async fetchRecommendedVolume(cropArea, product) {
      this.loading = true;

      try {
        const { success, data } = await this.$api.cropArea.getRecommenationCropArea({
          // stringify the data to be able to detect issues with the query params
          cropArea: JSON.stringify(cropArea.crmId),
          product: JSON.stringify(product.crmId),
        });
  
        if (success && Number.isFinite(data?.quantity)) {
          this.recommendedVolumeRaw = data.quantity;
        }
      } catch (error) {
        console.error(error);
        this.$notice(this.$translate("request_error"), undefined, {
          type: "error",
        });
      } finally {
        this.loading = false;
      }
    },
    async submit() {
      if (this.loading || this.area == null) {
        return false;
      }

      this.loading = true;

      this.emitter.emit('orderRecommendedVolume', {
        quantity: this.recommendedVolume,
      });
    },
    resetForm() {
      this.formKey = 'faqForm_' + this.$rnd(0, 100);
      this.recommendedVolumeRaw = null;
      this.areaCurrent = null;
    },
    closeModal() {
      this.$popup.hide('OrderRecommendedVolume', () => this.resetForm());
    },
    onAreaChange(item) {
      // celculate recommended volume after user select area
      this.fetchRecommendedVolume(
        item,
        this.popupData?.product,
      );
    },
    changeDropdown(_key, name, item) {
      switch (name) {
        case 'area':
          this.areaCurrent = item;
          this.onAreaChange(item);
          break;

        default:
          break;
      }
    },
  },
  created() {
    this.emitter.on('SuccessUpdateOrderRecommendedVolume', () => {
      this.$popup.hide('OrderRecommendedVolume', () => {
        this.loading = false;
        this.resetForm();
      });
    });

    this.$store.subscribeAction(({ type, payload }) => {
      if (type === 'SHOW_Popup' && payload?.name === 'OrderRecommendedVolume') {
        void this.fetchAreasList({
          fields: ['name', 'crmId'],
          populate: false,
        })
          .catch(console.error);
      }
    });
  },
  beforeUnmount() {
    this.emitter.off('SuccessUpdateOrderRecommendedVolume');
  },
};
</script>
<style lang="scss" src="./component.scss"></style>
