// eslint-disable-next-line
export default ($api, store, router) => {

    return {
        async login(data) {
            const response = await $api.auth.login(data);
            console.log('response login', response);
            const { success = false, jwt, user } = response;

            if (success) {
                this.setUser({ jwt, user, success });
                $api.io.connect({ authToken: jwt })
                    .then(() => {
                        store.dispatch('EFFECT_WatchNotifications');
                    })
                    .catch(err => console.error(err));
            }

            return response
        },
        async update(data) {
            const response = await $api.auth.update(this.user.id, data);
            const { success = false, data: user } = response;
            if (success) {
                this.setUser({ jwt: store.getters['auth/GET_Token'], user, success });
            }
            return response
        },
        async changePassword({ password }) {
            const response = await $api.auth.update(this.user.id, { password });
            const { success = false } = response;
            if (success) {
                const { email } = this.user;
                this.setUser({ jwt: null, user: null, success: false })
                await this.login({ email, password });
            }
            return response
        },
        async getMe() {
            const response = await $api.auth.getMe();
            const { success = false, user } = response;
            if (success) {
                this.setUser({ user, success, jwt: store.getters['auth/GET_Token'] })
            } else {
                this.logOut()
            }
            return response
        },
        setUser({ jwt, user, success }) {
            store.dispatch('auth/SET_User', { jwt, user, success })
            localStorage.setItem('api_logined', success);
            localStorage[success ? 'setItem' : 'removeItem']('api_token', jwt);
        },
        logOut() {
            this.setUser({ jwt: null, user: null, success: false })
            $api.io.disconnect();
            return router.push({ name: 'login' });
        },
        async forgotPassword({ phone }) {
            const response = await $api.auth.forgotPassword({ phone });
            console.log('forgotPassword response', response);
            return response
        },
        get loggedIn() {
            return store.getters['auth/GET_IsLogined']
        },
        get user() {
            return store.getters['auth/GET_User']
        },
    }
}