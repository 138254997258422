<template>
  <tr @click="click">
    <td>
      <span class="date" v-if="currentDate">{{ currentDate }}</span>
      <span class="time" v-if="currentTime">{{ currentTime }}</span>
    </td>
    <td>
      <span v-if="row?.documentNumber" class="detailing"
        ><b>{{ row.documentNumber }}</b></span
      >
    </td>
    <td>
      <Tag
        class="w160"
        :text="row.documentType.name"
        v-if="row?.documentType?.name"
      />
    </td>
    <td>
      <span class="additionalInformation" v-if="row.additionalInformation">{{
        row.additionalInformation
      }}</span>
    </td>
    <td>
      <Dropdown ref="dropdown" :contentClass="'padding--min'">
        <button @click="goIn" class="dropdown-btn" type="button" aria-label="">
          <PenIcon /> <span>{{ $translate("review") }}</span>
        </button>
      </Dropdown>
    </td>
  </tr>
</template>

<script>
import Tag from "@/components/Table/PageTable/Tag";
import Dropdown from "@/components/Table/PageTable/Dropdown";
import PenIcon from "@/assets/img/pen-white.svg?.raw";

/**
 * The DocumentsTableItem component.
 */
export default {
  name: "DocumentsTableItem",
  components: { Tag, Dropdown, PenIcon },
  props: {
    row: { default: () => {}, type: Object },
  },
  computed: {
    // ...mapGetters(['IS_Tablet']),
    hasLink() {
      return this.row?.document?.link != undefined;
    },
    currentDate() {
      if (!this.row.date) return false;
      return this.$moment(this.row.date).format("DD.MM.YY");
    },
    currentTime() {
      if (!this.row.date) return false;
      return this.$moment(this.row.date).format("HH:mm");
    },
  },
  methods: {
    // ...mapActions(['GET_PaymentInfo']),
    click(e) {
      try {
        const cancelClass = this.$refs.dropdown
          ? this.$refs.dropdown.$el.classList.toString()
          : ".Dropdown";
        let cancelTarget = e.target.closest(`.${cancelClass}`);
        if (cancelTarget) return null;
        return this.goIn();
      } catch (e) {
        console.log(e);
      }
    },
    goIn() {
      const id = this.row?.crmId || null;
      if (!id) return null;
      return this.$router.push({ name: "documents-item", params: { id } });
    },
  },
};
</script>

<style lang="scss" scoped src="./component.scss"></style>
