<template>
  <tr>
    <td>
      <span class="article" v-if="row?.suPriceList?.name">{{
        row.suPriceList.name
      }}</span>
    </td>
    <td>
      <span class="product" v-if="row?.product?.name">{{
        row?.product.suGoodWithoutPackaging?.name || row?.product.name
      }}</span>
    </td>
    <td>
      <Tag
        v-if="row?.deliveryCondition?.name"
        :text="row.deliveryCondition.name"
      />
    </td>
    <td>
      <span class="type" v-if="row?.basePriceGroup?.name">{{
        row.basePriceGroup.name
      }}</span>
    </td>
    <td>
      <ul class="storageList" v-if="row.storages && row.storages.length">
        <li
          v-for="(item, index) in row.storages.slice(0, 2)"
          :key="index"
          :class="{ last: index === 1 }"
        >
          <Tag :text="item.name" type="primary" class="small" />
        </li>
        <li class="moreStorage" v-if="moreStorageList">
          <span>+{{ moreStorageList }}</span>
          <Notice :title="$translate('all_locations', 'orders')">
            <ul>
              <li v-for="item in row.storages.slice(2)" :key="item.id">
                <Tag :text="item.name" type="primary" class="small" />
              </li>
            </ul>
          </Notice>
        </li>
      </ul>
      <span v-else>
        {{ $translate("no_storage_placeholder") }}
      </span>
    </td>
    <td>
      <span class="price_per_ton" v-if="row?.sellingPrice">{{
        $price(row.sellingPrice)
      }}</span>
    </td>
    <td>
      <span class="valid_until" v-if="validUntilDate">{{
        validUntilDate
      }}</span>
    </td>
  </tr>
</template>

<script>
import Tag from "@/components/Table/PageTable/Tag";
import Notice from "@/components/Notice";
/**
 * The DocumentsTableItem component.
 */
export default {
  name: "DocumentsTableItem",
  components: { Tag, Notice },
  props: {
    row: { default: () => {}, type: Object },
  },
  computed: {
    // ...mapGetters(['IS_Tablet']),
    validUntilDate() {
      if (!this.row?.validTo) return false;
      return this.$moment(this.row.validTo).format("DD.MM.YYYY");
    },
    moreStorageList() {
      if (this.row.storages && this.row.storages.length > 2) {
        return this.row.storages.slice(2).length;
      }
      return false;
    },
  },
  methods: {
    // ...mapActions(['GET_PaymentInfo']),
  },
};
</script>

<style lang="scss" scoped src="./component.scss"></style>
