<template>
  <LogoSVG class="Logo" />
</template>

<script>
import LogoSVG from "@/assets/img/logo.svg";
/**
 * The Logo component.
 */
export default {
  name: "Logo-Component",
  components: { LogoSVG },
};
</script>

<style lang="scss" scoped>
.Logo {
  width: toRem(331);
  height: toRem(54);
  display: inline-block;
  @media (max-width: $notebook) {
    width: toRem(265);
    height: toRem(43);
  }
  @media (max-width: $mobile) {
    width: toRem(223);
    height: toRem(37);
  }
}
</style>