<template>
  <router-view v-if="isChildrenPage"></router-view>
  <LayoutDashboard name="companies" v-else>
    <CounterpartiesSection />
    <AreasFundSection />
  </LayoutDashboard>
</template>

<script>
import LayoutDashboard from "@/layouts/Dashboard.vue";
import CounterpartiesSection from "@/components/CounterpartiesSection";
import AreasFundSection from "@/components/AreasFundSection";

/**
 * The companies-page component.
 */
export default {
  name: "CompaniesPage",
  components: { LayoutDashboard, CounterpartiesSection, AreasFundSection },
  data() {
    return {};
  },
  computed: {
    isChildrenPage() {
      return this.$route.name !== "companies";
    },
  },
};
</script>

<style lang="scss" scoped src="./component.scss"></style>
