<template>
  <tr>
    <td>
      <span class="date" v-if="row?.suAmountVat">
        <span class="document">{{ $price(row.suAmountVat) }}</span>
        <a
          v-if="downloadUrl != null"
          class="simpleLink download-document-btn"
          :href="downloadUrl"
          target="_blank"
          download>
          <DownloadSVG />
        </a>
      </span>
    </td>
    <td>
      <span v-if="row?.documentNumber">{{
        row.documentNumber
      }}</span>
    </td>
    <td>
      <span class="paid" v-if="row?.orderPaymentAmount != null"
        ><b>{{ $price(row.orderPaymentAmount) }}</b></span
      >
    </td>
    <td>
      <span class="order" v-if="row?.order?.number">{{
        row.order.number
      }}</span>
    </td>
    <td>
      <span class="extreme_date" :class="{ extremeDateToday, paymentOverdue }">
        {{ extremeDate }}
        <span class="today" v-if="extremeDateToday">
          ({{ $translate("today") }})
        </span>
      </span>
    </td>
    <td>
      <span class="change_non_payment">
        <template v-if="row?.cautionAmount">
          {{
            row.cautionMessage
              ? row.cautionMessage
              : $translate("the_account_will_increase_by", "orders")
          }}
          <span class="change_non_payment_price">
            {{ $price(row.cautionAmount) }}
            <Notice>
              {{ row.cautionCaption }}
            </Notice>
          </span>
        </template>
        <template v-else>
          {{ $translate("case_non_payment_invoice_new_price", "orders") }}
        </template>
      </span>
    </td>
    <td>
      <!-- <Dropdown  /> -->
    </td>
  </tr>
</template>

<script>
import DownloadSVG from "@/assets/img/download.svg";
import Notice from "@/components/Notice";
/**
 * The DocumentsTableItem component.
 */
export default {
  name: "DocumentsTableItem",
  components: {
    Notice,
    DownloadSVG,
  },
  props: {
    row: { default: () => {}, type: Object },
  },
  computed: {
    // ...mapGetters(['IS_Tablet']),
    hasLink() {
      return false;
      // return this.row?.document?.link != undefined;
    },
    extremeDate() {
      if (!this.row.documentValidToDate) return false;
      return this.$moment(this.row.documentValidToDate).format("DD.MM.YY");
    },
    extremeDateToday() {
      if (!this.row.documentValidToDate) return false;
      return (
        this.$moment(this.row.documentValidToDate).format("DD.MM.YY") ===
        this.$moment().format("DD.MM.YY")
      );
    },
    paymentOverdue() {
      if (!this.row.documentValidToDate) return false;
      return this.$moment().diff(this.row.documentValidToDate, "days") > 0;
    },
    downloadUrl() {
      const fileUrl = this.row?.file.url;
      
      if (typeof fileUrl != "string") {
        return null;
      }

      const url = new URL(process.env.VUE_APP_API_DOMAIN);
      url.pathname = fileUrl;

      return url.toString();
    },
  },
  methods: {
    // ...mapActions(['GET_PaymentInfo']),
  },
};
</script>

<style lang="scss" scoped src="./component.scss"></style>
