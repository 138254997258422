<template>
  <div class="LoadingIcon" :class="{ absolute }">
    <LoadingSVG />
  </div>
</template>

<script>
import LoadingSVG from "@/assets/img/loadingIcon.svg";
/**
 * The Loading icon component.
 */
export default {
  name: "LoadingIcon",
  props: {
    /**
     * Absolute position
     * @values true, false
     */
    absolute: { default: false, type: Boolean },
  },
  components: { LoadingSVG },
};
</script>

<style lang="scss" scoped>
@keyframes rotate {
  0% {
    transform: rotate(0deg);
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    transform: rotate(360deg);
    opacity: 1;
  }
}
.LoadingIcon {
  display: table;
  margin: auto;
  z-index: 50;
  :deep(svg) {
    width: 150px;
    height: 150px;
    animation: rotate 1.3s linear infinite;
  }
  &.absolute {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>