<template>
  <tr @click="click">
    <td>
      <span class="date" v-if="currentDate">{{ currentDate }}</span>
      <span class="time" v-if="currentTime">{{ currentTime }}</span>
    </td>
    <td>
      <Tag class="w190" :text="docType" v-if="docType" />
    </td>
    <td>
      <a
        v-if="download"
        class="simpleLink"
        :download="filename"
        :href="download"
      >
        <span v-if="docNum" class="document">{{ docNum }}</span>
        <DownloadSVG class="DownloadSVG" v-if="row.file?.url" />
      </a>
      <span v-else-if="!download && docNum" class="document">{{ docNum }}</span>
    </td>
    <td>
      <span v-if="details">{{ details }}</span>
    </td>
    <td>
      <Dropdown ref="dropdown" :contentClass="'padding--min'">
        <button @click="goIn" class="dropdown-btn" type="button" aria-label="">
          <PenIcon /> <span>{{ $translate("review") }}</span>
        </button>
      </Dropdown>
    </td>
  </tr>
</template>

<script>
import DownloadSVG from "@/assets/img/download.svg";
import Tag from "@/components/Table/PageTable/Tag";
import Dropdown from "@/components/Table/PageTable/Dropdown";
import PenIcon from "@/assets/img/pen-white.svg?.raw";
export default {
  name: "DocumentsTableItem",
  components: {
    DownloadSVG,
    Tag,
    Dropdown,
    PenIcon,
  },
  props: {
    row: { default: () => {}, type: Object },
  },
  computed: {
    currentDate() {
      if (!this.row.date) return false;
      return this.$moment(this.row.date).format("DD.MM.YY");
    },
    currentTime() {
      if (!this.row.createdAt) return false;
      return this.$moment(this.row.createdAt).format("HH.mm");
    },
    docType() {
      return this.row?.documentType?.name;
    },
    docNum() {
      return this.row?.documentNumber;
    },
    download() {
      const pre = process.env.VUE_APP_API_DOMAIN;
      const url = this.row?.file?.url;
      return url ? pre + url : null;
    },
    filename() {
      return this.row?.file?.name || "file";
    },
    details() {
      return this.row?.additionalInformation;
    },
  },
  methods: {
    click(e) {
      try {
        const cancelClass = this.$refs.dropdown
          ? this.$refs.dropdown.$el.classList.toString()
          : ".Dropdown";
        let cancelTarget = e.target.closest(`.${cancelClass}`);
        if (cancelTarget) return null;
        return this.goIn();
      } catch (e) {
        console.log(e);
      }
    },
    goIn() {
      const id = this.row?.crmId || null;
      if (!id) return null;
      return this.$router.push({ name: "documents-item", params: { id } });
    },
  },
};
</script>

<style lang="scss" scoped src="./component.scss"></style>
