import mitt from 'mitt';
const emitter = mitt();

import translate from './functions/translate';
import simpleInputValidate from './functions/simpleInputValidate';
import slideAccordion from './functions/slideAccordion';
import myAxios from './axios';
import api from './api/index';
import auth from './auth';
import num2word from './functions/num2word';
import moment from 'moment';
import popup from './popup';
import price from './functions/price';
import scrollbarWidth from './functions/scrollbarWidth';
import queryParams from './functions/queryParams';
import order from './functions/order';
import PageFilter from './PageFilter';
import placesSearchService from './functions/placesSearch';
import directives from './directives';

import notice from './notice';
import getNotice from './functions/getNotice';

// device
import device from 'current-device';

const prefix = (index) => (index < 10 ? '0' + index : index);
const upperCase = (text = ' ') => text[0].toUpperCase() + text.slice(1);
const capitalize = (text = ' ') => text[0].toUpperCase() + text.slice(1);
const copyObject = (obj = {}) => {
  return JSON.parse(JSON.stringify(obj));
};
const roundToNearestMultiple = (num, multiple) => Math.round(num / multiple) * multiple;
const offset = (elem, getRect = false) => {
  // this method is jQuery 3.6.0 offset method
  let rect, win;
  if (!elem) return;
  // Return zeros for disconnected and hidden (display: none) elements (gh-2310)
  // Support: IE <=11 only
  // Running getBoundingClientRect on a
  // disconnected node in IE throws an error
  if (!elem.getClientRects().length) {
    return { top: 0, left: 0 };
  }
  // Get document-relative position by adding viewport scroll to viewport-relative gBCR
  rect = elem.getBoundingClientRect();
  win = elem.ownerDocument.defaultView;
  win = window;

  // return simple rect info
  if (getRect) {
    const { top, left, width, height } = rect;
    return { top, left, width, height };
  }
  return {
    top: rect.top + win.pageYOffset,
    left: rect.left + win.pageXOffset,
  };
};
const initThrottle = () => {
  let throttleTimer;
  return {
    init: (callback, time) => {
      if (throttleTimer) return;
      clearTimeout(throttleTimer);
      throttleTimer = setTimeout(() => {
        callback();
        clearTimeout(throttleTimer);
        throttleTimer = false;
      }, time);
    },
    clear: () => {
      clearTimeout(throttleTimer), (throttleTimer = null);
    },
  };
};

// const clearSort = (sort) => {
//     return Object.keys(sort).reduce((acc, key) => {
//         if (typeof sort[key] == "string" && sort[key]) {
//             acc[key] = sort[key];
//         } else if (typeof sort[key] == "object" && sort[key].name) {
//             acc[key] = { name: sort[key].name };
//         }
//         return acc;
//     }, {});
// }
// window.reverseSortParam = reverseSortParam
export default (app, store, router) => {
  app.config.globalProperties.emitter = emitter;
  app.config.globalProperties.$translate = translate;
  app.config.globalProperties.$simpleInputValidate = simpleInputValidate;
  app.config.globalProperties.$slideAccordion = slideAccordion;
  app.config.globalProperties.$rnd = (min = 0, max = 2048) => {
    var rand = min - 0.5 + Math.random() * (max - min + 1);
    rand = Math.round(rand);
    return rand;
  };
  app.config.globalProperties.$numberWithCommas = (x) => {
    return parseFloat(x.toFixed(10))
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
      .replace('.', ',');
  };

  app.config.globalProperties.$prefix = prefix;
  app.config.globalProperties.$num2word = num2word;
  app.config.globalProperties.$moment = moment;
  app.config.globalProperties.$popup = popup;
  app.config.globalProperties.$upperCase = upperCase;
  app.config.globalProperties.$capitalize = capitalize;
  app.config.globalProperties.$price = price;
  app.config.globalProperties.$scrollbarWidth = scrollbarWidth;
  app.config.globalProperties.$queryParams = queryParams;
  app.config.globalProperties.$PageFilter = new PageFilter({ emitter });
  app.config.globalProperties.$copyObject = copyObject;
  app.config.globalProperties.$order = order;
  app.config.globalProperties.$roundToNearestMultiple = roundToNearestMultiple;
  app.config.globalProperties.$notice = notice(app);
  app.config.globalProperties.$getNotice = getNotice;
  app.config.globalProperties.$offset = offset;
  app.config.globalProperties.$initThrottle = initThrottle;
  app.config.globalProperties.$placesSearch = placesSearchService;

  // myAxios
  app.config.globalProperties.$axios = myAxios(store);
  app.config.globalProperties.$api = api(app.config.globalProperties.$axios);
  app.config.globalProperties.$auth = auth(app.config.globalProperties.$api, store, router);
  app.config.globalProperties.$api.io.init();

  // device
  app.config.globalProperties.$device = device;

  // params
  const appElem = document.getElementById('app');
  const params = (() => {
    const isIpad =
      navigator.maxTouchPoints &&
      navigator.maxTouchPoints > 2 &&
      /MacIntel/.test(navigator.platform);
    const isMacLike = navigator.platform.match(/(Mac|iPhone|iPod|iPad)/i) ? true : false || isIpad;
    const isIOS = navigator.platform.match(/(iPhone|iPod|iPad)/i) ? true : false || isIpad;
    const mobile = device.mobile() || isIpad || device.tablet() || isIOS;
    const tablet = device.tablet() || isIpad;
    const mobileDevice = mobile || tablet || isIOS;
    return { isIpad, isMacLike, isIOS, mobile, tablet, mobileDevice };
  })();
  for (const key in params) {
    const value = params[key];
    if (value) appElem.classList.add(key);
    app.config.globalProperties[`$${key}`] = value;
  }

  directives(app);
};
