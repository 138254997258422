import auth from './auth';
import global from './global';
import document from './document';
import checkout from './checkout';
import product from './product';
import file from './file';
import faq from './faq';
import cropArea from './cropArea';
import company from './company';
import io from './io';

export default ($axios) => {
  return {
    auth: auth($axios),
    document: document($axios),
    checkout: checkout($axios),
    product: product($axios),
    file: file($axios),
    faq: faq($axios),
    cropArea: cropArea($axios),
    company: company($axios),
    io: io(),
    ...global($axios),
  };
};
