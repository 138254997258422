<template>
  <router-view v-if="isChildrenPage"></router-view>
  <LayoutDashboard name="company" v-else>
    <template v-if="loading && !company"> <LoadingIcon /></template>
    <template v-else>
      <DashboardTitleRow
        class="counterparties"
        :title="company.name"
        :titleTag="'h4'"
        :titleTagClass="'h4'"
        :goBack="{ name: 'companies' }"
      >
        <template v-slot:title>
          <div class="btnWrap">
            <MyButton
              class="primary primary400 width-auto border--40 size_xs"
              iconAfter="edit"
              :text="$translate('add_details', 'companies')"
              size="s"
              tag="router-link"
              :to="{
                name: 'add-company-bills',
                params: { id: company.id },
              }"
            />
          </div>
        </template>
      </DashboardTitleRow>
      <PageTabs
        class="marg-big"
        v-if="pageTabsList && pageTabsList.length"
        :list="pageTabsList"
      />
      <LoadingIcon v-if="list === null" />
      <template v-else>
        <template v-if="list.length">
          <CompaniesTable
            :list="list"
            :loading="loading"
            :name="sort.name"
            :bankName="sort.bankName"
            @sort="sortTable"
          />
          <Pagination
            @paginate="paginate"
            :pageCurrent="pageCurrent"
            :pageCount="pageCount"
            :perPage="PAGE_SIZE"
            :class="{ disabledElem: loading }"
          />
        </template>
        <p class="no_data" v-else>{{ $translate("no_data") }}</p>
      </template>
    </template>
  </LayoutDashboard>
</template>
<script>
const PAGE_SIZE = 7;
import LayoutDashboard from "@/layouts/Dashboard.vue";
import DashboardTitleRow from "@/components/DashboardTitleRow";
import PageTabs from "@/components/PageTabs";
import CompaniesTable from "@/components/Table/Companies";
import Pagination from "@/components/Pagination";
//pageTabsList
import pageTabList from "../pageTabList.js";
import { mapGetters } from "vuex";

export default {
  name: "CompanyBills",
  components: {
    LayoutDashboard,
    DashboardTitleRow,
    PageTabs,
    CompaniesTable,
    Pagination,
  },
  data() {
    const sort = this.$queryParams.parse(this.$route.query.sort);
    console.log("sort", sort);
    return {
      pageTabsList: pageTabList(this, "bills"),
      currentPage: 1,
      loading: false,
      list: null,
      PAGE_SIZE: PAGE_SIZE,
      pageCurrent: parseInt(this.$route.query.page) || 1,
      pageCount: 1,
      sort: {
        name: sort.name || "asc",
        bankName: sort?.bankName,
      },
    };
  },
  computed: {
    ...mapGetters(["GET_Companies"]),
    isChildrenPage() {
      return this.$route.name !== "company-bills";
    },
    company() {
      return this.GET_Companies[this.$route.params.id];
    },
  },
  methods: {
    paginate(page) {
      this.getList(page);
    },
    async getCompany() {
      this.loading = true;

      let { data, success = false } = await this.$api.company.getCompany(
        this.$route.params.id
      );
      this.loading = false;
      if (!success || !data) {
        return this.$store.dispatch("SET_ErrorPage", {
          code: 404,
          message: this.$translate("page_not_found"),
        });
      }
      this.$store.dispatch("SET_Company", { id: this.$route.params.id, data });
    },
    async getList(page, sort = this.sort, params = {}) {
      const clearSort = this.$queryParams.getClear(sort);
      if (this.loading) return;
      this.loading = true;
      const { list, pagination, success } =
        await this.$api.company.getRequisites({
          pagination: { pageSize: PAGE_SIZE, page: page || this.pageCurrent },
          sort: clearSort,
          filters: {
            company: this.$route.params.id,
          },
          ...params,
        });

      if (success) {
        this.list = list;
        this.pageCurrent = pagination.page;
        this.pageCount = pagination.pageCount;

        this.sort = {
          ...this.$queryParams.resetParams(this.sort),
          ...clearSort,
        };

        this.$router.push({
          query: this.$queryParams.getClear({
            page: this.pageCurrent,
            sort: this.$queryParams.stringify(clearSort),
          }),
        });
      }
      this.loading = false;
    },
    async sortTable(params) {
      this.$queryParams.reverseParams(params);
      await this.getList(this.pageCurrent, params);
    },
  },
  watch: {
    $route(to, from) {
      if (to.name === "company-bills") {
        if (to.query.sort) {
          this.sort = this.$queryParams.parse(to.query.sort);
        } else {
          this.sort = {
            name: "asc",
            bankName: null,
          };
        }
        if (from.name != to.name) this.getList();
      }
    },
  },
  async created() {
    if (!this.isChildrenPage) {
      if (!this.company) await this.getCompany();
      await this.getList();
      this.emitter.on("RemoveCompanyItem", () => this.getList());
    }
  },
  beforeUnmount() {
    this.emitter.off("RemoveCompanyItem");
  },
};
</script>
<style scoped src="../style.scss" lang="scss"></style>
