<template>
  <div class="SiteNav" :class="{ 'is-minimized': minimized }">
    <section class="content">
      <nav>
        <ol>
          <li v-for="item in navList" :key="item.title">
            <router-link
              :to="item.link"
              class="navLink simpleLink"
              :key="item.title"
            >
              <span class="icon">
                <component :is="item.icon + 'SVG'" />
              </span>
              <span class="text">{{ $translate(item.title) }}</span>
            </router-link>
          </li>
        </ol>
      </nav>
      <div class="line"></div>
      <Manager />
    </section>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
import Manager from "./Manager";
/**
 * The Sidebar component.
 */
export default {
  name: "SidebarComponent",
  props: {
    minimized: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Manager,
    ordersSVG: defineAsyncComponent(() =>
      import("@/assets/img/nav/orders.svg")
    ),
    priceListSVG: defineAsyncComponent(() =>
      import("@/assets/img/nav/priceList.svg")
    ),
    documentsSVG: defineAsyncComponent(() =>
      import("@/assets/img/nav/documents.svg")
    ),
    usersSVG: defineAsyncComponent(() => import("@/assets/img/nav/users.svg")),
    packagesSVG: defineAsyncComponent(() =>
      import("@/assets/img/nav/packages.svg")
    ),
    paymentsSVG: defineAsyncComponent(() =>
      import("@/assets/img/nav/payments.svg")
    ),
    notificationsSVG: defineAsyncComponent(() =>
      import("@/assets/img/nav/notifications.svg")
    ),
    faqSVG: defineAsyncComponent(() => import("@/assets/img/nav/faq.svg")),
  },
  data() {
    return {
      navList: [
        {
          title: "my_orders",
          link: { name: "orders" },
          icon: "orders",
        },
        {
          title: "price_list",
          link: { name: "price-list" },
          icon: "priceList",
        },
        {
          title: "my_documents",
          link: { name: "documents" },
          icon: "documents",
        },
        {
          title: "my_users",
          link: { name: "companies" },
          icon: "users",
        },
        {
          title: "my_packages",
          link: { name: "packages" },
          icon: "packages",
        },
        {
          title: "my_payments",
          link: { name: "payments" },
          icon: "payments",
        },
        {
          title: "my_notifications",
          link: { name: "notifications" },
          icon: "notifications",
        },
        {
          title: "faq",
          link: { name: "faq" },
          icon: "faq",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped src="./component.scss"></style>
