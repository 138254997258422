<template>
  <div class="DashboardCard" ref="wrap">
    <div class="wrap">
      <slot name="icon"></slot>
      <div class="content">
        <div class="topContent">
          <component
            :is="titleTag"
            v-if="title"
            v-html="title"
            class="h4"
          ></component>
          <p v-if="text" v-html="text"></p>
        </div>
        <div class="close" v-if="close" @click="closeClick">
          <CloseSVG />
        </div>
        <div class="slot">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CloseSVG from "@/assets/img/close.svg";
import gsap from "gsap";
/**
 * The DashboardCard component.
 */
export default {
  name: "DashboardCard",
  components: { CloseSVG },
  props: {
    title: { default: null, type: String },
    titleTag: { default: "h4", type: String },
    text: { default: null, type: String },
    close: { default: false, type: Boolean },
  },
  methods: {
    closeClick() {
      gsap.to(this.$refs.wrap, {
        opacity: 0,
        duration: 0.3,
        height: 0,
        padding: 0,
        margin: 0,
        onComplete: () => {
          this.$emit("close");
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped src="./component.scss"></style>
