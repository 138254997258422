<template>
  <ul>
    <li v-if="canEdit">
      <button @click="edit" aria-label="edit" type="button">
        <PenSVG />
        <span>{{ $translate('edit', 'general') }}</span>
      </button>
    </li>
    <li v-if="canDivide">
      <button @click="separete" aria-label="separete" type="button">
        <SepareteSVG />
        <span>{{ $translate('split_TI', 'documents') }}</span>
      </button>
    </li>
    <li v-if="canDelete">
      <button @click="tiCancell" aria-label="cansel" type="button">
        <CloseWhiteSVG />
        <span>{{ $translate('Cancel', 'general') }}</span>
      </button>
    </li>
  </ul>
</template>
<script>
import PenSVG from '@/assets/img/Pen.svg';
import SepareteSVG from '@/assets/img/Separate.svg';
import CloseWhiteSVG from '@/assets/img/CloseWhite.svg';

export default {
  name: 'TableActions',
  components: {
    PenSVG,
    SepareteSVG,
    CloseWhiteSVG,
  },
  props: {
    data: { default: () => {}, type: Object },
  },
  methods: {
    edit() {
      this.$popup.show('TIEdit', this.data);
    },
    separete() {
      this.$popup.show('TIDuplication', this.data);
    },
    tiCancell() {
      this.$popup.show('TICancellation', this.data);
    },
  },
  computed: {
    canEdit() {
      return this.data?.actions?.update;
    },
    canDivide() {
      return this.data?.actions?.divide;
    },
    canDelete() {
      return this.data?.actions?.delete;
    },
  },
};
</script>
<style scoped lang="scss">
ul {
  list-style-type: none;
  li {
    margin-bottom: toRem(10);
    &:last-child {
      margin-bottom: 0;
    }
  }
  button {
    cursor: pointer;
    border: none;
    outline: none;
    background: none;
    display: flex;
    align-items: center;
    padding: 0;
    min-height: toRem(18);
    svg {
      width: toRem(14);
      height: toRem(14);
      object-fit: contain;
      object-position: center;
      margin-right: toRem(10);
    }
    font-weight: 400;
    font-size: toRem(14);
    line-height: toRem(18);

    color: $white;
  }
}
</style>
