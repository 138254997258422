<template>
  <LayoutDashboard name="documents">
    <DashboardTitleRow :title="$translate('my_orders', 'orders')">
      <template v-slot:title>
        <LabelDropdown
          class="w222 marginBottom0"
          name="number"
          :list="balanceTypeList"
          :active="balanceTypeActive"
          @change="changeBalanceType"
          :size="changeBalanceSize"
        />
      </template>
    </DashboardTitleRow>
    <PageTabs :list="pageTabsList" />
    <PageFilter
      :loading="loading"
      :list="filterList"
      @submit="submitFilter"
      @reset="resetFilter"
      @remove="removeFilterTag"
    >
      <LabelDropdown
        name="number"
        :title="$translate('order_number', 'orders')"
        :list="filter.number"
        :active="filterCurrent.number"
        @change="changeDropdown"
      />
    </PageFilter>
    <div
      class="OrderAccordionsList"
      v-if="list.length"
      :class="{ loadingElem: loading }"
    >
      <OrderAccordion
        v-for="(item, index) in list"
        :order="item"
        :key="index"
        :balanceType="balanceTypeActive?.id"
      />
    </div>
    <LoadingIcon v-if="loading && !list.length" />
    <template v-if="!loading && !list.length">
      <p class="no_data">{{ $translate("no_data") }}</p>
    </template>
    <Pagination
      @paginate="paginate"
      :pageCurrent="pageCurrent"
      :pageCount="pageCount"
      :perPage="PAGE_SIZE"
      :class="{ disabledElem: loading }"
    />
  </LayoutDashboard>
</template>

<script>
const PAGE_SIZE = 5;
import LayoutDashboard from "@/layouts/Dashboard.vue";
import DashboardTitleRow from "@/components/DashboardTitleRow";
import PageTabs from "@/components/PageTabs";
import PageFilter from "@/components/PageFilter";
import Pagination from "@/components/Pagination";
import OrderAccordion from "@/components/OrderAccordion";
import { mapGetters } from "vuex";
/**
 * The orders-final-balance-page component.
 */
export default {
  name: "OrdersFinalBalancePage",
  components: {
    LayoutDashboard,
    DashboardTitleRow,
    PageTabs,
    PageFilter,
    Pagination,
    OrderAccordion,
  },
  data() {
    const filter = this.$queryParams.parse(this.$route.query.filter);
    return {
      PAGE_SIZE: PAGE_SIZE,
      loading: true,
      preList: [],
      list: [],
      pageCurrent: parseInt(this.$route.query.page) || 1,
      pageCount: 1,
      filterCurrentCopy: null,
      filterCurrent: {
        number: filter.number || null,
      },
      pageTabsList: [
        {
          id: 1,
          text: this.$translate("order_history", "orders"),
          to: { name: "orders" },
          active: false,
        },
        {
          id: 2,
          text: this.$translate("final_balance", "orders"),
          to: { name: "final-balance" },
          active: true,
        },
      ],
      formKey: "form_0",
      balanceTypeList: [
        {
          id: "trade",
          name: this.$translate("trade_balance", "orders"),
        },
        {
          id: "volume",
          name: this.$translate("volume_balance", "orders"),
        },
      ],
      balanceTypeActive: null,
    };
  },
  computed: {
    ...mapGetters(["IS_Notebook"]),
    changeBalanceSize() {
      return this.IS_Notebook ? "m" : "l";
    },
    filter() {
      const data = {
        number: [],
      };
      this.preList.forEach((item) => {
        data.number.push(item.number);
      });
      // get unique values of array
      data.number = [...new Set(data.number)].filter((item) => item);

      return data;
    },
    filterList() {
      const list = [];
      // number
      if (this.filterCurrentCopy.number) {
        list.push({
          id: "number",
          type: this.$translate("order_number", "orders"),
          text: this.filterCurrentCopy.number,
        });
      }
      return list;
    },
  },
  methods: {
    paginate(page) {
      this.getOrders(page);
    },
    async getPreList() {
      const { list = [] } = await this.$api.getDocumentsTree({
        populate: ["number"],
      });
      this.preList = list;
      this.loading = false;
    },
    async getOrders(page, filter = this.filterCurrent, params = {}) {
      const clearFilter = this.$queryParams.getClear(filter, true, true);
      if (this.loading) return;
      this.loading = true;
      const { list, pagination, success } = await this.$api.getDocumentsTree({
        pagination: { pageSize: PAGE_SIZE, page: page || this.pageCurrent },
        filters: clearFilter,
        ...params,
      });
      if (success) {
        this.filterCurrentCopy = this.$copyObject(this.filterCurrent);
        this.list = list;
        this.pageCurrent = pagination.page;
        this.pageCount = pagination.pageCount;

        this.$router.push({
          query: this.$queryParams.getClear({
            page: this.pageCurrent,
            filter: this.$queryParams.stringify(clearFilter),
          }),
        });
      }
      this.loading = false;
    },

    changeDropdown(key, name) {
      switch (name) {
        case "number":
          this.filterCurrent.number = key;
          break;
        default:
          break;
      }
    },
    async submitFilter() {
      await this.getOrders(1);
    },
    async resetFilter() {
      this.$queryParams.resetParams(this.filterCurrent);
      this.formKey = "form_" + this.$rnd(0, 100);
      await this.getOrders(1);
    },
    async removeFilterTag(id) {
      this.changeDropdown(null, id);
      await this.getOrders();
    },
    changeBalanceType(id) {
      this.balanceTypeActive = this.balanceTypeList.find((el) => el.id === id);
      localStorage.setItem("ordersBalanceType", id);
    },
  },
  async created() {
    this.balanceTypeActive = (() => {
      if (localStorage.getItem("ordersBalanceType")) {
        return (
          this.balanceTypeList.find(
            (el) => el.id === localStorage.getItem("ordersBalanceType")
          ) || this.balanceTypeList[0]
        );
      }
      return this.balanceTypeList[0];
    })();

    this.filterCurrentCopy = this.$copyObject(this.filterCurrent);
    await this.getPreList();
    this.loading = false;
    await this.getOrders();
  },
  mounted() {
    this.emitter.on("PageFilter:show", () => {
      this.filterCurrent = this.$copyObject(this.filterCurrentCopy);
      this.formKey = "form_" + this.$rnd(0, 100);
    });
  },
};
</script>

<style lang="scss" scoped src="./component.scss"></style>
