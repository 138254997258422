<template>
  <div class="datePicker">
    <VueDatePicker
      :auto-position="false"
      :month-picker="monthPicker"
      :range="range"
      :locale="locale"
      :select-text="$translate('select', 'documents')"
      :cancel-text="$translate('cancel', 'general')"
      :placeholder="placeholder"
      :mode-height="monthPicker ? 160 : 'auto'"
      v-model="date"
      @update:model-value="handleDate"
    ></VueDatePicker>
  </div>
</template>
<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
export default {
  name: 'datePicker',
  components: {
    VueDatePicker,
  },
  props: {
    locale: {
      type: String,
      default: 'uk',
    },
    range: {
      type: Boolean,
      default: true,
    },
    monthPicker: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  data: function () {
    return {
      date: null,
    };
  },
  methods: {
    handleDate(modelData) {
      this.$emit('dateUpdate', modelData);
    },
  },
};
</script>
<style lang="scss" scoped>
.datePicker {
  max-width: toRem(205);
  height: auto;
  margin-top: toRem(8);
  margin-bottom: toRem(10);
  border-radius: toRem(8);
  box-shadow: 0px 3px 9px rgba(28, 52, 84, 0.12);
  ::v-deep(.dp__pointer) {
    color: $gray;
    font-weight: 400;
    font-size: toRem(14);
    line-height: toRem(14);
    font-family: inherit;
    height: toRem(32);
    border: none;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
  ::v-deep(.dp__input) {
    font-family: inherit;
    font-weight: 500;
    font-size: toRem(14);
    line-height: toRem(14);
    color: $gray;
    &::placeholder {
      font-weight: 400;
    }
  }
  ::v-deep(.dp__overlay_cell) {
    color: $gray;
  }
  ::v-deep(.dp__overlay_cell),
  ::v-deep(.dp__overlay_cell_active) {
    font-weight: 400;
    font-size: toRem(14);
    line-height: toRem(14);
    font-family: inherit;
  }
  ::v-deep(.dp__main) {
    font-family: 'Futura PT';

    .dp__theme_light {
      --dp-primary-color: #56af40;
    }
    .dp__menu {
      font-family: inherit;
    }
    .dp__menu_index {
      z-index: 9;
    }
    .dp__action_select,
    .dp__action_cancel {
      font-family: inherit;
      font-size: toRem(14);
    }
    .dp__action_select {
      background: $yellow;
    }
    .dp__action_cancel {
      border-color: $red;
    }
  }
}
</style>
