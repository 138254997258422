<template>
  <Layout class="TI-Edit" id="TIEdit">
    <h4 class="title">
      {{ $translate('edit', 'documents') }} <br />
      {{ $translate('TI', 'documents') }}
    </h4>
    <span class="section-name">{{
      $translate('order_details', 'orders')
    }}</span>
    <ul class="box--ul --row">
      <li v-if="gtransporType">
        <span class="name">{{
          $translate('type_transportation', 'orders')
        }}</span>
        <span class="val big">{{ gtransporType }}</span>
      </li>
      <li v-if="totalVolume">
        <span class="name">{{ $translate('total_volume', 'orders') }}</span>
        <span class="val big"
          >{{ totalVolume }} {{ $translate('t', 'orders') }}</span
        >
      </li>
    </ul>
    <span class="section-name">{{ $translate('edit_data', 'general') }}</span>
    <template v-if="gCondition === 'CPT'">
      <div class="section-loading" :class="{ loading: loading }">
        <ul class="box--ul --row">
          <li>
            <LabelDropdown
              name="railway"
              :title="$translate('choose_a_regional_railway', 'orders')"
              :list="railwayList"
              :active="railwayCurrent"
              @change="changeDropdown"
              :disabled="railwayLoading"
            />
          </li>
          <li>
            <LabelDropdown
              name="station"
              :title="$translate('ZD_destination_station', 'profile')"
              :list="deliveryStationList"
              :active="deliveryStationCurrent"
              @change="changeDropdown"
              :disabled="!railwayCurrent || deliveryLoading"
            />
          </li>

          <li>
            <LabelDropdown
              name="company"
              :title="$translate('counterparty', 'orders')"
              :list="companyList"
              :active="companyCurrent"
              @change="changeDropdown"
              :disabled="companyLoading"
            />
          </li>

          <li>
            <LabelDropdown
              name="consignee"
              :title="$translate('consignee', 'orders')"
              :list="consigneeList"
              :active="consigneeCurrent"
              @change="changeDropdown"
              :disabled="consigneeLoading"
            />
          </li>
          <li class="w100">
            <Label
              :title="$translate('delivery_address', 'orders')"
              :placeholder="$translate('delivery_address', 'orders')"
              name="destination"
              inputType="textarea"
              @onInput="onInputDestination"
              :value="destination"
              ref="destination"
            />
          </li>
          <li class="w100">
            <Label
              :title="$translate('notes', 'general')"
              :placeholder="$translate('notes', 'general')"
              name="notes"
              inputType="textarea"
              @onInput="onInputNotes"
              :value="notes"
              ref="notes"
            />
          </li>
          <li class="w100">
            <Label
              :title="$translate('notes_for_zd', 'profile')"
              :placeholder="$translate('notes_for_zd', 'profile')"
              name="railroadNote"
              inputType="textarea"
              @onInput="onInputRailroadNote"
              :value="railroadNote"
              ref="railroadNote"
            />
          </li>
          <li class="w100">
            <Label
              :title="$translate('column_15_for_the_railway', 'orders')"
              :placeholder="$translate('column_15_for_the_railway', 'orders')"
              name="railroadNote15"
              inputType="textarea"
              @onInput="onInputRailroadNote15"
              :value="railroadNote15"
              ref="railroadNote15"
            />
          </li>
        </ul>
      </div>
    </template>
    <template v-else-if="gCondition === 'EXW'">
      <div class="section-loading" :class="{ loading: loading }">
        <ul class="box--ul --row">
          <li>
            <LabelDropdown
              name="region"
              :title="$translate('region', 'orders')"
              :list="regionList"
              :active="regionCurrent"
              @change="changeDropdown"
              :disabled="regionLoading"
            />
          </li>
          <li>
            <LabelDropdown
              name="storage"
              :title="$translate('composition_of_receipt', 'orders')"
              :list="storageList"
              :active="storageCurrent"
              @change="changeDropdown"
              :disabled="!regionCurrent || storageLoading"
            />
          </li>

          <li class="w100">
            <LabelDropdown
              name="company"
              :title="$translate('counterparty', 'orders')"
              :list="companyList"
              :active="companyCurrent"
              @change="changeDropdown"
              :disabled="companyLoading"
            />
          </li>
          <li class="w100">
            <Label
              :title="$translate('notes', 'general')"
              :placeholder="$translate('notes', 'general')"
              name="notes"
              inputType="textarea"
              @onInput="onInputNotes"
              :value="notes"
              ref="notes"
            />
          </li>
        </ul>
      </div>
    </template>
    <template v-else-if="gCondition === 'DDP'">
      <div class="section-loading" :class="{ loading: loading }">
        <ul class="box--ul --row">
          <li>
            <LabelDropdown
              name="region"
              :title="$translate('region', 'orders')"
              :list="regionList"
              :active="regionCurrent"
              @change="changeDropdown"
              :disabled="regionLoading"
            />
          </li>
          <li>
            <LabelDropdown
              name="storage"
              :title="$translate('composition_of_receipt', 'orders')"
              :list="storageList"
              :active="storageCurrent"
              @change="changeDropdown"
              :disabled="!regionCurrent || storageLoading"
            />
          </li>

          <li>
            <LabelDropdown
              name="company"
              :title="$translate('counterparty', 'orders')"
              :list="companyList"
              :active="companyCurrent"
              @change="changeDropdown"
              :disabled="companyLoading"
            />
          </li>

          <li>
            <LabelDropdown
              name="logisticCompany"
              :title="$translate('carrier', 'orders')"
              :list="logisticCompanyList"
              :active="logisticCompanyCurrent"
              @change="changeDropdown"
              :disabled="logisticCompanyLoading"
            />
          </li>
          <li class="w100">
            <Label
              :title="$translate('delivery_address', 'orders')"
              :placeholder="$translate('delivery_address', 'orders')"
              name="destination"
              inputType="textarea"
              @onInput="onInputDestination"
              :value="destination"
              ref="destination"
            />
          </li>
          <li class="w100">
            <Label
              :title="$translate('notes', 'general')"
              :placeholder="$translate('notes', 'general')"
              name="notes"
              inputType="textarea"
              @onInput="onInputNotes"
              :value="notes"
              ref="notes"
            />
          </li>
        </ul>
      </div>
    </template>
    <template v-else-if="gCondition === 'FCA'">
      <div class="section-loading" :class="{ loading: loading }">
        <ul class="box--ul --row">
          <li>
            <LabelDropdown
              name="region"
              :title="$translate('region', 'orders')"
              :list="regionList"
              :active="regionCurrent"
              @change="changeDropdown"
              :disabled="regionLoading"
            />
          </li>
          <li>
            <LabelDropdown
              name="storage"
              :title="$translate('composition_of_receipt', 'orders')"
              :list="storageList"
              :active="storageCurrent"
              @change="changeDropdown"
              :disabled="!regionCurrent || storageLoading"
            />
          </li>

          <li>
            <LabelDropdown
              name="railway"
              :title="$translate('choose_a_regional_railway', 'orders')"
              :list="railwayList"
              :active="railwayCurrent"
              @change="changeDropdown"
              :disabled="railwayLoading"
            />
          </li>
          <li>
            <LabelDropdown
              name="station"
              :title="$translate('ZD_destination_station', 'profile')"
              :list="deliveryStationList"
              :active="deliveryStationCurrent"
              @change="changeDropdown"
              :disabled="!railwayCurrent || deliveryLoading"
            />
          </li>

          <li>
            <LabelDropdown
              name="company"
              :title="$translate('counterparty', 'orders')"
              :list="companyList"
              :active="companyCurrent"
              @change="changeDropdown"
              :disabled="companyLoading"
            />
          </li>
          <li>
            <LabelDropdown
              name="consignee"
              :title="$translate('consignee', 'orders')"
              :list="consigneeList"
              :active="consigneeCurrent"
              @change="changeDropdown"
              :disabled="consigneeLoading"
            />
          </li>

          <li class="w100">
            <Label
              :title="$translate('delivery_address', 'orders')"
              :placeholder="$translate('delivery_address', 'orders')"
              name="destination"
              inputType="textarea"
              @onInput="onInputDestination"
              :value="destination"
              ref="destination"
            />
          </li>
          <li class="w100">
            <Label
              :title="$translate('notes', 'general')"
              :placeholder="$translate('notes', 'general')"
              name="notes"
              inputType="textarea"
              @onInput="onInputNotes"
              :value="notes"
              ref="notes"
            />
          </li>
          <li class="w100">
            <Label
              :title="$translate('notes_for_zd', 'profile')"
              :placeholder="$translate('notes_for_zd', 'profile')"
              name="railroadNote"
              inputType="textarea"
              @onInput="onInputRailroadNote"
              :value="railroadNote"
              ref="railroadNote"
            />
          </li>
          <li class="w100">
            <Label
              :title="$translate('column_15_for_the_railway', 'orders')"
              :placeholder="$translate('column_15_for_the_railway', 'orders')"
              name="railroadNote15"
              inputType="textarea"
              @onInput="onInputRailroadNote15"
              :value="railroadNote15"
              ref="railroadNote15"
            />
          </li>
        </ul>
      </div>
    </template>

    <div class="formWrap" :class="{ loading: loading }">
      <LoadingIcon class="loader" v-if="loading" :absolute="true" />
      <MyButton
        v-if="tiId"
        @click="submit"
        class="w100"
        :text="$translate('save', 'profile')"
        tag="button"
        :dynamicSize="true"
        :disabled="loading"
      />
      <MyButton
        @click="goBack"
        class="w100"
        :text="$translate('go_back', 'general')"
        tag="button"
        tagType="reset"
        type="cancel"
        :dynamicSize="true"
      />
    </div>
  </Layout>
</template>

<script>
import { mapGetters } from 'vuex';
import Layout from '../../PopupLayout';

export default {
  name: 'TIEdit',
  components: {
    Layout,
  },
  data() {
    return {
      loading: false,
      //new
      railwayList: [],
      railwayCurrent: null,
      railwayLoading: false,

      deliveryStationList: [],
      deliveryStationCurrent: null,
      deliveryLoading: false,

      companyList: [],
      companyCurrent: null,
      companyLoading: false,

      consigneeList: [],
      consigneeCurrent: null,
      consigneeLoading: false,

      destination: null,

      notes: null,

      railroadNote: null,

      railroadNote15: null,

      regionList: [],
      regionCurrent: null,
      regionLoading: false,

      storageList: [],
      storageCurrent: null,
      storageLoading: false,

      logisticCompanyList: [],
      logisticCompanyCurrent: null,
      logisticCompanyLoading: false,
    };
  },
  computed: {
    ...mapGetters(['IS_Mobile', 'GET_CurrentPopup', 'GET_PopupData']),
    gData() {
      return this.GET_PopupData?.[this.GET_CurrentPopup];
    },
    gCondition() {
      return this.gData?.deliveryCondition?.name;
    },
    gtransporType() {
      return [this.gData?.shipmentType?.name, this.gCondition]
        .filter((item) => item)
        .join(', ');
    },
    totalVolume() {
      return [this.gData?.order?.product?.name, this.gData?.order?.quantity]
        .filter((item) => item)
        .join(', ');
    },
    tiId() {
      return this.gData?.id;
    },
  },
  methods: {
    async getRailways(params = {}) {
      try {
        this.railwayLoading = true;
        const { list = [] } = await this.$api.checkout.getRailways(params);
        if (list.length) {
          const railwayId = this.gData?.station?.railway?.id;
          this.railwayList = list;
          this.railwayCurrent =
            this.railwayList.find(({ id }) => id === railwayId) || null;
          if (this.railwayCurrent) {
            this.getStations(railwayId);
          }
        }
        this.railwayLoading = false;
      } catch (e) {
        console.log(e);
        this.railwayLoading = false;
      }
    },
    async getStations(id) {
      try {
        this.deliveryLoading = true;
        const { list = [] } = await this.$api.checkout.getStations({
          filters: {
            railway: {
              id,
            },
          },
        });
        if (list.length) {
          this.deliveryStationList = list;
          this.deliveryStationCurrent =
            list.find((item) => item.id === this.gData?.station?.id) || null;
        }
        this.deliveryLoading = false;
      } catch (e) {
        console.log(e);
        this.deliveryLoading = false;
      }
    },
    async getСompanies() {
      try {
        this.companyLoading = true;
        const { list = [] } = await this.$api.getСompanies();
        console.log('getСompanies', list);
        if (list.length) {
          const id = this.gData?.company?.id;
          this.companyList = list;
          this.companyCurrent = this.companyList.find((item) => item.id === id);
        }
        this.companyLoading = false;
      } catch (e) {
        console.log(e);
        this.companyLoading = false;
      }
    },
    async getConsignees() {
      try {
        this.consigneeLoading = true;
        const { list = [] } = await this.$api.checkout.getConsignees();
        if (list.length) {
          const consogneeId = this.gData?.consignee?.id;
          this.consigneeList = list;
          this.consigneeCurrent =
            this.consigneeList.find(({ id }) => id === consogneeId) || null;
        }
        this.consigneeLoading = false;
      } catch (e) {
        console.log(e);
        this.consigneeLoading = false;
      }
    },
    async getRegions() {
      try {
        this.regionLoading = true;
        const { list = [] } = await this.$api.checkout.getRegions();
        if (list.length) {
          const regionId = this.gData?.storage?.region?.id;
          this.regionList = list;
          this.regionCurrent = list.find(({ id }) => id == regionId) || null;
          if (this.regionCurrent) this.getStorage(this.regionCurrent.id);
        }
        this.regionLoading = false;
      } catch (e) {
        this.regionLoading = false;
        console.log(e);
      }
    },
    async getStorage(id) {
      try {
        this.storageLoading = true;
        const { list = [] } = await this.$api.checkout.getStorage({
          filters: {
            region: {
              id,
            },
          },
        });
        if (list.length) {
          const storageId = this.gData?.storage?.id;
          this.storageList = list;
          this.storageCurrent = list.find(({ id }) => id === storageId);
        }
        this.storageLoading = false;
      } catch (e) {
        console.log(e);
        this.storageLoading = false;
      }
    },
    async getLogisticCompanies() {
      try {
        this.logisticCompanyLoading = true;
        const { list = [] } = await this.$api.checkout.getLogisticCompanies();
        if (list.length) {
          console.log('getLogisticCompanies', list);
          const logisticCompanyListId = this.gData?.logisticCompany?.id;
          this.logisticCompanyList = list;
          this.logisticCompanyCurrent =
            list.find(({ id }) => id === logisticCompanyListId) || null;
        }
        this.logisticCompanyLoading = false;
      } catch (e) {
        console.log(e);
        this.logisticCompanyLoading = false;
      }
    },
    setDestination() {
      const val = this.gData?.destination;
      if (val) this.destination = val;
    },
    setNotes() {
      const val = this.gData?.notes;
      if (val) this.notes = val;
    },
    setRailroadNote() {
      const val = this.gData?.railroadNote;
      if (val) this.railroadNote = val;
    },
    setRailroadNote15() {
      const val = this.gData?.railroadNote15;
      if (val) this.railroadNote15 = val;
    },
    async submit() {
      try {
        if (this.loading) return false;
        const payload = {};
        if (this.deliveryStationCurrent) {
          payload.station = this.deliveryStationCurrent;
        }
        if (this.companyCurrent) {
          payload.company = this.companyCurrent;
        }
        if (this.consigneeCurrent) {
          payload.consignee = this.consigneeCurrent;
        }
        if (this.destination) {
          payload.destination = this.destination;
        }
        if (this.notes) {
          payload.notes = this.notes;
        }
        if (this.railroadNote) {
          payload.railroadNote = this.railroadNote;
        }
        if (this.railroadNote15) {
          payload.railroadNote15 = this.railroadNote15;
        }
        if (this.storageCurrent) {
          payload.storage = this.storageCurrent;
        }
        if (this.logisticCompanyCurrent) {
          payload.logisticCompany = this.logisticCompanyCurrent;
        }
        const { success = false } = await this.$api.document.putInstruction(
          this.tiId,
          payload
        );
        if (success) {
          this.$notice(this.$translate('Edited', 'documents'));
          document.dispatchEvent(new CustomEvent('update-instruction'));
          document.dispatchEvent(new CustomEvent('update-instructions'));
        } else {
          this.$notice(this.$translate('request_error'), undefined, {
            type: 'error',
          });
        }
        this.loading = false;
        this.$popup.hide(undefined, () => this.resetForm());
      } catch (e) {
        console.log(e);
      }
    },
    goBack() {
      this.resetForm();
      this.$popup.hide();
    },
    resetForm() {
      this.railwayCurrent = null;
      this.deliveryStationCurrent = null;
      this.companyCurrent = null;
      this.consigneeCurrent = null;
      this.destination = null;
      this.notes = null;
      this.railroadNote = null;
      this.railroadNote15 = null;
      this.regionCurrent = null;
      this.storageCurrent = null;
      this.logisticCompanyCurrent = null;
      //loadings
      this.railwayLoading = false;
      this.deliveryLoading = false;
      this.companyLoading = false;
      this.consigneeLoading = false;
      this.regionLoading = false;
      this.storageLoading = false;
      this.logisticCompanyLoading = false;
    },
    log(evt) {
      console.log(evt);
    },
    changeDropdown(key, name, item) {
      switch (name) {
        case 'railway':
          this.railwayCurrent = item;
          this.getStations(item.id);
          break;
        case 'station':
          this.deliveryStationCurrent = item;
          break;
        case 'company':
          this.companyCurrent = item;
          break;
        case 'consignee':
          this.consigneeCurrent = item;
          break;
        case 'region':
          this.regionCurrent = item;
          this.getStorage(item.id);
          break;
        case 'storage':
          this.storageCurrent = item;
          break;
        case 'logisticCompany':
          this.logisticCompanyCurrent = item;
          break;
        default:
          break;
      }
    },
    onInputDestination(value = this.destination) {
      this.destination = value;
    },
    onInputNotes(value = this.notes) {
      this.notes = value;
    },
    onInputRailroadNote(value = this.railroadNote) {
      this.railroadNote = value;
    },
    onInputRailroadNote15(value = this.railroadNote15) {
      this.railroadNote15 = value;
    },
    async scopeFunc() {
      if (this.gCondition === 'CPT') {
        this.setDestination();
        this.setNotes();
        this.setRailroadNote();
        this.setRailroadNote15();
        this.getRailways();
        this.getСompanies();
        this.getConsignees();
      }
      if (this.gCondition === 'EXW') {
        this.setNotes();
        this.getRegions();
        this.getСompanies();
      }
      if (this.gCondition === 'DDP') {
        this.setDestination();
        this.setNotes();
        this.getRegions();
        this.getСompanies();
        this.getLogisticCompanies();
      }
      if (this.gCondition === 'FCA') {
        this.setDestination();
        this.setNotes();
        this.setRailroadNote();
        this.setRailroadNote15();
        this.getRegions();
        this.getRailways();
        this.getСompanies();
        this.getConsignees();
      }
    },
  },
  watch: {
    GET_CurrentPopup: function (current) {
      if (current === 'TIEdit') {
        this.scopeFunc();
      }
    },
  },
};
</script>
<style lang="scss" src="./style.scss"></style>
