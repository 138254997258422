<template>
  <router-view :key="$route.fullPath" v-if="isChildrenPage"></router-view>
  <LayoutDashboard name="documents" v-else>
    <DashboardTitleRow
      :title="$translate('my_documents', 'documents')"
      :text="$translate('my_documents_text', 'documents')"
    />
    <PageFilter
      :loading="loading"
      :list="filterList"
      @submit="submitFilter"
      @reset="resetFilter"
      @remove="removeFilterTag"
    >
      <Label
        :title="$translate('calendar_select_date')"
        name="filterDate"
        inputType="datepicker"
        @onInput="onInputFilterDate"
        ref="filterDate"
        :value="filterCurrent.date"
        :upperLimit="false"
        calendarStartingView="months"
        :key="formKey"
      />
      <LabelDropdown
        name="documentType_name"
        :title="$translate('document_type', 'documents')"
        :list="filter.documentType.name"
        :active="filterCurrent.documentType.name"
        @change="changeDropdown"
      />
      <LabelDropdown
        name="order_number"
        :title="$translate('order', 'orders')"
        :list="filter.orders.number"
        :active="filterCurrent.orders?.number"
        @change="changeDropdown"
      />
    </PageFilter>
    <DocumentsTable
      :list="list"
      v-if="list.length"
      :loading="loading"
      :date="sort.date"
      :documentNumber="sort.documentNumber"
      :documentType="sort.documentType"
      :order="sort.order"
      @sort="sortTable"
    />
    <LoadingIcon v-if="loading && !list.length" />
    <template v-if="!loading && !list.length">
      <p class="no_data">{{ $translate('no_data') }}</p>
    </template>
    <Pagination
      @paginate="paginate"
      :pageCurrent="pageCurrent"
      :pageCount="pageCount"
      :perPage="PAGE_SIZE"
      :class="{ disabledElem: loading }"
    />
  </LayoutDashboard>
</template>

<script>
const PAGE_SIZE = 9;

import LayoutDashboard from '@/layouts/Dashboard.vue';
import DashboardTitleRow from '@/components/DashboardTitleRow';
import PageFilter from '@/components/PageFilter';
import DocumentsTable from '@/components/Table/Documents';
import Pagination from '@/components/Pagination';
/**
 * The documents-page component.
 */
export default {
  name: 'DocumentsPage',
  components: {
    LayoutDashboard,
    DashboardTitleRow,
    PageFilter,
    DocumentsTable,
    Pagination,
  },
  data() {
    const filter = this.$queryParams.parse(this.$route.query.filter);
    const sort = this.$queryParams.parse(this.$route.query.sort);

    if (Object.keys(sort).length === 0) {
      sort.date = 'desc';
    }

    return {
      PAGE_SIZE: PAGE_SIZE,
      loading: true,
      documentTypes: [],
      documentOrders: [],
      list: [],
      currentPage: 1,
      pageCurrent: parseInt(this.$route.query.page) || 1,
      pageCount: 1,
      sort: {
        date: sort.date || undefined,
        documentNumber: sort.documentNumber || undefined,
        documentType: { name: sort.documentType?.name || undefined },
        order: { number: sort.order?.number || undefined },
      },
      filterCurrentCopy: null,
      filterCurrent: {
        date: filter.date
          ? this.$moment(filter.date, 'YYYY-MM-DD').format('DD/MM/YYYY')
          : null,
        documentType: {
          name: filter.documentType?.name || null,
        },
        orders: {
          number: filter.orders?.number || null,
        },
      },
      formKey: 'form_0',
    };
  },
  computed: {
    isChildrenPage() {
      return this.$route.name !== 'documents';
    },
    filter() {
      const data = {
        documentType: {
          name: [],
        },
        orders: {
          number: new Set(),
        },
      };

      this.documentTypes.forEach(({ name }) => {
        data.documentType.name.push(name);
      });

      this.documentOrders.forEach(({ orders }) => {
        orders.forEach(({ number }) => {
          data.orders.number.add(number);
        });
      });

      data.orders.number = [...data.orders.number];

      return data;
    },
    filterList() {
      const list = [];

      // documentType_name
      if (this.filterCurrentCopy.documentType?.name) {
        list.push({
          id: 'documentType_name',
          type: this.$translate('document_type', 'documents'),
          text: this.filterCurrentCopy.documentType.name,
        });
      }
      if (this.filterCurrentCopy.orders?.number) {
        list.push({
          id: 'order_number',
          type: this.$translate('order', 'orders'),
          text: this.filterCurrentCopy.orders.number,
        });
      }

      return list;
    },
  },
  methods: {
    paginate(page) {
      console.log('paginate page', page);
      this.pageCurrent = page;
      this.getDocuments(page);
    },
    async getDocumentTypes() {
      try {
        const { list, success } = await this.$api.document.getTypes();
        if (success) {
          this.documentTypes = list;
        }
      } catch (error) {
        console.error(error);
      }
    },
    // Prefetch all the documents for order number filter
    async getAllDocuments() {
      try {
        const { list, success } = await this.$api.document.getDocuments({
          fields: ['id'],
          pagination: {
            pageSize: 2000,
            withCount: false,
          }
        });
        if (success) {
          this.documentOrders = list;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getPreList() {
      this.loading = false;
      try {
        await Promise.all([
          this.getDocumentTypes(),
          this.getAllDocuments(),
        ]);
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    async getDocuments(
      page,
      sort = this.sort,
      filter = this.filterCurrent,
      params = {}
    ) {
      const clearSort = this.$queryParams.getClear(sort);
      const clearFilter = this.$queryParams.getClear(filter, true, true);
      if (this.loading) return;
      this.loading = true;
      const { list, pagination, success } =
        await this.$api.document.getDocuments({
          pagination: { pageSize: PAGE_SIZE, page: page || this.pageCurrent },
          sort: clearSort,
          filters: clearFilter,
          ...params,
        });
      if (success) {
        this.filterCurrentCopy = this.$copyObject(this.filterCurrent);
        this.list = list;
        this.pageCurrent = pagination.page;
        this.pageCount = pagination.pageCount;

        this.sort = {
          ...this.$queryParams.resetParams(this.sort),
          ...clearSort,
        };

        this.$router.push({
          query: this.$queryParams.getClear({
            page: this.pageCurrent,
            sort: this.$queryParams.stringify(clearSort),
            filter: this.$queryParams.stringify(clearFilter),
          }),
        });
      }
      this.loading = false;
    },
    async sortTable(params) {
      this.$queryParams.reverseParams(params);
      await this.getDocuments(this.pageCurrent, params);
    },
    changeDropdown(key, name) {
      switch (name) {
        case 'documentType_name':
          this.filterCurrent.documentType.name = key;
          break;
        case 'date':
          this.filterCurrent.date = key;
          break;
        case 'order_number':
          this.filterCurrent.orders.number = key;
          break;
        default:
          break;
      }
    },
    onInputFilterDate(value) {
      this.filterCurrent.date = value;
    },
    async submitFilter() {
      await this.getDocuments(1);
    },
    async resetFilter() {
      this.$queryParams.resetParams(this.filterCurrent);
      this.formKey = 'form_' + this.$rnd(0, 100);
      await this.getDocuments(1);
    },
    async removeFilterTag(id) {
      this.changeDropdown(null, id);
      await this.getDocuments();
    },
  },
  watch: {
    async isChildrenPage(val) {
      if (val) return;
      this.filterCurrentCopy = this.$copyObject(this.filterCurrent);
      await this.getPreList();
      await this.getDocuments();
    },
  },
  async created() {
    this.filterCurrentCopy = this.$copyObject(this.filterCurrent);
    if (!this.isChildrenPage) {
      await this.getPreList();
      await this.getDocuments();
    }
  },
  mounted() {
    this.emitter.on('PageFilter:show', () => {
      this.filterCurrent = this.$copyObject(this.filterCurrentCopy);
      this.formKey = 'form_' + this.$rnd(0, 100);
    });
  },
  beforeUnmount() {
    this.emitter.off('PageFilter:show');
  },
};
</script>

<style lang="scss" scoped src="./component.scss"></style>
