<template>
  <LabelDropdown
    :name="name"
    :list="listSorted"
    :active="current"
    :loading="loading"
    :disabled="disabled"
    @change="changeDropdown"
  >
    <template v-slot:top="dropdownSlotProps">
      <Label
        :title="title"
        :placeholder="placeholder"
        :value="searchField"
        ref="searchField"
        name="searchField"
        :type="inputType"
        :class="labelClass"
        :key="current?.id"
        @focus="
          dropdownSlotProps?.toggle($event, true);
          maybeFetchSearchData();
        "
        @onInput="onSearchInputDebounced"
      />
    </template>
    <template v-slot:item="itemProps">
      <slot name="item" :item="itemProps.item">
        {{ itemProps.item[displayProperty] }}
      </slot>
    </template>
  </LabelDropdown>
</template>
<script>
import debounce from 'lodash/debounce';

/* LabelSearchSelect */
export default {
  name: 'LabelSearchSelect',
  props: {
    // A function to fetch data. Must return a promise.
    // It's important for the function to pass the abort signal to the axios
    // for performance reasons,
    getData: {
      type: Function,
      required: true
    },
    // A function to construct API-compatible params. Must return an object.
    getDataParams: {
      type: Function,
      default: null,
    },
    name: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    inputType: {
      type: String,
      default: 'text'
    },
    labelClass: {
      type: [String, Object, Array],
      default: null
    },
    placeholder: {
      type: String,
      default: ''
    },
    displayProperty: {
      type: String,
      default: 'name'
    },
    sortResults: {
      type: Boolean,
      default: true
    },
    value: {
      type: [Object, String, Number],
      default: () => null
    },
  },
  data() {
    return {
      current: null,
      searchField: null,
      searchFieldError: null,
      list: [],
      abortController: null,
      loading: false,
    }
  },
  computed: {
    listSorted() {
      if (!this.sortResults) return this.list;

      const searchField = this.searchField?.trim().toLowerCase();
      return this.list.slice().sort((a, b) => {
        const ad = a[this.displayProperty]?.toLowerCase().indexOf(searchField);
        const bd = b[this.displayProperty]?.toLowerCase().indexOf(searchField);

        if (ad === bd) {
          // If the searchField is found at the same index in both strings,
          // compare the strings alphabetically
          return a[this.displayProperty]?.localeCompare(b[this.displayProperty]);
        }
        
        return ad - bd;
      });
    },
  },
  methods: {
    changeDropdown(key, name, item) {
      if (item == null) return;

      this.current = item;
      this.searchField = item[this.displayProperty];
      this.$emit('change', key, name, item);
    },
    async maybeFetchSearchData() {
      if (this.list?.length > 0) {
        return
      }
      
      this.abortController?.abort();
      this.abortController = new AbortController();
      this.loading = true;

      try {
        const params = this.getDataParams instanceof Function
          ? this.getDataParams()
          : {
            sort: {
              [this.displayProperty]: 'asc',
            },
          };
        this.list = await this.getData(params, this.abortController.signal);
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    onInputField(value) {
      if (value != undefined) {
        this.searchField = value;
      }
      
      const validateError = this.$simpleInputValidate(
        value || this.searchField,
        this.required,
        "name"  // validateType
      );
      
      this.searchFieldError = validateError;

      if (validateError.error) {
        this.$refs.searchField.$refs.input.focus();
      }

      return validateError.error;
    },
    async onSearchInput(value) {
      if (this.current?.name === value?.trim()) return;

      const error = this.onInputField(value);

      if (error) return;

      const _value = value.trim();
      const params = this.getDataParams instanceof Function
        ? this.getDataParams(_value)
        : {
            filters: {
              [this.displayProperty]: {
                $containsi: _value,
              }
            },
            sort: {
              [this.displayProperty]: 'asc',
            },
          };

      this.loading = true;

      try {
        this.abortController?.abort();
        this.abortController = new AbortController();
        this.list = await this.getData(
          {
            ...params,
          },
          this.abortController.signal
        );
      } catch (error) {
        console.error(error);
        this.list = [];
        this.searchFieldError = {
          error: true,
          text: this.$translate("request_error"),
        };
      } finally {
        this.loading = false;
      }
    },
    onSearchInputDebounced: debounce(function (...args) {
      this.onSearchInput(...args);
    }, 100),
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        console.log(
          'LabelSearchSelect: watch value',
          value,
          this.current,
          value?.id,
        )
        if (value == null) {
          this.current = null;
          this.searchField = null;
          return;
        }

        if (this.current?.id === value?.id) return;

        this.current = value;
        this.searchField = value[this.displayProperty];
      }
    },
  },
}
</script>