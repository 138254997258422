<template>
  <router-view v-if="isChildrenPage"></router-view>
  <LayoutDashboard name="documents" v-else>
    <DashboardTitleRow :title="$translate('my_orders', 'orders')" />
    <PageTabs :list="pageTabsList" />
    <PageFilter
      :loading="loading"
      :list="filterList"
      @submit="submitFilter"
      @reset="resetFilter"
      @remove="removeFilterTag"
    >
      <Label
        :title="$translate('calendar_select_date')"
        name="filterDate"
        inputType="datepicker"
        @onInput="onInputFilterDate"
        ref="filterDate"
        :value="filterCurrent.date"
        :upperLimit="false"
        calendarStartingView="months"
        :key="formKey"
      />
      <!-- <LabelDropdown
        name="date"
        :title="$translate('date', 'documents')"
        :list="filtersData.date"
        :active="filterCurrent.date"
        @change="changeDropdown"
      /> -->
      <LabelDropdown
        name="number"
        :title="$translate('order_number', 'orders')"
        :list="filtersData.number"
        :active="filterCurrent.number"
        @change="changeDropdown"
      />
      <LabelDropdown
        name="account_name"
        :title="$translate('counterparty', 'orders')"
        :list="filtersData.account"
        :active="filterCurrent.account"
        @change="changeDropdown"
      />
      <LabelDropdown
        name="delivery_condition"
        :title="$translate('logistics', 'orders')"
        :list="filtersData.deliveryCondition"
        :active="filterCurrent.deliveryCondition"
        @change="changeDropdown"
      />
      <LabelDropdown
        name="status"
        :title="$translate('order_status', 'orders')"
        :list="filtersData.status"
        :active="filterCurrent.status"
        @change="changeDropdown"
      />
      <LabelDropdown
        name="product"
        :title="$translate('product', 'orders')"
        :list="filtersData.product"
        :active="filterCurrent.product"
        @change="changeDropdown"
      />
    </PageFilter>
    <OrdersTable
      :list="list"
      v-if="list.length"
      :loading="loading"
      :sortByDate="sort.date"
      :sortByNumber="sort.number"
      :sortByAccount="sort.account"
      :sortByStatus="sort.status"
      @sort="sortTable"
    />
    <LoadingIcon v-if="loading && !list.length" />
    <template v-if="!loading && !list.length">
      <p class="no_data">{{ $translate("no_data") }}</p>
    </template>
    <Pagination
      @paginate="paginate"
      :pageCurrent="pageCurrent"
      :pageCount="pageCount"
      :perPage="PAGE_SIZE"
      :class="{ disabledElem: loading }"
    />
  </LayoutDashboard>
</template>

<script>
const PAGE_SIZE = 9;

import LayoutDashboard from "@/layouts/Dashboard.vue";
import DashboardTitleRow from "@/components/DashboardTitleRow";
import PageTabs from "@/components/PageTabs";
import PageFilter from "@/components/PageFilter";
import OrdersTable from "@/components/Table/Orders";
import Pagination from "@/components/Pagination";
/**
 * The orders-page component.
 */
export default {
  name: "DocumentsPage",
  components: {
    LayoutDashboard,
    DashboardTitleRow,
    PageTabs,
    PageFilter,
    OrdersTable,
    Pagination,
  },
  data() {
    const filtersData = this.$queryParams.parse(this.$route.query.filtersData);
    const sort = this.$queryParams.parse(this.$route.query.sort);
    return {
      title: this.$translate("my_orders", "orders"),
      PAGE_SIZE: PAGE_SIZE,
      loading: true,
      preList: [],
      list: [],
      pageCurrent: parseInt(this.$route.query.page) || 1,
      pageCount: 1,
      sort: {
        date: Object.keys(sort).length === 0 ? 'desc' : sort?.date,
        number: sort?.number,
        account: { name: sort?.account?.name || undefined },
        status: { name: sort?.status?.name || undefined },
      },
      filtersData: {
        date: null,
        number: [],
        account: [],
        deliveryCondition: [],
        status: [],
        product: [],
      },
      filterCurrentCopy: {},
      filterCurrent: {
        date: filtersData.date != null && this.$moment(filtersData.date).isValid()
          ? this.$moment(filtersData.date, "YYYY-MM-DD").format("DD/MM/YYYY")
          : null,
        number: filtersData.number || null,
        account: {
          id: filtersData.account?.id || null,
        },
        deliveryCondition: {
          id: filtersData.deliveryCondition?.id || null,
        },
        status: {
          id: filtersData.status?.id || null,
        },
        product: {
          id: filtersData.product?.id || null,
        },
      },
      pageTabsList: [
        {
          id: 1,
          text: this.$translate("order_history", "orders"),
          to: { name: "orders" },
          active: true,
        },
        {
          id: 2,
          text: this.$translate("final_balance", "orders"),
          to: { name: "final-balance" },
          active: false,
        },
      ],
      formKey: "form_0",
    };
  },
  computed: {
    isChildrenPage() {
      return this.$route.name !== "orders";
    },
    filterList() {
      const list = [];

      // date
      if (this.filterCurrentCopy.date) {
        list.push({
          id: "date",
          type: this.$translate("date"),
          text: this.$moment(this.filterCurrentCopy.date, "DD/MM/YYYY").format(
            "DD.MM.YY"
          ),
        });
      }
      // number
      if (this.filterCurrentCopy.number) {
        list.push({
          id: "number",
          type: this.$translate("order_number", "orders"),
          text: this.filterCurrentCopy.number,
        });
      }
      // counterparty
      if (
        this.filterCurrentCopy.account?.id &&
        // check if collection is filled up to map out the current filter's name
        // the collection might not be initialized with the current data at the time
        // of this computed property re-evaluation
        this.filtersData.account.length > 0
      ) {
        const item = this.filtersData.account.find((el) => 
          el.id === this.filterCurrentCopy.account.id
        );
        list.push({
          id: "company_name",
          type: this.$translate("counterparty", "orders"),
          text: item.name,
        });
      }
      // delivery condition
      if (
        this.filterCurrentCopy.deliveryCondition?.id &&
        this.filtersData.deliveryCondition.length > 0
      ) {
        const item = this.filtersData.deliveryCondition.find((el) => 
          el.id === this.filterCurrentCopy.deliveryCondition.id
        );
        list.push({
          id: "delivery_condition",
          type: this.$translate("logistics", "orders"),
          text: item.name,
        });
      }
      // status
      if (
        this.filterCurrentCopy.status?.id &&
        this.filtersData.status.length > 0
      ) {
        const item = this.filtersData.status.find((el) => 
          el.id === this.filterCurrentCopy.status.id
        );

        list.push({
          id: "status",
          type: this.$translate("order_status", "orders"),
          text: item.name,
        });
      }
      // product
      if (
        this.filterCurrentCopy.product?.id &&
        this.filtersData.product.length > 0
      ) {
        const item = this.filtersData.product.find((el) => 
          el.id === this.filterCurrentCopy.product.id
        );
        list.push({
          id: "product",
          type: this.$translate("product", "orders"),
          text: item.name,
        });
      }

      return list;
    },
  },
  methods: {
    paginate(page) {
      this.getOrders(page);
    },
    extractFiltersData(payload) {
      const result = {
        date: [],
        number: [],
        account: {},
        deliveryCondition: {},
        status: {},
        product: {},
      };

      for (let i = 0; i < payload.length; i++) {
        const order = payload[i];

        if (order.number && !result.number.includes(order.number)) {
          result.number.push(order.number);
        }

        if (
          order.account &&
          !(order.account.id in result.account)
        ) {
          result.account[order.account.id] = {
            id: order.account.id,
            name: order.account.name,
          };
        }

        if (
          order.deliveryCondition &&
          !(order.deliveryCondition.id in result.deliveryCondition)
        ) {
          result.deliveryCondition[order.deliveryCondition.id] = {
            id: order.deliveryCondition.id,
            name: order.deliveryCondition.name,
          };
        }

        if (
          order.status &&
          !(order.status.id in result.status)
        ) {
          result.status[order.status.id] = {
            id: order.status.id,
            name: order.status.name,
          };
        }

        if (
          order.product &&
          !(order.product.id in result.product)
        ) {
          result.product[order.product.id] = {
            id: order.product.id,
            name: order.product.name,
          };
        }
      }
      
      return {
        number: result.number,
        account: Object.values(result.account),
        deliveryCondition: Object.values(result.deliveryCondition),
        status: Object.values(result.status),
        product: Object.values(result.product),
      };
    },
    async getPreList() {
      const { list = [] } = await this.$api.getOrders({
        populate: {
          product: {
            fields: ['name']
          },
          account: {
            fields: ['name']
          }, 
          deliveryCondition: {
            fields: ['name']
          },
          status: {
            fields: ['name']
          },
        },
        fields: ['number', 'date'],
      });
      this.filtersData = this.extractFiltersData(list);
      this.loading = false;
    },
    async getOrders(
      page,
      sort = this.sort,
      filtersData = this.filterCurrent,
      params = {}
    ) {
      const clearSort = this.$queryParams.getClear(sort);
      const clearFilter = this.$queryParams.getClear(filtersData, true, true);

      if (this.loading) return;
      this.loading = true;
      const { list, pagination, success } = await this.$api.getOrders({
        pagination: { pageSize: PAGE_SIZE, page: page || this.pageCurrent },
        sort: clearSort,
        filters: clearFilter,
        ...params,
      });
      if (success) {
        this.filterCurrentCopy = this.$copyObject(this.filterCurrent);
        this.list = list;
        this.pageCurrent = pagination.page;
        this.pageCount = pagination.pageCount;

        this.sort = {
          ...this.$queryParams.resetParams(this.sort),
          ...clearSort,
        };

        this.$router.push({
          query: this.$queryParams.getClear({
            page: this.pageCurrent,
            sort: this.$queryParams.stringify(clearSort),
            filtersData: this.$queryParams.stringify(clearFilter),
          }),
          replace: true,
        });
      }
      this.loading = false;
    },
    async sortTable(sortParams) {
      this.$queryParams.reverseParams(sortParams);
      await this.getOrders(this.pageCurrent, sortParams);
    },
    changeDropdown(key, name) {
      switch (name) {
        case "number":
          this.filterCurrent.number = key;
          break;
        case "date":
          this.filterCurrent.date = key;
          break;
        case "account_name":
          this.filterCurrent.account.id = key;
          break;
        case "delivery_condition":
          this.filterCurrent.deliveryCondition.id = key;
          break;
        case "status":
          this.filterCurrent.status.id = key;
          break;
        case "product":
          this.filterCurrent.product.id = key;
          break;
        default:
          break;
      }
    },
    onInputFilterDate(value) {
      this.filterCurrent.date = value;
    },
    async submitFilter() {
      await this.getOrders(1);
    },
    async resetFilter() {
      this.$queryParams.resetParams(this.filterCurrent);
      this.formKey = "form_" + this.$rnd(0, 100);
      await this.getOrders(1);
    },
    async removeFilterTag(id) {
      this.changeDropdown(null, id);
      await this.getOrders();
    },
  },
  watch: {
    async isChildrenPage(val) {
      if (val) return;
      this.filterCurrentCopy = this.$copyObject(this.filterCurrent);
      await this.getPreList();
      await this.getOrders();
    },
  },
  async created() {
    console.log("created orders page");
    this.filterCurrentCopy = this.$copyObject(this.filterCurrent);
    if (!this.isChildrenPage) {
      await this.getPreList();
      await this.getOrders();
    }
  },
  mounted() {
    console.log("mounted orders page");
    this.emitter.on("PageFilter:show", () => {
      this.filterCurrent = this.$copyObject(this.filterCurrentCopy);
      this.formKey = "form_" + this.$rnd(0, 100);
    });
  },
  beforeUnmount() {
    console.log("beforeUnmount orders page");
    this.emitter.off("PageFilter:show");
  },
};
</script>

<style lang="scss" scoped src="./component.scss"></style>
