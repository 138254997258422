export default {
    // namespaced: true,
    state: {
        translations: null,
    },
    getters: {
        GET_Translations: (state) => state.translations,
    },
    mutations: {
        SET_Translations(state, payload) {
            state.translations = payload;
        },
    },
    actions: {
        async GET_Translations({ commit }, app = window.$app) {
            const { list = [] } = await app.config.globalProperties.$api.getI18ns()
            const translations = list.reduce((acc, {
                section, key, translation
            }) => {
                if (section === null) section = 'general'
                // console.log({ section, key, translation });
                acc[section] ?? (() => acc[section] = {})();
                acc[section][key] = translation
                return acc
            }, {})
            commit('SET_Translations', translations)
        }
    }
}