<template>
  <DashboardCard class="CounterpartiesItem">
    <div class="column">
      <!-- <div class="Tag" :class="{ accent: status.status, gray: !status.status }">
        {{ status.txt }}
      </div> -->

      <Tag
        :text="status.txt"
        class="small2"
        :class="{ active_green: status.status, gray: !status.status }"
      />

      <h4 v-if="name" class="small">{{ name }}</h4>
    </div>

    <template v-if="!IS_Tablet2">
      <div class="column ipnColumn">
        <span class="columnTitle">{{
          $translate("edrpou_ipn", "companies")
        }}</span>
        <span class="val" v-if="ipn">{{ ipn }}</span>
      </div>
      <div class="column balanceColumn">
        <template v-if="status.status">
          <span class="columnTitle">{{
            $translate("balance", "companies")
          }}</span>
          <span class="balance val">{{ balance }}</span>
        </template>
      </div>
    </template>
    <template v-else>
      <table>
        <tbody>
          <tr>
            <td>
              <span class="columnTitle">{{
                $translate("edrpou_ipn", "companies")
              }}</span>
            </td>
            <td>{{ ipn }}</td>
          </tr>
          <tr>
            <td>
              <span v-if="status.status" class="columnTitle">{{
                $translate("balance", "companies")
              }}</span>
            </td>
            <td>
              <span v-if="status.status" class="balance val">{{
                balance
              }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </template>
    <div class="column tagsColumn" v-if="!IS_Tablet2">
      <template v-if="manager && manager.name && status.status">
        <span class="columnTitle">{{ $translate("your_manager") }}</span>
        <div class="managerAction">
          <span class="manager">
            {{ manager.name }}
          </span>
          <div v-if="manager.email || manager.phone" class="action">
            <MyButton
              v-if="manager.email"
              class="action-ico"
              size="s"
              :href="'mailto:' + manager.email"
              tag="a"
              ><MessageSVG
            /></MyButton>
            <MyButton
              v-if="manager.phone"
              class="action-ico"
              size="s"
              :href="'tel:' + trim(manager.phone)"
              tag="a"
              ><PhoneSVG
            /></MyButton>
          </div>
        </div>
      </template>
    </div>
    <div class="column">
      <router-link
        v-if="status.status"
        :to="{
          name: 'company-bills',
          params: {
            id: item.id
          }
        }"
        class="editLink simpleLink accent"
      >
        <span>{{ $translate("editing_profile", "companies") }}</span>
        <ArrowLeftSVG />
      </router-link>
    </div>
  </DashboardCard>
</template>

<script>
import { mapGetters } from "vuex";
import DashboardCard from "@/components/DashboardCard";
import ArrowLeftSVG from "@/assets/img/arrow_left.svg";
import MessageSVG from "@/assets/img/message-square.svg";
import PhoneSVG from "@/assets/img/phone.svg";
import Tag from "@/components/Table/PageTable/Tag";
/**
 * The CounterpartiesItem component.
 */
export default {
  name: "CounterpartiesItem",
  components: { DashboardCard, ArrowLeftSVG, MessageSVG, PhoneSVG, Tag },
  props: {
    item: { default: () => {}, type: [Object, Number] },
  },
  data() {
    return {
      orders: 12,
      documents: 10,
    };
  },
  computed: {
    ...mapGetters(["IS_Tablet2"]),
    status() {
      return {
        txt: this.$translate(
          this.item?.verified ? "status_active" : "on_verification",
          "companies"
        ),
        status: this.item?.verified,
      };
    },
    name() {
      return this.item?.name || "-";
    },
    ipn() {
      return this.item?.edrpou || "-";
    },
    balance() {
      return this.$price(
        this.item?.balance ? this.item.balance : 0,
        undefined,
        true
      );
    },
    manager() {
      return this.item?.manager;
    },
  },
  methods: {
    trim(val) {
      return val.trim().replace(/\s/g, "");
    },
  },
};
</script>

<style lang="scss" scoped src="./component.scss"></style>
