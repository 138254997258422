<template>
  <tr @click="click" :class="{ hasInfo: row?.number }">
    <td>
      <span class="date" v-if="currentDate">
        <span
          v-if="row.status"
          class="status"
          :style="{
            background: row.status.labelColor,
          }"
        ></span>
        {{ currentDate }}</span
      >
    </td>
    <td>
      <span>{{ row.number }}</span>
    </td>
    <td>
      <span
        class="volume"
        v-if="row?.product?.name"
        v-html="row.product.name"
      ></span>
    </td>
    <td>
      <span class="counterparty" v-if="row?.account?.name">{{
        row.account.name
      }}</span>
    </td>
    <td>
      <span class="logistics" v-if="logistics">{{ logistics }}</span>
    </td>
    <td>
      <Tag
        class="w110"
        :color="row.status.labelColor"
        :text="row.status.name"
        v-if="row.status"
      />
    </td>
    <td>
      <Dropdown ref="dropdown" :contentClass="'padding--min'">
        <button
          @click="goIn"
          class="dropdown-btn"
          type="button"
          :class="{ disabled: disabledOpenBtn }"
        >
          <template v-if="row.status.slug === 'draft'">
            <PenIcon /> <span>{{ $translate("continue_edit", "orders") }}</span>
          </template>
          <template v-else>
            <PenIcon /> <span>{{ $translate("review") }}</span>
          </template>
        </button>
      </Dropdown>
    </td>
  </tr>
</template>

<script>
import Tag from "@/components/Table/PageTable/Tag";
import Dropdown from "@/components/Table/PageTable/Dropdown";
import PenIcon from "@/assets/img/pen-white.svg?.raw";

/**
 * The OrdersTableItem component.
 */
export default {
  name: "OrdersTableItem",
  components: { Tag, Dropdown, PenIcon },
  props: {
    row: { default: () => {}, type: Object },
  },
  computed: {
    hasLink() {
      return this.row?.document?.link != undefined;
    },
    currentDate() {
      if (!this.row.date) return false;
      return this.$moment(this.row.date).format("DD.MM.YY");
    },
    currentTime() {
      if (!this.row.date) return false;
      return this.$moment(this.row.date).format("HH:mm");
    },
    logistics() {
      const list = [];
      // shipmentType
      if (this.row.shipmentType) {
        list.push(this.row.shipmentType.name);
      }
      // deliveryCondition
      if (this.row.deliveryCondition) {
        list.push(this.row.deliveryCondition.name);
      }
      // storage
      if (this.row.storage) {
        list.push(
          this.$translate("stock", "orders") + ": " + this.row.storage.name
        );
      }
      // destinationStation
      if (this.row.destinationStation) {
        list.push(this.row.destinationStation.name);
      }
      return list.join(", ");
    },
    disabledOpenBtn() {
      return !this.row?.number;
    },
  },
  methods: {
    encodeURI(id) {
      return id ? encodeURI(id) : "#";
    },
    click(e) {
      try {
        const cancelClass = this.$refs.dropdown
          ? this.$refs.dropdown.$el.classList.toString()
          : ".Dropdown";
        let cancelTarget = e.target.closest(`.${cancelClass}`);
        if (cancelTarget) return null;
        return this.goIn();
      } catch (e) {
        console.log(e);
      }
    },
    goIn() {
      if (this.row?.status?.slug == "draft") {
        // draft status
        const query = this.$queryParams.getClear({
          suGoodWithoutPackaging:
            this.row?.product?.suGoodWithoutPackaging?.crmId,
          packagingId: this.row?.product?.suPackaging?.crmId,
          productId: this.row?.product?.crmId,
          id: this.row?.product?.id,
          data: { id: this.row.id },
        });
        this.$order.set(query);
        return this.$router.push({ name: "checkout" });
      }
      const id = this.row?.number || null;
      if (!id) return null;
      console.log("id", id);
      return this.$router.push({
        name: "my-order",
        params: { id },
      });
    },
  },
};
</script>

<style lang="scss" scoped src="./component.scss"></style>
