export default ($axios) => {
  return {
    async getProducts(params = {}) {
      return $axios({
        url: '/products-without-packaging',
        method: 'get',
        params: { ...params },
      })
        .then(({ data }) => {
          return {
            success: true,
            list: data.data,
            pagination: data.meta.pagination,
          };
        })
        .catch((e) => e);
    },
    async getProductsCategories() {
      return $axios({
        url: '/product-categories',
        method: 'get',
      })
        .then(({ data }) => {
          return {
            success: true,
            list: data.data,
            pagination: data.meta.pagination,
          };
        })
        .catch((e) => e);
    },
    async getProductsByCategory(params = {}) {
      return $axios({
        url: '/su-good-without-packagings',
        method: 'get',
        params: { ...params },
      })
        .then(({ data }) => {
          return {
            success: true,
            list: data.data,
            pagination: data.meta.pagination,
          };
        })
        .catch((e) => e);
    },
    async getMarketingProducts() {
      return $axios({
        url: '/marketing-products',
        method: 'get',
      })
        .then(({ data }) => {
          return {
            success: true,
            list: data.data,
            pagination: data.meta.pagination,
          };
        })
        .catch((e) => e);
    },
    async getMarketingProductById(id, params = {}) {
      return $axios({
        url: '/marketing-product/' + id,
        method: 'get',
        params,
      })
        .then(({ data }) => {
          return {
            success: true,
            data: data.data,
          };
        })
        .catch((e) => e);
    },
    async getProductCRM(id, params = {}) {
      return $axios({
        url: '/product/crm-id/' + id,
        method: 'get',
        params: { ...params },
      })
        .then(({ data }) => {
          return { success: true, data: data.data };
        })
        .catch((e) => e);
    },
    async getProductID(id, params = {}) {
      return $axios({
        url: '/product/' + id,
        method: 'get',
        params: { ...params },
      })
        .then(({ data }) => {
          return { success: true, data: data.data };
        })
        .catch((e) => e);
    },

    async getProductWithoutPacking(id, params = {}) {
      return $axios({
        url: '/su-good-without-packagings/' + id,
        method: 'get',
        params: { ...params },
      })
        .then(({ data }) => {
          return { success: true, data: data.data };
        })
        .catch((e) => e);
    },
  };
};
// https://backend.ostchem.dev.aurocraft.com/api/su-goods-without-packagings/crm-id/:crmId
