import mitt from 'mitt';

/**
 * @typedef {object} Matchedsubstring
 * @property {number} length
 * @property {number} offset
 *
 * @typedef {object} Structuredformatting
 * @property {string} main_text
 * @property {Matchedsubstring[]} main_text_matched_substrings
 * @property {string} secondary_text
 *
 * @typedef {object} AutocompleteEntry
 * @property {string} description
 * @property {Matchedsubstring[]} matched_substrings
 * @property {string} place_id
 * @property {string} reference
 * @property {Structuredformatting} structured_formatting
 * @property {{ offset: number; value: string }[]} terms
 * @property {string[]} types
 */

class PlacesSearchService {
  ready = false;
  service = null;
  emitter = mitt();

  async init() {
    const { Loader } = await import('@googlemaps/js-api-loader');
    const loader = new Loader({
      apiKey: process.env.VUE_APP_GMAPS_API_KEY,
      version: 'weekly',
      libraries: ['places'],
    });
    const { AutocompleteService, PlacesServiceStatus } = await loader.importLibrary('places');
    this.service = new AutocompleteService();
    this.PlacesServiceStatus = PlacesServiceStatus;
    this.ready = true;
    this.emitter.emit('ready');
  }

  /** @returns {Promise<AutocompleteEntry[]>} */
  search(input) {
    return new Promise((resolve, reject) => {
      const options = {
        input,
        componentRestrictions: {
          country: 'ua',
        },
        language: 'uk',
      };
      this.service.getPlacePredictions(options, (predictions, status) => {
        if (status !== this.PlacesServiceStatus.OK) {
          reject(status);
        }
        resolve(predictions);
      });
    });
  }

  onReady(callback) {
    if (this.ready) {
      callback();
    } else {
      this.emitter.on('ready', callback);
      return this.emitter.off('ready', callback);
    }
    return () => {};
  }
}

export default new PlacesSearchService();
