export default ($axios) => {
    return {
        async upload(files = []) {
            if (!files || !files.length) throw new Error('No files to upload')

            const formData = new FormData();
            files.forEach(file => formData.append("files", file));
            return await $axios({
                url: '/upload',
                method: 'post',
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(({ data }) => {
                return { success: true, data }
            }).catch(e => e)
        },
        getFullUrl(url) {
            return `${process.env.VUE_APP_API_URL}${url}`
        },
    }
}