<template>
  <tr @click="click">
    <td>
      <span class="date">{{ currentDate }}</span>
    </td>
    <td>
      <span class="instruction" v-if="row?.number">{{ row.number }}</span>
    </td>
    <td>
      <span class="name" v-if="row?.order?.number">{{ row.order.number }}</span>
    </td>
    <td>
      <span
        class="quantity"
        v-if="row?.order?.quantity"
        v-html="row?.order?.quantity"
      ></span>
    </td>
    <td>
      <span class="counterparty" v-if="counterparty">{{ counterparty }}</span>
    </td>
    <td>
      <span v-if="destination">{{ destination }}</span>
    </td>
    <td>
      <DeliveryStatus
        :class="{ topPosition: index === 0 }"
        :quantity="row.quantity"
        :quantityInTransit="row.quantityInTransit"
        :quantityOnStation="row.quantityOnStation"
        :quantityShipped="row.quantityShipped"
        :quantityInStock="row.quantityInStock"
      />
    </td>
    <td>
      <Dropdown
        ref="dropdown" 
        class="menu-dropdown"
        :contentClass="'padding--min'">
        <button @click="goIn" class="dropdown-btn" type="button" aria-label="">
          <PenIcon /> <span>{{ $translate("review") }}</span>
        </button>
      </Dropdown>
    </td>
  </tr>
</template>

<script>
import Dropdown from "@/components/Table/PageTable/Dropdown";
import DeliveryStatus from "@/components/DeliveryStatus";
import PenIcon from "@/assets/img/pen-white.svg?.raw";
/**
 * The PackagesTableItem component.
 */
export default {
  name: "PackagesTableItem",
  components: { Dropdown, DeliveryStatus, PenIcon },
  props: {
    row: { default: () => {}, type: Object },
    index: { default: 0, type: Number },
  },
  computed: {
    // ...mapGetters(['IS_Tablet']),
    hasLink() {
      return this.row?.document?.link != undefined;
    },
    currentDate() {
      return this.$moment(this.row.createdAt).format("DD.MM.YY");
    },
    counterparty() {
      const list = [];
      // deliveryCondition
      if (this.row?.deliveryCondition?.name) {
        list.push(this.row.deliveryCondition.name);
      }
      // shipmentType
      if (this.row?.shipmentType?.name) {
        list.push(this.row.shipmentType.name);
      }
      return list.join(", ");
    },
    destination() {
      return this.row?.storage?.name;
    },
    orderStatus() {
      return this.row?.status;
    },
  },
  methods: {
    // ...mapActions(['GET_PaymentInfo']),
    click(e) {
      try {
        const cancelClass = this.$refs.dropdown
          ? this.$refs.dropdown.$el.classList.toString()
          : ".Dropdown";
        let cancelTarget = e.target.closest(`.${cancelClass}`);
        if (cancelTarget) return null;
        return this.goIn();
      } catch (e) {
        console.log(e);
      }
    },
    goIn() {
      const id = this.row?.id || null;
      if (!id) return null;
      return this.$router.push({
        name: "my-order-transport-instruction", 
        params: {
          id: this.row?.order?.number,
          tiID: id,
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped src="./component.scss"></style>
