<template>
  <div class="DeliveryCalendarWrapMain">
    <LoadingIcon v-if="GET_LoadingOrder" :absolute="true" />
    <div
      class="DeliveryCalendarWrap"
      ref="wrap"
      :class="{ disabledElem: GET_LoadingOrder }"
    >
      <div class="DeliveryCalendar">
        <table>
          <thead>
            <tr>
              <th v-for="index in 7" :key="index">{{ weekDays[index - 1] }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in tableRow" :key="index">
              <Cell
                v-for="(cell, indexCell) in row"
                :key="'row_' + index + '_column_' + indexCell"
                :cell="cell"
                :data="findQuotaForCell(cell)"
              />
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import Scrollbar from "smooth-scrollbar";
import Cell from "./Cell";
import { mapGetters } from "vuex";
/**
 * The DeliveryCalendar component.
 */
export default {
  name: "DeliveryCalendar",
  components: { Cell },
  props: {
    quotas: { default: () => [], type: Array },
    reserved: { default: () => [], type: Array },
  },
  data() {
    return {
      weekDays: this.$translate("week_days", "orders").split(","),
      months: this.$translate("months", "orders").split(","),
      tableRow: [],
      calendar: {},
      date: null,
      // quota,
      scrollbar: null,
    };
  },
  computed: {
    ...mapGetters(["IS_Tablet2"]),
    ...mapGetters("checkout", ["GET_LoadingOrder"]),
    currentDate() {
      return `${this.months[this.calendar.month]} ${this.calendar.year}`;
    },
  },
  methods: {
    // ...mapActions(['GET_PaymentInfo']),
    createRableRow() {
      this.tableRow = [];
      for (let i = 0; i < 6; i++) {
        this.tableRow.push(new Array(7));
      }
    },
    setDay(row = 0, setDisabled = false) {
      let columnIndex = this.calendar.day - 1;
      if (columnIndex < 0) columnIndex = 6;

      const nextDay = this.$moment(
        this.$moment().add(2, "day").format("DD.MM.YYYY"),
        "DD.MM.YYYY"
      );

      const disabled = setDisabled || nextDay.unix() >= this.date.unix();
      const isToday =
        this.$moment().format("DD.MM.YYYY") === this.date.format("DD.MM.YYYY");

      this.tableRow[row][columnIndex] = {
        day: this.weekDays[columnIndex],
        date: this.date.format("DD"),
        date_full: this.date.format("DD.MM.YYYY"),
        month: this.date.get("month"),
        disabled,
        isToday,
      };
      return columnIndex;
    },
    setRow(row = 0) {
      if (row == 0) this.createRableRow();
      let columnIndex = this.setDay(row);
      if (row === 0 && columnIndex > 0) {
        while (columnIndex > 0) {
          columnIndex--;
          this.date.subtract(1, "day");
          this.calendar.day = this.date.get("day");
          this.setDay(row);
        }

        this.date.set("year", this.calendar.year);
        this.date.set("date", this.calendar.date);
        this.date.set("month", this.calendar.month);
        this.calendar.day = this.date.get("day");
        columnIndex = this.setDay(row);
      }
      // const month = this.calendar.month;
      while (columnIndex < 6) {
        columnIndex++;
        this.date.add(1, "day");
        this.calendar.day = this.date.get("day");
        // const disabled = row === 4 && this.date.get("month") != month;
        this.setDay(row);
      }
      this.date.add(1, "day");
      this.calendar.day = this.date.get("day");
      if (row < 5) {
        this.setRow(row + 1);
      } else {
        // remove last row if has not current month
        if (
          !this.tableRow[row].find(({ month }) => month === this.calendar.month)
        ) {
          this.tableRow.pop();
        }
        // set default date
        this.date.set("year", this.calendar.year);
        this.date.set("date", this.calendar.date);
        this.date.set("month", this.calendar.month);
        this.calendar.day = this.date.get("day");
        // console.log("end setRow", this.date.format("DD.MM.YYYY"));
      }
    },
    moveCalendar(direction) {
      // console.log("moveCalendar", direction);

      this.date[direction === "next" ? "add" : "subtract"](1, "month");
      this.date.set("date", 1); // current month
      this.calendar.month = this.date.get("month");
      this.calendar.year = this.date.get("year");
      this.calendar.date = this.date.get("date");
      this.calendar.day = this.date.get("day");
      this.setRow();
      this.$emit("setCalendar", this.calendar, this.currentDate);
    },
    findQuotaForCell(cell) {
      const find = this.quotas.find(
        ({ date }) => this.$moment(date).format("DD.MM.YYYY") === cell.date_full
      );
      if (find) {
        find.reserved = this.reserved.find(
          ({ date }) =>
            this.$moment(date).format("DD.MM.YYYY") === cell.date_full
        );
      }
      return find;
    },
    destroyScroll() {
      this.scrollbar.destroy();
      this.scrollbar = null;
    },
    initScroll() {
      if (this.scrollbar) return;
      this.scrollbar = Scrollbar.init(this.$refs.wrap, {
        renderByPixels: true,
        damping: 0.1,
        continuousScrolling: true,
        alwaysShowTracks: true,
      });
    },
    updateDeliveryCell({ cell, data, quantity }) {
      console.log("updateDeliveryCell", data);
      const reservedClone = [...this.reserved];
      if (!data.reserved && quantity) {
        reservedClone.push({
          quantity,
          date: this.$moment(cell.date_full, "DD.MM.YYYY").format("YYYY-MM-DD"),
        });
      } else if (data.reserved && quantity) {
        const find = reservedClone.find(({ id }) => id === data.reserved.id);
        find.quantity = quantity;
      } else if (data.reserved && !quantity) {
        const findIndex = reservedClone.findIndex(
          ({ id }) => id === data.reserved.id
        );
        reservedClone.splice(findIndex, 1);
      }

      reservedClone.forEach((el) => delete el.reserved); // clear reserved

      console.log("reservedClone", reservedClone);
      this.emitter.emit("updateOrderQuotas", reservedClone);
    },
  },
  watch: {
    IS_Tablet2(val) {
      val ? this.initScroll() : this.destroyScroll();
    },
  },
  created() {
    this.date = this.$moment();
    // reset time
    this.date
      .set("hour", 0)
      .set("minute", 0)
      .set("second", 0)
      .set("millisecond", 0);

    this.calendar.current = {
      month: this.date.get("month"),
      year: this.date.get("year"),
      date: this.date.get("date"),
    };
    // this.date.set("month", 0);
    this.date.set("date", 1); // current month
    this.calendar.month = this.date.get("month");
    this.calendar.year = this.date.get("year");
    this.calendar.date = this.date.get("date");
    this.calendar.day = this.date.get("day");
    this.setRow();
    this.$emit("setCalendar", this.calendar, this.currentDate);
    //
    this.emitter.on("slideDeliveryCalendar", this.moveCalendar);
    this.emitter.on("updateDeliveryCell", this.updateDeliveryCell);
  },
  mounted() {
    if (this.IS_Tablet2) this.initScroll();
  },
  beforeUnmount() {
    this.emitter.off("slideDeliveryCalendar");
    this.emitter.off("updateDeliveryCell");
    if (this.scrollbar) this.destroyScroll();
  },
};
</script>

<style lang="scss" scoped src="./component.scss"></style>
