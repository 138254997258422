export default {
    // namespaced: true,
    state: {
        graphics: null,
        companies: []
    },
    getters: {
        GET_Graphics: (state) => state.graphics,
        GET_Сompanies: (state) => state.companies,
    },
    mutations: {
        SET_Graphics(state, payload) {
            state.graphics = payload;
        },
        SET_Сompanies(state, payload) {
            state.companies = payload;
        },
    },
    actions: {
        async GET_GraphicsRequest({ commit }, { filters, params = {}, companyFilters = {} }) {
            const { list = [] } = await window.$app.config.globalProperties.$api.getСompanies({
                filters: { ...companyFilters }
            });
            commit('SET_Сompanies', list);

            const { data, success } = await window.$app.config.globalProperties.$api.getGraphics({
                filters,
                ...params,
            });
            commit('SET_Graphics', success ? data : null);

            return { data, success }
        }
    }
}