<template>
  <LayoutDashboard name="add-company">
    <DashboardTitleRow
      :title="
        $translate(
          editPage ? 'edit_contact_person' : 'creating_contact_person',
          'companies'
        )
      "
      :titleTag="'h1'"
      :titleTagClass="'h1'"
      :goBack="{
        name: 'company-contacts',
        params: { id: $route.params.id },
        query: { page: 1, sort: 'name=asc' },
      }"
    />
    <template v-if="editPage && !item">
      <LoadingIcon />
    </template>
    <DashboardCard class="p40 overflow w100per company-add" v-else>
      <div class="formWrap marg--0">
        <LoadingIcon v-if="loading" :absolute="true" />
        <form
          novalidate
          @submit.prevent="submit"
          :class="{ loadingElem: loading }"
          :key="formKey"
        >
          <div class="row margin47 topRow">
            <Label
              :title="$translate('full_name')"
              :placeholder="$translate('full_name')"
              name="name"
              inputType="text"
              @onInput="onInputField"
              :error="inputError['name']"
              ref="name"
              class="w50 mobilew100"
              :value="name"
            />
            <Label
              :title="$translate('position')"
              :placeholder="$translate('position')"
              name="role"
              inputType="text"
              @onInput="onInputField"
              :error="inputError['role']"
              ref="role"
              class="w50 mobilew100"
              :value="role"
            />
            <Label
              :title="$translate('phone')"
              placeholder="+38 093 000 00 00"
              name="phone"
              inputType="tel"
              @onInput="onInputField"
              :error="inputError['phone']"
              ref="phone"
              autocomplete="tel"
              mask="+38 ### ### ## ##"
              class="w50 mobilew100"
              :value="phone"
            />
            <Label
              :title="$translate('email', 'auth')"
              :placeholder="$translate('email_example', 'auth')"
              name="email"
              inputType="email"
              @onInput="onInputField"
              :error="inputError['email']"
              ref="email"
              class="w50 mobilew100"
              :value="email"
            />
          </div>
          <div class="row margin0 between bottomRow">
            <MyButton
              :text="$translate('cancel')"
              tag="button"
              tagType="reset"
              type="cancel"
              :dynamicSize="true"
              @click="resetForm"
            />
            <MyButton
              class="w288"
              :text="
                $translate(
                  editPage ? 'refresh' : 'create',
                  editPage ? 'orders' : 'companies'
                )
              "
              tag="button"
              tagType="submit"
              :dynamicSize="true"
            />
          </div>
        </form>
      </div>
    </DashboardCard>
  </LayoutDashboard>
</template>
<script>
import LayoutDashboard from "@/layouts/Dashboard.vue";
import DashboardTitleRow from "@/components/DashboardTitleRow";
import DashboardCard from "@/components/DashboardCard";

export default {
  name: "AddCompanyContacts",
  components: {
    LayoutDashboard,
    DashboardTitleRow,
    DashboardCard,
  },
  data() {
    return {
      formKey: "form_",
      id: this.$route.params.id,
      loading: false,
      inputError: {},
      name: "",
      role: "",
      phone: "",
      email: "",
      editPage: this.$route.name == "edit-company-contacts",
      item: null,
    };
  },
  computed: {},
  methods: {
    onInputField(value, $event, name, required = false, validateType = "name") {
      if (!name) throw new Error("onInputField: name is undefined");
      if (value != undefined) this[name] = value;
      const validateError = this.$simpleInputValidate(
        value || this[name],
        required,
        validateType
      );
      this.inputError[name] = validateError;
      if (validateError.error) {
        this.$refs[name].$refs.input.focus();
      }
      return validateError.error;
    },
    async submit() {
      if (this.loading) return false;

      const error = (() => {
        const nameError = this.onInputField(undefined, undefined, "name", true);
        const roleError = this.onInputField(undefined, undefined, "role", true);
        const phoneError = this.onInputField(
          undefined,
          undefined,
          "phone",
          true,
          "tel"
        );
        const emailError = this.onInputField(
          undefined,
          undefined,
          "email",
          true,
          "email"
        );
        return nameError || roleError || phoneError || emailError;
      })();
      if (!error) {
        this.loading = true;

        let { success } = await this.$api.company[
          this.editPage ? "editCompanyContactPerson" : "addCompanyContactPerson"
        ](
          {
            name: this.name,
            role: this.role,
            phone: this.phone,
            email: this.email,
            company: this.id,
          },
          this.item?.id
        );
        this.loading = false;
        if (success) {
          this.$router.push({
            name: "company-contacts",
            params: { id: this.id },
          });
          setTimeout(() => {
            this.$notice(
              this.$translate(
                this.editPage
                  ? "contact_person_updated"
                  : "contact_person_created",
                "companies"
              )
            );
          }, 500);
        } else {
          this.$notice(this.$translate("request_error"), "error");
        }
      } else console.log("submit", { error });
    },
    resetForm() {
      this.loading = false;
      this.name = this.editPage ? this.item.name : "";
      this.role = this.editPage ? this.item.role : "";
      this.phone = this.editPage ? this.item.phone : "";
      this.email = this.editPage ? this.item.email : "";
      this.formKey = "form_" + this.$rnd(0, 100);
    },
    async getItem() {
      const { list, success } = await this.$api.company.getCompanyManagers({
        filters: {
          company: this.id,
          id: this.$route.params.itemId,
        },
      });
      this.item = list[0];
      if (!success || !this.item) {
        return this.$store.dispatch("SET_ErrorPage", {
          code: 404,
          message: this.$translate("page_not_found"),
        });
      }
      this.name = this.item.name;
      this.role = this.item.role;
      this.phone = this.item.phone;
      this.email = this.item.email;
    },
  },
  async created() {
    if (this.editPage) await this.getItem();
  },
};
</script>
<style lang="scss" src="../../style.scss" scoped></style>
