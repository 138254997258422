<template>
  <router-link :to="{ name: 'notifications' }" class="NoticeIcon simpleLink">
    <NoticeSVG />
    <span class="circle" v-if="hasNew"></span>
  </router-link>
</template>

<script>
import NoticeSVG from "@/assets/img/notice.svg";
/**
 * The NoticeIcon component.
 */
export default {
  name: "NoticeIcon",
  components: { NoticeSVG },
  props: {
    hasNew: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.NoticeIcon {
  position: relative;
  display: inline-block;
  margin-right: toRem(41);
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  
  @media (max-width: $tablet) {
    margin-right: toRem(40);
  }
  &::after {
    content: "";
    position: absolute;
    top: toRem(-10);
    right: toRem(-10);
    left: toRem(-10);
    bottom: toRem(-10);
  }
  :deep(svg) {
    width: toRem(22);
    height: toRem(22);
    display: block;
  }
  .circle {
    position: absolute;
    width: toRem(8);
    height: toRem(8);
    border-radius: 50%;
    background: #e13131;
    top: toRem(-2);
    right: toRem(3);
  }
}
</style>