<template>
  <div ref="mapHost" id="app-map-view" class="map-view-wrapper"></div>
</template>

<script>
import * as L from "leaflet"
import "leaflet/dist/leaflet.css"

const MAP_CENTER = [48.379433, 31.16558]
const MAP_ZOOM = 6

/**
 * The MapView component.
 */
export default {
  name: "MapView",
  components: {
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    icons: {
      type: Object,
      default: () => {},
    },
    iconSize: {
      type: Object,
      default: () => {},
    }
  },
  data() {
    return {
      appMap: null,
      mapGeoJSON: null,
      mapIcons: {},
    };
  },
  computed: {
  },
  methods: {
    getAllMarkers() {
      const markers = []

      this.appMap.eachLayer((layer) => {
        if (layer instanceof L.Marker) {
          markers.push(layer)
        }
      })

      return markers
    },
    clearMarkers() {
      const markers = this.getAllMarkers()

      for (const marker of markers) {
        marker.remove()
      }
    },
    renderMarkers() {
      this.clearMarkers()

      if (Array.isArray(this.items) && this.items.length - 0) {
        const renderedStationIds = [];
        
        for (const item of this.items) {
          const { id, lat, lng, icon } = item;

          if (id && lat && lng && icon) {
            const existingRenderedMarkers = renderedStationIds.filter(renderedStationId => renderedStationId === id);
            
            renderedStationIds.push(id);

            if (existingRenderedMarkers.length > 0) {
              this.getAllMarkers()
                .find(marker => marker.options.stationId === id)
                ?.bindTooltip(`${existingRenderedMarkers.length + 1}`, {
                  permanent: true,
                  direction: 'right',
                  className: 'packages-map-marker-tooltip',
                  offset: [0, 0],
                });
              continue;
            }

            L.marker([lat, lng], { icon: this.mapIcons[icon], stationId: id })
              .addTo(this.appMap)
              .on('click', () => {
                this.$emit('markerClick', item);
              });
          }
        }
      }
    },
    $exposeResetGeoJSON() {
      this.mapGeoJSON.clearLayers();
    },
    $exposeUpdateGeoJsonData(data) {
      this.mapGeoJSON.addData(data);
    },
  },
  watch: {
    items() {
      this.renderMarkers();
    },
  },
  mounted() {
    this.appMap = L.map(this.$refs.mapHost).setView(MAP_CENTER, MAP_ZOOM);
    this.mapGeoJSON = L.geoJSON().addTo(this.appMap);

    const tilesAttribution = [
      'Maps © <a href="https://www.thunderforest.com">Thunderforest</a>',
      'Data © <a href="https://www.openstreetmap.org/copyright">OpenStreetMap contributors</a>',
    ].join(", ")

    L.tileLayer(`//tile.thunderforest.com/transport/{z}/{x}/{y}.png?apikey=${process.env.VUE_APP_MAP_TILE_SERVICE_API_KEY}`, {
      type: 'png',
      maxZoom: 19,
      scheme: 'xyz',
      subdomains: ['a.', 'b.', 'c.', 'd.'],
      attribution: tilesAttribution,
    }).addTo(this.appMap);

    this.appMap.on('click', (e) => {
      this.$emit('mapClick', e);
    });

    this.renderMarkers();
  },
  beforeUnmount() {
    this.appMap.remove();
  },
  created() {
    const defaultSize = this.iconSize?.default
      ? this.iconSize.default
      : [60, 60];

    for (let iconId in this.icons) {
      const iconUrl = this.icons[iconId];
      const iconSize = iconId in this.iconSize
        ? this.iconSize[iconId]
        : defaultSize;
        
      this.mapIcons[iconId] = L.icon({ iconUrl, iconSize });
    }
  }
};
</script>

<style lang="scss" scoped src="./component.scss"></style>
<style lang="scss">
.packages-map-marker-tooltip {
  background: white;
  padding: 0;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 100%;
  margin-top: -1.2rem;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;

  &::before {
    display: none;
  }
}
</style>