<template>
  <router-view v-if="isChildrenPage"></router-view>
  <LayoutDashboard name="company" v-else>
    <template v-if="loading && !company"> <LoadingIcon /></template>
    <template v-else>
      <DashboardTitleRow
        :title="company.name"
        :titleTag="'h4'"
        :titleTagClass="'h4'"
        :goBack="{ name: 'companies' }"
      />
      <PageTabs
        class="marg-big"
        v-if="pageTabsList && pageTabsList.length"
        :list="pageTabsList"
      />
      <DashboardCard class="p40 overflow w100per">
        <div class="formWrap marg--0">
          <LoadingIcon v-if="loading" :absolute="true" />
          <form
            novalidate
            @submit.prevent="submit"
            :class="{ loadingElem: loading }"
            :key="formKey"
          >
            <div
              class="row no-marg space--beetween space--56 inputRow"
              :class="{ w632: IS_Notebook }"
            >
              <div class="column inputColumn">
                <Label
                  :title="
                    $translate('name_counterparty_in_office', 'companies')
                  "
                  :placeholder="
                    $translate('name_counterparty_in_office', 'companies')
                  "
                  name="name_counterparty"
                  inputType="text"
                  @onInput="onNameCounterparty"
                  :error="nameCounterpartyError"
                  :errorText="nameCounterpartyErrorText"
                  ref="nameCounterparty"
                  :value="nameCounterparty"
                  :size="IS_Notebook ? 'm' : 'l'"
                />
              </div>
              <div class="column inputColumn optional-absolute-notice">
                <Label
                  :title="$translate('TIN_YEDRPOU_company', 'companies')"
                  :placeholder="$translate('TIN_YEDRPOU_company', 'companies')"
                  name="TIN_YEDRPOU_company"
                  inputType="text"
                  @onInput="onTIN_YEDRPOU"
                  :error="TIN_YEDRPOU_Error"
                  :errorText="TIN_YEDRPOU_ErrorText"
                  ref="TIN_YEDRPOU"
                  :value="TIN_YEDRPOU"
                  :size="IS_Notebook ? 'm' : 'l'"
                />
                <Notice>
                  <InfoIcon class="icon" />
                  <p v-html="$translate('openDataBot_info', 'companies')"></p>
                </Notice>
              </div>
            </div>
            <div
              class="row no-marg marg--bot--8 mobile--max-width--268 columnRow"
            >
              <div
                class="column info"
                v-if="company.legalName || company.owner || company.phone"
              >
                <span class="title">{{
                  $translate("registrationData", "general")
                }}</span>
                <ul>
                  <li v-if="company.legalName">
                    <span class="name">{{
                      $translate("legal_name_of_company", "companies")
                    }}</span>
                    <span>{{ company.legalName }}</span>
                  </li>
                  <li :class="{ w50: !IS_Notebook }" v-if="company.owner">
                    <span class="name">{{
                      $translate("name_of_director", "companies")
                    }}</span>
                    <span>{{ company.owner }}</span>
                  </li>
                  <li :class="{ w50: !IS_Notebook }" v-if="company.phone">
                    <span class="name">{{
                      $translate("phone", "general")
                    }}</span>
                    <span>{{ company.phone }}</span>
                  </li>
                </ul>
              </div>
              <div
                class="column info"
                v-if="company.region || company.city || company.address"
              >
                <span class="title">{{
                  $translate("legal_address", "companies")
                }}</span>
                <ul>
                  <li class="w50" v-if="company.region">
                    <span class="name">{{
                      $translate("region", "orders")
                    }}</span>
                    <span>{{ company.region }}</span>
                  </li>
                  <li class="w50" v-if="company.city">
                    <span class="name">{{
                      $translate("settlement", "orders")
                    }}</span>
                    <span>{{ company.city }}</span>
                  </li>
                  <li v-if="company.address">
                    <span class="name">{{
                      $translate("legal_address", "companies")
                    }}</span>
                    <span>{{ company.address }}</span>
                  </li>
                </ul>
              </div>
            </div>
            <MyButton
              type="outline-yellow"
              class="w288 text--size--18 marg-0 size_l"
              :text="$translate('request_to_change_details')"
              tag="button"
              tagType="submit"
              :dynamicSize="true"
            />
          </form>
        </div>
      </DashboardCard>
    </template>
  </LayoutDashboard>
</template>
<script>
import LayoutDashboard from "@/layouts/Dashboard.vue";
import DashboardTitleRow from "@/components/DashboardTitleRow";
import PageTabs from "@/components/PageTabs";
import DashboardCard from "@/components/DashboardCard";
import Notice from "@/components/Notice";
//svg
import InfoIcon from "@/assets/img/infoIcoWhite.svg";
//pageTabsList
import pageTabList from "./pageTabList.js";
import { mapGetters } from "vuex";
export default {
  name: "CompanyPage",
  data() {
    return {
      formKey: "registrationData",
      loading: false,
      pageTabsList: pageTabList(this),
      //inputs
      //nameCounterparty
      nameCounterparty: null,
      nameCounterpartyError: false,
      nameCounterpartyErrorText: "",
      //TIN_YEDRPOU_company
      TIN_YEDRPOU: null,
      TIN_YEDRPOU_Error: false,
      TIN_YEDRPOU_ErrorText: "",
      id: this.$route.params.id,
    };
  },
  components: {
    LayoutDashboard,
    DashboardTitleRow,
    PageTabs,
    DashboardCard,
    Notice,
    InfoIcon,
  },
  computed: {
    ...mapGetters(["IS_Notebook", "GET_Companies"]),
    isChildrenPage() {
      return this.$route.name !== "company";
    },
    company() {
      return this.GET_Companies[this.$route.params.id];
    },
  },
  methods: {
    onNameCounterparty(value = this.nameCounterparty, e, required = false) {
      this.nameCounterparty = value;
      const NameCounterparty = this.$simpleInputValidate(
        value,
        required,
        "name"
      );
      this.nameCounterpartyError = NameCounterparty.error;
      if (NameCounterparty.error) {
        this.nameCounterpartyErrorText = NameCounterparty.text;
        this.$refs.nameCounterparty.$refs.input.focus();
      }
      return NameCounterparty.error;
    },
    onTIN_YEDRPOU(value = this.TIN_YEDRPOU, e, required = false) {
      this.TIN_YEDRPOU = value;
      const TIN_YEDRPOU = this.$simpleInputValidate(
        value,
        required,
        "TIN_YEDRPOU"
      );
      this.TIN_YEDRPOU_Error = TIN_YEDRPOU.error;
      if (TIN_YEDRPOU.error) {
        this.TIN_YEDRPOU_ErrorText = TIN_YEDRPOU.text;
        this.$refs.TIN_YEDRPOU.$refs.input.focus();
      }
      return TIN_YEDRPOU.error;
    },
    async submit() {
      if (this.loading) return false;

      const error = (() => {
        const errorNameCounterparty = this.onNameCounterparty(
          undefined,
          undefined,
          true
        );
        const errorTIN_YEDRPOU = this.onTIN_YEDRPOU(undefined, undefined, true);
        return errorNameCounterparty || errorTIN_YEDRPOU;
      })();
      if (!error) {
        this.loading = true;

        const { success } = await this.$api.updateCompany(this.company.id, {
          edrpou: this.TIN_YEDRPOU,
          name: this.nameCounterparty,
        });
        this.loading = false;
        if (success) this.$router.push({ name: "companies" });
      }
    },
    async getCompany() {
      this.loading = true;

      let { data, success = false } = await this.$api.company.getCompany(
        this.id
      );
      this.loading = false;
      if (!success || !data) {
        return this.$store.dispatch("SET_ErrorPage", {
          code: 404,
          message: this.$translate("page_not_found"),
        });
      }
      this.$store.dispatch("SET_Company", { id: this.id, data });
      this.nameCounterparty = data.name;
      this.TIN_YEDRPOU = data.edrpou;
    },
  },
  async created() {
    if (!this.company) await this.getCompany();
  },
  beforeUnmount() {
    this.$store.dispatch("SET_Company", {
      id: this.id,
      data: null,
    });
  },
};
</script>
<style lang="scss" src="./style.scss" scoped></style>
