<template>
  <div class="FaqListItem">
    <div class="topRow" @click="click()">
      <span class="title" v-html="highlightedTextTitle"></span>
      <div class="icon" :class="{ iconOpen: open }">
        <ArrowBottomSVG />
      </div>
    </div>
    <div class="content" ref="content">
      <Markdown class="text" :source="highlightedText" :html="true" />
    </div>
  </div>
</template>

<script>
import ArrowBottomSVG from "@/assets/img/arrow_bottom.svg";
import Markdown from "vue3-markdown-it";
/**
 * The FaqListItem component.
 */
export default {
  name: "FaqListItem",
  components: { ArrowBottomSVG, Markdown },
  props: {
    title: { default: null, type: String },
    text: { default: null, type: String },
    /**
     * search query.
     */
    search: { default: null, type: String },
  },
  data() {
    return {
      disabled: false,
      open: false,
    };
  },
  computed: {
    /**
     * The text with highlighted search query.
     */
    highlightedTextTitle() {
      if (!this.search) return this.title;
      const query = this.search.toLowerCase();
      const text = this.title.toLowerCase();
      const index = text.indexOf(query);
      if (index === -1) return this.title;
      const start = index;
      const end = index + query.length;
      return `${this.title.slice(
        0,
        start
      )}<span class="highlight">${this.title.slice(
        start,
        end
      )}</span>${this.title.slice(end)}`;
    },
    /**
     * The text with highlighted search query.
     */
    highlightedText() {
      if (!this.search) return this.text;
      const query = this.search.toLowerCase();
      const text = this.text.toLowerCase();
      const index = text.indexOf(query);
      if (index === -1) return this.text;
      const start = index;
      const end = index + query.length;
      return `${this.text.slice(
        0,
        start
      )}<span class="highlight">${this.text.slice(
        start,
        end
      )}</span>${this.text.slice(end)}`;
    },
  },
  methods: {
    click(time = 0.25) {
      if (this.disabled) return;
      this.disabled = true;
      this.open = !this.open;
      this.$slideAccordion(
        this.$refs.content,
        this.open,
        () => {
          this.disabled = false;
        },
        time
      );
    },
    hasSearchInTitle() {
      if (!this.search) return false;
      const query = this.search.toLowerCase();
      const text = this.title.toLowerCase();
      return text.indexOf(query) !== -1;
    },
    hasSearchInText() {
      if (!this.search) return false;
      const query = this.search.toLowerCase();
      const text = this.text.toLowerCase();
      return text.indexOf(query) !== -1;
    },
    watchSearch() {
      if (this.open) {
        if (
          // close if search is not in title and text or not in text
          (this.hasSearchInTitle() && !this.hasSearchInText()) ||
          (!this.hasSearchInTitle() && !this.hasSearchInText())
        ) {
          this.click(0);
        }
      } else {
        // open if search is in text only
        if (!this.hasSearchInTitle() && this.hasSearchInText()) {
          this.click(0);
        }
      }
    },
  },
  mounted() {
    this.watchSearch();
  },
  watch: {
    search() {
      this.watchSearch();
    },
  },
};
</script>

<style lang="scss" scoped src="./component.scss"></style>