<template>
  <header :class="{ logined: $auth.loggedIn }" :style="headerStyles">
    <!-- <span class="infoText" v-if="infoText">{{ infoText }}</span> -->
    <div class="row margin0 between alignCenter" ref="content">
      <router-link
        :to="{ name: $auth.loggedIn ? 'dashboard' : 'home' }"
        class="LogoLink"
      >
        <Logo />
      </router-link>
      <template v-if="!IS_Tablet2">
        <div class="rightContent row margin0 alignCenter" v-if="$auth.loggedIn">
          <MyButton
            :text="$translate('make_an_order')"
            :link="{ name: 'order' }"
            tag="router-link"
            :dynamicSize="true"
            class="p42"
          />
          <!-- <UkraineFlag class="UkraineFlag" /> -->
          <NoticeIcon @click="handleNoticeClick" :hasNew="GET_HasUnread" />
          <UserInfo />
        </div>
        <div class="loginButtonsWrap row margin0 alignCenter" v-else>
          <MyButton
            type="outline-green"
            :text="$translate('registration')"
            :link="{ name: 'registration' }"
            tag="router-link"
            :dynamicSize="true"
          />
          <MyButton
            :text="$translate('login')"
            :link="{ name: 'login' }"
            tag="router-link"
            :dynamicSize="true"
          />
        </div>
      </template>
      <template v-if="IS_Tablet2">
        <button
          type="button"
          @click="menuOpened = !menuOpened"
          class="mobile-menu-btn">
          <MenuSVG v-show="!menuOpened" class="MenuSVG" />
          <CloseSVG v-show="menuOpened" class="MenuSVG" />
        </button>
        <MobileMenu v-if="menuOpened" class="mobile-menu" ref="mobileMenu" />
      </template>
    </div>
  </header>
</template>

<script>
import Logo from "@/components/Logo";
import MobileMenu from "@/components/MobileMenu";
import MenuSVG from "@/assets/img/menu.svg";
import CloseSVG from "@/assets/img/close.svg";
import NoticeIcon from "./NoticeIcon.vue";
import UserInfo from "./UserInfo.vue";
// import UkraineFlag from "@/assets/img/ukraine_flag.svg";
import Scrollbar from "smooth-scrollbar";
import { mapGetters } from "vuex";
/**
 * The Header component.
 */
export default {
  name: "Header-Component",
  components: {
    CloseSVG,
    Logo,
    MenuSVG,
    NoticeIcon,
    MobileMenu,
    // UkraineFlag,
    UserInfo,
  },

  data() {
    return {
      menuOpened: false,
      scrollbar: null,
      headerHeight: 0,
      handleResize: () => {
        this.setHeaderHeight();
      },
    };
  },

  computed: {
    ...mapGetters(["IS_Tablet2", "GET_CostCalculation", "GET_HasUnread"]),
    headerStyles() {
      return {
        "--header-height": `${this.headerHeight}px`,
      };
    },
    // infoText() {
    //   return this.GET_CostCalculation;
    // },
  },

  methods: {
    handleNoticeClick() {
      this.$store.dispatch("SET_MarkAsRead");
    },
    initScroll() {
      this.scrollbar = Scrollbar.init(this.$refs.mobileMenu, {
        alwaysShowTracks: true,
        continuousScrolling: false,
      });
      this.scrollbar.track.xAxis.hide();
    },
    setHeaderHeight() {
      if (this.IS_Tablet2) {
        const { height } = this.$el.getBoundingClientRect();
        this.headerHeight = height;
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.setHeaderHeight();
    });
  },
  create() {
    window.addEventListener("resize", this.handleResize, { passive: true });
  },
  beforeUnmount() {
    if (this.scrollbar) this.scrollbar.destroy();

    window.removeEventListener("resize", this.handleResize, { passive: true });
  },
};
</script>

<style lang="scss" scoped src="./component.scss"></style>
