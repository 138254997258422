<template>
  <LayoutDashboard name="faq">
    <div class="row margin0 nowrap wrapTablet wrapTablet">
      <div class="column columnLeft">
        <h1 v-html="$translate('question_and_answers', 'faq')"></h1>
        <p v-html="$translate('question_and_answers_text', 'faq')"></p>
        <Label
          class="faqSearch"
          name="search"
          :placeholder="$translate('search')"
          inputType="search"
          @onInput="onInputSearch"
          ref="search"
          size="m"
        />
        <div class="notFondWhatLooking">
          <p>{{ $translate("didn_t_find_what_looking", "faq") }}</p>
          <MyButton
            class="w240"
            :text="$translate('ask_a_question', 'documents')"
            :dynamicSize="true"
            @click="$popup.show('AskQuestion')"
          />
        </div>
      </div>
      <div class="column columnRight">
        <h4>{{ $translate("list_of_questions", "faq") }}</h4>
        <div class="faqList">
          <LoadingIcon v-if="loading" />
          <template v-else>
            <template v-if="filteredList.length">
              <FaqListItem
                v-for="item in filteredList"
                :key="item.id"
                :title="item.question"
                :text="item.answer"
                :search="search"
              />
            </template>
            <template v-else>
              <p class="no_questions">
                {{
                  $translate(
                    this.search ? "no_questions_change_query" : "no_questions",
                    "faq"
                  )
                }}
              </p>
            </template>
          </template>
        </div>
      </div>
    </div>
  </LayoutDashboard>
</template>

<script>
import LayoutDashboard from "@/layouts/Dashboard.vue";
import FaqListItem from "@/components/FaqListItem";
/**
 * The faq-page component.
 */
export default {
  name: "FAQPage",
  components: { LayoutDashboard, FaqListItem },
  data() {
    return {
      loading: true,
      list: [],
      search: "",
    };
  },
  computed: {
    filteredList() {
      return this.list.filter(({ question, answer }) => {
        const query = this.search.toLowerCase();
        return (
          question.toLowerCase().includes(query) ||
          answer.toLowerCase().includes(query)
        );
      });
    },
  },
  methods: {
    async getFaqList() {
      this.loading = true;
      const { list } = await this.$api.faq.getList();
      this.list = list;
      this.loading = false;
    },
    onInputSearch(val) {
      this.search = val;
      console.log("onInputSearch", val);
    },
  },
  created() {
    this.getFaqList();
  },
};
</script>

<style lang="scss" scoped src="./component.scss"></style>
