<template>
  <div class="popup_wrap">
    <div class="popup_bg" @click="$popup.hide()"></div>
    <AddVehicle />
    <AskDocumentQuestion />
    <AskQuestion />
    <AskQuestionProfile />
    <ConfigureMarketingProduct />
    <DeleteCar />
    <DeliverySchedule />
    <OrderCancellationRequest />
    <RemoveCompanyItem />
    <SectionIntro />
    <TICancellation />
    <TIDuplication />
    <TIEdit />
    <TIUploadAttachment />
    <OrderRecommendedVolume />
    <MarketingProductOffer />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AddVehicle from './ModalsList/AddVehicle';
import AskDocumentQuestion from './ModalsList/AskDocumentQuestion';
import AskQuestion from './ModalsList/AskQuestion';
import AskQuestionProfile from './ModalsList/AskQuestionProfile';
import ConfigureMarketingProduct from './ModalsList/ConfigureMarketingProduct';
import DeleteCar from './ModalsList/DeleteCar';
import DeliverySchedule from './ModalsList/DeliverySchedule';
import OrderCancellationRequest from './ModalsList/OrderCancellationRequest';
import OrderRecommendedVolume from './ModalsList/OrderRecommendedVolume';
import RemoveCompanyItem from './ModalsList/RemoveCompanyItem';
import SectionIntro from './ModalsList/SectionIntro';
import TICancellation from './ModalsList/TICancellation';
import TIDuplication from './ModalsList/TIDuplication';
import TIEdit from './ModalsList/TIEdit';
import TIUploadAttachment from './ModalsList/TIUploadAttachment';
import MarketingProductOffer from './ModalsList/MarketingProductOffer';

/**
 * The modals component.
 */
export default {
  name: 'ModalsComponent',
  components: {
    AddVehicle,
    AskDocumentQuestion,
    AskQuestion,
    AskQuestionProfile,
    ConfigureMarketingProduct,
    DeleteCar,
    DeliverySchedule,
    OrderCancellationRequest,
    OrderRecommendedVolume,
    RemoveCompanyItem,
    SectionIntro,
    TICancellation,
    TIDuplication,
    TIEdit,
    TIUploadAttachment,
    MarketingProductOffer,
  },
  computed: {
    ...mapGetters(['GET_CurrentPopup']),
  },
};
</script>

<style lang="scss" src="./component.scss"></style>
