<template>
  <PageTable :loading="loading">
    <template v-slot:thead>
      <th>
        <!-- <span class="dateControll">
          {{ $translate("date", "documents") }}
          <ArrowSVG />
        </span> -->
        {{ $translate("date", "documents") }}
      </th>
      <th>
        {{ $translate("document", "documents") }}
      </th>
      <th>
        {{ $translate("document_type", "documents") }}
      </th>
      <th>
        {{ $translate("notes") }}
      </th>
      <th></th>
    </template>
    <template v-slot:default>
      <RowItem v-for="item in list" :key="item.id" :row="item" />
    </template>
  </PageTable>
</template>

<script>
import RowItem from "./Item";
import PageTable from "../PageTable";
// import ArrowSVG from "@/assets/img/arrow_top.svg";
/**
 * The RelatedDocuments component.
 */
export default {
  name: "RelatedDocuments",
  components: {
    RowItem,
    // ArrowSVG,
    PageTable,
  },
  props: {
    list: { default: () => [], type: Array },
    loading: { default: false, type: Boolean },
  },
};
</script>

<style lang="scss" scoped src="./component.scss"></style>
