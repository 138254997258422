<template>
  <div class="PageFilterWrap">
    <div
      class="PageFilter"
      v-if="!IS_Tablet2"
      :class="{ disabledElem: loading }"
    >
      <div class="row margin0 between alignStart w100 nowrap">
        <div class="column columnLeft">
          <template v-if="currentTagsList && currentTagsList.length">
            <span class="name">{{ $translate("filter", "documents") }}:</span>
            <div class="wrap">
              <Tag
                v-for="tag in currentTagsList"
                :key="tag.id"
                :id="tag.id"
                :type="tag.type"
                :text="tag.text"
                @click="$emit('remove', tag.id)"
              />
            </div>
          </template>
        </div>
        <div class="column columnRight">
          <MyButton
            class="width-auto fz16"
            type="outline-green"
            icon="edit"
            :text="$translate('edit_filter')"
            size="m"
            :dynamicSize="true"
            @click="$PageFilter.show()"
          />
          <MyButton
            class="width-auto fz16"
            type="outline-red"
            :text="$translate('clear')"
            size="m"
            :dynamicSize="true"
            @click="$emit('reset')"
            v-if="currentTagsList && currentTagsList.length"
          />
        </div>
      </div>
    </div>

    <div class="PageFilterPopupWrap">
      <div
        class="PageFilterBG"
        id="PageFilterBG"
        @click="$PageFilter.hide()"
      ></div>
      <div class="PageFilterPopup" id="PageFilterPopup">
        <div class="topContent">
          <h5>{{ $translate("filter") }}</h5>
          <div class="close" @click.stop="$PageFilter.hide()">
            <CloseSVG />
          </div>
        </div>
        <div class="mainContent" ref="filtersContent">
          <slot></slot>
        </div>
        <div class="controllRow row margin0 between nowrap">
          <MyButton
            :text="$translate('apply_filter')"
            :dynamicSize="true"
            class="submit"
            @click="submit"
          />
          <MyButton
            :text="$translate('clear')"
            type="outline-red"
            :dynamicSize="true"
            class="reset"
            @click="$emit('reset')"
          />
        </div>
      </div>
    </div>
    <MyButton
      type="circle"
      icon="filter"
      class="filterButton"
      @click="$PageFilter.show()"
      v-if="IS_Tablet2"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Scrollbar from "smooth-scrollbar";
import Tag from "./Tag";
import CloseSVG from "@/assets/img/close.svg";
/**
 * The PageFilter component.
 */
export default {
  name: "PageFilter",
  components: { Tag, CloseSVG },
  props: {
    /**
     * List of tags.
     */
    list: { default: null, type: Array },
    /**
     * Loading state.
     */
    loading: { default: false, type: Boolean },
  },
  data() {
    return {
      scrollbar: null,
    };
  },
  computed: {
    ...mapGetters(["IS_Tablet2", "IS_Mobile"]),
    currentTagsList() {
      return this.list || [];
    },
  },
  methods: {
    // ...mapActions(['GET_PaymentInfo']),
    initScroll() {
      this.scrollbar = Scrollbar.init(this.$refs.filtersContent, {
        alwaysShowTracks: true,
        continuousScrolling: false,
      });
      this.scrollbar.track.xAxis.hide();
    },
    submit() {
      this.$emit("submit");
      this.$PageFilter.hide();
    },
  },
  mounted() {
    this.initScroll();
    // this.$PageFilter.show();
  },
  beforeUnmount() {
    if (this.scrollbar) this.scrollbar.destroy();
  },
};
</script>

<style lang="scss" scoped src="./component.scss"></style>
