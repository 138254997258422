<template>
  <Layout id="MarketingProductOffer" :data-id="popupData?.id">
    <h4>
      {{ $translate("product_settings", "orders") }}:
      <span class="product">{{ marketingProduct?.marketingProduct?.name }}</span>
    </h4>
    <div class="formWrap">
      <LoadingIcon v-if="loading" :absolute="true" />
      <form
        novalidate
        @submit.prevent="submit"
        :key="formKey"
      >
        <h5 class="maxi-discount-label">
          {{ $translate('product-maxi-discount-label', 'orders') }}
        </h5>
        <p class="maxi-discount-new-price">
          <span>{{ $price(maxiDiscountNewPrice, undefined, true) }}</span>&nbsp;
          <span class="maxi-discount-new-price-percentage">
            {{ maxiDiscount }}%
          </span>
        </p>
        <MyButton
          class="w100"
          :text="submitButtonLabel"
          tag="button"
          tagType="submit"
        />
      </form>
      <MyButton
        :text="$translate('go_back')"
        tag="button"
        type="cancel w100"
        @click="closeModal"
        :disabled="loading"
      />
    </div>
  </Layout>
</template>

<script>
import { mapGetters } from "vuex";
import Layout from "../../PopupLayout";
/**
 * The MarketingProductOffer component.
 */
export default {
  name: "MarketingProductOffer",
  components: {
    Layout,
  },
  data() {
    return {
      formKey: "faqForm_0",
      loading: false,
    };
  },
  computed: {
    ...mapGetters("checkout", ["GET_Checkout"]),
    ...mapGetters(["GET_PopupData"]),
    popupData() {
      return this.GET_PopupData["MarketingProductOffer"];
    },
    marketingProduct() {
      return this.popupData?.marketingProduct;
    },
    submitButtonLabel() {
      return this.$translate("product-offer-apply", "orders");
    },
    maxiDiscountNewPrice() {
      return this.marketingProduct?.actionMetadata?.newPrice;
    },
    maxiDiscount() {
      return (this.marketingProduct?.actionMetadata?.changePercent || 0) * 100;
    }
  },
  methods: {
    async submit() {
      this.emitter.emit("MarketingProductOffer", this.marketingProduct);
    },
    closeModal() {
      this.$popup.hide("MarketingProductOffer");
    },
  },
  created() {
  },
  mounted() {
  },
  beforeUnmount() {
  },
};
</script>
<style lang="scss" src="./component.scss"></style>
