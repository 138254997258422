<template>
  <MetaTags>
    <title>{{ title }}</title>
  </MetaTags>
  <div class="home-page" v-if="false">
    <div class="wrap">
      <h2>Ostchem | home page</h2>
      <div class="container container_without_padding">
        <div class="loginButtonsWrap row margin0 alignCenter">
          <MyButton
            type="outline-green"
            :text="$translate('registration')"
            :link="{ name: 'registration' }"
            tag="router-link"
            :dynamicSize="true"
          />
          <MyButton
            :text="$translate('login')"
            :link="{ name: 'login' }"
            tag="router-link"
            :dynamicSize="true"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * The home-page component.
 */
export default {
  name: "home-page",
  components: {},
  data() {
    return {
      title: "Ostchem | Home page",
    };
  },
  created() {
    if (localStorage.getItem("api_logined") == "true") {
      return this.$router.push({ name: "dashboard" });
    } else {
      return this.$router.push({ name: "login" });
    }
  },
};
</script>
<style lang="scss" scoped>
.home-page {
  padding-top: toRem(100);
  padding-bottom: toRem(100);
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  min-height: var(--windowHeight);
}
.loginButtonsWrap {
  width: toRem(380);
  max-width: 100%;
  margin: auto;
  .Button {
    width: 100%;
    margin-bottom: toRem(10);
  }
}
</style>
