import { io } from 'socket.io-client';

const CLIENT_EVENTS = {
  REQUEST_NEW: 'request:new',
  REQUEST_UPDATE: 'request:update',
}

export default function () {
  /**
   * @type {null|import('socket.io-client').Socket<DefaultEventsMap, DefaultEventsMap>} */
  let socket = null

  const _io = {
    init() {
      socket = io(process.env.VUE_APP_API_DOMAIN, {
        auth: {
          token: null
        },
        autoConnect: false,
      });
    },

    connect({ authToken }) {
      if (_io.isConnected()) {
        socket.once('disconnect', () => _io.connect({ authToken }));
        socket.disconnect();
        return;
      }
      
      return new Promise((resolve, reject) => {
        socket.once(
          'connect',
          () => resolve({ success: true })
        );
        socket.once(
          'connect_error',
          (err) => reject({ success: false, data: err })
        );
        socket.auth.token = authToken;
        socket.connect();
      })
    },

    disconnect() {
      socket.auth.token = null;
      socket.disconnect();
    },

    isConnected() {
      return socket.connected;
    },

    emit(event, data, ack) {
      socket.emit(event, data, ack);
    },

    on(event, callback) {
      socket.on(event, callback);
    },

    onAny(callback) {
      socket.onAny(callback);
    },

    off(event, callback) {
      socket.off(event, callback);
    },
    
    getEvents() {
      return CLIENT_EVENTS
    }
  }

  return _io
}