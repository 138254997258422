<template>
  <LayoutDashboard name="add-company">
    <DashboardTitleRow
      :title="
        $translate(
          editPage ? 'edit_bank_details' : 'creation_bank_details',
          'companies'
        )
      "
      :titleTag="'h1'"
      :titleTagClass="'h1'"
      :goBack="{
        name: 'company-bills',
        params: { id: $route.params.id },
        query: { page: 1, sort: 'name=asc' },
      }"
    />
    <template v-if="editPage && !item">
      <LoadingIcon />
    </template>
    <DashboardCard class="p40 overflow w100per company-add" v-else>
      <div class="formWrap marg--0">
        <LoadingIcon v-if="loading" :absolute="true" />
        <form
          novalidate
          @submit.prevent="submit"
          :class="{ loadingElem: loading }"
          :key="formKey"
        >
          <div class="row margin47 topRow">
            <Label
              :title="$translate('account_name')"
              :placeholder="$translate('account_name')"
              name="accountName"
              inputType="text"
              @onInput="onInputField"
              :error="inputError['accountName']"
              ref="accountName"
              class="w50 mobilew100"
              :value="accountName"
            />
            <Label
              :title="$translate('name_of_bank')"
              :placeholder="$translate('name_of_bank')"
              name="bankName"
              inputType="text"
              @onInput="onInputField"
              :error="inputError['bankName']"
              ref="bankName"
              class="w50 mobilew100"
              :value="bankName"
            />
            <Label
              :title="$translate('MFO_Bank')"
              :placeholder="$translate('MFO_Bank')"
              name="bankCode"
              inputType="text"
              @onInput="onInputField"
              :error="inputError['bankCode']"
              ref="bankCode"
              class="w50 mobilew100"
              mask="######"
              :value="bankCode"
            />
            <Label
              :title="$translate('IBAN_account')"
              :placeholder="$translate('IBAN_account')"
              name="iban"
              inputType="text"
              @onInput="onInputField"
              :error="inputError['iban']"
              ref="iban"
              class="w50 mobilew100"
              :value="iban"
            />
          </div>
          <div class="row margin0 between bottomRow">
            <MyButton
              :text="$translate('cancel')"
              tag="button"
              tagType="reset"
              type="cancel"
              :dynamicSize="true"
              @click="resetForm"
            />
            <MyButton
              class="w288"
              :text="
                $translate(
                  editPage ? 'refresh' : 'create',
                  editPage ? 'orders' : 'companies'
                )
              "
              tag="button"
              tagType="submit"
              :dynamicSize="true"
            />
          </div>
        </form>
      </div>
    </DashboardCard>
  </LayoutDashboard>
</template>
<script>
import LayoutDashboard from "@/layouts/Dashboard.vue";
import DashboardTitleRow from "@/components/DashboardTitleRow";
import DashboardCard from "@/components/DashboardCard";

export default {
  name: "AddCompanyBills",
  components: {
    LayoutDashboard,
    DashboardTitleRow,
    DashboardCard,
  },
  data() {
    return {
      formKey: "form_",
      id: this.$route.params.id,
      loading: false,
      inputError: {},
      accountName: "",
      bankName: "",
      bankCode: "",
      iban: "",
      editPage: this.$route.name == "edit-company-bills",
      item: null,
    };
  },
  computed: {},
  methods: {
    onInputField(value, $event, name, required = false, validateType = "name") {
      if (!name) throw new Error("onInputField: name is undefined");
      if (value != undefined) this[name] = value;
      const validateError = this.$simpleInputValidate(
        value || this[name],
        required,
        validateType
      );
      this.inputError[name] = validateError;
      if (validateError.error) {
        this.$refs[name].$refs.input.focus();
      }
      return validateError.error;
    },
    async submit() {
      if (this.loading) return false;

      const error = (() => {
        const accountNameError = this.onInputField(
          undefined,
          undefined,
          "accountName",
          true
        );
        const bankNameError = this.onInputField(
          undefined,
          undefined,
          "bankName",
          true
        );
        const bankCodeError = this.onInputField(
          undefined,
          undefined,
          "bankCode",
          true,
          "MFO"
        );
        const IBANError = this.onInputField(
          undefined,
          undefined,
          "iban",
          true,
          "IBAN"
        );
        return accountNameError || bankNameError || bankCodeError || IBANError;
      })();
      if (!error) {
        this.loading = true;

        let { success } = await this.$api.company[
          this.editPage ? "editCompanyBills" : "addCompanyBills"
        ](
          {
            name: this.accountName,
            bankCode: this.bankCode,
            bankName: this.bankName,
            iban: this.iban,
            company: this.id,
          },
          this.item?.id
        );
        this.loading = false;
        if (success) {
          this.$router.push({
            name: "company-bills",
            params: { id: this.id },
          });
          setTimeout(() => {
            this.$notice(
              this.$translate(
                this.editPage ? "bank_details_updated" : "bank_details_created",
                "companies"
              )
            );
          }, 500);
        } else {
          this.$notice(this.$translate("request_error"), "error");
        }
      } else console.log("submit", { error });
    },
    resetForm() {
      this.loading = false;
      this.accountName = this.editPage ? this.item.name : "";
      this.bankName = this.editPage ? this.item.bankName : "";
      this.bankCode = this.editPage ? this.item.bankCode : "";
      this.iban = this.editPage ? this.item.iban : "";
      this.inputError = {};
      this.formKey = "form_" + this.$rnd(0, 100);
    },
    async getItem() {
      const { list, success } = await this.$api.company.getRequisites({
        filters: {
          company: this.$route.params.id,
          id: this.$route.params.itemId,
        },
      });
      this.item = list[0];
      if (!success || !this.item) {
        return this.$store.dispatch("SET_ErrorPage", {
          code: 404,
          message: this.$translate("page_not_found"),
        });
      }
      this.accountName = this.item.name;
      this.bankName = this.item.bankName;
      this.bankCode = this.item.bankCode;
      this.iban = this.item.iban;
    },
  },
  async created() {
    if (this.editPage) await this.getItem();
  },
};
</script>
<style lang="scss" src="../../style.scss" scoped></style>
