<template>
  <div class="UserInfo noBalance">
    <div class="row margin0 alignCenter">
      <!-- <div class="row wrap margin0 alignEnd"> -->
      <!-- <div class="yourBalance">
          <InfoIcon /> {{ $translate("your_balance") }}:
        </div> -->
      <!-- <span class="balance"> -->
      <!-- {{ $auth.user.balance }} грн. -->
      <!-- 25 566,46 грн.</span -->
      <!-- > -->
      <!-- </div> -->
      <router-link :to="{ name: 'profile' }" class="icon simpleLink">
        <span class="avatar-initials">{{ initials }}</span>
      </router-link>
    </div>
  </div>
</template>

<script>
// import InfoIcon from "@/assets/img/info.svg";
/**
 * The UserInfo component.
 */
export default {
  name: "UserInfo",
  components: {
    // InfoIcon
  },
  data() {
    return {};
  },
  computed: {
    initials() {
      return this.$auth.user.fullName
        .split(" ")
        .map((item) => item[0])
        .join("")
        .toLocaleUpperCase();
    },
  },
  methods: {
    // ...mapActions(['GET_PaymentInfo']),
  },
};
</script>

<style lang="scss" scoped>
.UserInfo {
  position: relative;
  top: toRem(8);
  @media (max-width: $notebook) {
    top: toRem(1);
  }
}

.yourBalance {
  display: flex;
  align-items: center;
  font-weight: 450;
  font-size: toRem(14);
  line-height: toRem(18);
  color: #767676;
  svg {
    width: toRem(14);
    height: toRem(14);
    margin-right: toRem(5);
  }
}
.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: toRem(11);
  border-radius: 50%;
  overflow: hidden;
  width: toRem(44);
  height: toRem(44);
  position: relative;
  top: toRem(-3);
  background-color: $gray_100;
  &:first-child {
    margin-left: 0;
  }
  img {
    display: block;
    width: 100%;
    height: 100%;
  }
}
.avatar-initials {
  font-weight: bold;
  font-size: 1.2rem;
  line-height: 1;
  margin-block-start: 4px;
}
.wrap {
  flex-direction: column;
  position: relative;
  top: toRem(-4);
}
.balance {
  font-weight: 500;
  display: block;
  font-size: toRem(24);
  line-height: toRem(31);
  margin-top: toRem(-3);
}

.UserInfo.noBalance {
  top: 0;
  .icon {
    top: toRem(1);
  }
}
</style>
