<template>
  <router-view v-if="isChildrenPage"></router-view>
  <LayoutDashboard name="my-order" v-else>
    <DashboardTitleRow
      v-if="gtitle"
      class="my-order"
      :title="gtitle"
      :titleTag="'h4'"
      :titleTagClass="'h4'"
      :goBack="{ name: 'orders' }"
    >
      <template v-slot:title>
        <div class="top-info">
          <Transition mode="out-in">
            <div v-if="!loading">
              <Tag v-if="gstatus" class="w100 small" :text="gstatus" />
              <span v-if="amount" class="total-sum"
                >{{ $translate("sum", "general") }}:
                <b>{{ $price(amount, undefined, true) }}</b></span
              >
            </div>
          </Transition>
        </div>
      </template>
    </DashboardTitleRow>
    <PageTabs
      class="marg-big"
      v-if="pageTabsList && pageTabsList.length"
      :list="pageTabsList"
    />
    <Transition mode="out-in">
      <LoadingIcon v-if="loading" :absolute="true" />
      <div
        class="myOrder-field"
        v-else-if="!loading && !orderDataEmpty && orderData"
      >
        <div class="main">
          <OrderDetails :data="orderData" />
        </div>
        <div class="other">
          <PaymentData
            v-if="orderData.paymentData.suAmountVat !== 0"
            :data="gpaymentData"
            :id="orderData.id"
            :numOrder="orderData.number"
          />
          <TransportInstructions :data="gtransportInstructions" />
        </div>
      </div>
      <div v-else class="empty-data">
        {{ $translate("data_not_found", "general") }}
      </div>
    </Transition>
  </LayoutDashboard>
</template>
<script>
import LayoutDashboard from "@/layouts/Dashboard.vue";
import DashboardTitleRow from "@/components/DashboardTitleRow";
import PageTabs from "@/components/PageTabs";
import Tag from "@/components/Table/PageTable/Tag";
import OrderDetails from "@/components/OrderComponents/Details";
import PaymentData from "@/components/OrderComponents/PaymentData";
import TransportInstructions from "@/components/OrderComponents/TransportInstructions";
//pageTabsList
import pageTabList from "./pageTabList.js";
import merge from 'lodash/merge';

export default {
  name: "MyOrder",
  components: {
    LayoutDashboard,
    DashboardTitleRow,
    PageTabs,
    Tag,
    OrderDetails,
    PaymentData,
    TransportInstructions,
  },
  data() {
    return {
      pageTabsList: pageTabList(this, "my-order"),
      loading: false,
      orderData: null,
      orderDataEmpty: false,
    };
  },
  computed: {
    isChildrenPage() {
      return this.$route.name !== "my-order";
    },
    gtitle() {
      const num = this.orderData?.number || this.$route?.params?.id;
      if (!num) return null;
      return `${this.$translate("order", "orders")} ${num}`;
    },
    gstatus() {
      return this.orderData?.status?.name;
    },
    order_status_name() {
      return this.orderData?.status?.name;
    },
    amount() {
      return this.orderData?.amount;
    },
    gpaymentData() {
      return this.orderData?.paymentData;
    },
    gtransportInstructions() {
      return {
        transportInstructions: this.orderData?.transportInstructions,
        shipmentProgress: this.orderData?.shipmentProgress,
        quotas: this.orderData?.quotas,
        actions: this.orderData?.actions,
      };
    },
  },
  methods: {
    getUrlId() {
      let { id = "" } = this.$route.params;
      return id;
    },
    async getOrder() {
      try {
        this.loading = true;
        let filters = {
          number: this.getUrlId(),
        };
        let { list = [], success = false } = await this.$api.getOrders({
          filters,
        });
        if (success && list.length) {
          let { id = null } = list.find(({ number }) => number);
          if (!id) throw new Error("Order id not found");
          let { data = {}, success: done = false } =
            await this.$api.checkout.getOrder(id);
          if (done && data) {
            this.orderData = data;
            this.orderDataEmpty = false;
          } else {
            this.orderDataEmpty = true;
          }
        } else {
          this.orderDataEmpty = true;
        }
        await Promise.all(
          this.orderData.marketingProducts.map(async (item, index) => {
            try {
              const { data } = await this.$api.product.getMarketingProductById(
                item.marketingProduct.id,
                {
                  fields: ['name'],
                  populate: {
                    icon: {
                      fields: 'formats'
                    }
                  }
                }
              );
              this.orderData.marketingProducts[index].marketingProduct = merge(item.marketingProduct, data);
            } catch (error) {
              console.error(error);
            }
          })
        );
        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    async handleRecalculateOrderPrice() {
      try {
        this.loading = true;
        await this.$api.checkout.recalculateOrderPrice(this.data?.id);
        this.$popup.hide("MarketingProductOffer");
        await this.getOrder();
      } catch (error) {
        console.error(error);
        this.$notice(this.$translate("request_error"), undefined, {
          type: "error",
        });
      } finally {
        this.loading = false;
      }
    },
  },
  async created() {
    if (!this.isChildrenPage) await this.getOrder();

    this.emitter.on('MarketingProductOffer', this.handleRecalculateOrderPrice);
  },
  beforeUnmount() {
    this.emitter.off('MarketingProductOffer', this.handleRecalculateOrderPrice);
  },
};
</script>
<style scoped src="./style.scss" lang="scss"></style>
