<template>
  <div
    ref="tooltip"
    class="charTooltip"
    :style="{
      'max-width': maxWidth + 'px',
      top: top,
      left: left,
      right: right,
      bottom: bottom,
    }"
  >
    <div class="box" :class="{ show: show }">
      <div v-if="html" v-html="html" class="content"></div>
      <router-link class="btn" v-if="showLink" :to="link">
        <span>{{ $translate('more_details', 'orders') }}</span>
        <IconSvg />
      </router-link>
    </div>
  </div>
</template>
<script>
import IconSvg from '@/assets/img/chartArrowRight.svg';
export default {
  name: 'CharTooltip',
  components: {
    IconSvg,
  },
  props: {
    html: {
      type: String,
      default: '',
    },
    showLink: {
      type: Boolean,
      default: false,
    },
    link: {
      type: Object,
      default() {
        return {};
      },
    },
    target: {
      type: Object,
      default() {
        return {};
      },
    },
    maxWidth: {
      type: Number,
      default: 245,
    },
  },
  data: function () {
    return {
      top: '60px',
      left: '15px',
      right: 'auto',
      bottom: 'auto',
      show: false,
    };
  },
  methods: {
    calculatePos() {
      try {
        const parent = this.$refs?.tooltip?.parentElement;
        if (!parent) return null;
        const { left: parentLeft = 0, right: parentRight = 0 } =
          parent.getBoundingClientRect();
        const {
          left = 0,
          right = 0,
          width = 0,
          top = 0,
          //   height = 0,
        } = this.target.getBoundingClientRect();
        let halfWidth = width / 2;
        if (left + halfWidth + this.maxWidth < parentRight) {
          this.left = left + halfWidth + 'px';
          this.right = 'auto';
        } else if (right - halfWidth - this.maxWidth > parentLeft) {
          this.left = right - halfWidth - this.maxWidth + 'px';
          this.right = 'auto';
        } else if (left + this.maxWidth < parentRight) {
          this.left = left + 'px';
          this.right = 'auto';
        } else if (right - width - this.maxWidth > parentLeft) {
          this.left = right - width - this.maxWidth + 'px';
          this.right = 'auto';
        }
        this.top = top - this.$refs?.tooltip.offsetHeight / 2 + 'px';
        this.bottom = 'auto';
        console.log('calculatePos');
        if (!this.show)
          setTimeout(() => {
            this.show = true;
          }, 200);
      } catch (e) {
        console.log(e);
      }
    },
  },
  watch: {
    target: function () {
      this.calculatePos();
    },
  },
  mounted() {
    this.calculatePos();
    window.addEventListener('scroll', this.calculatePos);
    window.addEventListener('resize', this.calculatePos);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.calculatePos);
    window.removeEventListener('resize', this.calculatePos);
  },
};
</script>
<style lang="scss" scoped>
.charTooltip {
  transition: all 0.2s;
  width: 100%;
  margin: 0;
  position: fixed;
  .box {
    background: $gray_800;
    box-shadow: 0px 3px 9px rgba(28, 52, 84, 0.12);
    border-radius: toRem(24);
    display: flex;
    flex-direction: column;
    padding: toRem(20);
    color: $white;
    opacity: 0;
    transition: opacity 0.2s;
    &.show {
      opacity: 1;
    }
  }
  ::v-deep(.content) {
    color: inherit;
    .title {
      color: inherit;
      font-weight: 450;
      font-size: toRem(24);
      line-height: 100%;
      margin-bottom: toRem(20);
    }
    .roW {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: inherit;
      font-weight: 400;
      font-size: toRem(14);
      line-height: toRem(18);
      margin-bottom: toRem(20);
      &:last-child {
        margin-bottom: 0;
      }
      span {
        &:first-child {
          padding-right: toRem(5);
        }
      }
      &.alighnStart {
        justify-content: flex-start;
      }
    }
  }
  .btn {
    cursor: pointer;
    color: inherit;
    display: inline-flex;
    align-items: center;
    font-weight: 450;
    font-size: toRem(14);
    line-height: 100%;
    &:last-child {
      margin-top: toRem(20);
    }
    ::v-deep(svg) {
      width: toRem(16);
      height: toRem(16);
      object-fit: contain;
      margin-left: toRem(5);
      path {
        fill: $white;
      }
    }
  }
  ::v-deep(.circleTooltip) {
    width: toRem(10);
    height: toRem(10);
    border-radius: 50%;
    margin-right: toRem(6);
  }
  ::v-deep(.v3) {
    .roW {
      margin-bottom: toRem(10);
    }
    span {
      font-size: toRem(12);
      line-height: toRem(15);
      font-weight: 400;
      b {
        font-weight: 500;
      }
    }
    .resultation {
      padding-top: toRem(10);
      font-weight: 400;
      font-size: toRem(14);
      line-height: 100%;
      b {
        font-weight: 500;
      }
    }
  }
}
</style>
