<template>
  <div class="AreasFundSection">
    <DashboardTitleRow
      :title="$translate('land_fund', 'companies')"
      titleTag="h2"
    >
      <template v-slot:title>
        <div class="btnWrap">
          <MyButton
            class="width-auto"
            iconAfter="add_document"
            :text="$translate('add_plot', 'companies')"
            size="s"
            tag="router-link"
            :to="{ name: 'create-area' }"
          />
        </div>
      </template>
    </DashboardTitleRow>
    <Transition mode="out-in">
      <LoadingIcon v-if="loading" />
      <AreasTable
        @toggleSort="toggleSort"
        @removeItem="handleRemove"
        v-else-if="!loading && cropAreasList.length"
        :list="cropAreasList"
        :sortStatus="sort"
        :loading="tableLoad"
        :active="activeSort"
      />
      <h5 v-else>{{ $translate('empty') }}</h5>
    </Transition>
    <Transition mode="out-in">
      <Pagination
        v-if="totalPage && totalPage > 1 && cropAreasList.length"
        @paginate="paginate"
        :pageCount="totalPage"
        :pageCurrent="currentPage"
        :class="{ disabledElem: loading }"
      />
    </Transition>
  </div>
</template>

<script>
import DashboardTitleRow from '@/components/DashboardTitleRow';
import AreasTable from '@/components/Table/Areas';
import Pagination from '@/components/Pagination';
/**
 * The AreasFundSection component.
 */
export default {
  name: 'AreasFundSection',
  components: { DashboardTitleRow, AreasTable, Pagination },
  data() {
    return {
      loading: !true,
      tableLoad: false,
      cropAreasList: [],
      totalPage: 0,
      currentPage: 1,
      //default api setting
      pageSize: 6,
      //sort param
      sort: false,
      //active
      activeSort: '',
    };
  },

  methods: {
    setQuery(obj = {}) {
      let query = Object.assign({}, this.$route.query || {}, obj);
      this.$router.push({ query });
    },
    readQuery() {
      try {
        const {
          page = 1,
          activeSort = '',
          sort = false,
        } = this.$route.query || {};
        if (page && page > 1) this.currentPage = page;
        if (activeSort) this.activeSort = activeSort;
        if (sort && sort != 'false') this.sort = sort;
      } catch (e) {
        console.log(e);
      }
    },

    paginate(page) {
      this.setQuery({ page: page });
      this.currentPage = page;
      return this.getCropAreas();
    },
    toggleSort(key) {
      try {
        if (this.activeSort == key) {
          this.sort = !this.sort;
        } else {
          this.activeSort = key;
          this.sort = true;
        }
        this.setQuery({ activeSort: key, sort: this.sort });
        this.tableLoad = true;
        this.getCropAreas({ onDefaultLoad: false });
      } catch (e) {
        console.log(e);
        this.tableLoad = false;
      }
    },
    sortCreated() {
      if (this.activeSort) {
        const type = this.sort ? 'desc' : 'asc';
        switch (this.activeSort) {
          case 'name':
            return { name: type };
          case 'region':
            return { region: { name: type } };
          case 'cropArea':
            return { cropArea: type };
          case 'company':
            return { company: { name: type } };
        }
      }
      return {};
    },
    async getCropAreas({ create = false, onDefaultLoad = true } = {}) {
      try {
        if (create) this.readQuery();
        if (onDefaultLoad) this.loading = true;
        const sort = this.sortCreated();
        const params = {
          pagination: { pageSize: this.pageSize, page: this.currentPage },
          sort,
          populate: ['region.name', 'company.name'],
          fields: 'name,cropArea,region,company',
        };
        let {
          success = false,
          list = [],
          pagination = {},
        } = await this.$api.cropArea.getCropArea(params);
        if (success) {
          this.cropAreasList = list;
          const { pageCount } = pagination;
          this.totalPage = pageCount;
        }
        this.loading = false;
        this.tableLoad = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
        this.tableLoad = false;
      }
    },
    async handleRemove(id) {
      try {
        this.loading = true;
        await this.$api.cropArea.delete(id);
        await this.getCropAreas({ onDefaultLoad: true });
      } catch (error) {
        console.error(error);
        this.$notice(this.$translate("request_error"), undefined, {
          type: "error",
        });
      } finally {
        this.loading = false;
      }
    }
  },
  async created() {
    await this.getCropAreas({ create: true });
  },
};
</script>
