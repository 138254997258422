<template>
  <div class="chartInfo" :class="'id--' + data.id">
    <div class="top">
      <div class="info">
        <h2 v-if="name">{{ name }}</h2>
        <span v-if="caption" class="sub">{{ caption }}</span>
      </div>
      <div class="action"></div>
    </div>
    <div
      class="mid"
      :class="{ bigPadd: saldo || paidBalance || shippedBalance }"
    >
      <!-- info -->
      <div v-if="saldo" class="roW bigmarg">
        <span class="name b">{{ $translate('group_balance', 'general') }}</span>
        <div class="val big">
          <span>{{ $price(saldo, undefined, true) }}</span>
        </div>
      </div>
      <div v-if="paidBalance" class="roW">
        <span class="name">{{ $translate('paid', 'orders') }}</span>
        <div class="val">
          <span>{{ $price(paidBalance, undefined, true) }}</span>
          <Tippy :text="$translate('dashboard_balance_paid', 'notice')" />
        </div>
      </div>
      <div v-if="shippedBalance" class="roW">
        <span class="name">{{ $translate('sent', 'orders') }}:</span>
        <div class="val">
          <span>{{ $price(shippedBalance, undefined, true) }}</span>
          <Tippy :text="$translate('dashboard_balance_shipped', 'notice')" />
        </div>
      </div>
      <!-- info -->
      <!-- datepicker -->
      <DatePicker
        v-if="datePickerOptions"
        :range="datePickerOptions.range"
        :monthPicker="datePickerOptions.monthPicker"
        :placeholder="datePickerOptions.placeholder"
        @dateUpdate="dateUpdate"
      />
      <!-- datepicker -->
      <!-- dropdown -->
      <LabelDropdown
        class="customize"
        v-if="selectPeriodLists"
        name="period"
        :title="$translate('сhoose_a_period', 'dashboard')"
        :list="selectPeriodLists"
        :active="selected"
        @change="changeDropdown"
      />
      <!-- dropdown -->
      <ul class="fundInfo" v-if="areaOfLandFund && potentialOfLandFund">
        <li v-if="areaOfLandFund">
          <span class="name">{{
            $translate('the_area_of_land_fund', 'dashboard')
          }}</span>
          <div class="val">
            <span>{{ areaOfLandFund }} га</span>
            <Tippy
              :text="$translate('dashboard_landFund_areaOfLandFund', 'notice')"
            />
          </div>
        </li>
        <li v-if="potentialOfLandFund">
          <span class="name">{{
            $translate('potential_the_land_fund', 'dashboard')
          }}</span>
          <div class="val">
            <span>{{ potentialOfLandFund }} т</span>
            <Tippy
              :text="
                $translate('dashboard_landFund_potentialOfLandFund', 'notice')
              "
            />
          </div>
        </li>
      </ul>
      <!-- chart -->
      <div
        ref="chartBox"
        v-if="chartType && chartOptions && chartSeries"
        class="chart"
        :class="`_id--${data.id}`"
      >
        <VueApexCharts
          ref="chart"
          height="100%"
          :type="chartType"
          :options="chartOptions"
          :series="chartSeries"
          @dataPointMouseEnter="chartMouseEnter"
          @dataPointSelection="chartMouseClick"
          @dataPointMouseLeave="chartMouseLeave"
          @mounted="charMounted"
          @updated="chartUpdated"
        ></VueApexCharts>
      </div>
      <!-- chart -->
    </div>
    <router-link v-if="toPage" class="btn Button" :to="toPage.link">
      <span v-if="toPage.text">{{ toPage.text }}</span>
      <IconSvg />
    </router-link>
    <!-- PieCenterInfo -->
    <Transition mode="out-in">
      <PieCenterInfo
        ref="piecenterinfo"
        v-if="showCenterInfo && centerInfoHtml"
        :html="centerInfoHtml"
        :parent="parentForCenterInfo"
      />
    </Transition>
    <!-- custom tooltip -->
    <Transition mode="out-in">
      <CharTooltip
        v-if="tooltip.show"
        :html="tooltip.html"
        :link="tooltip.link"
        :showLink="tooltip.showLink"
        :maxWidth="tooltip.maxWidth"
        :target="tooltip.target"
      />
    </Transition>
    <!-- custom tooltip -->
  </div>
</template>
<script>
import CharTooltip from './charTooltip.vue';
import PieCenterInfo from './pieCenterInfo.vue';
import VueApexCharts from 'vue3-apexcharts';
import DatePicker from './datePicker.vue';
import Tippy from '@/components/Tippy';
import IconSvg from '@/assets/img/chartArrowRight.svg';
export default {
  name: 'ChartInfo',
  components: {
    Tippy,
    CharTooltip,
    PieCenterInfo,
    VueApexCharts,
    DatePicker,
    IconSvg,
  },
  data: function () {
    return {
      colors: {
        green: '#55A832',
        yellow: '#F8AE1A',
        text: '#6F798A',
        text2: '#808B9F',
        text3: '#2B3951',
        text4: '#9CA2AF',
        white: '#FEFEFE',
        white2: '#F3F5F5',
        orange: '#DE6710',
        blue: '#416BA9',
      },
      tooltip: {
        show: false,
        html: '',
        link: {},
        showLink: false,
        target: {},
        maxWidth: 0,
      },
      showCenterInfo: false,
      parentForCenterInfo: null,
      //time
      dateFrom: null,
      dateTo: null,
      //select
      selected: null,
    };
  },
  props: {
    data: {
      type: Object,
      requred: true,
      default() {
        return {};
      },
    },
  },
  computed: {
    selectPeriodLists() {
      if (this.chartData.length && this.data.id == 7) {
        return this.chartData.map(({ period }) => period);
      } else {
        return null;
      }
    },
    datePickerOptions() {
      switch (this.data.id) {
        case 2:
        case 3:
          return {
            range: true,
            monthPicker: false,
            placeholder: this.$translate('сhoose_a_date', 'documents'),
          };
        case 6:
        case 8:
          return {
            range: true,
            monthPicker: true,
            placeholder: this.$translate('chouse_mounths', 'dashboard'),
          };
        default:
          return false;
      }
    },
    name() {
      return this.data?.name;
    },
    caption() {
      return this.data?.caption;
    },
    chartData() {
      return this.data?.data;
    },
    chartType() {
      const id = this.data?.id;
      switch (id) {
        case 1:
          return 'bar';
        case 2:
          return 'donut';
        case 3:
          return 'bar';
        case 6:
          return 'area';
        case 7:
        case 8:
          return 'pie';
        // return 'polarArea';
        default:
          return null;
      }
    },
    chartOptions() {
      const id = this.data?.id;
      const data = this.chartData;
      let categories = null;
      switch (id) {
        case 1:
          categories =
            data?.companies && data.companies.length
              ? data.companies.map(({ name }) => name)
              : null;
          if (!categories) return null;
          return {
            chart: {
              type: this.chartType,
              toolbar: {
                show: false,
              },
            },

            dataLabels: {
              enabled: false,
            },
            plotOptions: {
              bar: {
                horizontal: false,
                borderRadius: 10,
                borderRadiusApplication: 'end',
                columnWidth: '60%',
              },
            },
            stroke: {
              show: true,
              width: 5,
              colors: ['transparent'],
            },
            grid: {
              show: false,
            },
            xaxis: {
              categories,
              labels: {
                trim: false,
                rotate: 0,
                style: {
                  colors: this.colors.text,
                  fontSize: '12px',
                  fontFamily: 'Futura PT, Arial',
                  fontWeight: 400,
                },
              },
            },
            yaxis: {
              show: false,
            },
            fill: {
              opacity: 1,
              colors: [this.colors.green, this.colors.yellow],
            },
            legend: {
              layout: 'vertical',
              position: 'top',
              horizontalAlign: 'left',
              fontSize: '12px',
              fontWeight: 450,
              fontFamily: 'Futura PT, Arial',
              markers: {
                width: 10,
                height: 10,
                radius: 50,
              },
              labels: {
                colors: [this.colors.text],
              },
              itemMargin: {
                vertical: 8,
              },
            },
            tooltip: {
              // custom: this.tooltipCustomMarkup,
              enabled: false,
            },
            responsive: [
              {
                breakpoint: 768,
                options: {
                  // tooltip: {
                  //   custom: this.tooltipCustomMarkup,
                  //   intersect: true,
                  //   fixed: {
                  //     enabled: true,
                  //     position: 'topRight',
                  //     offsetX: 0,
                  //     offsetY: 0,
                  //   },
                  // },
                },
              },
            ],
          };
        case 2:
          categories =
            data && data.length
              ? this.changeData(data).map(
                  ({ name, quantity }) => `${name}, ${quantity} т`
                )
              : null;
          if (!categories) return null;
          return {
            chart: {
              type: this.chartType,
              toolbar: {
                show: false,
              },
            },
            labels: categories,
            dataLabels: {
              formatter: function (val) {
                const percent = parseFloat(val.toFixed(2));
                return `${percent}%`;
              },
              style: {
                fontSize: '14px',
                fontFamily: 'Futura PT, Arial',
                fontWeight: 400,
                colors: [this.colors.white],
              },
            },
            plotOptions: {
              pie: {
                customScale: 1.1,
                donut: {
                  size: '70%',
                },
              },
            },

            stroke: {
              show: true,
              width: 5,
              colors: [this.colors.white2],
            },
            fill: {
              opacity: 1,
            },
            legend: {
              layout: 'vertical',
              position: 'top',
              horizontalAlign: 'left',
              fontSize: '12px',
              fontWeight: 450,
              fontFamily: 'Futura PT, Arial',
              markers: {
                width: 10,
                height: 10,
                radius: 50,
              },
              labels: {
                colors: [this.colors.text],
              },
              itemMargin: {
                vertical: 8,
              },
            },
            tooltip: {
              enabled: false,
            },
            responsive: [
              {
                breakpoint: 540,
                options: {
                  plotOptions: {
                    pie: {
                      donut: {
                        size: '65%',
                      },
                    },
                  },
                  dataLabels: {
                    style: {
                      fontSize: '11px',
                    },
                  },
                },
              },
            ],
          };
        case 3:
          categories =
            data && data.length
              ? this.changeData(data).map(({ name }) => name)
              : null;
          if (!categories) return null;
          return {
            chart: {
              type: this.chartType,
              toolbar: {
                show: false,
              },
            },

            dataLabels: {
              enabled: false,
            },
            plotOptions: {
              bar: {
                horizontal: true,
                borderRadius: 4,
                borderRadiusApplication: 'end',
                borderRadiusWhenStacked: 'all',
                barHeight: '82%',
              },
            },
            stroke: {
              show: true,
              width: 8,
              colors: ['transparent'],
            },
            grid: {
              xaxis: {
                lines: {
                  show: true,
                },
              },
              yaxis: {
                lines: {
                  show: false,
                },
              },
            },
            xaxis: {
              categories,
              labels: {
                trim: true,
                style: {
                  colors: this.colors.text,
                  fontSize: '10px',
                  fontFamily: 'Futura PT, Arial',
                  fontWeight: 400,
                },
              },
            },
            yaxis: {
              labels: {
                show: true,
                style: {
                  fontFamily: 'Futura PT, Arial',
                  fontSize: '10px',
                  fontWeight: 400,
                  colors: [this.colors.text2],
                },
              },
            },
            fill: {
              opacity: 1,
            },
            legend: {
              layout: 'vertical',
              position: 'bottom',
              horizontalAlign: 'left',
              fontSize: '12px',
              fontWeight: 450,
              fontFamily: 'Futura PT, Arial',
              markers: {
                width: 10,
                height: 10,
                radius: 50,
              },
              labels: {
                colors: [this.colors.text],
              },
              itemMargin: {
                vertical: 8,
              },
            },
            tooltip: {
              enabled: false,
            },
            responsive: [
              {
                breakpoint: 768,
                options: {
                  // tooltip: {
                  //   custom: this.tooltipCustomMarkup,
                  //   intersect: true,
                  //   fixed: {
                  //     enabled: true,
                  //     position: 'topRight',
                  //     offsetX: 0,
                  //     offsetY: 0,
                  //   },
                  // },
                },
              },
            ],
          };
        case 6:
          categories =
            data && data.length
              ? this.changeData(data).map(
                  ({ month, year }) => `${month}.${year}`
                )
              : null;
          if (!categories) return null;
          return {
            chart: {
              type: this.chartType,
              toolbar: {
                show: false,
              },
            },
            dataLabels: {
              enabled: false,
            },
            labels: categories,
            xaxis: {
              labels: {
                trim: true,
                style: {
                  colors: this.colors.text3,
                  fontSize: '8px',
                  fontFamily: 'Futura PT, Arial',
                  fontWeight: 400,
                },
              },
            },
            yaxis: {
              labels: {
                trim: true,
                style: {
                  colors: this.colors.text4,
                  fontSize: '10px',
                  fontFamily: 'Futura PT, Arial',
                  fontWeight: 400,
                },
              },
            },
            grid: {
              xaxis: {
                lines: {
                  show: true,
                },
              },
              yaxis: {
                lines: {
                  show: true,
                },
              },
            },
            tooltip: {
              custom: this.tooltipCustomMarkup,
            },
          };
        case 7:
          categories =
            data && data.length
              ? [
                  this.$translate(
                    'uncovered_potential_of_the_fund',
                    'dashboard'
                  ),
                  this.$translate(
                    'covering_the_need_for_nitrogen_fertilizers',
                    'dashboard'
                  ),
                ]
              : null;
          if (!categories) return null;
          return {
            chart: {
              type: this.chartType,
              toolbar: {
                show: false,
              },
            },
            labels: categories,
            colors: [this.colors.green, this.colors.blue],
            dataLabels: {
              enabled: true,
              formatter: function (val) {
                const percent = parseFloat(val.toFixed(2));
                return `${percent}%`;
              },
              style: {
                fontSize: '15px',
                fontFamily: 'Futura PT, Arial',
                fontWeight: 400,
              },
              // background: {
              //   enabled: true,
              //   borderWidth: 0,
              // },
            },
            plotOptions: {
              // polarArea: {
              //   rings: {
              //     // strokeWidth: 0,
              //     strokeColor: '#e8e8e8',
              //     labels: {
              //       show: false, // Установка свойства show в false для скрытия меток на кольцах
              //     },
              //   },
              //   spokes: {
              //     strokeWidth: 0,
              //     connectorColors: '#e8e8e8',
              //   },
              // },
            },
            stroke: {
              show: false,
            },
            fill: {
              opacity: 1,
            },
            legend: {
              layout: 'vertical',
              position: 'bottom',
              horizontalAlign: 'left',
              fontSize: '12px',
              fontWeight: 450,
              fontFamily: 'Futura PT, Arial',
              markers: {
                width: 10,
                height: 10,
                radius: 50,
              },
              labels: {
                colors: [this.colors.text],
              },
              itemMargin: {
                // vertical: 8,
              },
            },
            tooltip: {
              enabled: false,
            },
            responsive: [
              {
                breakpoint: 768,
                options: {
                  // tooltip: {
                  //   custom: this.tooltipCustomMarkup,
                  //   intersect: true,
                  //   fixed: {
                  //     enabled: true,
                  //     position: 'topRight',
                  //     offsetX: 0,
                  //     offsetY: 0,
                  //   },
                  // },
                },
              },
            ],
          };
        case 8:
          categories =
            data && data.length
              ? this.changeData(data, 'deliveryCondition')
              : null;
          if (!categories) return null;
          return {
            chart: {
              type: this.chartType,
              toolbar: {
                show: false,
              },
            },
            labels: categories,
            colors: [
              this.colors.green,
              this.colors.blue,
              this.colors.yellow,
              this.colors.orange,
            ],
            dataLabels: {
              enabled: true,
              formatter: function (val, { seriesIndex, w: { globals } }) {
                const label = globals.labels[seriesIndex];
                const percent = parseFloat(val.toFixed(2));
                return `${label}, ${percent}%`;
              },
              style: {
                fontSize: '15px',
                fontFamily: 'Futura PT, Arial',
                fontWeight: 400,
              },
              // background: {
              //   enabled: true,
              //   borderWidth: 0,
              // },
            },
            plotOptions: {
              pie: {
                dataLabels: {
                  offset: -10,
                },
              },
              // polarArea: {
              //   rings: {
              //     // strokeWidth: 0,
              //     strokeColor: '#e8e8e8',
              //     labels: {
              //       show: false, // Установка свойства show в false для скрытия меток на кольцах
              //     },
              //   },
              //   spokes: {
              //     strokeWidth: 0,
              //     connectorColors: '#e8e8e8',
              //   },
              // },
            },
            stroke: {
              show: false,
            },
            fill: {
              opacity: 1,
            },
            legend: {
              show: false,
            },
            tooltip: {
              enabled: false,
            },
            responsive: [
              {
                breakpoint: 768,
                options: {
                  // tooltip: {
                  //   custom: this.tooltipCustomMarkup,
                  //   intersect: true,
                  //   fixed: {
                  //     enabled: true,
                  //     position: 'topRight',
                  //     offsetX: 0,
                  //     offsetY: 0,
                  //   },
                  // },
                },
              },
            ],
          };
        default:
          return null;
      }
    },
    chartSeries() {
      const id = this.data?.id;
      const data = this.chartData;
      const dateFrom = this.dateFrom;
      const dateTo = this.dateTo;
      const selected = this.selected;
      let series = null;
      switch (id) {
        case 1:
          series =
            data?.companies && data.companies.length
              ? [
                  {
                    name: this.$translate('paid', 'orders'),
                    color: this.colors.green,
                    data: data.companies.map(({ paidBalance }) => paidBalance),
                  },
                  {
                    name: this.$translate('sent', 'orders'),
                    color: this.colors.yellow,
                    data: data.companies.map(
                      ({ shippedBalance }) => shippedBalance
                    ),
                  },
                ]
              : null;
          return series;
        case 2:
          series =
            data && data.length
              ? this.changeData(data, 'quantity', dateFrom, dateTo)
              : null;
          return series;
        case 3:
          series =
            data && data.length
              ? [
                  {
                    name: this.$translate('everything', 'orders'),
                    color: this.colors.orange,
                    data: this.changeData(data, 'quantity', dateFrom, dateTo),
                  },
                  {
                    name: this.$translate('paid', 'orders'),
                    color: this.colors.blue,
                    data: this.changeData(
                      data,
                      'paidQuantity',
                      dateFrom,
                      dateTo
                    ),
                  },
                  {
                    name: this.$translate('sent', 'orders'),
                    color: this.colors.green,
                    data: this.changeData(
                      data,
                      'shippedQuantity',
                      dateFrom,
                      dateTo
                    ),
                  },
                ]
              : null;
          return series;
        case 6:
          series =
            data && data.length
              ? [
                  {
                    name: 'basic',
                    color: this.colors.orange,
                    data: this.changeData(data, 'quantity', dateFrom, dateTo),
                  },
                ]
              : null;
          return series;
        case 7:
          series =
            data && data.length
              ? [
                  this.changeData(
                    data,
                    'uncoveredPotential',
                    dateFrom,
                    dateTo,
                    selected
                  ),
                  this.changeData(
                    data,
                    'coveredPotential',
                    dateFrom,
                    dateTo,
                    selected
                  ),
                ]
              : null;
          return series;
        case 8:
          series =
            data && data.length
              ? this.changeData(data, 'quantity', dateFrom, dateTo)
              : null;
          return series;
        default:
          return null;
      }
    },
    saldo() {
      return this.chartData?.saldo;
    },
    paidBalance() {
      return this.chartData?.paidBalance;
    },
    shippedBalance() {
      return this.chartData?.shippedBalance;
    },
    toPage() {
      switch (this.data.id) {
        case 1:
          return {
            link: { name: 'companies', href: '/dashboard/companies' },
            text: this.$translate('list_of_counterparties_t', 'general'),
          };
        case 2:
          return {
            link: { name: 'orders', href: '/dashboard/orders' },
            text: this.$translate('list_of_orders_t', 'general'),
          };
        case 3:
          return {
            link: { name: 'orders', href: '/dashboard/orders' },
            text: this.$translate('list_of_orders_t', 'general'),
          };
        case 6:
        case 8:
          return {
            link: { name: 'packages', href: '/dashboard/packages' },
            text: this.$translate('view_all_deliveries', 'dashboard'),
          };
        case 7:
          return {
            link: { name: 'companies', href: '/dashboard/companies' },
            text: this.$translate('land_fund_of_companies', 'dashboard'),
          };
        default:
          return null;
      }
    },
    centerInfoHtml() {
      if (this.data.id == 2) {
        let price = this.changeData(this.chartData, 'price').reduce(
          (accum, nextEl) => accum + nextEl,
          0
        );
        price = parseFloat(price.toFixed(2));
        let quantity = this.changeData(this.chartData, 'quantity').reduce(
          (accum, nextEl) => accum + nextEl,
          0
        );
        quantity = parseFloat(quantity.toFixed(2));
        if (!price && !quantity) return '';
        const total = this.$translate('total', 'orders');
        return `<span class="total">${total}:</span>
        <span class="colorize">${quantity} т</span><span>${this.$price(
          price,
          undefined,
          true
        )}</span>`;
      } else {
        return false;
      }
    },
    areaOfLandFund() {
      if (this.selected) return this.changeData(this.chartData, 'area');
      return null;
    },
    potentialOfLandFund() {
      if (this.selected) return this.changeData(this.chartData, 'potential');
      return null;
    },
  },
  methods: {
    changeDropdown(key, name, item) {
      switch (name) {
        case 'period':
          this.selected = item;
          break;
        default:
          break;
      }
    },
    tooltipCustomMarkup({ series, seriesIndex, dataPointIndex, w }) {
      try {
        let optional = '';
        let optional2 = '';
        let optional3 = '';
        switch (this.data.id) {
          case 6:
            optional = this.changeData(this.chartData)?.[dataPointIndex]
              ?.company?.name;
            optional = optional ? `<h4 class="title">${optional}</h4>` : '';
            optional2 = w.globals?.categoryLabels?.[dataPointIndex];
            optional2 = optional2
              ? `  <span class="dAte">${optional2}</span>`
              : '';
            optional3 = series?.[seriesIndex]?.[dataPointIndex];
            optional3 = optional3 ? ` <span>${optional3} шт</span>` : '';
            return `<div class="chartTooltip">
                    ${optional}
                    <div class="roW">
                      ${optional2}
                      ${optional3}
                        </div>
                    </div>`;
          default:
            return null;
        }
      } catch (e) {
        console.log(e);
      }
    },
    cleanDataTooltip() {
      this.tooltip = {
        show: false,
        html: '',
        link: {},
        showLink: false,
        target: {},
        maxWidth: 0,
      };
    },
    showTooltip(event, chartContext, { dataPointIndex, seriesIndex, w }) {
      try {
        this.tooltip.target = event.target;
        let prices = null;
        switch (this.data.id) {
          case 1:
            this.tooltip.show = true;
            this.tooltip.maxWidth = 245;
            this.tooltip.html = `
          <h4 class="title">${w.globals.labels[dataPointIndex]}</h4>
          <div class="roW">
                       <span>${w.globals.seriesNames[seriesIndex]}</span>
                       <span>${this.$price(
                         w.globals.series[seriesIndex][dataPointIndex],
                         undefined,
                         true
                       )}</span>
                        </div>
          `;
            this.tooltip.showLink = true;
            return true;
          case 2:
            prices = this.changeData(this.chartData, 'price');
            this.tooltip.show = true;
            this.tooltip.maxWidth = 146;
            this.tooltip.html = `
          <h4 class="title">${w.globals.labels[dataPointIndex].replace(
            ',',
            ''
          )}</h4>
          <div class="roW">
                       <span>${this.$price(
                         prices[dataPointIndex],
                         undefined,
                         true
                       )}</span>
                        </div>
          `;
            this.tooltip.showLink = true;
            return true;
          case 3:
            prices = this.changeData(this.chartData);
            this.tooltip.show = true;
            this.tooltip.maxWidth = 150;
            this.tooltip.showLink = false;
            this.tooltip.html = `
            <div class="v3">
              <div class="roW alighnStart"><span style="background:${
                this.colors.orange
              }" class="circleTooltip"></span><span><b>${
              prices[dataPointIndex].quantity
            } т</b> ${this.$translate('everything', 'orders')}</span></div>
            <div class="roW alighnStart"><span style="background:${
              this.colors.blue
            }" class="circleTooltip"></span><span><b>${
              prices[dataPointIndex].paidQuantity
            } т</b> ${this.$translate('paid', 'orders')}</span></div>
            <div class="roW alighnStart"><span style="background:${
              this.colors.green
            }" class="circleTooltip"></span><span><b>${
              prices[dataPointIndex].shippedQuantity
            } т</b> ${this.$translate('sent', 'orders')}</span></div>
            <div class="resultation">${this.$translate(
              'Realization',
              'orders'
            )}: <b>${this.percentCalcualte(
              prices[dataPointIndex].quantity,
              prices[dataPointIndex].paidQuantity
            )}%</b></div>
              </div>
            `;
            return true;
          case 7:
            this.tooltip.show = true;
            this.tooltip.maxWidth = 100;
            this.tooltip.html = `
          <div class="roW">
                       <span>${w.globals.series[dataPointIndex]} т</span>
                        </div>
          `;
            this.tooltip.showLink = false;
            return true;
          case 8:
            this.tooltip.show = true;
            this.tooltip.maxWidth = 150;
            this.tooltip.html = `
          <div class="roW">
                       <span>${w.globals.labels[dataPointIndex]}  </span>
                       <span>${w.globals.series[dataPointIndex]} т</span>
                        </div>
          `;
            this.tooltip.showLink = false;
            return true;
          default:
            return null;
        }
      } catch (e) {
        console.log(e);
      }
    },
    hideTooltip() {
      this.cleanDataTooltip();
    },
    chartMouseEnter(event, chartContext, config) {
      this.showTooltip(event, chartContext, config);
    },
    chartMouseClick(event, chartContext, config) {
      console.log('click');
      console.log('event', event);
      console.log('chartContext', chartContext);
      console.log('config', config);
    },
    chartMouseLeave() {
      // const targ = event.target.closest('.charTooltip');
      // console.log('targ', event);
      // if (!targ) this.hideTooltip();
    },
    charCustomMouseLeave(e) {
      const targ =
        e.target.closest('.apexcharts-bar-area') ||
        e.target.closest('.charTooltip') ||
        e.target.closest('.apexcharts-pie-area');
      if (!targ) this.hideTooltip();
    },
    charMounted(chartContext, config) {
      if (this.data.id == 2) this.setCenterInfo();
      console.log(chartContext, config);
    },
    setCenterInfo() {
      try {
        this.parentForCenterInfo = this.$refs.chartBox;
        this.showCenterInfo = true;
      } catch (e) {
        console.log(e);
      }
    },
    changeData(
      data,
      key = false,
      dateFrom = this.dateFrom,
      dateTo = this.dateTo,
      selected = this.selected
    ) {
      try {
        let result = null;
        switch (this.data.id) {
          case 2:
            result = [];
            if (dateFrom && dateTo) {
              dateFrom = new Date(dateFrom);
              dateTo = new Date(dateTo);
              data = data.filter(
                ({ date }) =>
                  new Date(date) >= dateFrom && new Date(date) <= dateTo
              );
            } else if (dateFrom) {
              dateFrom = new Date(dateFrom);
              data = data.filter(({ date }) => {
                return new Date(date) >= dateFrom;
              });
            }
            data.forEach((item) => {
              const hasId = result.find((it) => it.crmId === item.crmId);
              if (!hasId) {
                let scope = data.filter((it) => it.crmId == item.crmId);
                let val = {
                  ...item,
                  price: scope.reduce(
                    (accumulator, currentValue) =>
                      accumulator + currentValue['price'],
                    0
                  ),
                  quantity: scope.reduce(
                    (accumulator, currentValue) =>
                      accumulator + currentValue['quantity'],
                    0
                  ),
                  crmId: item.crmId,
                  date: false,
                };
                result.push(val);
              }
            });
            if (key) {
              return result.map((item) => item[key]);
            }
            return result;
          case 3:
            result = [];
            if (dateFrom && dateTo) {
              dateFrom = new Date(dateFrom);
              dateTo = new Date(dateTo);
              data = data.filter(
                ({ date }) =>
                  new Date(date) >= dateFrom && new Date(date) <= dateTo
              );
            } else if (dateFrom) {
              dateFrom = new Date(dateFrom);
              data = data.filter(({ date }) => {
                return new Date(date) >= dateFrom;
              });
            }
            data.forEach((item) => {
              const hasId = result.find((it) => it.crmId === item.crmId);
              if (!hasId) {
                let scope = data.filter((it) => it.crmId == item.crmId);
                let val = {
                  ...item,
                  quantity: scope.reduce(
                    (accumulator, currentValue) =>
                      accumulator + currentValue['quantity'],
                    0
                  ),
                  paidQuantity: scope.reduce(
                    (accumulator, currentValue) =>
                      accumulator + currentValue['paidQuantity'],
                    0
                  ),
                  shippedQuantity: scope.reduce(
                    (accumulator, currentValue) =>
                      accumulator + currentValue['shippedQuantity'],
                    0
                  ),
                  crmId: item.crmId,
                  date: false,
                };
                result.push(val);
              }
            });
            if (key) {
              return result.map((item) => item[key]);
            }
            return result;
          case 6:
            result = [];
            if (dateFrom && dateTo) {
              data = data.filter(({ month, year }) => {
                return (
                  parseFloat(dateTo.year) >= parseFloat(year) &&
                  parseFloat(dateTo.month + 1) >= parseFloat(month) &&
                  parseFloat(dateFrom.year) <= parseFloat(year) &&
                  parseFloat(dateFrom.month + 1) <= parseFloat(month)
                );
              });
            } else if (dateFrom) {
              data = data.filter(({ month, year }) => {
                return (
                  parseFloat(dateFrom.year) <= parseFloat(year) &&
                  parseFloat(dateFrom.month + 1) <= parseFloat(month)
                );
              });
            }
            result.push(...data);
            console.log('result', result);

            if (key) {
              return result.map((item) => item[key]);
            }
            return result;
          case 7:
            result = {};
            if (!data.length) return data;
            if (!selected) {
              result = { ...data[data.length - 1] };
              this.selected = result.period;
            } else {
              result = data.find(({ period }) => period === selected) || {};
            }
            if (key) {
              return result?.[key];
            }
            return result;
          case 8:
            result = [];
            if (dateFrom && dateTo) {
              data = data.filter(({ month, year }) => {
                return (
                  parseFloat(dateTo.year) >= parseFloat(year) &&
                  parseFloat(dateTo.month + 1) >= parseFloat(month) &&
                  parseFloat(dateFrom.year) <= parseFloat(year) &&
                  parseFloat(dateFrom.month + 1) <= parseFloat(month)
                );
              });
            } else if (dateFrom) {
              data = data.filter(({ month, year }) => {
                return (
                  parseFloat(dateFrom.year) <= parseFloat(year) &&
                  parseFloat(dateFrom.month + 1) <= parseFloat(month)
                );
              });
            }
            data.forEach((item) => {
              const hasId = result.find(
                (it) => it.deliveryCondition === item.deliveryCondition
              );
              if (!hasId) {
                let scope = data.filter(
                  (it) => it.deliveryCondition == item.deliveryCondition
                );
                let val = {
                  ...item,
                  quantity: scope.reduce(
                    (accumulator, currentValue) =>
                      accumulator + currentValue['quantity'],
                    0
                  ),
                  month: 'scope',
                  year: 'scope',
                };
                result.push(val);
              }
            });
            if (key) {
              return result.map((item) => item[key]);
            }
            return result;
          default:
            return data;
        }
      } catch (e) {
        console.log(e);
      }
    },
    dateUpdate(values) {
      if (values) {
        this.dateFrom = values[0];
        this.dateTo = values[1];
      } else {
        this.dateFrom = null;
        this.dateTo = null;
      }
      console.log('dateUpdate', values);
    },
    percentCalcualte(total = 0, part = 0) {
      try {
        if (!total || !part) return 0;
        return parseFloat(((part / total) * 100).toFixed(2));
      } catch (e) {
        console.log(e);
      }
    },
    chartUpdated() {
      document.dispatchEvent(new CustomEvent('chart-update'));
    },
  },
  mounted() {
    document.addEventListener('mousemove', this.charCustomMouseLeave);
    document.addEventListener('click', this.charCustomMouseLeave);
  },
  beforeUnmount() {
    document.removeEventListener('mousemove', this.charCustomMouseLeave);
    document.removeEventListener('click', this.charCustomMouseLeave);
  },
};
</script>
<style scoped lang="scss">
.chartInfo {
  position: relative;
  padding: toRem(18) toRem(20);
  padding-bottom: toRem(28);
  background: $gray_100_purple;
  border: toRem(1) solid rgba(255, 255, 255, 0.4);
  box-shadow: -5px -5px 20px rgba(255, 255, 255, 0.8),
    5px 5px 10px rgba(166, 171, 189, 0.3);
  border-radius: toRem(20);
  display: flex;
  flex-direction: column;
  @media (max-width: $notebook) {
    padding: toRem(8) toRem(30) toRem(30) toRem(30);
  }
  @media (max-width: $mobile) {
    padding: toRem(15) toRem(20) toRem(17) toRem(20);
  }
  .top {
    display: flex;
    justify-content: space-between;
    .info {
      color: $gray_800;
      h2 {
        color: inherit;
        font-size: toRem(36);
        line-height: toRem(41);
        margin: 0;
        @media (max-width: $mobile) {
          line-height: toRem(41);
          font-size: toRem(32);
        }
      }
      .sub {
        font-weight: 450;
        font-size: toRem(14);
        line-height: toRem(18);
        color: inherit;
        @media (max-width: $notebook) {
          padding-top: toRem(8);
          display: block;
        }
        @media (max-width: $mobile) {
          padding-top: toRem(2);
        }
      }
    }
  }
  .mid {
    @media (max-width: $notebook) {
      padding-top: toRem(5);
    }
    &.bigPadd {
      padding-top: toRem(32);
      @media (max-width: $notebook) {
        padding-top: toRem(35);
      }
    }
    .roW {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 450;
      font-size: toRem(14);
      line-height: toRem(18);
      color: $gray_700;
      margin-bottom: toRem(6);
      .name,
      .val {
        font-weight: inherit;
        font-size: inherit;
        line-height: inherit;
        color: inherit;
      }
      .val {
        display: flex;
        align-items: center;
        .Notice {
          margin-left: toRem(10);
        }
        &.big {
          font-weight: 500;
          font-size: toRem(20);
          line-height: toRem(26);
          color: $gray_900;
        }
      }
      .name {
        &.b {
          font-weight: 500;
          @media (max-width: $notebook) {
            font-weight: 400;
          }
        }
      }
      &.bigmarg {
        margin-bottom: toRem(16);
        &:last-child {
          margin-bottom: 0;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    .chart {
      position: relative;
      min-height: toRem(400);
      &._id {
        &--1 {
          padding-top: toRem(21);
          min-height: toRem(397);
          padding-bottom: toRem(21);
          @media (max-width: $mobile) {
            margin: 0 toRem(-15);
            padding-top: toRem(2);
            min-height: toRem(370);
            ::v-deep(.apexcharts-legend) {
              padding-left: toRem(9);
            }
          }
        }
        &--2 {
          @media (max-width: $notebook) {
            min-height: toRem(430);
          }
          @media (max-width: $mobile) {
            min-height: toRem(435);
            margin: 0 toRem(-15);
            ::v-deep(.apexcharts-legend) {
              padding-left: toRem(10);
            }
          }
        }
        &--3 {
          padding-bottom: toRem(21);
          min-height: toRem(500);
          @media (max-width: $mobile) {
            min-height: toRem(450);
            margin: 0 toRem(-15);
            ::v-deep(.apexcharts-legend) {
              padding-left: toRem(10);
            }
          }
        }
        &--6 {
          padding-top: toRem(10);
          padding-bottom: toRem(21);
          min-height: toRem(450);
          @media (max-width: $mobile) {
            min-height: toRem(430);
            margin: 0 toRem(-15);
            ::v-deep(.apexcharts-legend) {
              padding-left: toRem(10);
            }
          }
        }
        &--7 {
          min-height: toRem(380);
          @media (max-width: $mobile) {
            min-height: toRem(385);
            margin: 0 toRem(-15);
            ::v-deep(.apexcharts-legend) {
              padding-left: toRem(10);
            }
          }
        }
        &--8 {
          @media (max-width: $mobile) {
            min-height: toRem(375);
            margin: 0 toRem(-15);
            ::v-deep(.apexcharts-legend) {
              padding-left: toRem(10);
            }
          }
        }
      }
      ::v-deep(.apexcharts-legend) {
        padding: 0;
      }
    }
  }

  ::v-deep(.apexcharts-tooltip) {
    background: $gray_800;
    padding: toRem(20);
    box-shadow: 0px 3px 9px rgba(28, 52, 84, 0.12);
    border-radius: toRem(24);
    color: $white;
    border: none;
    outline: none;
    .title {
      font-weight: 450;
      font-size: toRem(24);
      line-height: 100%;
      margin-bottom: toRem(20);
      color: inherit;
      h4 {
        font-weight: inherit;
        font-size: inherit;
        line-height: inherit;
        margin-bottom: 0;
        color: inherit;
      }
    }
    .roW {
      display: flex;
      font-weight: 400;
      font-size: toRem(14);
      line-height: toRem(18);
      color: $white;
      justify-content: space-between;
      margin-bottom: toRem(20);
      &:last-child {
        margin-bottom: 0;
      }
      .dAte {
        margin-right: toRem(15);
      }
    }
    .btn {
      outline: none;
      border: none;
      padding: 0;
      background: none;
      font-weight: 450;
      font-size: toRem(14);
      line-height: 100%;
      color: $white;
      cursor: pointer;
      margin-top: auto;
    }
  }
  .btn {
    margin-top: auto;
    font-weight: 450;
    font-size: toRem(14);
    line-height: 100%;
    color: $gray_900;
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    &::before {
      content: none;
    }
    span {
      font-weight: inherit;
      font-size: inherit;
      line-height: inherit;
      text-decoration: none;
    }
    svg {
      width: toRem(16);
      height: toRem(16);
      object-fit: contain;
      margin-left: toRem(5);
    }
  }
  ::v-deep(.Notice) {
    .content {
      right: 100%;
      left: auto;
    }
  }
  ::v-deep(.CustomDropdownWrap.customize) {
    margin-top: toRem(14);
    margin-bottom: toRem(8);
    max-width: toRem(205);
    box-shadow: 0px 3px 9px rgba(28, 52, 84, 0.12);
    border-radius: toRem(8);
    .title {
      display: none;
    }
    .dropdownTop {
      border: none;
    }
    .dropdownTop,
    .dropdownItem {
      height: toRem(32);
      padding: toRem(7) toRem(24);
      font-weight: 500;
      font-size: toRem(14);
      line-height: toRem(14);
      color: $gray;
    }
  }
  .fundInfo {
    list-style-type: none;
    display: flex;
    margin-bottom: toRem(8);
    li {
      display: flex;
      flex-direction: column;
      margin-right: toRem(34);
      @media (max-width: $mobile) {
        margin-right: toRem(48);
      }
      &:last-child {
        margin-right: 0;
      }
      .name {
        font-weight: 450;
        font-size: toRem(14);
        line-height: toRem(18);
        margin-bottom: toRem(4);
        color: $gray_700;
      }
      .val {
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: toRem(20);
        line-height: toRem(26);
        color: $gray;
        ::v-deep(.Notice) {
          margin-left: toRem(6);
          &:first-child {
            margin-left: 0;
          }
        }
      }
    }
  }
}
</style>
