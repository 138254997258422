<template>
  <div class="LabelWrap" :class="['type_' + type, { disabled }]">
    <label
      class="Label"
      :class="[
        name,
        'type_' + type,
        'size_' + currentSize,
        'type_' + inputType,
        {
          error: errorNow,
          focus: focusNow,
          firstFocus: firstFocus,
          hasTextarea: inputType == 'textarea',
        },
      ]"
    >
      <span class="titleRow" v-if="title">
        <span class="title">{{ title }}</span>
        <slot name="title"></slot>
      </span>
      <template v-if="inputType == 'textarea'">
        <textarea
          v-model="input"
          @focus="focus"
          @blur="blur"
          @input="onInput($event)"
          :placeholder="placeholder"
          :maxlength="limit"
          ref="input"
          :disabled="disabled"
        ></textarea>
        <span class="limit" v-if="showLimit">
          <span>{{ input.length }}</span> / {{ limit }}
        </span>
      </template>
      <template v-else-if="inputType == 'datepicker'">
        <Datepicker
          v-model="input"
          :startingView="calendarStartingView"
          inputFormat="dd/MM/yyyy"
          :upperLimit="upperLimit ? new Date() : undefined"
          :lowerLimit="lowerLimit || undefined"
          :locale="locale"
          :class="['size_' + currentSize]"
          ref="Datepicker"
          :disabled="editableStatus && !editableNow"
        />
        <template v-if="editableStatus && !editableNow">
          <span class="input placeholder stub">{{
            $moment(input).format("DD/MM/yyyy")
          }}</span>
          <div class="EditSVG">
            <EditSVG @click="editClick('datepicker')" />
          </div>
        </template>
        <template v-else>
          <span class="input placeholder" v-if="!input">{{ currentDate }}</span>
          <CalendarSVG class="calendarIcon" />
        </template>
      </template>
      <template v-else-if="inputType == 'search'">
        <SearchSVG class="SearchSVG" />
        <input
          type="text"
          v-model="input"
          @focus="focus"
          @blur="blur"
          :placeholder="placeholder"
          ref="input"
          :name="name"
          :disabled="disabled"
        />
      </template>
      <template v-else>
        <input
          :type="inputType"
          v-model="input"
          @focus="focus"
          @blur="blur"
          :placeholder="placeholder"
          ref="input"
          :autocomplete="autocompleteComputed"
          :name="name"
          v-maska="mask ? {} : undefined"
          :data-maska="mask"
          :data-maska-tokens="maskTokens"
          :disabled="(editableStatus && !editableNow) || disabled"
        />
        <template v-if="editableStatus && !editableNow">
          <span class="input placeholder stub">{{ input }}</span>
          <div class="EditSVG">
            <EditSVG @click="editClick('input')" />
          </div>
        </template>
      </template>
    </label>
    <slot name="bottom"></slot>
    <span
      class="errorText"
      v-if="(error && errorText) || (error?.error && error?.text)"
      >{{ errorText || error?.text }}</span
    >
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { vMaska } from "maska";
import Datepicker from "vue3-datepicker";
import { uk } from "date-fns/locale";

import CalendarSVG from "@/assets/img/calendar.svg";
import SearchSVG from "@/assets/img/search.svg";
import EditSVG from "@/assets/img/edit.svg";

/**
 * The Label component.
 */
export default {
  name: "LabelComponent",
  components: { Datepicker, CalendarSVG, SearchSVG, EditSVG },
  directives: { maska: vMaska },
  props: {
    title: { default: null, type: String },
    name: { default: null, type: String },
    /**
     * The placeholder for the label
     */
    placeholder: { default: null, type: String },
    /**
     * Error state
     */
    error: { default: false, type: [Boolean, Object] },
    /**
     * Error text
     */
    errorText: { default: null, type: String },
    /**
     * Init value in v-model
     */
    value: { default: null, type: [String, Number] },
    /**
     * Visual view type
     * @values primary
     */
    type: { default: "primary" },
    /**
     * Type of input element
     * @values text, email, textarea...
     */
    inputType: { default: "text" },
    /**
     * autocomplete attribute
     * @values on, off
     */
    autocomplete: { default: null, type: String },
    limit: { default: 450, type: Number },
    /**
     * The size of the button
     * @values m, l, xs
     */
    size: { default: null, type: String },
    // dynamicSize: { default: true, type: Boolean },
    /**
     * Mask teamplate
     */
    mask: { default: undefined, type: String },
    maskTokens: { default: undefined, type: String },
    /**
     * Editable state in profile
     */
    editable: { default: false, type: Boolean },
    /**
     * Show limin in textarea
     */
    showLimit: { default: false, type: Boolean },
    calendarStartingView: { default: "year", type: String },
    /**
     * Upper limit for datepicker
     */
    upperLimit: { default: true, type: Boolean },
    /**
     * Lower limit for datepicker
     */
    lowerLimit: { default: null, type: Object },
    min: { default: void 0, type: Number }, // min
    max: { default: void 0, type: Number }, // max
    /**
     * Disabled state
     */
    disabled: { default: false, type: Boolean },
    numbersOnly: { default: false, type: Boolean },
  },
  data() {
    return {
      locale: uk,
      firstFocus: false,
      focusNow: false,
      input: "",
      currentDate: "",
      editableStatus: false,
      editableNow: false,
    };
  },
  computed: {
    ...mapGetters(["IS_Notebook"]),
    errorNow() {
      return typeof this.error === "object" ? this.error.error : this.error;
    },
    inputHasValue() {
      return (
        this.input !== "" && this.input !== null && this.input !== undefined
      );
    },
    autocompleteComputed() {
      return this.autocomplete || this.inputType;
    },
    currentSize() {
      // if (this.dynamicSize) {
      //   if (!this.IS_Notebook) return "l";
      //   return "m";
      // }
      if (this.size) return this.size;
      if (!this.IS_Notebook) return "l";
      return "m";
    },
  },
  methods: {
    focus($event) {
      this.focusNow = true;
      this.firstFocus = true;
      this.$emit("focus", this.input, $event, this.name);
    },
    blur($event) {
      this.focusNow = this.input.length > 0;
      this.$emit("blur", this.input, $event, this.name);
    },
    onInput($event) {
      /**
       * Emit input value.
       * @property {$event} event
       */
      this.$emit("onInput", this.input, $event, this.name);
    },
    editClick(type) {
      this.editableNow = true;
      this.$nextTick(() => {
        if (type == "input") {
          this.$refs.input.focus();
        } else if (type == "datepicker") {
          // this.$refs.Datepicker.value.openMenu();
        }
      });
    },
    updateValue(val) {
      this.input = val;
    },
  },
  watch: {
    input(val) {
      if (this.inputType !== 'number' && this.numbersOnly) {
        val = val.replace(/\D/g, "");
        this.input = val;
      }
      if (this.inputType == "datepicker" && val != null) {
        this.$emit("onInput", this.$moment(val).format("DD/MM/YYYY"));
      } else {
        this.onInput();
      }
    },
  },
  created() {
    if (this.value != null) {
      this.input = this.value;
      this.focusNow = true;
      this.firstFocus = true;
    }
    if (this.editable && this.inputHasValue) this.editableStatus = true;
    if (this.inputType == "datepicker") {
      this.currentDate = this.$moment().format("DD/MM/YYYY");
      this.input = this.value
        ? new Date(this.$moment(this.value, "DD/MM/YYYY"))
        : null;
    }
    this.$emit("updateValueFunc", this.updateValue);
  },
};
</script>

<style lang="scss" scoped src="./component.scss"></style>
