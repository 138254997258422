<template>
  <Layout id="ConfigureMarketingProduct" :data-id="popupData?.id">
    <h4>
      {{ $translate("product_settings", "orders") }}:
      <span class="product">{{ marketingProduct?.name }}</span>
    </h4>
    <div class="formWrap">
      <LoadingIcon v-if="loading" :absolute="true" />
      <form
        novalidate
        @submit.prevent="submit"
        :class="{ loadingElem: loading }"
        :key="formKey"
      >
        <template
          v-for="(field, fieldIndex) of formConfig"
          :key="formFieldNames[fieldIndex]">
          <LabelUploadFile
            v-if="field.type === 'file'"
            :ref="formFieldNames[fieldIndex]"
            :title="field.title"
            :name="formFieldNames[fieldIndex]"
            @onInput="onInputField"
            :error="inputError[formFieldNames[fieldIndex]]"
            :value="formValue[formFieldNames[fieldIndex]]"
          />
          <Label v-else
            :ref="formFieldNames[fieldIndex]"
            :title="field.title"
            :placeholder="field.placeholder"
            :name="formFieldNames[fieldIndex]"
            :inputType="field.type"
            @onInput="onInputField"
            :error="inputError[formFieldNames[fieldIndex]]"
            :value="formValue[formFieldNames[fieldIndex]]"
          />
        </template>
        <MyButton
          class="w100"
          :text="$translate('save', 'profile')"
          tag="button"
          tagType="submit"
        />
      </form>
      <MyButton
        :text="$translate('go_back')"
        tag="button"
        type="cancel w100"
        @click="closeModal"
        :disabled="loading"
      />
    </div>
  </Layout>
</template>

<script>
import { mapGetters } from "vuex";
import Layout from "../../PopupLayout";
import LabelUploadFile from "@/components/LabelUploadFile";
/**
 * The ConfigureMarketingProductModal component.
 */
export default {
  name: "ConfigureMarketingProductModal",
  components: {
    Layout,
    LabelUploadFile,
  },
  data() {
    return {
      formKey: "faqForm_0",
      loading: false,
      inputError: {},
      formValue: {},
      unsubscribeStoreAction: () => {},
    };
  },
  computed: {
    ...mapGetters("checkout", ["GET_Checkout"]),
    ...mapGetters(["GET_PopupData"]),
    popupData() {
      return this.GET_PopupData["ConfigureMarketingProduct"];
    },
    marketingProduct() {
      return this.popupData?.marketingProduct;
    },
    currentMarketingProduct() {
      return this.popupData?.currentMarketingProduct;
    },
    formConfig() {
      return this.marketingProduct?.formData;
    },
    formFieldNames() {
      return Array.isArray(this.formConfig) ?
        this.formConfig.map(
          ({ title }, fieldIndex) => this.createFormFieldHash(title, fieldIndex)
        )
        : []
    },
    formConfigByName() {
      const entries = this.formFieldNames.map((name, index) => {
        return [name, this.formConfig[index]];
      });
      return Object.fromEntries(entries);
    },
  },
  methods: {
    createFormFieldHash(title, index) {
      return [index, title].join('_').replace(/\s/g, '_').toLowerCase()
    },
    onInputField(value, $event, name) {
      if (!name) {
        throw new Error("onInputField: name is undefined");
      }

      if (value != undefined) {
        this.formValue[name] = value;
      }

      const fieldConfig = this.formConfigByName[name]
      const validateError = this.$simpleInputValidate(
        value || this.formValue[name],
        Boolean(fieldConfig.required) || true,
        fieldConfig.type === 'file' ? 'files' : 'name'
      );

      this.inputError[name] = validateError;

      if (validateError.error && this.$refs[name]?.$refs?.input) {
        this.$refs[name].$refs.input.focus();
      }

      return validateError.error;
    },
    async submit() {
      if (this.loading) return false;

      /** @type {boolean} */
      const formHasErrors = (() => {
        const errors = this.formFieldNames.map((name) => 
          this.onInputField(undefined, undefined, name)
        );
        return errors.some(Boolean);
      })();

      if (formHasErrors) {
        return
      }

      const successFunc = () => {
        const marketingProduct = this.createMarketingProductData();
        this.emitter.emit("ConfigureMarketingProduct", marketingProduct);
      };
      /** @type {string[]} form field names */
      const filesToUpload = this.formFieldNames
        .filter((name) => 
          (
            this.formConfigByName[name]?.type === 'file' &&
            this.formValue[name] &&
            this.formValue[name].some(entry => entry instanceof File)
          )
        )

      if (filesToUpload.length === 0) {
        return successFunc();
      }

      try {
        this.loading = true;
        const results = await Promise.all(
          filesToUpload.map(async (name) => {
            const { data } = await this.$api.file.upload(this.formValue[name])
            return { name, value: data }
          })
        )

        results.forEach(({ name, value }) => {
          this.formValue[name] = value;
        });
        successFunc();
      } catch (error) {
        this.$notice(this.$translate("request_error"), undefined, {
          type: "error",
        });
      } finally {
        this.loading = false;
      }
    },
    createMarketingProductData() {
      return {
        marketingProduct: { id: this.marketingProduct.id },
        formData: this.formFieldNames.map((name) => {
          const fieldConfig = this.formConfigByName[name];
          const value = this.formValue[name];
          return {
            type: fieldConfig.type,
            title: fieldConfig.title,
            value: fieldConfig.type === 'file' ? value[0] : value,
          };
        }),
      };
    },
    resetForm() {
      this.files = [];
      this.comment = "";
      this.formKey = "faqForm_" + this.$rnd(0, 100);
    },
    closeModal() {
      this.$popup.hide("ConfigureMarketingProduct", () => this.resetForm());
    },
    restoreModalData({ currentMarketingProduct }) {
      if (currentMarketingProduct == null) {
        return
      }

      currentMarketingProduct.formData.forEach(({ title, value, type }, index) => {
        const name = this.createFormFieldHash(title, index);

        this.formValue[name] = type === 'file' ? [value] : value;
      });
      this.inputError = {};
      this.formKey = "faqForm_" + this.$rnd(0, 100);
    },
  },
  created() {
    this.emitter.on("SuccessUpdateConfigureMarketingProduct", () => {
      this.$popup.hide("ConfigureMarketingProduct", () => {
        this.loading = false;
        this.resetForm();
      });
    });
    this.unsubscribeStoreAction = this.$store.subscribeAction(({ type, payload }) => {
      if (
        type === "SHOW_Popup" &&
        payload?.name === "ConfigureMarketingProduct"
      ) {
        this.restoreModalData(payload.data);
      }
    });
  },
  mounted() {
    // this.$popup.show("ConfigureMarketingProduct");
  },
  beforeUnmount() {
    this.unsubscribeStoreAction();
    this.emitter.off("SuccessUpdateConfigureMarketingProduct", this.saveDrafts);
  },
};
</script>
<style lang="scss" src="./component.scss"></style>
