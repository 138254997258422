<template>
  <Layout id="RemoveCompanyItem">
    <h4 class="title" v-html="title" v-if="title"></h4>
    <p v-html="text" v-if="text"></p>
    <LoadingIcon v-if="loading" :absolute="true" />
    <div class="controls" :class="{ disabledElem: loading }">
      <MyButton
        :text="$translate('delete')"
        tag="button"
        tagType="submit"
        class="w100"
        size="m"
        @click="remove"
      />
      <MyButton
        :text="$translate('go_back')"
        tag="button"
        tagType="reset"
        type="cancel"
        class="w100"
        size="m"
        @click="() => $popup.hide('RemoveCompanyItem')"
      />
    </div>
  </Layout>
</template>

<script>
import { mapGetters } from "vuex";
import Layout from "../../PopupLayout";
/**
 * The RemoveCompanyItem component.
 */
export default {
  name: "RemoveCompanyItem",
  components: {
    Layout,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["GET_RemoveCompanyData", "GET_CurrentPopup"]),
    modalType() {
      return this.GET_RemoveCompanyData?.type;
    },
    title() {
      if (this.modalType === "bills") {
        return this.$translate("remove_bank_details", "companies");
      } else if (this.modalType === "company-contacts") {
        return this.$translate("remove_contact_person", "companies");
      }
      return this.$translate("remove_delivery_address", "companies");
    },
    text() {
      if (this.modalType === "bills") {
        return this.$translate("remove_bank_details_text", "companies");
      } else if (this.modalType === "company-contacts") {
        return this.$translate("remove_contact_person_text", "companies");
      }
      return this.$translate("remove_delivery_address_text", "companies");
    },
  },
  methods: {
    async remove() {
      if (this.loading) return true;
      this.loading = true;
      const method = (() => {
        if (this.modalType === "bills") return "removeRequisite";
        if (this.modalType === "delivery-address")
          return "removeDeliveryAddress";
        if (this.modalType === "company-contacts")
          return "removeCompanyManager";
        return null;
      })();
      if (!method) return;
      const { success } = await this.$api.company[method](
        this.GET_RemoveCompanyData.id
      );
      this.loading = false;
      if (success) {
        this.emitter.emit("RemoveCompanyItem", {
          success,
          id: this.GET_RemoveCompanyData.id,
          type: this.modalType,
        });
        this.$popup.hide("RemoveCompanyItem");
      }
    },
  },
};
</script>
<style lang="scss" src="./style.scss"></style>
