export default (context = false, active = 'company') => {
  if (!context) return [];
  return [
    {
      id: 2,
      text: context.$translate("bankingDetails", "general"),
      to: { name: "company-bills", params: { id: context.$route.params.id } },
      active: active == 'bills',
    },
    {
      id: 3,
      text: context.$translate("shippingAddresses", "general"),
      to: { name: "delivery-address", params: { id: context.$route.params.id } },
      active: active == 'delivery-address',
    },
    {
      id: 4,
      text: context.$translate("contactPersons", "general"),
      to: { name: "company-contacts", params: { id: context.$route.params.id } },
      active: active == 'company-contacts',
    },
    {
      id: 1,
      text: context.$translate('registrationData', 'general'),
      to: { name: 'company', params: { id: context.$route.params.id } },
      active: active == 'company',
    },
  ];
};
