import axios from "axios";

export default function (store) {
    const myAxios = axios.create({
        method: 'POST',
        baseURL: process.env.VUE_APP_API_URL,
        headers: {
            common: {
                Accept: "application/json;",
                // authorization: `Bearer ${store.getters['auth/GET_Token']}`,
            }
        }
    });

    myAxios.interceptors.request.use(async (req) => {
        if (store.getters['auth/GET_Token'] && !req.disableToken) { // set user token
            req.headers.authorization = `Bearer ${store.getters['auth/GET_Token']}`;
        }
        req.time = { startTime: new Date() };
        return req;
    })

    myAxios.interceptors.response.use(res => {
        return res;
    }, async (error) => {
        const { status: code } = error?.response
        const { message, name, success = false, details } = error.response?.data?.error;
        console.log('error', { code, message, name, success, details })
        return Promise.reject({ code, message, name, success, details });
    })
    return myAxios;
}