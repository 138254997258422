<template>
  <Layout id="AskQuestionProfile">
    <h4>{{ $translate('ask_a_question', 'documents') }}</h4>
    <!-- <p>{{ $translate('faq_modal_text', 'faq') }}</p> -->
    <div class="formWrap">
      <LoadingIcon v-if="loading" :absolute="true" />
      <form
        novalidate
        @submit.prevent="submit"
        :class="{ loadingElem: loading }"
        :key="formKey"
      >
        <Label
          :title="$translate('your_question')"
          name="comment"
          inputType="textarea"
          @onInput="onInputComment"
          :error="commentError"
          :errorText="commentErrorText"
          ref="comment"
        />
        <MyButton
          class="w100"
          :text="$translate('ask_question')"
          tag="button"
          tagType="submit"
          :dynamicSize="true"
        />
      </form>
    </div>
  </Layout>
</template>

<script>
import { mapGetters } from 'vuex';
import Layout from '../../PopupLayout';
/**
 * The AskQuestionModal component.
 */
export default {
  name: 'AskQuestionModal',
  components: {
    Layout,
  },
  data() {
    return {
      formKey: 'faqForm_0',
      loading: false,
      comment: '',
      commentError: false,
      commentErrorText: '',
    };
  },
  computed: {
    ...mapGetters(['IS_Mobile', 'GET_CurrentPopup', 'GET_PopupData']),
    thisID() {
      return this.GET_PopupData?.[this.GET_CurrentPopup]?.id;
    },
    thisType() {
      return this.GET_PopupData?.[this.GET_CurrentPopup]?.type;
    },
  },
  methods: {
    onInputComment(value = this.comment, e, required = false) {
      this.comment = value;
      const validateError = this.$simpleInputValidate(value, required, 'name');
      this.commentError = validateError.error;
      if (validateError.error) {
        this.commentErrorText = validateError.text;
        this.$refs.comment.$refs.input.focus();
      }
      return validateError.error;
    },
    async submit() {
      try {
        if (this.loading) return false;

        const error = this.onInputComment(undefined, undefined, true);
        if (!error) {
          this.loading = true;
          const data = {
            data: {
              requestBody: this.comment,
              notificationType: 'question',
              entityId: this.thisID,
              name: 'Поставити запитання',
            },
          };
          const type = this.thisType ? this.thisType : '';
          const { success } = await this.$api.document.askQuestionProfile(
            data,
            type
          );
          this.loading = false;
          if (success) {
            this.$popup.hide(undefined, () => this.resetForm());
            this.$notice(this.$translate('question_sent'));
          } else {
            this.$notice(this.$translate('request_error'), undefined, {
              type: 'error',
            });
          }
        }
      } catch (e) {
        this.loading = false;
        this.$notice(this.$translate('request_error'), undefined, {
          type: 'error',
          text: e,
        });
      }
    },
    resetForm() {
      this.comment = '';
      this.commentError = false;
      this.commentErrorText = '';
      this.formKey = 'faqForm_' + this.$rnd(0, 100);
    },
  },
};
</script>
<style lang="scss" src="./component.scss"></style>
