<template>
  <div class="DocumentCard">
    <DashboardTitleRow
      :title="
        $translate('document', 'documents') + ' ' + document.documentNumber
      "
      titleTag="h1"
      titleTagClass="h4"
      :goBack="{ text: $translate('back', 'documents'), name: 'documents' }"
    />
    <DashboardCard class="p40">
      <table>
        <thead>
          <tr>
            <th>
              <span class="document_name">{{
                $translate("document_name", "documents")
              }}</span>
            </th>
            <th>
              <span class="date_of_creation">{{
                $translate("date_of_creation", "documents")
              }}</span>
            </th>
            <th v-if="ordersList.length?.length > 0">
              <span class="related_order">{{
                $translate("related_order", "documents")
              }}</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <span class="thead" v-if="IS_Tablet2">{{
                $translate("document_name", "documents")
              }}</span>
              <span class="document_name">
                <b>{{ document.documentNumber }}</b>
              </span>
              <MyButton
                v-if="document.file?.url"
                :text="$translate('download')"
                size="xs"
                iconAfter="download"
                class="primary400"
                tag="a"
                :link="fileLink"
                target="_blank"
              />
            </td>
            <td>
              <span class="thead" v-if="IS_Tablet2">{{
                $translate("date_of_creation", "documents")
              }}</span>
              <span class="creationDate">{{ creationDate }}</span>
            </td>
            <td v-if="ordersList.length > 0">
              <span class="thead" v-if="IS_Tablet2">{{
                $translate("related_order", "documents")
              }}</span>
              <ul class="related-orders-list">
                <li v-for="(order, index) in ordersList"
                  :key="order.number"
                  class="related-orders-list__item">
                  <component 
                    :is="order.routerLinkParams ? 'router-link' : 'span'"
                    class="related_order"
                    :to="order.routerLinkParams"
                    >
                    <b>
                      {{ order.number }}
                    </b>
                    <span v-if="index !== ordersList.length - 1">,&nbsp;</span>
                  </component>
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
      <div
        v-if="document && document.additionalInformation"
        class="document_details"
      >
        <p>{{ $translate("notes") }}</p>
        <div class="text" v-html="document.additionalInformation"></div>
      </div>
      <div class="basic_actions">
        <p>
          <b>{{ $translate("basic_actions", "documents") }}</b>
        </p>
        <MyButton
          :text="$translate('ask_a_question', 'documents')"
          type="outline-yellow"
          class="w259"
          :dynamicSize="true"
          @click="$popup.show('AskDocumentQuestion', this.document)"
        />
      </div>
    </DashboardCard>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DashboardTitleRow from "@/components/DashboardTitleRow";
import DashboardCard from "@/components/DashboardCard";
/**
 * The DocumentCard component.
 */
export default {
  name: "DocumentCard",
  components: { DashboardTitleRow, DashboardCard },
  props: {
    document: { default: () => {}, type: Object },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["IS_Tablet2"]),
    creationDate() {
      return this.$moment(this.document.createdAt).format("DD.MM.YY");
    },
    fileLink() {
      return process.env.VUE_APP_API_DOMAIN + this.document.file.url;
    },
    ordersList() {
      if (!Array.isArray(this.document?.orders)) {
        return []
      }
      return this.document.orders.map((order) => {
        if (
          order?.status ||
          order.status.slug == "draft"
        ) {
          return order;
        }

        return {
          ...order,
          routerLinkParams: {
            name: "my-order",
            params: { id: order.number },
          }
        };
      });
    },
  },
};
</script>

<style lang="scss" scoped src="./component.scss"></style>
