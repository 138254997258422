<template>
  <div class="ReserveTimer">
    {{ $translate("reserve", "orders") }}:
    {{ time }}
  </div>
</template>

<script>
/**
 * The ReserveTimer component.
 */
export default {
  name: "ReserveTimer",
  components: {},
  props: {
    date: { default: null, type: String },
  },
  data() {
    return {
      time: "",
      dateMoment: null,
      interval: null,
    };
  },
  computed: {},
  methods: {
    calcTime() {
      if (this.date) {
        const startTime = this.$moment(this.date).add(15, "minutes");
        const endTime = this.$moment();

        // calculate total duration
        const duration = this.$moment.duration(endTime.diff(startTime));

        const minutes = duration.minutes();
        const seconds = duration.seconds();
        const minutesLeft = Math.abs(minutes);
        const secondsLeft = Math.abs(seconds);

        this.time = `${minutesLeft}:${this.$prefix(secondsLeft)}`;
        if (minutes >= 0 && seconds >= 0) this.resetQuotas();
      }
    },
    resetQuotas() {
      clearInterval(this.interval);
      this.interval = null;
      this.emitter.emit("resetQuotas", true);
      this.$notice(
        this.$translate("reset_quotas_by_timer", "orders"),
        undefined,
        {
          type: "error",
        }
      );
    },
  },
  created() {
    if (!this.date) return;
    this.calcTime();
    this.interval = setInterval(this.calcTime, 1000);
  },
  beforeUnmount() {
    clearInterval(this.interval);
  },
};
</script>

<style lang="scss" scoped src="./component.scss"></style>
