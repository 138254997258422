export default ($axios) => {
  return {
    async getFrontendConfig(params = {}) {
      return await $axios({
        url: '/frontend',
        method: 'get',
        params,
      })
        .then(({ data }) => {
          return { success: true, ...data };
        })
        .catch((e) => e);
    },
    async getOnboarding(slug = '') {
      return await $axios({
        url: '/onboarding/' + slug,
        method: 'get',
      })
        .then(({ data }) => {
          return { success: true, ...data };
        })
        .catch((e) => e);
    },
    async getI18ns() {
      return await $axios({
        url: '/i18ns',
        method: 'get',
      })
        .then(({ data }) => {
          return { success: true, list: data.data };
        })
        .catch((e) => e);
    },
    async getOrders(params = {}) {
      return $axios({
        url: '/orders',
        method: 'get',
        disableToken: false,
        params: { populate: 'deep', ...params },
      })
        .then(({ data }) => {
          return {
            success: true,
            list: data.data,
            pagination: data.meta.pagination,
          };
        })
        .catch((e) => e);
    },
    async getDocumentsTree(params = {}) {
      return $axios({
        url: '/orders/document-tree',
        method: 'get',
        disableToken: false,
        params: { ...params },
      })
        .then(({ data }) => {
          return {
            success: true,
            list: data.data,
            pagination: data.meta.pagination,
          };
        })
        .catch((e) => e);
    },
    async getPackages(params = {}) {
      return $axios({
        url: '/transport-instructions',
        method: 'get',
        params: { populate: 'deep', ...params },
      })
        .then(({ data }) => {
          return {
            success: true,
            list: data.data,
            pagination: data.meta.pagination,
          };
        })
        .catch((e) => e);
    },
    async getPriceList(params = {}) {
      return $axios({
        url: '/product-price-histories',
        method: 'get',
        params: { populate: 'deep', ...params },
      })
        .then(({ data }) => {
          return {
            success: true,
            list: data.data,
            pagination: data.meta.pagination,
          };
        })
        .catch((e) => e);
    },
    async getPayments(params = {}) {
      return $axios({
        url: '/payments',
        method: 'get',
        params: { populate: 'deep', ...params },
      })
        .then(({ data }) => {
          return {
            success: true,
            list: data.data,
            pagination: data.meta.pagination,
          };
        })
        .catch((e) => e);
    },
    async updateCompany(id, data) {
      return $axios({
        url: '/company/' + id,
        method: 'PUT',
        data: { data },
      })
        .then(({ data }) => {
          return { success: true, data };
        })
        .catch((e) => e);
    },
    async getСompanies(params = {}) {
      return $axios({
        url: '/companies',
        method: 'get',
        params: params,
      })
        .then(({ data }) => {
          return { success: true, list: data.data };
        })
        .catch((e) => e);
    },
    async setСompany(data) {
      return $axios({
        url: '/company',
        method: 'post',
        data,
      })
        .then(({ data }) => {
          return { success: true, data: data.data };
        })
        .catch((e) => e);
    },
    async getCompanyManagers(params = {}) {
      return $axios({
        url: '/company-managers',
        method: 'get',
        params: params,
      })
        .then(({ data }) => {
          return { success: true, list: data.data };
        })
        .catch((e) => {
          return { success: false, error: e, list: [] };
        });
    },
    async getRequests(params = {}) {
      return $axios({
        url: '/requests',
        method: 'get',
        params: params,
      })
        .then(({ data }) => {
          return {
            success: true,
            list: data.data,
            pagination: data.meta.pagination,
          };
        })
        .catch((e) => {
          return { success: false, error: e };
        });
    },
    async getRequestById(id) {
      return $axios({
        url: `/request/${id}`,
        method: 'get',
      })
        .then(({ data }) => {
          return { success: true, data: data.data };
        })
        .catch((e) => e);
    },
    async sendMessage({ requestId, payload }) {
      return $axios({
        url: `/request/${requestId}/comment`,
        method: 'put',
        data: {
          data: payload,
        },
      })
        .then(({ data }) => {
          return { success: true, data };
        })
        .catch((e) => e);
    },
    async markAsRead({ requestIds }) {
      return $axios({
        url: `/requests/mark-as-read`,
        method: 'post',
        data: {
          data: {
            ids: requestIds,
          },
        },
      })
        .then(({ data }) => ({ success: true, data }))
        .catch((e) => ({ success: false, error: e }));
    },

    async getGraphics(params = {}) {
      return $axios({
        url: '/graphics',
        method: 'get',
        params: { ...params },
      })
        .then(({ data }) => {
          return {
            success: true,
            data,
          };
        })
        .catch((e) => e);
    },

    async getCities(params = {}, cancelControllerSignal) {
      return $axios({
        url: '/cities',
        method: 'get',
        params: { ...params },
        signal: cancelControllerSignal,
      })
        .then(({ data }) => {
          return { success: true, list: data.data };
        })
        .catch((e) => e);
    },

    async getRegions(params = {}, cancelControllerSignal) {
      return $axios({
        url: '/regions',
        method: 'get',
        params: { ...params },
        signal: cancelControllerSignal,
      })
        .then(({ data }) => {
          return { success: true, list: data.data };
        })
        .catch((e) => e);
    },

    async getPriceListDownloadLink(params = {}) {
      return $axios({
        url: '/product-price-history/download',
        method: 'get',
        params: { ...params },
      })
        .then(({ data }) => {
          return { success: true, data };
        })
        .catch((e) => e);
    },
  };
};
