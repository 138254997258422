<template>
  <footer class="Footer">
    <div class="container maxWidth medium container_without_padding">
      <div class="row margin0 nowrap wrapTablet alignCenter">
        <div class="column columnLeft">
          <router-link
            :to="{ name: $auth.loggedIn ? 'dashboard' : 'home' }"
            class="LogoLink"
          >
            <LogoSVG class="Logo" />
          </router-link>
        </div>
        <div class="column columnRight">
          <nav>
            <ul>
              <li>
                <a :href="termsURL" class="simpleLink" target="_blank">
                  {{ $translate("terms_and_conditions") }}
                </a>
              </li>
              <li>
                <a :href="policyURL" class="simpleLink" target="_blank">
                  {{ $translate("privacy_notice") }}
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <div class="row margin0 nowrap wrapTablet alignCenter">
        <div class="column columnLeft">
          <span class="copy">{{
            $translate("copy_footer").replace("##", currentYear)
          }}</span>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import LogoSVG from "@/assets/img/logo_gray.svg";
import { mapGetters } from "vuex";
/**
 * The Footer component.
 */
export default {
  name: "FooterComponent",
  components: { LogoSVG },
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
  computed: {
    ...mapGetters(["GET_MinimizedSidebar", "GET_SidebarContacts"]),
    policyURL() {
      return this.GET_SidebarContacts?.policy?.url;
    },
    termsURL() {
      return this.GET_SidebarContacts?.terms?.url;
    },
  }
};
</script>

<style lang="scss" scoped src="./component.scss"></style>