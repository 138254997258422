export default ($axios) => {
    return {
        async login({ phone: identifier = '', password = '' }) {
            return await $axios({
                url: '/auth/local',
                data: { identifier, password },
            }).then(({ data }) => {
                return { success: true, ...data }
            }).catch(e => e)
        },
        async register(data) {
            return await $axios({
                url: '/auth/otp/register',
                data: data
            }).then(({ data }) => {
                return { success: true, ...data.data }
            }).catch(e => e)
        },
        async confirmOTP(data) {
            return await $axios({
                url: '/auth/otp/confirmation',
                data: data
            }).then(({ data }) => {
                return { success: true, ...data.data }
            }).catch(e => e)
        },
        async update(id, data) {
            console.log('update', { id, data });
            return await $axios({
                url: '/users/' + id,
                method: 'put',
                data: data
            }).then(({ data }) => {
                return { success: true, data }
            }).catch(e => e)
        },
        async getMe() {
            return await $axios({
                url: '/users/me',
                method: 'get',
            }).then(({ data }) => {
                return { success: true, user: data }
            }).catch(e => e)
        },
        async forgotPassword({ phone }) {
            return await $axios({
                url: '/auth/forgot-password',
                data: { phone }
            }).then(({ data }) => {
                return { success: true, ...data.data }
            }).catch(e => e)
        },
        async resetPassword({ code, password, passwordConfirmation }) {
            return await $axios({
                url: '/auth/reset-password',
                data: { code, password, passwordConfirmation }
            }).then(({ data }) => {
                return { success: true, ...data.data }
            }).catch(e => e)
        },
        async changePassword({ password, currentPassword, passwordConfirmation }) {
            return await $axios({
                url: '/auth/change-password',
                data: { password, currentPassword, passwordConfirmation }
            }).then(({ data }) => {
                return { success: true, ...data.data }
            }).catch(e => e)
        },
    }

}