<template>
  <tr @click="click">
    <td>
      <span class="date" v-if="currentDate">{{ currentDate }}</span>
      <span class="time" v-if="currentTime">{{ currentTime }}</span>
    </td>
    <td>
      <Tag class="w160" :text="row.documentType.name" v-if="row.documentType" />
    </td>
    <td>
      <span class="document">{{ row.documentNumber }}</span>
      <a
        v-if="downloadUrl != null"
        class="simpleLink download-document-btn"
        :href="downloadUrl"
        @click.stop
        target="_blank"
        download>
        <DownloadSVG />
      </a>
    </td>
    <td>
      <template
        v-for="(order, orderIx) in row?.orders"
        :key="order.number">
        <span class="document">{{order.number}}</span>
        <template v-if="row.orders?.length > 1 && orderIx < row.orders?.length - 1">,&nbsp;</template>
      </template>
    </td>
    <td>
      <Dropdown ref="dropdown" :contentClass="'padding--min'">
        <button @click="goIn" class="dropdown-btn" type="button" aria-label="">
          <PenIcon /> <span>{{ $translate('review') }}</span>
        </button>
      </Dropdown>
    </td>
  </tr>
</template>

<script>
import DownloadSVG from '@/assets/img/download.svg';
import Tag from '@/components/Table/PageTable/Tag';
import Dropdown from '@/components/Table/PageTable/Dropdown';
import PenIcon from '@/assets/img/pen-white.svg?.raw';

/**
 * The DocumentsTableItem component.
 */
export default {
  name: 'DocumentsTableItem',
  components: { DownloadSVG, Tag, Dropdown, PenIcon },
  props: {
    row: { default: () => {}, type: Object },
  },
  computed: {
    // ...mapGetters(['IS_Tablet']),
    currentDate() {
      if (!this.row.date) return false;
      return this.$moment(this.row.date).format('DD.MM.YY');
    },
    currentTime() {
      if (!this.row.date) return false;
      return this.$moment(this.row.date).format('HH:mm');
    },
    downloadUrl() {
      const fileUrl = this.row?.file?.url;

      if (typeof fileUrl != "string") {
        return null;
      }

      const url = new URL(process.env.VUE_APP_API_DOMAIN);
      url.pathname = fileUrl;

      return url.toString();
    },
  },
  methods: {
    // ...mapActions(['GET_PaymentInfo']),
    click(e) {
      try {
        const cancelClass = this.$refs.dropdown
          ? this.$refs.dropdown.$el.classList.toString()
          : '.Dropdown';
        let cancelTarget = e.target.closest(`.${cancelClass}`);
        if (cancelTarget) return null;
        return this.goIn();
      } catch (e) {
        console.log(e);
      }
    },
    goIn() {
      const id = this.row?.crmId || null;
      if (!id) return null;
      return this.$router.push({ name: 'documents-item', params: { id } });
    },
  },
};
</script>

<style lang="scss" scoped src="./component.scss"></style>
