<template>
  <div class="Chat">
    <div class="messagesWrap" ref="messagesWrap">
      <div class="messagesSecondWrap">
        <ChatMessage v-for="item in list" :key="item.id" :message="item" />
      </div>
    </div>
    <div class="controllRow">
      <div class="formWrap">
        <LoadingIcon v-if="loading" :absolute="true" />
        <form
          novalidate
          @submit.prevent="submit"
          :class="{ loadingElem: loading }"
          :key="formKey"
        >
          <Label
            name="message"
            :placeholder="$translate('write_message', 'purchase')"
            inputType="text"
            @onInput="onInputMessage"
            :error="messageError"
            :errorText="messageErrorText"
            ref="message"
            size="l">
            <template v-slot:bottom>
              <button type="button" class="messageBoxAddAttachment" @click="handleAddAttachment">
                <FileClipSvg class="messageBoxAddAttachmentIcon" />
                <input
                  type="file"
                  class="messageBoxAddAttachmentInput"
                  @change="handleFile"
                  ref="attachmentFile" />
              </button>
              <div class="messageBoxAttachment" v-if="newAttachment != null">
                <button type="button" class="messageBoxAttachmentRemove" @click="newAttachment = null">
                  <CloseSVG class="messageBoxAttachmentRemoveIcon" />
                </button>
                <span class="messageBoxAttachmentFilename">
                  {{ newAttachment?.name }}
                </span>
              </div>
            </template>
          </Label>
          <MyButton
            class="w240"
            :text="$translate('send', 'purchase')"
            tag="button"
            size="l"
          />
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import ChatMessage from "./Message";
import Scrollbar from "smooth-scrollbar";
import CloseSVG from "@/assets/img/close.svg"
import FileClipSvg from "@/assets/img/file-clip.svg"

/**
 * The Chat component.
 */
export default {
  name: "ChatComponent",
  components: {
    ChatMessage,
    CloseSVG,
    FileClipSvg,
    // RequestCommentAttachmentSvg,
  },
  props: {
    /**
     * Title of the page
     */
    title: { default: null, type: String },
    loading: { default: false, type: Boolean },
    list: { default: () => [], type: Array },
  },
  data() {
    return {
      message: "",
      messageError: false,
      messageErrorText: "",
      formKey: "messageForm_0",
      scrollbar: null,
      newAttachment: null,
    };
  },
  watch: {
    list() {
      const tid = setTimeout(() => {
        this.scrollToBottom();
        clearTimeout(tid);
      }, 100);
    },
  },
  methods: {
    onInputMessage(value = this.message, e, required = false) {
      this.message = value;
      const messageError = this.$simpleInputValidate(value, required, "name");
      this.messageError = messageError.error;

      // If at least one of the fields is filled in, then we don't show the error
      if (messageError.error && this.newAttachment == null) {
        this.messageErrorText = messageError.text;
        this.$refs.message.$refs.input.focus();
      }
      return messageError.error;
    },
    resetForm() {
      this.message = "";
      this.messageError = false;
      this.newAttachment = null;
      this.formKey = "messageForm_" + this.$rnd(0, 100);
    },
    success() {
      this.resetForm();
    },
    async submit() {
      if (this.loading) return false;
      
      const error = (() => {
        return this.onInputMessage(undefined, undefined, true);
      })();
      
      if (error && this.newAttachment == null) {
        return
      }

      this.$emit("message", {
        text: this.message,
        file: this.newAttachment,
      });
    },
    initScroll() {
      this.scrollbar = Scrollbar.init(this.$refs.messagesWrap, {
        alwaysShowTracks: true,
        continuousScrolling: false,
      });
      this.scrollbar.track.xAxis.hide();
      this.scrollToBottom();
    },
    scrollToBottom() {
      if (this.scrollbar) {
        this.scrollbar.scrollTo(0, 99999);
      } else {
        this.$refs.messagesWrap.scrollTop = 99999;
      }
    },
    handleAddAttachment() {
      this.$refs.attachmentFile?.click()
    },
    handleFile(e) {
      if (e.target.files[0]) {
        this.newAttachment = e.target.files[0]
      }
    },
  },
  mounted() {
    if (!this.$mobileDevice) {
      this.initScroll();
    } else {
      this.scrollToBottom();
    }
  },
  beforeUnmount() {
    if (this.scrollbar) this.scrollbar.destroy();
  },
};
</script>

<style lang="scss" scoped src="./component.scss"></style>
