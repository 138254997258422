<template>
  <component
    class="SpecialSliderItem simpleImage"
    :class="{ single, simpleLink: item.active }"
    :is="item.active && !single ? 'router-link' : 'div'"
    :to="
      item.active
        ? {
            name: 'order-specials',
            params: { category: item.slug },
          }
        : ''
    "
  >
    <!-- <div class="Image">
      <img :src="image" alt="@" />
    </div>
    <div class="contentWrap">
      <div class="topRop">
        <span class="dateUntil" v-if="dateUntil">
          {{ $translate("until") + " " + dateUntil }}
        </span>
      </div>
      <div class="bottomRow">
        <h2 class="h4">{{ item.title }}</h2>
        <template v-if="!$props.single">
          <p>{{ item.text }}</p>
          <MyButton
            class="w180"
            type="outline-yellow"
            :text="$translate('details', 'orders')"
            :to="{
              name: 'order-specials',
              params: { category: item.specials },
            }"
            tag="router-link"
          />
        </template>
      </div>
    </div> -->
    <img :src="image" alt="" v-if="image" />
  </component>
</template>

<script>
// import image from "@/assets/img/special.jpg";

/**
 * The SpecialSliderItem component.
 */
export default {
  name: "SpecialSliderItem",
  components: {},
  props: {
    item: { default: () => {}, type: Object },
    single: { default: false, type: Boolean },
  },
  data() {
    return {};
  },
  computed: {
    // ...mapGetters(['IS_Tablet']),
    image() {
      const url = this.single
        ? this.item?.icon?.url
        : this.item?.mainBanner?.url;
      return process.env.VUE_APP_API_DOMAIN + url;
    },
    dateUntil() {
      if (!this.item.date) return null;
      return this.$moment(this.item.date).format("DD.MM.YY");
    },
  },
  methods: {
    // ...mapActions(['GET_PaymentInfo']),
  },
};
</script>

<style lang="scss" scoped src="./component.scss"></style>
