<template>
  <div class="Content404">
    <span class="code">{{ code }}</span>
    <h5 class="h3">{{ message || $translate("page_not_found") }}</h5>
    <MyButton
      :text="$translate('go_home')"
      tag="router-link"
      :to="{ name: 'dashboard' }"
    />
  </div>
</template>

<script>
// import { mapGetters, mapActions } from 'vuex'
/**
 * The Content404 component.
 */
export default {
  name: "ShowContent404",
  components: {},
  props: {
    code: { default: 404, type: Number },
    message: { default: null, type: String },
  },
  data() {
    return {};
  },
  computed: {
    // ...mapGetters(['IS_Tablet']),
  },
  methods: {
    // ...mapActions(['GET_PaymentInfo']),
  },
};
</script>

<style lang="scss" scoped src="./component.scss"></style>
