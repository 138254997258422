<template>
  <LayoutLogin :logOut="false">
    <Content404 />
  </LayoutLogin>
</template>

<script>
import LayoutLogin from "@/layouts/Login.vue";
import Content404 from "@/components/Content404";
/**
 * The login-page component.
 */
export default {
  name: "LoginPage",
  components: { LayoutLogin, Content404 },
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.content {
  text-align: center;
}
</style>
