<template>
  <component
    :is="tag"
    v-bind:to="link"
    v-bind:href="href"
    v-bind:target="target"
    v-bind:type="tagType"
    class="Button"
    :class="[
      type,
      'size_' + currentSize,
      {
        disabled,
      },
    ]"
    :style="{}"
  >
    <div class="background"></div>
    <div class="ButtonContent">
      <span class="icon" v-if="icon">
        <component :is="icon + 'SVG'" />
      </span>
      <span class="textContent" v-if="text">{{ text }}</span>
      <!-- @slot Use this slot for insert main content -->
      <span class="textContent"><slot></slot></span>
      <span class="icon iconAfter" v-if="iconAfter">
        <component :is="iconAfter + 'SVG'" />
      </span>
    </div>
  </component>
</template>

<script>
import { mapGetters } from "vuex";
import { defineAsyncComponent } from "vue";
/**
 * The Button component.
 */
export default {
  name: "ButtonItem",
  components: {
    editSVG: defineAsyncComponent(() => import("@/assets/img/edit.svg")),
    arrow_leftSVG: defineAsyncComponent(() =>
      import("@/assets/img/arrow_left.svg")
    ),
    arrow_left2SVG: defineAsyncComponent(() =>
      import("@/assets/img/arrow_left2.svg")
    ),
    add_documentSVG: defineAsyncComponent(() =>
      import("@/assets/img/add_document.svg")
    ),
    downloadSVG: defineAsyncComponent(() =>
      import("@/assets/img/download.svg")
    ),
    arrow_rightSVG: defineAsyncComponent(() =>
      import("@/assets/img/arrow_right.svg")
    ),
    filterSVG: defineAsyncComponent(() => import("@/assets/img/filter.svg")),
    busketSVG: defineAsyncComponent(() => import("@/assets/img/busket.svg")),
    mapMarkerSVG: defineAsyncComponent(() =>
      import("@/assets/img/mapMarker.svg")
    ),
    printSVG: defineAsyncComponent(() => import("@/assets/img/print.svg")),
    starSVG: defineAsyncComponent(() => import("@/assets/img/Star.svg")),
    calendarSVG: defineAsyncComponent(() =>
      import("@/assets/img/calendar.svg")
    ),
    refreshSVG: defineAsyncComponent(() => import("@/assets/img/refresh.svg")),
  },
  props: {
    /**
     * The tag element.
     * @values div, a, button...
     */
    tag: { default: "div", type: String },
    /**
     * The tag element.
     * @values div, a, button...
     */
    tagType: { default: null, type: String },
    /**
     * The text for the button.
     */
    text: { default: null, type: String },
    /**
     * Disabled state
     * @values true, false
     */
    disabled: { default: false, type: Boolean },
    /**
     * Visual view type
     * @values primary, outline-green, outline-yellow, icon, icon-circle, cancel
     */
    type: { default: "primary", type: String },
    /**
     * The size of the button
     * @values s, m, l
     */
    size: { default: "l", type: String },
    /**
     * Link to page \ web
     */
    link: { default: null, type: [String, Object] },
    /**
     * Target attr for link
     * @values _blank
     */
    target: { default: "", type: String },
    dynamicSize: { default: false, type: Boolean },
    /**
     * Icon in button.
     * Expects the name of the connected icon component as input in lowercase.
     */
    icon: { default: null, type: String },
    /**
     * Icon in button after text.
     * Expects the name of the connected icon component as input in lowercase.
     */
    iconAfter: { default: null, type: String },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["IS_Notebook"]),
    href() {
      if (this.tag == "a" && this.link) return this.link;
      return undefined;
    },
    currentSize() {
      if (this.dynamicSize) {
        if (this.size == "l") {
          if (!this.IS_Notebook) return "l";
          return "m";
        } else if (this.size == "m") {
          if (this.IS_Notebook) return "s";
          return "m";
        }
      }

      return this.size;
    },
  },
};
</script>

<style lang="scss" scoped src="./component.scss"></style>

<docs lang="md">
```jsx
<MyButton> Primary </MyButton>
<MyButton type="outline-yellow"> outline-yellow </MyButton>
<MyButton type="outline-green"> outline-green </MyButton>
```
</docs>
