<template>
  <div class="Sidebar" :class="{ minimized: GET_MinimizedSidebar }">
    <div class="wrap">
      <MyButton
        class="sizeButton shadow"
        :dynamicSize="true"
        type="circle"
        icon="arrow_left"
        v-on:click="minimizeClick"
      />

      <div class="topRow">
        <h6>
          {{ $translate("management_services") }}
        </h6>
      </div>

      <div ref="content" class="content">
        <SiteNav :minimized="GET_MinimizedSidebar" />
      </div>

      <footer class="bottomRow">
        <div class="line"></div>
        <div class="logOutButton simpleLink" @click="$auth.logOut()">
          <LogOutSVG />
          <span>{{ $translate("log_out") }}</span>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Scrollbar from "smooth-scrollbar";
import SiteNav from "@/components/SiteNav";
import LogOutSVG from "@/assets/img/log_out.svg";

/**
 * The Sidebar component.
 */
export default {
  name: "SidebarComponent",
  components: {
    LogOutSVG,
    SiteNav,
  },
  data() {
    return {
      layout: null,
      scrollbar: null,
    };
  },
  computed: {
    ...mapGetters(["GET_MinimizedSidebar"]),
  },
  methods: {
    ...mapActions(["SET_MinimizedSidebar"]),
    initScroll() {
      this.scrollbar = Scrollbar.init(this.$refs.content, {
        alwaysShowTracks: true,
        continuousScrolling: false,
      });
      this.scrollbar.track.xAxis.hide();
    },
    minimizeClick() {
      this.SET_MinimizedSidebar(!this.GET_MinimizedSidebar);
    },
  },
  mounted() {
    this.initScroll();
  },
  beforeUnmount() {
    if (this.scrollbar) this.scrollbar.destroy();
  },
};
</script>

<style lang="scss" scoped src="./component.scss"></style>
