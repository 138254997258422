<template>
  <Layout class="TI-duplication" id="TIDuplication">
    <h4 class="title">{{ $translate('duplication_of _TI', 'documents') }}</h4>
    <div class="flex column">
      <ul class="block">
        <li v-if="tiNumber">
          <span class="name-block">
            {{ $translate('current', 'general') }}
            {{ tiNumber }}
          </span>
        </li>
        <li v-if="totalVolume">
          <span class="name">
            {{ $translate('total_volume_by_TI', 'general') }}
          </span>
          <span class="val">
            {{ totalVolume }} {{ $translate('t', 'orders') }}</span
          >
        </li>
        <li>
          <span class="name">
            {{ $translate('selected_delivery_dates', 'orders') }}
          </span>
          <draggable
            class="draggable-zone"
            :list="oldList"
            group="delivery_dates"
            @change="log"
            itemKey="name"
          >
            <template #item="{ element }">
              <Tag
                class="primary small draggable"
                icon="draggable"
                :text="parseQuote(element)"
              />
            </template>
          </draggable>
        </li>
      </ul>
      <ul class="block">
        <li>
          <span class="name-block">
            {{ $translate('new_TI', 'documents') }}
          </span>
        </li>
        <li v-if="totalVolume">
          <span class="name">
            {{ $translate('total_volume_by_TI', 'general') }}
          </span>
          <span class="val">
            {{ totalVolume }} {{ $translate('t', 'orders') }}</span
          >
        </li>
        <li>
          <span class="name">
            {{ $translate('selected_delivery_dates', 'orders') }}
          </span>
          <draggable
            class="draggable-zone"
            :list="newTi"
            group="delivery_dates"
            @change="log"
            itemKey="name"
          >
            <template #item="{ element }">
              <Tag
                class="primary small draggable"
                icon="draggable"
                :text="parseQuote(element)"
              />
            </template>
          </draggable>
        </li>
      </ul>
    </div>
    <div class="formWrap" :class="{ loading: loading }">
      <MyButton
        @click="submit"
        class=""
        :text="$translate('create_TI', 'documents')"
        tag="button"
        :dynamicSize="true"
        :disabled="disabledSubmit"
      />
      <MyButton
        @click="goBack"
        class=""
        :text="$translate('go_back', 'general')"
        tag="button"
        tagType="reset"
        type="cancel"
        :dynamicSize="true"
      />
    </div>
    <transition>
      <LoadingIcon v-if="loading" :absolute="true" />
    </transition>
  </Layout>
</template>

<script>
import draggable from 'vuedraggable';
import { mapGetters } from 'vuex';
import Layout from '../../PopupLayout';
import Tag from '@/components/Table/PageTable/Tag';

/**
 * The OrderCancellationRequest component.
 */
export default {
  name: 'TIDuplication',
  components: {
    Layout,
    draggable,
    Tag,
  },
  data() {
    return {
      loading: false,
      oldList: [],
      newTi: [],
    };
  },
  computed: {
    ...mapGetters(['IS_Mobile', 'GET_CurrentPopup', 'GET_PopupData']),
    gData() {
      return this.GET_PopupData?.[this.GET_CurrentPopup];
    },
    tiNumber() {
      return this.gData?.number;
    },
    totalVolume() {
      return [this.gData?.order?.product?.name, this.gData?.order?.quantity]
        .filter((item) => item)
        .join(', ');
    },
    gQuotas() {
      return this.gData?.quotas;
    },
    getID() {
      return this.gData?.id;
    },
    disabledSubmit() {
      return this.getID == null || !this.newTi.length;
    },
  },
  watch: {
    gQuotas: function (val) {
      this.oldList = val && val.length ? [...val] : [];
    },
  },
  methods: {
    async submit() {
      try {
        if (this.loading) return false;
        if (!this.getID) throw Error('getId is null');
        if (!this.newTi || !this.newTi.length)
          throw Error('newTi is empty array');
        this.loading = true;

        const reqestData = {
          currentTiId: this.getID,
          quotas: this.newTi.map((item) => {
            return {
              date: item.date,
              quantity: item.quantity,
            };
          }),
        };
        const { success = false, data = {} } =
          await this.$api.document.createTI({ data: reqestData });
        console.log('TI DUBLICATE', success, data);
        if (success) {
          this.$notice(this.$translate('ti_will_created'));
          document.dispatchEvent(new CustomEvent('update-instructions'));
        } else {
          this.$notice(this.$translate('request_error'), undefined, {
            type: 'error',
          });
        }
        this.loading = false;
        this.$popup.hide(undefined, () => this.resetForm());
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    goBack() {
      this.$popup.hide();
      this.resetForm();
    },
    resetForm() {
      this.oldList = [];
      this.newTi = [];
    },
    log(evt) {
      console.log(evt);
    },
    parseQuote({ date = null, quantity = null } = {}) {
      if (!date || !quantity) return '';
      return `${this.$moment(new Date(date)).format(
        'DD.MM.YY'
      )} - ${quantity} ${this.$translate('t', 'orders')}`;
    },
  },
  mounted() {
    document.addEventListener('popup-hide', this.resetForm);
  },
  beforeUnmount() {
    document.removeEventListener('popup-hide', this.resetForm);
  },
};
</script>
<style lang="scss" src="./style.scss"></style>
