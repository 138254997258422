import moment from "moment";

export default {
    namespaced: true,
    state: {
        costCalculation: null,
        checkout: null,
        loadingOrder: false,
    },
    getters: {
        GET_CostCalculation: (state) => state.costCalculation,
        GET_Checkout: (state) => state.checkout,
        GET_LoadingOrder: (state) => state.loadingOrder,
    },
    mutations: {
        SET_CostCalculation(state, payload) {
            state.costCalculation = payload;
        },
        SET_Checkout(state, payload) {
            state.checkout = payload;
        },
        SET_LoadingOrder(state, payload) {
            state.loadingOrder = payload;
        },
    },
    actions: {
        SET_CostCalculation({ commit }, [dateFrom, dateTo]) {
            const cost_calculation = window.$app.config.globalProperties.$translate('cost_calculation', 'orders');
            const text = cost_calculation
                .replace(/###/, moment(dateFrom).format("D.MM.YYYY"))
                .replace(/###/, moment(dateTo).format("D.MM.YYYY"))
            commit('SET_CostCalculation', text);
        },
        SET_Checkout({ commit }, payload) {
            commit('SET_Checkout', payload);
        }
    }
}