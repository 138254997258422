export default new class ScrollbarWidth {
    root = document.documentElement;
    getScrollbarWidth = () => {
        // Creating invisible container
        const outer = document.createElement('div');
        outer.style.visibility = 'hidden';
        outer.style.overflow = 'scroll'; // forcing scrollbar to appear
        outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
        document.body.appendChild(outer);
        // Creating inner element and placing it in the container
        const inner = document.createElement('div');
        outer.appendChild(inner);
        // Calculating difference between container's full width and the child width
        const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth);
        // Removing temporary elements from the DOM
        outer.parentNode.removeChild(outer);
        return document.body.clientHeight > window.innerHeight ? scrollbarWidth : 0;
    }
    set() {
        this.root.style.setProperty('--ScrollbarWidth', this.getScrollbarWidth() + "px");
    }
    init() {
        this.resizeFunc = this.resize.bind(this)
        window.addEventListener("resize", this.resizeFunc);
        this.set()
        setTimeout(() => this.set(), 250);
        setTimeout(() => this.set(), 500);
        setTimeout(() => this.set(), 1500);
        setTimeout(() => this.set(), 5000);
    }
    resize() {
        this.set()
    }
    destroy() {
        window.removeEventListener("resize", this.resizeFunc);
    }
}