import translate from './translate'

function formatMoney(amount, decimalCount = 2, decimal = ",", thousands = " ", toFixed = false) {
    // if (toFixed) amount = amount.toFixed(decimalCount)



    if (amount % 1 === 0 && !toFixed) decimalCount = 0
    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = amount < 0 ? "-" : "";

        let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
        let j = (i.length > 3) ? i.length % 3 : 0;
        return negativeSign +
            (j ? i.substr(0, j) + thousands : '') +
            i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
            (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
    } catch (e) {
        console.log(e)
        return amount
    }
}
export default (summ = 0, decimalCount, toFixed, decimal, thousands) => formatMoney(summ, decimalCount, decimal, thousands, toFixed) + ' ' + translate('uah');