<template>
  <PageTable
    class="bills"
    :class="{
      address: template == 'delivery-address',
      contacts: template == 'company-contacts',
    }"
    :loading="loading"
  >
    <template v-slot:thead>
      <template v-if="template == 'bills'">
        <th>
          <span
            class="controll"
            :class="[
              name,
              { active: $queryParams.parse(this.$route.query.sort).name },
            ]"
            @click="$emit('sort', { name: name || 'asc' })"
          >
            {{ $translate("account_name", "general") }}
            <ArrowSVG />
          </span>
        </th>
        <th>
          {{ $translate("MFO_Bank", "general") }}
        </th>
        <th>
          <span
            class="controll"
            :class="[
              bankName,
              { active: $queryParams.parse(this.$route.query.sort).bankName },
            ]"
            @click="$emit('sort', { bankName: bankName || 'asc' })"
          >
            {{ $translate("name_of_bank", "general") }}
            <ArrowSVG />
          </span>
        </th>
        <th>
          {{ $translate("IBAN_account", "general") }}
        </th>
        <th></th>
      </template>
      <template v-else-if="template == 'delivery-address'">
        <th>
          <span
            class="controll"
            :class="[
              name,
              { active: $queryParams.parse(this.$route.query.sort).name },
            ]"
            @click="$emit('sort', { name: name || 'asc' })"
          >
            {{ $translate("address_name", "general") }}
            <ArrowSVG />
          </span>
        </th>
        <th>
          {{ $translate("region", "orders") }}
        </th>
        <th>
          {{ $translate("city", "companies") }}
        </th>
        <th>
          {{ $translate("index", "general") }}
        </th>
        <th>
          {{ $translate("address") }}
        </th>
        <th></th>
      </template>
      <template v-else-if="template == 'company-contacts'">
        <th>
          <span
            class="controll"
            :class="[
              name,
              { active: $queryParams.parse(this.$route.query.sort).name },
            ]"
            @click="$emit('sort', { name: name || 'asc' })"
          >
            {{ $translate("full_name", "general") }}
            <ArrowSVG />
          </span>
        </th>
        <th>
          <span
            class="controll"
            :class="[
              role,
              { active: $queryParams.parse(this.$route.query.sort).role },
            ]"
            @click="$emit('sort', { role: role || 'asc' })"
          >
            {{ $translate("position", "general") }}

            <ArrowSVG />
          </span>
        </th>
        <th>
          {{ $translate("phone") }}
        </th>
        <th>
          {{ $translate("email", "auth") }}
        </th>
        <th></th>
      </template>
    </template>
    <template v-if="list && list.length" v-slot:default>
      <CounterpartiesItem
        :template="template"
        v-for="item in list"
        :key="item.id"
        :row="item"
        @removeItem="(type, id) => $emit('removeItem', type, id)"
      />
    </template>
  </PageTable>
</template>
<script>
import PageTable from "@/components/Table/PageTable";
import CounterpartiesItem from "./Item";
import ArrowSVG from "@/assets/img/arrow_top.svg";
export default {
  name: "CompaniesTable",
  components: { PageTable, ArrowSVG, CounterpartiesItem },
  props: {
    list: { default: () => [], type: Array },
    loading: { default: false, type: Boolean },
    name: { default: "asc", type: String },
    role: { default: "asc", type: String },
    bankName: { default: "asc", type: String },
    template: {
      default: "bills",
      type: String,
    },
  },
};
</script>
<style scoped src="./style.scss" lang="scss"></style>
