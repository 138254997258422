<template>
  <tr
    @click="click"
    :class="[
      'level_' + row.level,
      { hasInfo: row?.number, hasRelatedDocuments },
    ]"
    :data-id="row.id"
  >
    <td>
      <span class="date" v-if="currentDate">
        <span
          v-if="row.status"
          class="status"
          :style="{
            background: row.status.labelColor,
          }"
        ></span>
        {{ currentDate }}</span
      >
      <span v-else>–</span>
    </td>
    <td>
      <div class="TagWrap">
        <span
          class="line vert"
          v-if="row.level > 0 && row.last"
          ref="line"
        ></span>
        <span class="line hor" v-if="row.level >= 1"></span>
        <ArrowRight class="arrowRight" v-if="row.level === 2" />
        <Tag
          ref="tag"
          :text="documentTagText"
          class="small primary"
          :class="{
            primary_color_800: row.level === 0,
            primary_color_500: row.level === 1,
            primary_color_200: row.level === 2,
          }"
        />
      </div>
    </td>
    <td>
      <span class="additionalInformation" v-if="row.additionalInformation">{{
        row.additionalInformation
      }}</span>
      <span v-else>–</span>
    </td>
    <td>
      <template v-if="balanceType === 'trade'">
        <span class="amount" v-if="row.amount">{{ $price(row.amount) }}</span>
        <span v-else>–</span>
      </template>
      <template v-else-if="balanceType === 'volume'">
        <span class="quantity">{{
          row.quantity ? row.quantity + " т" : "–"
        }}</span>
      </template>
    </td>
    <td>
      <span class="finalSaldo">{{ finalSaldo }}</span>
    </td>
    <td>
      <Dropdown ref="dropdown" :contentClass="'padding--min'">
        <button @click="goIn" class="dropdown-btn" type="button">
          <PenIcon /> <span>{{ $translate("review") }}</span>
        </button>
      </Dropdown>
    </td>
  </tr>
</template>

<script>
// import Tag from "@/components/Table/PageTable/Tag";
import Dropdown from "@/components/Table/PageTable/Dropdown";
import PenIcon from "@/assets/img/pen-white.svg?.raw";
import ArrowRight from "@/assets/img/arrow_right_long2.svg?.raw";
import Tag from "../../PageTable/Tag";

/**
 * The DocumentsTableItem component.
 */
export default {
  name: "DocumentsTableItem",
  components: {
    Tag,
    Dropdown,
    PenIcon,
    ArrowRight,
  },
  props: {
    row: { default: () => {}, type: Object },
    orderId: { default: null, type: [String, Number] },
    balanceType: { default: null, type: String },
  },
  data() {
    return {
      parentTag: null,
      resizeFunc: null,
      throttle: this.$initThrottle(),
    };
  },
  computed: {
    hasRelatedDocuments() {
      return this.row.relatedDocuments && this.row.relatedDocuments.length > 0;
    },
    currentDate() {
      if (!this.row.date) return false;
      return this.$moment(this.row.date).format("DD.MM.YY");
    },
    documentTagText() {
      if (this.row?.documentType?.name) {
        return this.row.documentType.name + " " + this.row.documentNumber;
      }
      return this.row.documentNumber;
    },
    finalSaldo() {
      if (!this.row.saldo) return "–";
      if (this.balanceType === "trade") return this.$price(this.row.saldo);
      if (this.balanceType === "volume") return this.row.saldo + " т";
      return this.row.saldo;
    },
  },
  methods: {
    encodeURI(id) {
      return id ? encodeURI(id) : "#";
    },
    click(e) {
      try {
        const cancelClass = this.$refs.dropdown
          ? this.$refs.dropdown.$el.classList.toString()
          : ".Dropdown";
        let cancelTarget = e.target.closest(`.${cancelClass}`);
        if (cancelTarget) return null;
        return this.goIn();
      } catch (e) {
        console.log(e);
      }
    },
    goIn() {
      const id = this.row?.crmId || null;
      if (!id) return null;
      return this.$router.push({ name: "documents-item", params: { id } });
    },
    setLinePos() {
      const parent = this.$offset(this.parentTag, true);
      const current = this.$offset(this.$refs.tag.$el, true);
      const height = current.top - parent.top;
      this.$refs.line.style.height = height + "px";
    },
    resize() {
      this.setLinePos();
      this.throttle.init(() => {
        this.setLinePos();
      }, 500);
    },
  },
  mounted() {
    if (this.row.last && this.row.level > 0) {
      this.resizeFunc = this.resize.bind(this);
      this.parentTag = this.$el
        .closest("table")
        .querySelector(`tr[data-id="${this.row.parentId}"] .Tag`);
      this.resize();
      window.addEventListener("resize", this.resizeFunc, { passive: true });
      this.emitter.on("openOrderAccordion", this.resizeFunc);
    }
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.resizeFunc, { passive: true });
    this.emitter.off("openOrderAccordion");
  },
};
</script>

<style lang="scss" scoped src="./component.scss"></style>
