// middleware
import middlewarePipeline from '@/middleware/middlewarePipeline';
import auth from '@/middleware/auth';
import store from './store';
// router
import { createWebHistory, createRouter } from 'vue-router';
// pages
import Home from '@/pages/';
import Login from '@/pages/Login';
import PasswordRecovery from '@/pages/Login/PasswordRecovery';
import PasswordRecoveryCreate from '@/pages/Login/PasswordRecoveryCreate';
import Registration from '@/pages/Registration';
import RegistrationSendOTP from '@/pages/Registration/SendOTP';
import RegistrationSuccess from '@/pages/Registration/Success';
import Dashboard from '@/pages/Dashboard';
import Payments from '@/pages/Dashboard/Payments';
import PaymentsSchedule from '@/pages/Dashboard/Payments/Schedule';
import Faq from '@/pages/Dashboard/Faq';
import Order from '@/pages/Dashboard/Order';
import OrderCategory from '@/pages/Dashboard/Order/Category';
import OrderCheckout from '@/pages/Dashboard/Order/Checkout';
import OrderProduct from '@/pages/Dashboard/Order/Category/Product';
import Orders from '@/pages/Dashboard/Orders';
import FinalBalance from '@/pages/Dashboard/Orders/Balance';
import Packages from '@/pages/Dashboard/Packages';
import PackagesSchedule from '@/pages/Dashboard/Packages/Schedule';
import PackagesMap from '@/pages/Dashboard/Packages/Map';
import PriceList from '@/pages/Dashboard/PriceList';
import Documents from '@/pages/Dashboard/Documents';
import DocumentsItem from '@/pages/Dashboard/Documents/Item';
import Companies from '@/pages/Dashboard/Companies';
import CompaniesUpsertArea from '@/pages/Dashboard/Companies/UpsertArea';
import Profile from '@/pages/Dashboard/Profile';
import Page404 from '@/pages/Page404';
import Notifications from '@/pages/Dashboard/Notifications';
import NotificationsChat from '@/pages/Dashboard/Notifications/Chat';
import Company from '@/pages/Dashboard/Companies/Company';
import CompanyBills from '@/pages/Dashboard/Companies/Company/Bills';
import AddCompanyBills from '@/pages/Dashboard/Companies/Company/Bills/Add';
import CompanyDeliveryAddress from '@/pages/Dashboard/Companies/Company/DeliveryAddress';
import AddCompanyDeliveryAddress from '@/pages/Dashboard/Companies/Company/DeliveryAddress/Add';
import CounterpartiesContacts from '@/pages/Dashboard/Companies/Company/Contacts';
import AddCompanyContacts from '@/pages/Dashboard/Companies/Company/Contacts/Add';
import AddCompany from '@/pages/Dashboard/Companies/Add';
import MyOrder from '@/pages/Dashboard/Orders/Order';
import AccompanyingDocuments from '@/pages/Dashboard/Orders/Order/Documents';
import TransportInstructions from '@/pages/Dashboard/Orders/Order/Instructions';
import TransportInstruction from '@/pages/Dashboard/Orders/Order/Instructions/Instruction';

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/login/password-recovery',
    name: 'password-recovery',
    component: PasswordRecovery,
  },
  {
    path: '/login/password-recovery-create',
    name: 'password-recovery-create',
    component: PasswordRecoveryCreate,
  },
  {
    path: '/registration',
    name: 'registration',
    component: Registration,
  },
  {
    path: '/registration/send-otp',
    name: 'registration-send-otp',
    component: RegistrationSendOTP,
  },
  {
    path: '/registration/success',
    name: 'registration-success',
    component: RegistrationSuccess,
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      middleware: [auth],
    },
    children: [
      {
        path: 'order',
        name: 'order',
        component: Order,
        children: [
          {
            path: 'category/:category',
            name: 'order-category',
            component: OrderCategory,
            props: { orderCategory: true },
            children: [
              {
                path: ':product',
                name: 'order-product',
                component: OrderProduct,
                props: { orderCategory: true },
              },
            ],
          },
          {
            path: 'specials/:category',
            name: 'order-specials',
            component: OrderCategory,
            props: { orderSpecials: true },
            children: [
              {
                path: ':product',
                name: 'order-specials-product',
                component: OrderProduct,
                props: { orderSpecials: true },
              },
            ],
          },
          {
            path: 'checkout',
            name: 'checkout',
            component: OrderCheckout,
          },
        ],
      },
      {
        path: 'orders',
        name: 'orders',
        component: Orders,
        children: [
          {
            path: 'final-balance',
            name: 'final-balance',
            component: FinalBalance,
          },
          {
            path: ':id',
            name: 'my-order',
            component: MyOrder,
            children: [
              {
                path: 'documents',
                name: 'accompanying-documents',
                component: AccompanyingDocuments,
              },
              {
                path: 'instructions',
                name: 'my-order-transport-instructions',
                component: TransportInstructions,
                children: [
                  {
                    path: ':tiID',
                    name: 'my-order-transport-instruction',
                    component: TransportInstruction,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: 'price-list',
        name: 'price-list',
        component: PriceList,
      },
      {
        path: 'documents',
        name: 'documents',
        component: Documents,
        children: [
          {
            path: ':id',
            name: 'documents-item',
            component: DocumentsItem,
          },
        ],
      },
      {
        path: 'companies',
        name: 'companies',
        component: Companies,

        children: [
          {
            path: 'create-area',
            name: 'create-area',
            component: CompaniesUpsertArea,
          },
          {
            path: 'area/:id',
            name: 'area-edit',
            component: CompaniesUpsertArea,
          },
          {
            path: 'add-company',
            name: 'add-company',
            component: AddCompany,
          },
          {
            path: ':id',
            name: 'company',
            component: Company,
            children: [
              {
                path: 'bills',
                name: 'company-bills',
                component: CompanyBills,
                children: [
                  {
                    path: 'add',
                    name: 'add-company-bills',
                    component: AddCompanyBills,
                  },
                  {
                    path: 'edit/:itemId',
                    name: 'edit-company-bills',
                    component: AddCompanyBills,
                  },
                ],
              },
              {
                path: 'delivery-address',
                name: 'delivery-address',
                component: CompanyDeliveryAddress,
                children: [
                  {
                    path: 'add',
                    name: 'add-delivery-address',
                    component: AddCompanyDeliveryAddress,
                  },
                  {
                    path: 'edit/:itemId',
                    name: 'edit-delivery-address',
                    component: AddCompanyDeliveryAddress,
                  },
                ],
              },
              {
                path: 'contacts',
                name: 'company-contacts',
                component: CounterpartiesContacts,
                children: [
                  {
                    path: 'add',
                    name: 'add-company-contacts',
                    component: AddCompanyContacts,
                  },
                  {
                    path: 'edit/:itemId',
                    name: 'edit-company-contacts',
                    component: AddCompanyContacts,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: 'packages',
        name: 'packages',
        component: Packages,
        children: [
          {
            path: 'schedule',
            name: 'packages-schedule',
            component: PackagesSchedule,
          },
          {
            path: 'map',
            name: 'packages-map',
            component: PackagesMap,
          },
        ],
      },
      {
        path: 'payments',
        name: 'payments',
        component: Payments,
        children: [
          {
            path: 'schedule',
            name: 'payments-schedule',
            component: PaymentsSchedule,
          },
        ],
      },
      {
        path: 'faq',
        name: 'faq',
        component: Faq,
      },
      {
        path: 'profile',
        name: 'profile',
        component: Profile,
      },
      {
        path: 'notifications',
        name: 'notifications',
        component: Notifications,
        children: [
          {
            path: 'chat/:id',
            name: 'chat',
            component: NotificationsChat,
          },
        ],
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: Page404,
    meta: {
      middleware: [auth],
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.name == from.name && to.name == 'companies') return false;
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
    return next();
  }
  const middleware = to.meta.middleware;

  const context = {
    to,
    from,
    next,
    store,
  };

  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1),
  });
});

export default router;
