<template>
  <div class="promotional-offers">
    <img v-if="icon" :src="icon" :alt="'icon'" width="60" height="60" />
    <span v-if="gname" class="name">{{ gname }}</span>
    <button
      v-if="isEditable"
      @click="$emit('menuClick')"
      class="action"
      type="button"
      aria-label="action">
      <DotsSvg />
    </button>
  </div>
</template>
<script>
const apiUrl = process.env.VUE_APP_API_DOMAIN;
import DotsSvg from '@/assets/img/dots.svg';
export default {
  name: 'PromotionalOffers',
  components: {
    DotsSvg,
  },
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    gname() {
      return this.data?.marketingProduct?.name;
    },
    isEditable() {
      return this.data?.editable;
    },
    icon() {
      const icon =
        this.data?.marketingProduct?.icon?.formats?.small?.url ||
        this.data?.marketingProduct?.icon?.formats?.medium?.url ||
        this.data?.marketingProduct?.icon?.formats?.medium?.url;
      return icon ? apiUrl + icon : null;
    },
  },
  mounted() {
    console.log(
      '🚀 ~ file: index.vue ~ line 47 ~ PromotionalOffers ~ created ~ this.data',
      this.data
    );
  }
};
</script>
<style scoped src="./style.scss" lang="scss"></style>
