const vhCheck = require('vh-check')

export default {
    // namespaced: true,
    state: {
        windowWidth: 375,
        windowHeight: 540,
    },
    getters: {
        GET_WindowWidth: (state) => state.windowWidth,
        GET_WindowHeight: (state) => state.windowHeight,
        IS_Notebook: (state) => state.windowWidth <= 1480,
        IS_Tablet: (state) => state.windowWidth <= 1100,
        IS_Tablet2: (state) => state.windowWidth <= 992,
        IS_Mobile: (state) => state.windowWidth <= 540,
    },
    mutations: {
        SET_WindowSize(state, [width, height]) {
            state.windowWidth = width;
            state.windowHeight = height;
        },
    },
    actions: {
        SET_WindowSize({ commit }) {
            commit('SET_WindowSize', [window.innerWidth, window.innerHeight])
            let check = vhCheck()
            const root = document.documentElement;
            root.style.setProperty('--deltaVh', check.vh - check.windowHeight + "px");
            root.style.setProperty('--vh', check.vh + "px");
            root.style.setProperty('--windowHeight', (check.windowHeight - 1) + "px");
        }
    }
}