<template>
  <div class="ProductCard" :class="['type_' + type, 'view_' + viewType]">
    <div class="imageWrap">
      <component
        :is="link ? 'router-link' : 'div'"
        :to="link"
        class="image"
        :class="{ simpleLink: link }"
        :style="{ background: data.backgroundColor }"
      >
        <img
          :src="getImageUrl(data.image.url)"
          :alt="data.image.alternativeText"
          v-if="data.image"
        />
      </component>
      <component
        :is="link ? 'router-link' : 'div'"
        :to="link"
        class="wrap title"
        :class="{ simpleLink: link }"
        v-if="viewType === 'list'"
      >
        <h3 class="h6">{{ data.name }}</h3>
      </component>
    </div>

    <div class="content" :class="viewType">
      <div class="wrap top" v-if="viewType === 'grid'">
        <h3 class="h4">{{ data.name }}</h3>
        <p
          v-if="data.description && type == 'category'"
          v-html="data.description"
        ></p>
        <!-- elements -->
        <ul
          class="consistList"
          v-if="data?.elements?.length && type != 'category'"
        >
          <li v-for="item in data.elements" :key="item.id">
            <Tag :text="item.element" class="primary_500 medium" />
            <span>-</span> {{ $prefix(item.amount) }}
          </li>
        </ul>
      </div>
      <div v-else class="list top">
        <ul class="consistList" v-if="data?.elements?.length">
          <li v-for="item in data.elements" :key="item.id">
            <Tag :text="item.element" class="primary_500 medium" />
            <span>-</span> {{ $prefix(item.amount) }}
          </li>
        </ul>
        <div class="consistList" v-else></div>
      </div>
      <!-- elements -->
      <div class="wrap middle">
        <div class="wrap packaging" v-if="type == 'primary' && clearOptions">
          <LabelDropdown
            v-if="clearOptions"
            name="packaging"
            :title="$translate('packaging', 'orders')"
            :list="clearOptions"
            :active="activeOption"
            @change="changeDropdown"
          />
          <MyButton
            type="primary"
            icon="busket"
            class="orderButton"
            :disabled="!active"
            @click="goCheckout"
          />
        </div>
        <div class="wrap bottom" v-if="link || (data?.notes && !active)">
          <MyButton
            :text="currentButtonText"
            :class="[type === 'primary' ? 'w100' : 'w283']"
            v-if="link"
            tag="router-link"
            :link="link"
            :type="type === 'category' ? 'primary' : 'outline-green'"
          />
          <p
            class="notice"
            v-html="data.notes"
            v-if="data?.notes && !active"
          ></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Tag from '@/components/Table/PageTable/Tag';
/**
 * The ProductCard component.
 */
export default {
  name: 'ProductCard',
  components: { Tag },
  props: {
    data: { default: null, type: Object },
    link: { default: null, type: [Object, Boolean] },
    buttonText: { default: null, type: String },
    /**
     * Visual view.
     * @values grid, list
     */
    viewType: { default: 'grid', type: String },
    /**
     * The card type.
     * @values primary, category
     */
    type: { default: 'primary', type: String },
    active: { default: true, type: Boolean },
    currentSpecials: { default: null, type: Object },
    currentCategory: { default: null, type: Object },
  },
  data() {
    return {
      activeOption: null,
    };
  },
  computed: {
    currentButtonText() {
      if (this.buttonText) return this.buttonText;
      return this.$translate('product_page', 'orders');
    },
    clearOptions() {
      if (!this.data?.packaging?.length) return null;
      return this.data.packaging.map(({ id, name, crmId, product }) => ({
        key: id,
        name,
        crmId,
        product,
      }));
    },
  },
  methods: {
    changeDropdown(key, name, item) {
      this.activeOption = item;
    },
    getImageUrl(url = '') {
      return process.env.VUE_APP_API_DOMAIN + url;
    },
    goCheckout() {
      try {
        const query = this.$queryParams.getClear({
          specials: this.currentSpecials?.slug,
          category: this.currentCategory?.slug,
          suGoodWithoutPackaging: this.data.crmId,
          packagingId: this.activeOption?.crmId,
          // packagingName: this.activeOption.name,
          // productName: this.activeOption.product.name,
          productId: this.activeOption?.product?.crmId,
          id: this.activeOption?.product?.id,
        });
        this.$order.set(query);
        this.$router.push({ name: 'checkout' });
      } catch (e) {
        console.log(e);
      }
    },
  },
  created() {},
  mounted() {
    if (this.clearOptions) {
      this.activeOption = this.clearOptions[0];
    }
  },
};
</script>

<style lang="scss" scoped src="./component.scss"></style>
