<template>
  <div class="volume-data shadowX-box">
    <h4 class="small title">{{ $translate('volume_date', 'documents') }}</h4>
    <ul class="box--ul">
      <li v-if="totalVolume">
        <div class="flex alignCenter">
          <span class="name">{{ $translate('total_volume', 'orders') }}</span>
          <Tippy :text="$translate('transportInstruction_gquotas', 'orders')" />
        </div>
        <span class="val big"
          >{{ totalVolume }} {{ $translate('t', 'orders') }}</span
        >
      </li>
      <!-- shipmentProgress -->
      <li v-if="shipmentProgress && shipmentProgress.quantity != 0">
        <div class="flex alignCenter">
          <span class="name">{{
            $translate('shipment_status', 'orders')
          }}</span>
          <Tippy :text="$translate('volumeData_shipment_status', 'orders')" />
        </div>
        <DeliveryStatus
          :quantity="shipmentProgress.quantity"
          :quantityInTransit="shipmentProgress.quantityInTransit"
          :quantityOnStation="shipmentProgress.quantityOnStation"
          :quantityShipped="shipmentProgress.quantityShipped"
          :quantityInStock="shipmentProgress.quantityInStock"
        />
      </li>

      <li v-if="type == 'cpt'">
        <MyButton
          class="w100"
          tag="button"
          type="outline-yellow"
          :text="$translate('view_on_map', 'documents')"
          icon="mapMarker"
          :dynamicSize="true"
        />
      </li>
      <!-- delivery date -->
      <li v-if="gquotas && gquotas.length">
        <div class="flex alignCenter marg-bot--3">
          <span class="name">{{
            $translate('preferred_delivery_dates', 'orders')
          }}</span>
          <Tippy :text="$translate('volumeData_gquotas', 'orders')" />
        </div>
        <div class="flex alignCenter wrap">
          <Tag
            type="primary"
            :key="item.id"
            v-for="item in gquotas"
            class="small"
            :text="parseQuote(item)"
          />
          <!-- <span class="count">+2</span> -->
          <Tippy :text="$translate('volumeData_gquotas2', 'orders')" />
        </div>
      </li>
    </ul>
    <div class="action">
      <MyButton
        v-if="editQouta"
        class="w100"
        tag="button"
        type="outline-yellow"
        :text="$translate('editable_qoutas', 'general')"
        :dynamicSize="true"
        @click="$popup.show('DeliverySchedule')"
      />
      <!-- <MyButton
        class="w100"
        tag="button"
        type="outline-yellow"
        :text="$translate('view_details', 'orders')"
        :dynamicSize="true"
      /> -->
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import Tag from '@/components/Table/PageTable/Tag';
import Tippy from '@/components/Tippy';
import DeliveryStatus from '@/components/DeliveryStatus';
export default {
  name: 'VolumeData',
  components: {
    Tag,
    Tippy,
    DeliveryStatus,
  },
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
    orderData: {
      type: Object,
      default: null,
    },
    quotas: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      type: undefined,
    };
  },
  methods: {
    ...mapActions('checkout', ['SET_Checkout']),
    parseQuote({ date = null, quantity = null } = {}) {
      if (!date || !quantity) return '';
      return `${this.$moment(new Date(date)).format(
        'DD.MM.YY'
      )} - ${quantity} ${this.$translate('t', 'orders')}`;
    },
  },
  computed: {
    shipmentProgress() {
      return {
        quantity: this.data?.quantity,
        quantityOnStation: this.data?.quantityOnStation,
        quantityInTransit: this.data?.quantityInTransit,
        quantityInStock: this.data?.quantityInStock,
      };
    },
    gquotas() {
      return this.data?.quotas;
    },
    totalVolume() {
      return [this.data?.order?.product?.name, this.data?.order?.quantity]
        .filter((item) => item)
        .join(', ');
    },
    editQouta() {
      return this.data?.actions?.editQuota;
    },
  },
};
</script>
<style scoped src="./style.scss" lang="scss"></style>
