export default ($axios) => {
    return {
        async getList() {
            return await $axios({
                url: '/faqs',
                method: 'get',
            }).then(({ data }) => {
                return { success: true, list: data.data }
            }).catch(e => e)
        },
        async askQuestion(data) {
            return await $axios({
                url: '/request/faq',
                method: 'post',
                data: data
            }).then(({ data }) => {
                return { success: true, data: data.data }
            }).catch(e => {
                return { success: false, error: e, list: [] }
            })
        },
    }
}