<template>
  <MetaTags>
    <title>{{ title }}</title>
  </MetaTags>
  <Header />
  <div
    class="dashboard-layout"
    id="dashboard-layout"
    :class="{ minimized: GET_MinimizedSidebar }"
  >
    <Sidebar />
    <div class="wrapper" :class="[name + '-page', customClass]">
      <div
        class="container medium container_without_padding maxWidth"
        :class="{ errorNow: GET_ErrorPage }"
      >
        <div class="page-content">
          <template v-if="GET_ErrorPage">
            <Content404
              :code="GET_ErrorPage.code"
              :message="GET_ErrorPage.message"
            />
          </template>
          <template v-else>
            <slot />
          </template>
        </div>
      </div>
      <Footer />
    </div>
  </div>
  <Modals />
  <notifications
    :max="3"
    width="100%"
    group="foo"
    position="top center"
    classes="vue-notification-template vue-notification notification"
  />
</template>

<script>
import { mapGetters } from "vuex";
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import Sidebar from "@/components/Sidebar";
import Modals from "@/components/Modals";
import Content404 from "@/components/Content404";
/**
 * The dashboard-layout component.
 */
export default {
  name: "dashboard-layout",
  components: { Header, Footer, Sidebar, Modals, Content404 },
  props: {
    name: { default: "inner", type: String },
    customClass: { default: null, type: [String, Object] },
  },
  data() {
    return {
      title: "OSTCHEM RETAIL",
    };
  },
  computed: {
    ...mapGetters(["GET_MinimizedSidebar", "GET_ErrorPage"]),
  },
  watch: {
    "$route.path": function (val, oldVal) {
      if (this.GET_ErrorPage && val != oldVal)
        return this.$store.dispatch("SET_ErrorPage", null);
    },
  },
  created() {
    this.$store.dispatch("DETECT_MinimizedSidebar");
  },
  mounted() {
    this.$scrollbarWidth.init();
  },
  beforeUnmount() {
    this.$scrollbarWidth.destroy();
  },
};
</script>
<style lang="scss">
.dashboard-layout {
  padding-left: toRem(253);
  transition: $transition;
  .wrapper {
    min-height: var(--windowHeight);
    padding-top: toRem(125);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-right: toRem(21);
    & > .container {
      flex: 1;
    }
  }
  &.minimized {
    padding-left: toRem(121);
  }
  .errorNow {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: toRem(20);
  }
  @media (max-width: $notebook) {
    &.minimized {
      padding-left: toRem(160);
    }
    .wrapper {
      padding-top: toRem(90);
      padding-right: toRem(37);
    }
    padding-left: toRem(269);
  }
  @media (max-width: $tablet) {
    padding-left: 0;
    &.minimized {
      padding-left: 0;
    }
    .wrapper {
      padding-right: 0;
    }
  }
  @media (max-width: $mobile) {
    .wrapper {
      padding-top: toRem(105);
    }
  }
}
</style>
