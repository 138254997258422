<template>
  <div class="cargoBlock">
    <div class="roW">
      <h2 class="title" v-if="name">{{ name }}</h2>
    </div>
    <div v-if="list && list.length" class="scroll-box">
      <div class="box">
        <ul class="list">
          <li class="top">
            <div class="ti">
              {{ $translate('transport_instructions', 'orders') }}
            </div>
            <div class="status">
              {{ $translate('delivery_status', 'orders') }}
            </div>
          </li>
          <li :key="i" v-for="(item, i) in list">
            <div class="ti">
              <div v-if="item.transportInstructionName" class="roW">
                <span class="number">{{ item.transportInstructionName }}</span>
                <Tippy
                  :text="$translate('dashboard_cargoLocation_TI', 'notice')"
                />
              </div>
              <div
                v-if="
                  getData(item, ['productName', 'quantity']) ||
                  item.deliveryCondition
                "
                class="roW"
              >
                <span v-if="getData(item, ['productName', 'quantity'])"
                  >{{ getData(item, ['productName', 'quantity']) }} т</span
                >
                <span class="conditions" v-if="item.deliveryCondition">{{
                  item.deliveryCondition
                }}</span>
              </div>
            </div>
            <div class="status">
              <!-- FROM -->
              <div v-if="check(item.quantityOnWarehouse)" class="part orange">
                <div class="name">
                  <template v-if="item.stock">
                    <span>{{ item.stock }}</span>
                    <Tippy
                      :text="
                        $translate(
                          'dashboard_delStatus_quantityOnWarehouse',
                          'notice'
                        )
                      "
                    />
                  </template>
                </div>
                <Icon />
                <div v-if="check(item.quantityOnWarehouse)" class="quantity">
                  <span>{{ item.quantityOnWarehouse }} т</span>
                  {{ $translate('is_expected', 'dashboard') }}
                </div>
              </div>
              <!-- FROM -->

              <!-- TRANSIT -->
              <div v-if="check(item.quantityInTransit)" class="part blue">
                <div v-if="check(item.wagonsInTransit)" class="name">
                  <span>{{ item.wagonsInTransit }}</span>&nbsp;
                  {{ decl(item.deliveryCondition, item.wagonsInTransit) }}
                  <Tippy
                    :text="
                      $translate(
                        'dashboard_delStatus_quantityInTransit',
                        'notice'
                      )
                    "
                  />
                </div>
                <Icon
                  :name="item.deliveryCondition === 'DDP' ? 'car' : 'wagon'"
                  pos="center"
                />
                <div v-if="check(item.quantityInTransit)" class="quantity">
                  <span>{{ item.quantityInTransit }} т</span>
                  {{ $translate('in_transit', 'documents') }}
                </div>
              </div>
              <!-- TRANSIT -->

              <!-- ON STATION -->
              <div v-if="check(item.quantityOnStation)" class="part blue">
                <div v-if="check(item.wagonsShipped)" class="name">
                  <span
                    >{{ item.wagonsShipped }}&nbsp;
                    {{ decl(item.deliveryCondition, item.wagonsShipped) }}</span
                  >
                  <Tippy
                    :text="
                      $translate(
                        'dashboard_delStatus_quantityOnStation',
                        'notice'
                      )
                    "
                  />
                </div>
                <Icon
                  :name="item.deliveryCondition === 'DDP' ? 'car' : 'wagon'"
                  pos="center"
                />
                <div v-if="check(item.quantityOnStation)" class="quantity">
                  <span>{{ item.quantityOnStation }} т</span>
                  {{ $translate('onStation', 'dashboard') }}
                </div>
              </div>
              <!-- ON STATION -->

              <!-- TO -->
              <div v-if="check(item.quantityShipped)" class="part green">
                <div v-if="item.destinationStation" class="name">
                  <span>{{ item.destinationStation }}</span>&nbsp;
                  <Tippy
                    :text="
                      $translate(
                        'dashboard_delStatus_quantityShipped',
                        'notice'
                      )
                    "
                  />
                </div>
                <Icon name="finish" pos="end" />
                <div v-if="check(item.quantityShipped)" class="quantity">
                  <span>{{ item.quantityShipped }} т</span>
                  {{ $translate('sent', 'orders') }}
                </div>
              </div>
              <!-- TO -->
            </div>
          </li>
        </ul>
      </div>
    </div>
    <router-link
      class="btn Button"
      :to="{ name: 'packages', href: '/dashboard/packages' }"
    >
      <span>{{ this.$translate('view_all_deliveries', 'dashboard') }}</span>
      <IconSvg />
    </router-link>
  </div>
</template>
<script>
import Tippy from '@/components/Tippy';

import Icon from './icon.vue';
import IconSvg from '@/assets/img/chartArrowRight.svg';

export default {
  name: 'CargoBlock',
  components: {
    Tippy,
    Icon,
    IconSvg,
  },
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    name() {
      return this.data?.name;
    },
    list() {
      return this.data?.data;
    },
  },
  methods: {
    check(value) {
      return value !== null;
    },
    getData(data, keys = [], shema = ', ') {
      try {
        if (!data || !keys.length) return null;
        return keys
          .map((key) => data?.[key])
          .filter((item) => item)
          .join(shema);
      } catch (e) {
        console.log(e);
      }
    },
    decl(type, number) {
      try {
        const one =
          type === 'DDP'
            ? this.$translate('car_one', 'dashboard')
            : this.$translate('wagon_one', 'dashboard');
        const two =
          type === 'DDP'
            ? this.$translate('car_two', 'dashboard')
            : this.$translate('wagon_two', 'dashboard');
        const five =
          type === 'DDP'
            ? this.$translate('car_five', 'dashboard')
            : this.$translate('wagon_five', 'dashboard');

        if (number === 0) {
          return five;
        }

        let n = Math.abs(number);
        n %= 100;
        if (n >= 5 && n <= 20) {
          return five;
        }
        n %= 10;
        if (n === 1) {
          return one;
        }
        if (n >= 2 && n <= 4) {
          return two;
        }
      } catch (e) {
        console.log(e);
      }
    },
    shipmentType(val) {
      switch (val) {
        case 'DDP':
          return this.$translate('car_short', 'dashboard');
        default:
          return this.$translate('car_short', 'dashboard');
      }
    },
  },
};
</script>
<style scoped lang="scss">
.cargoBlock {
  box-shadow: -5px -5px 20px rgba(255, 255, 255, 0.8),
    5px 5px 10px rgba(166, 171, 189, 0.3);
  border-radius: toRem(20);
  border: toRem(1) solid rgba(255, 255, 255, 0.4);
  padding: toRem(14) toRem(34) toRem(26) toRem(30);
  background: $gray_100_purple;
  position: relative;
  @media (max-width: $tablet2) {
    padding: toRem(19) toRem(20);
  }
  @media (max-width: $mobile) {
    padding: toRem(16) toRem(20);
  }

  .scroll-box {
    @media (max-width: $tablet2) {
      margin: 0 toRem(-20);
      overflow: hidden;
      width: calc(100% + toRem(40));
      .box {
        padding: 0 toRem(20);
        overflow: auto;
        width: auto;
      }
    }
  }
  .roW {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .title {
    font-weight: 500;
    font-size: toRem(36);
    line-height: toRem(41);
    color: $gray_800;
    margin: 0;
    @media (max-width: $mobile) {
      line-height: toRem(41);
      font-size: toRem(32);
    }
  }
  .list {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    margin-bottom: toRem(20);
    li {
      display: flex;
      align-items: center;
      margin-bottom: toRem(28);
      @media (max-width: $notebook) {
        margin-bottom: toRem(24);
      }

      &:last-child {
        margin-bottom: 0;
      }
      &.top {
        font-weight: 450;
        font-size: toRem(14);
        line-height: toRem(14);
        padding: toRem(12) 0 toRem(11) 0;
        border-bottom: toRem(1) solid #ebebeb;
        color: $gray_700;
        border-bottom: toRem(1) solid #dfdfdf;
        margin-bottom: toRem(15);
        @media (max-width: $notebook) {
          padding-top: toRem(16);
          padding-bottom: toRem(6);
          margin-bottom: toRem(18);
        }
      }
      .ti {
        width: toRem(420);
        padding-right: toRem(19);
        flex-shrink: 0;
        @media (max-width: $notebook) {
          width: toRem(232);
        }
        .roW {
          margin-bottom: toRem(5);
          flex-wrap: wrap;
          &:last-child {
            margin-bottom: 0;
          }
        }
        .number {
          font-size: toRem(16);
          line-height: toRem(21);
          margin-right: toRem(6);
          font-weight: 500;
          &:last-child {
            margin-right: 0;
          }
        }
        font-weight: 400;
        font-size: toRem(14);
        line-height: toRem(18);
        color: $gray_700;
        .conditions {
          padding: toRem(2) toRem(8);
          background: $gray_300;
          border-radius: toRem(41);
          font-weight: 400;
          font-size: toRem(14);
          line-height: toRem(18);
          color: $white;
          margin-left: toRem(12);
          &:first-child {
            margin-left: 0;
          }
        }
        ::v-deep(.Notice) {
          .InfoIcon {
            path {
              fill: $gray;
            }
          }
        }
      }
      .status {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media (max-width: $tablet2) {
          width: toRem(746);
          flex-shrink: 0;
          padding-right: toRem(20);
        }
        .part {
          display: flex;
          flex-direction: column;
          position: relative;
          align-items: center;
          text-align: center;
          width: 100%;
          flex-grow: 1;
          min-width: 25%;
          &.optPadd {
          }
          .name {
            display: flex;
            align-items: end;
            font-size: toRem(12);
            color: $gray_400;
            line-height: 100%;
            height: 4ch;
            min-height: 2ch;
            ::v-deep(.Notice) {
              margin-left: toRem(4);
              .InfoIcon {
                path {
                  fill: $gray_400;
                }
              }
              .content {
                left: auto;
                right: 100%;
              }
            }
          }
          ::v-deep(.iconBox) {
            margin: toRem(6) 0;
            @media (max-width: $notebook) {
              margin-bottom: toRem(9);
            }
          }
          .quantity {
            font-size: toRem(12);
            line-height: 100%;
            color: $gray_400;
            @media (max-width: $notebook) {
              font-weight: 400;
            }
            text-transform: lowercase;
            span {
              font-weight: 600;
              display: block;
              @media (max-width: $notebook) {
                padding-bottom: toRem(2);
              }
            }
          }
          &:first-child {
            align-items: flex-start;
            text-align: start;
          }
          &:last-child {
            align-items: flex-end;
            text-align: end;
          }
          &.orange {
            .quantity span {
              color: $orange;
            }
            ::v-deep(.iconBox) {
              &::before {
                background: $orange;
              }
            }
          }
          &.green {
            .quantity span {
              color: #55a832;
            }
            ::v-deep(.iconBox) {
              &::before {
                background: #55a832;
              }
            }
          }
          &.blue {
            .quantity span {
              color: $blue;
            }
            ::v-deep(.iconBox) {
              &::before {
                background: $blue;
              }
            }
          }
        }
      }
      .roW {
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
    }
  }

  .btn.Button {
    margin-top: auto;
    font-weight: 450;
    font-size: toRem(14);
    line-height: 100%;
    color: $gray_900;
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    &::before {
      content: none;
    }
    span {
      font-weight: inherit;
      font-size: inherit;
      line-height: inherit;
      text-decoration: none;
    }
    svg {
      width: toRem(16);
      height: toRem(16);
      object-fit: contain;
      margin-left: toRem(5);
    }
  }
}
</style>
