import moment from "moment";
import queryString from 'query-string';
export default {
    getClear(params, returnUndefinedIsEmpty = false, encodeDate = false) {
        const clearParams = Object.keys(params).reduce((acc, key) => {
            if (params[key]) {
                if (encodeDate && (key === 'date' || key === 'documentValidToDate')) {
                    acc[key] = moment(params[key], "DD/MM/YYYY").format("YYYY-MM-DD")
                } else {
                    acc[key] = params[key];
                }
            } else if (typeof params[key] == "object") {
                const innerObj = {};
                for (const innerKey in params[key]) {
                    if (params[key][innerKey]) innerObj[innerKey] = params[key][innerKey];
                }
                if (Object.keys(innerObj).length) {
                    acc[key] = innerObj;
                }
            }
            return acc;
        }, {});

        if (returnUndefinedIsEmpty) return Object.keys(clearParams).length ? clearParams : undefined;
        return clearParams;
    },
    reverseParams(params) {
        for (const key in params) {
            if (Object.hasOwnProperty.call(params, key)) {
                if (typeof params[key] == "string" && params[key]) {
                    params[key] = this.reverse(params[key]);
                } else if (typeof params[key] == "object") {
                    for (const innerKey in params[key]) {
                        if (Object.hasOwnProperty.call(params[key], innerKey)) {
                            params[key][innerKey] = this.reverse(params[key][innerKey]);
                        }
                    }
                }
            }
        }
        return params
    },
    reverse(value, list = ['asc', 'desc']) {
        const index = list.indexOf(value);
        if (index === -1) return list[0];
        console.log('reverse', value, list[index + 1] || list[0]);
        return list[index + 1] || list[0];
    },
    resetParams(params) {
        for (const key in params) {
            if (Object.hasOwnProperty.call(params, key)) {
                if (typeof params[key] == "string" && params[key]) {
                    params[key] = null;
                } else if (typeof params[key] == "object") {
                    for (const innerKey in params[key]) {
                        if (Object.hasOwnProperty.call(params[key], innerKey)) {
                            params[key] = { [innerKey]: null };
                        }
                    }
                }
            }
        }
        return params
    },
    encodeParams(params) {
        let newParams = {};
        for (const key in params) {
            if (Object.hasOwnProperty.call(params, key)) {
                if (typeof params[key] == "string" && params[key]) {
                    newParams[key] = params[key];
                } else if (typeof params[key] == "object") {
                    for (const innerKey in params[key]) {
                        if (Object.hasOwnProperty.call(params[key], innerKey)) {
                            newParams[key + '_' + innerKey] = params[key][innerKey];
                        }
                    }
                }
            }
        }
        return newParams
    },
    decodeParams(params) {
        let newParams = {};
        for (const key in params) {
            if (Object.hasOwnProperty.call(params, key)) {
                if (typeof key == "string" && params[key]) {
                    if (key.includes('_')) {
                        const split = key.split('_')
                        if (!newParams[split[0]]) {
                            newParams[split[0]] = { [split[1]]: params[key] }
                        } else {
                            newParams[split[0]][split[1]] = params[key]
                        }
                    } else {
                        newParams[key] = params[key];
                    }
                }
            }
        }
        return newParams
    },
    parse(query = '', decode = true) {
        const params = { parseNumbers: true, arrayFormat: 'index', parseBooleans: true }
        if (decode) return this.decodeParams(queryString.parse(query, params));
        return queryString.parse(query, params);
    },
    stringify(query = {}, encode = true) {
        if (typeof query == "object" && !Object.keys(query).length) return undefined;
        const params = { arrayFormat: 'index', strict: false, encode: true }
        if (encode) return queryString.stringify(this.encodeParams(query), params);
        return queryString.stringify(query, params);
    },
}