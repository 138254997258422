<template>
  <div class="popup_block" :class="[id]" :id="id">
    <div
      class="popupWrap flex min100vh"
      v-if="isActive"
      ref="wrap"
      @click="clickWrap"
    >
      <div class="popupContent">
        <div class="close" @click.stop="$popup.hide()">
          <CloseSVG />
        </div>
        <div class="content" :class="{ loading }">
          <LoadingIcon :absolute="true" v-if="loading" />
          <div :class="{ disabledElem: loading }">
            <slot />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CloseSVG from '@/assets/img/close.svg';
import Scrollbar from 'smooth-scrollbar';
import { mapGetters } from 'vuex';
/**
 * The PopupLayout component.
 */
export default {
  name: 'PopupLayout',
  components: { CloseSVG },
  props: {
    id: { type: String, default: null, required: true },
    loading: { default: false, type: Boolean },
  },
  data() {
    return {
      scrollbar: null,
    };
  },
  computed: {
    ...mapGetters(['GET_CurrentPopup']),
    isActive() {
      return this.GET_CurrentPopup && this.GET_CurrentPopup == this.id;
    },
    flex() {
      if (this.isActive) {
        return this.$refs.wrap?.clientHeight < window.innerHeight;
      }
      return false;
    },
  },
  methods: {
    clickWrap(e) {
      const closest = e.target.closest('.popupContent');
      if (e.target.tagName == 'path' || e.target.tagName == 'svg') return;
      const whiteList = ['editIcon', 'removeIcon', 'textContent'];
      const inWhiteList = whiteList.some((item) =>
        e.target.classList.contains(item)
      );
      // console.log({
      //   target: e.target,
      //   closest,
      //   inWhiteList,
      // });
      if (!closest && !inWhiteList) this.$popup.hide();
    },
    initScroll() {
      setTimeout(() => {
        this.scrollbar = Scrollbar.init(this.$refs.wrap, {
          renderByPixels: true,
          damping: 0.8,
          alwaysShowTracks: true,
        });
        // this.scrollbar = new this.$LocomotiveScroll({
        //   el: this.$refs.wrap,
        //   smooth: false,
        // })
      }, 150);
    },
    destroyScroll() {
      if (this.scrollbar) this.scrollbar.destroy(), (this.scrollbar = null);
    },
  },
  watch: {
    isActive(active) {
      if (active) {
        this.initScroll();
      } else {
        this.destroyScroll();
      }
    },
  },
  beforeUnmount() {
    this.destroyScroll();
  },
};
</script>

<style lang="scss" scoped src="./component.scss"></style>
