<template>
  <PageTable :loading="loading">
    <template v-slot:thead>
      <th>
        <span
          class="controll"
          :class="[{ active: currentSort?.date }, sortByDate]"
          @click="onSort('date')"
        >
          {{ $translate("date", "documents") }}
          <ArrowSVG />
        </span>
      </th>
      <th>
        <span
          class="controll"
          :class="[{ active: currentSort?.number }, sortByNumber]"
          @click="onSort('number')"
        >
          {{ $translate("order_number", "orders") }}
          <ArrowSVG />
        </span>
      </th>
      <th>
        {{ $translate("volume", "orders") }}
      </th>
      <th>
        <span
          class="controll"
          :class="[{ active: currentSort?.account?.name }, sortByAccount?.name]"
          @click="onSort('account')"
        >
          {{ $translate("counterparty", "orders") }}
          <ArrowSVG />
        </span>
      </th>
      <th>
        {{ $translate("logistics", "orders") }}
      </th>
      <th>
        <span
          class="controll"
          :class="[{ active: currentSort?.status?.name }, sortByStatus?.name]"
          @click="onSort('status')"
        >
          {{ $translate("order_status", "orders") }}
          <ArrowSVG />
        </span>
      </th>
      <th></th>
    </template>
    <template v-slot:default>
      <RowItem v-for="item in list" :key="item.id" :row="item" />
    </template>
  </PageTable>
</template>

<script>
import RowItem from "./Item";
import PageTable from "../PageTable";
import ArrowSVG from "@/assets/img/arrow_top.svg";

const SORT_ASCENDING = 'asc';
const SORT_DESCENDING = 'desc';

/**
 * The DocumentsTable component.
 */
export default {
  name: "DocumentsTable",
  components: { RowItem, ArrowSVG, PageTable },
  props: {
    list: { default: () => [], type: Array },
    loading: { default: false, type: Boolean },
    sortByDate: { default: "asc", type: String },
    sortByNumber: { default: "asc", type: String },
    sortByAccount: {
      default: () => ({ name: undefined }),
      type: Object
    },
    sortByStatus: {
      default: () => ({ name: undefined }),
      type: Object
    },
  },
  computed: {
    currentSort() {
      return this.$queryParams.parse(this.$route.query.sort)
    },
  },
  methods: {
    getSortPayload(key) {
      switch (key) {
        case 'account': {
          return {
            account: this.sortByAccount.name
              ? this.sortByAccount
              : { name: SORT_DESCENDING }
          };
        }
        case 'date': {
          return {
            date: this.sortByDate || SORT_ASCENDING
          }
        }
        case 'number': {
          return {
            number: this.sortByNumber || SORT_ASCENDING
          }
        }
        case 'status': {
          return {
            status: this.sortByStatus.name
              ? this.sortByStatus
              : { name: SORT_DESCENDING }
          };
        }
        default:
          throw new Error(`Uknown sort key "${JSON.stringify(key)}"`)
      }
    },
    onSort(key) {
      const payload = this.getSortPayload(key)
      this.$emit('sort', payload)
    },
  }
};
</script>

<style lang="scss" scoped src="./component.scss"></style>
