<template>
  <div class="order-details shadowX-box">
    <ul class="list">
      <!-- productName -->
      <li class="w32" v-if="productName">
        <span class="name">{{ $translate('product', 'orders') }}</span>
        <span class="small"
          ><b>{{ productName }}</b></span
        >
      </li>
      <!-- quantity -->
      <li class="w32" v-if="quantity">
        <span class="name">{{
          $translate('volume_engagement', 'orders')
        }}</span>
          <span class="small"
            ><b>{{ quantity }} {{ $translate('t', 'orders') }}</b></span
          >
          <span>
            <span class="grey">{{ $translate('price', 'orders') }}:</span> {{ $price(data.price, undefined, true) }}/{{ $translate('t', 'orders') }}
          </span>
      </li>
      <!-- deliveryPrice -->
      <li class="w32" v-if="data.deliveryPrice">
        <span class="name">
          {{
            $translate('delivery_price_total', 'orders')
          }}
        </span>
        <span class="small"><b>{{ $price(data.deliveryPrice) }}</b></span>
      </li>
      <!-- washSupplies -->
      <li class="w32" v-if="washSupplies">
        <span class="name">{{ $translate('wash_supplies', 'orders') }}</span>
        <span class="small"
          ><b>{{ washSupplies }}</b></span
        >
      </li>

      <!-- Customer -->
      <li v-if="customer.name && customer.edrpo" class="w32">
        <span class="name">{{ $translate('customer', 'general') }}</span>
        <span class="small"
          ><b>{{ customer.name }}</b></span
        >
        <span
          ><span class="grey">{{ $translate('EDRPO', 'general') }}:</span
          >{{ customer.edrpo }}</span
        >
      </li>
      <!-- Contact person -->
      <li
        v-if="contactPerson.name || contactPerson.role || contactPerson.phone"
        class="w32"
      >
        <span class="name">{{ $translate('contact_person', 'orders') }}</span>
        <span v-if="contactPerson.name" class="small"
          ><b>{{ contactPerson.name }}</b></span
        >
        <span v-if="contactPerson.role"
          ><span class="grey">{{ $translate('position', 'general') }}:</span>
          {{ contactPerson.role }}</span
        >
        <span v-if="contactPerson.phone"
          ><span class="grey">{{ $translate('phone', 'general') }}:</span>
          {{ contactPerson.phone }}</span
        >
      </li>

      <!-- Consignee -->
      <li
        v-if="consignee.name || consignee.edrpo || consignee.code"
        class="w32"
      >
        <span class="name">{{ $translate('consignee', 'orders') }}</span>
        <span v-if="consignee.name" class="small"
          ><b>{{ consignee.name }}</b></span
        >
        <span v-if="consignee.edrpo"
          ><span class="grey">{{ $translate('EDRPO', 'general') }}:</span>
          {{ consignee.edrpo }}</span
        >
        <span v-if="consignee.code"
          ><span class="grey">{{ $translate('zd_code', 'general') }}:</span>
          {{ consignee.code }}</span
        >
      </li>

      <!-- shippingComposition -->
      <li v-if="shippingComposition" class="w32">
        <span class="name">{{
          $translate('shipping_composition', 'orders')
        }}</span>
        <span>{{ shippingComposition }}</span>
      </li>

      <!-- Place of delivery -->
      <li v-if="deliveryPlace" class="w32">
        <span class="name">{{
          $translate('place_of_delivery', 'general')
        }}</span>
        <span>{{ deliveryPlace }}</span>
      </li>
      <!-- transportType -->
      <li v-if="transportType" class="w32">
        <span class="name">{{
          $translate('type_of_transport', 'general')
        }}</span>
        <span>{{ transportType }}</span>
      </li>
      <!-- Payment method -->
      <li v-if="paymentMethod" class="w32">
        <span class="name">{{ $translate('payment_method', 'general') }}</span>
        <span>{{ paymentMethod }}</span>
      </li>
      <!-- Promotional offers -->

      <li v-if="gpromotional && gpromotional.length" class="w68 w68--56">
        <span class="name">{{
          $translate('promotional_offers', 'orders')
        }}</span>
        <PromotionalOffers
          v-for="item in gpromotional.slice(0, 2)"
          :key="'promoff_' + item.id"
          :data="item"
          @menuClick="handlePromotionalOffersClick(item)"
        />
        <div v-show="marketingProductsListIsExpanded">
          <PromotionalOffers
            v-for="item in gpromotional.slice(2)"
            :key="'promoff_' + item.id"
            :data="item"
            @menuClick="handlePromotionalOffersClick(item)"
          />
        </div>
        <MyButton
          v-if="gpromotional.length > 2"
          :text="marketingProductsListExpandButtonLabel"
          class="w100 marketingProductsExpandButton"
          size="s"
          type="transparent"
          @click="marketingProductsListIsExpanded = !marketingProductsListIsExpanded"
        />
      </li>
      <!-- railwayMarks -->
      <li v-if="railwayMarks" class="w32">
        <span class="name">{{
          $translate('marks_for_the_railway', 'orders')
        }}</span>
        <span>{{ railwayMarks }}</span>
      </li>
      <!-- Notes -->
      <li v-if="notes" class="w32">
        <span class="name">{{ $translate('notes', 'general') }}</span>
        <span>{{ notes }}</span>
      </li>

      <li
        v-if="attachments.length > 0"
        class="w68 attachments black marg-bot--16">
        <h4 class="title">{{ $translate('order_attachments', 'orders') }}</h4>

        <ul class="attachments-list">
          <li
            v-for="(item) in attachments"
            :key="item.id"
            class="flex alignCenter attachments-list-item">
            <a
              target="_blank"
              :href="item?.url"
              :download="item?.name"
              class="simpleLink attachments-list-link"
            >
              <span class="attachments-list-name">
                {{ $translate('download_order_attachment', 'orders') }} "{{ item?.name }}"
              </span>
            </a>
            <DownloadSvg /> 
          </li>
        </ul>
      </li>
    </ul>
    <div class="flex actions mob--column">
      <div class="left">
        <MyButton
          v-if="btnActions && btnActions.request"
          class="width-auto"
          type="outline-yellow"
          tag="button"
          :text="$translate('ask_a_question', 'documents')"
          :dynamicSize="true"
          @click="
            $popup.show('AskQuestionProfile', {
              id: data?.id,
              type: 'orders',
            })
          "
        />
        <MyButton
          v-if="btnActions && btnActions.uploadFile"
          class="width-auto"
          type="outline-yellow"
          tag="button"
          :text="$translate('upload_file', 'general')"
          :dynamicSize="true"
          @click="$popup.show('TIUploadAttachment', {
            id: data?.id,
            type: 'orders',
          })"
        />
        <MyButton
          v-if="btnActions && btnActions.duplicate"
          class="width-auto"
          type="outline-green"
          tag="button"
          :text="$translate('duplicate', 'general')"
          :dynamicSize="true"
          @click="orderDublicate"
        />
      </div>
      <template v-if="btnActions && btnActions.cancel">
        <MyButton
          class="width-auto mob-marg-20"
          :text="$translate('cancel')"
          tag="button"
          type="outline-red"
          :dynamicSize="true"
          @click="$popup.show('OrderCancellationRequest', { id: data?.id })"
        />
      </template>
    </div>
    <Transition mode="out-in">
      <div class="box-load" v-if="loading">
        <LoadingIcon :absolute="true" />
      </div>
    </Transition>
  </div>
</template>
<script>
import PromotionalOffers from '@/components/OrderComponents/PromotionalOffers';
import DownloadSvg from '@/assets/img/downloadArrow.svg';

export default {
  name: 'OrderDetails',
  components: {
    PromotionalOffers,
    DownloadSvg,
  },
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data: () => ({
    loading: false,
    marketingProductsListIsExpanded: false,
  }),
  computed: {
    quantity() {
      return this.data?.quantity;
    },
    washSupplies() {
      const condition = this.data?.deliveryCondition?.name;
      const delType = this.data?.deliveryType?.name;
      const shipType = this.data?.shipmentType?.name;
      return [condition, delType, shipType].filter((item) => item).join(', ');
    },
    productName() {
      return this.data?.product?.name;
    },
    shippingComposition() {
      return this.data?.storage?.name;
    },
    customer() {
      return {
        name: this.data?.account?.name,
        edrpo: this.data?.account?.edrpou,
      };
    },
    contactPerson() {
      return {
        name: this.data?.accountContact?.name,
        role: this.data?.accountContact?.role,
        phone: this.data?.accountContact?.phone,
      };
    },
    consignee() {
      return {
        name: this.data?.consignee?.name,
        edrpo: this.data?.consignee?.edrpou,
        code: this.data?.consignee?.railCode,
      };
    },
    deliveryPlace() {
      return [this.data?.destinationStation?.name, this.data?.shipmentAddress]
        .filter((item) => item)
        .join(', ');
    },
    transportType() {
      return this.data?.typeWagon?.name;
    },
    paymentMethod() {
      return [this.data?.formOfRaisedFund?.name, this.data?.standartPaymentTerm]
        .filter((item) => item)
        .join(' ');
    },
    railwayMarks() {
      return [this.data?.railroadNote, this.data?.railroadNote15]
        .filter((item) => item)
        .join('. ');
    },
    notes() {
      return this.data?.notes;
    },
    gpromotional() {
      return this.data?.marketingProducts;
    },
    btnActions() {
      return {
        request: this.data?.actions?.request,
        duplicate: this.data?.actions?.duplicate,
        cancel: this.data?.actions?.cancel,
        uploadFile: this.data?.actions?.uploadFile,
      };
    },
    marketingProductsListExpandButtonLabel() {
      const key = this.marketingProductsListIsExpanded
        ? "marketing_list_collapse"
        : "marketing_list_expand";

      return this.$translate(key, "orders");
    },
    attachments() {
      if (Array.isArray(this.data?.attachments)) {
        return this.data.attachments.map(({ id, name, url }) => {
          return {
            id,
            name,
            url: url ? process.env.VUE_APP_API_DOMAIN + url : null,
          };
        });
      }
      return [];
    }
  },
  methods: {
    removeNullKeys(obj) {
      for (var key in obj) {
        if (obj[key] === null) {
          delete obj[key];
        }
      }
      return obj;
    },
    async orderDublicate() {
      try {
        this.loading = true;
        const product = this.data?.product?.id;
        const quantity = this.data?.quantity;
        const user = this.$auth.user || null;
        if (!product || !quantity || !user)
          throw Error('product,quantity,user is null');
        let dataDub = {
          account: {
            id: this.data?.account?.id,
            crmId: this.data?.account?.crmId,
          },
          accountContact: this.data?.accountContact,
          accountContactEmail: this.data?.accountContactEmail,
          accountContactName: this.data?.accountContactName,
          accountContactPhone: this.data?.accountContactPhone,
          accountEdrpou: this.data?.accountEdrpou,
          accountName: this.data?.accountName,
          consignee: {
            id: this.data?.consignee?.id,
            crmId: this.data?.consignee?.crmId,
          },
          consigneeCode: this.data?.consigneeCode,
          consigneeEdrpou: this.data?.consigneeEdrpou,
          consigneeName: this.data?.consigneeName,
          contactPerson: this.data?.contactPerson,
          deliveryCondition: {
            id: this.data?.deliveryCondition?.id,
            crmId: this.data?.deliveryCondition?.crmId,
          },
          deliveryType: {
            id: this.data?.deliveryType?.id,
            crmId: this.data?.deliveryType?.crmId,
          },
          destinationStation: {
            id: this.data?.destinationStation?.id,
            crmId: this.data?.destinationStation?.crmId,
          },
          formOfRaisedFund: {
            id: this.data?.formOfRaisedFund?.id,
            crmId: this.data?.formOfRaisedFund?.crmId,
          },
          notes: this.data?.notes,
          paymentType: this.data?.paymentType,
          railroadNote: this.data?.railroadNote,
          railroadNote15: this.data?.railroadNote15,
          requisite: this.data?.requisite,
          shipmentAddress: this.data?.shipmentAddress,
          shipmentType: {
            id: this.data?.shipmentType?.id,
            crmId: this.data?.shipmentType?.crmId,
          },
          standartPaymentTerm: this.data?.standartPaymentTerm,
          storage: {
            id: this.data?.storage?.id,
            crmId: this.data?.storage?.crmId,
          },
          typeWagon: {
            id: this.data?.typeWagon?.id,
            crmId: this.data?.typeWagon?.crmId,
          },
          useAgent: this.data?.useAgent,
        };
        dataDub = this.removeNullKeys(dataDub);
        const { data, success = false } = await this.$api.checkout.duplicateOrder(
          this.data.id,
          {
          data: {
            product,
            quantity,
            ...dataDub,
          },
          }
        );

        if (data && success) {
          console.log('dublicate success', data, this.$route);
          const { fullPath, name, hash, href, params, path, query } =
            this.$route;
          const localStorData = {
            data,
            id: data?.product?.id,
            productId: data?.product?.crmId,
            routerNavigation: {
              fullPath,
              name,
              hash,
              href,
              params,
              path,
              query,
            },
          };
          this.$order.set(localStorData);
          this.loading = false;
          this.$router.push({ name: 'checkout' });
        } else {
          throw Error('success false');
        }
      } catch (e) {
        this.loading = false;
        console.log(e);
      }
    },
    async handlePromotionalOffersClick(item) {
      if (item?.marketingProduct?.condition?.slug?.startsWith('maxi')) {
        try {
          const {
            success,
            data: actionMetadata
          } = await this.$api.checkout.getPriceRecalculationFromMarketingProduct(
            this.data?.id
          );

          if (success) {
            this.$popup.show('MarketingProductOffer', {
              marketingProduct: {
                ...item,
                actionMetadata,
              },
            });
          }
        } catch (error) {
          this.$notice(this.$translate("request_error"), undefined, {
            type: "error",
          });
          console.error(error);
        }
        return;
      }
    },
    async handleAttachmentUpload({ file, comment }) {
      const { success } = await this.$api.document.uploadOrderAttachment(this.data.id, {
        file: file.id,
        comment: comment,
      });

      if (success) {
        this.$notice(this.$translate('order_attachment_uploaded_notice', 'orders'));
        this.$popup.hide('TIUploadAttachment')
      } else {
        this.$notice(this.$translate('request_error'), undefined, {
          type: 'error',
        });
      }
    },
  },
  created() {
    this.emitter.on('attachmentFileUploaded', this.handleAttachmentUpload);
  },
  beforeUnmount() {
    this.emitter.off('attachmentFileUploaded', this.handleAttachmentUpload);
  },
};
</script>
<style lang="scss" scoped src="./style.scss"></style>
