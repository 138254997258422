export default ($axios) => {
  return {
    async getDeliveryType(params = {}) {
      return await $axios({
        url: '/delivery-types',
        method: 'get',
        params: { ...params },
      })
        .then(({ data }) => {
          return { success: true, list: data.data };
        })
        .catch((e) => e);
    },
    async getDeliveryConditions(params = {}) {
      return await $axios({
        url: '/delivery-conditions',
        method: 'get',
        params: { ...params },
      })
        .then(({ data }) => {
          return { success: true, list: data.data };
        })
        .catch((e) => e);
    },
    async getDeliveryCondition(id) {
      return await $axios({
        url: '/delivery-condition/' + id,
        method: 'get',
      })
        .then(({ data }) => {
          return { success: true, data: data.data };
        })
        .catch((e) => e);
    },
    async getPaymentTypes(params = {}) {
      return await $axios({
        url: '/su-form-of-raised-funds',
        method: 'get',
        params: { ...params },
      })
        .then(({ data }) => {
          return { success: true, list: data.data };
        })
        .catch((e) => e);
    },
    async getRegions(params = {}) {
      return await $axios({
        url: '/regions',
        method: 'get',
        params: { ...params },
      })
        .then(({ data }) => {
          return { success: true, list: data.data };
        })
        .catch((e) => e);
    },
    async getRailways(params = {}) {
      return await $axios({
        url: '/railways',
        method: 'get',
        params: { ...params },
      })
        .then(({ data }) => {
          return { success: true, list: data.data };
        })
        .catch((e) => e);
    },
    async getStations(params = {}, cancelControllerSignal) {
      return await $axios({
        url: '/stations',
        method: 'get',
        params: { ...params },
        signal: cancelControllerSignal,
      })
        .then(({ data }) => {
          return { success: true, list: data.data };
        })
        .catch((e) => e);
    },
    async getConsignees(params = {}) {
      return await $axios({
        url: '/consignees',
        method: 'get',
        params: { ...params },
      })
        .then(({ data }) => {
          return { success: true, list: data.data };
        })
        .catch((e) => e);
    },
    async getLogisticCompanies(params = {}) {
      return await $axios({
        url: '/logistic-companies',
        method: 'get',
        params: { ...params },
      })
        .then(({ data }) => {
          return { success: true, list: data.data };
        })
        .catch((e) => e);
    },
    async deliveryAddress(params = {}) {
      return await $axios({
        url: '/destinations',
        method: 'get',
        params: { ...params },
      })
        .then(({ data }) => {
          return { success: true, list: data.data };
        })
        .catch((e) => e);
    },
    async createOrder(data) {
      return await $axios({
        url: '/order',
        method: 'post',
        data,
      })
        .then(({ data }) => {
          return { success: true, data: data.data };
        })
        .catch((e) => e);
    },
    async duplicateOrder(id, data) {
      return await $axios({
        url: `/order/${id}/duplicate`,
        method: 'post',
        data,
      })
        .then(({ data }) => {
          return { success: true, data: data.data };
        })
        .catch((e) => e);
    },
    async getOrder(id) {
      return await $axios({
        url: '/order/' + id,
        method: 'get',
      })
        .then(({ data }) => {
          return { success: true, data: data.data };
        })
        .catch((e) => e);
    },
    async updateOrder(id, data = {}, newOrderData = {}, deleteStatus = true) {
      delete data.quotasUpdatedAt;
      // delete data.marketingProductsConfig
      if (deleteStatus) delete data.status;

      return await $axios({
        url: '/order/' + id,
        method: 'put',
        data: { data: { ...data, ...newOrderData } },
      })
        .then(({ data }) => {
          return { success: true, data: data.data };
        })
        .catch((e) => e);
    },
    async getQuotas(params = {}) {
      return await $axios({
        url: '/quotas',
        method: 'get',
        params: { ...params },
      })
        .then(({ data }) => {
          return { success: true, list: data.data };
        })
        .catch((e) => e);
    },

    async getStorage(params = {}) {
      return await $axios({
        url: '/storages',
        method: 'get',
        params: { ...params },
      })
        .then(({ data }) => {
          return { success: true, list: data.data };
        })
        .catch((e) => e);
    },

    async getPriceRecalculationFromMarketingProduct(orderId) {
      return await $axios({
        url: `/order/${orderId}/recalculation`,
        method: 'get',
      })
        .then(({ data }) => {
          return { success: true, data };
        })
        .catch((e) => e);
    },

    async recalculateOrderPrice(orderId) {
      return await $axios({
        url: `/order/${orderId}/change-price`,
        method: 'post',
        data: null,
      })
        .then(({ data }) => {
          return { success: true, data: data };
        })
        .catch((e) => e);
    },
  };
};
