<template>
  <router-view v-if="isChildrenPage"></router-view>
  <LayoutDashboard name="order" v-else>
    <DashboardTitleRow :title="$translate('catalog', 'orders')" />
    <LoadingIcon v-if="loading" />
    <template v-else>
      <SpecialSlider :list="marketingList" v-if="marketingList" />
      <div class="gridRow row">
        <ProductCard
          v-for="item in list"
          :key="item.id"
          :data="item"
          class="w50 tablet2w100"
          :link="getLink(item)"
          type="category"
          :buttonText="this.$translate('go_to_order', 'orders')"
          :active="item.active"
        />
      </div>
    </template>
  </LayoutDashboard>
</template>

<script>
// import sliderList from "./sliderList.json";
//
import LayoutDashboard from "@/layouts/Dashboard.vue";
import DashboardTitleRow from "@/components/DashboardTitleRow";
import ProductCard from "@/components/ProductCard";
import SpecialSlider from "@/components/SpecialSlider";
/**
 * The order-page component.
 */
export default {
  name: "OrderPage",
  components: {
    LayoutDashboard,
    DashboardTitleRow,
    ProductCard,
    SpecialSlider,
  },
  data() {
    return {
      title: this.$translate("my_orders", "orders"),
      loading: true,
      list: [],
      marketingList: null,
    };
  },
  computed: {
    isChildrenPage() {
      return this.$route.name !== "order";
    },
  },
  methods: {
    getLink(item) {
      if (!item.slug || !item.active) return false;
      return {
        name: "order-category",
        params: { category: item.slug },
      };
    },
    isActive() {},
  },
  async created() {
    const { list, success: successCategories } =
      await this.$api.product.getProductsCategories();
    if (successCategories) this.list = list;

    const { success: successMarketing, list: marketingList } =
      await this.$api.product.getMarketingProducts();

    if (successMarketing) this.marketingList = marketingList;
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped src="./component.scss"></style>
