<template>
  <div
    class="Tag"
    :class="[type]"
    :style="{
      borderColor: color,
    }"
  >
    <template v-if="_icon">
      <component :is="_icon"></component>
    </template>
    {{ text }}
    <slot></slot>
  </div>
</template>

<script>
/**
 * The Tag component.
 */
import Draggable from "@/assets/img/iconDraggable.svg";

export default {
  name: "TagConponent",
  components: {
    Draggable,
  },
  props: {
    text: { default: null, type: String },
    /**
     * Visual view type
     * @values agreed, active, cancelled, waiting, primary, secondary
     */
    type: { default: "agreed", type: String },
    /**
     * border color
     */
    color: { default: null, type: String },
    icon: {
      type: String,
      default: "",
    },
  },
  computed: {
    _icon() {
      switch (this.icon) {
        case "draggable":
          return "Draggable";
        default:
          return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped src="./component.scss"></style>
