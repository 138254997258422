<template>
  <LayoutDashboard name="documents">
    <DashboardTitleRow
      :title="$translate('price_list', 'orders')"
      :text="
        $translate('latest_price_update', 'orders') + ' ' + latest_price_update
      "
    >
      <template v-slot:title v-if="fileLink != null">
        <div class="btnWrap">
          <MyButton
            class="width-auto primary400"
            iconAfter="download"
            :text="$translate('download_price_list_xls')"
            size="xs"
            tag="a"
            :link="fileLink"
            target="_blank"
          />
        </div>
      </template>
    </DashboardTitleRow>
    <PageFilter
      :loading="loading"
      :list="filterList"
      @submit="submitFilter"
      @reset="resetFilter"
      @remove="removeFilterTag"
    >
      <LabelDropdown
        name="deliveryCondition_name"
        :title="$translate('basis', 'documents')"
        :list="filter.deliveryCondition"
        :active="filterCurrent.deliveryCondition"
        @change="changeDropdown"
      />
      <LabelDropdown
        name="basePriceGroup_name"
        :title="$translate('type', 'documents')"
        :list="filter.basePriceGroup"
        :active="filterCurrent.basePriceGroup"
        @change="changeDropdown"
      />
      <LabelDropdown
        name="product_name"
        :title="$translate('product', 'orders')"
        :list="filter.product"
        :active="filterCurrent.product"
        @change="changeDropdown"
      />
    </PageFilter>

    <PriceListTable
      v-if="list.length"
      :list="list"
      :loading="loading"
      :sortByProduct="sort.product"
      :sortByBasePriceGroup="sort.basePriceGroup"
      :sortByDeliveryCondition="sort.deliveryCondition"
      :sortBySellingPrice="sort.sellingPrice"
      :sortByValidTo="sort.validTo"
      @sort="sortTable"
    />
    <LoadingIcon v-if="loading && !list.length" />
    <template v-if="!loading && !list.length">
      <p class="no_data">{{ $translate('no_data') }}</p>
    </template>
    <Pagination
      @paginate="paginate"
      :pageCurrent="pageCurrent"
      :pageCount="pageCount"
      :perPage="PAGE_SIZE"
      :class="{ disabledElem: loading }"
    />
  </LayoutDashboard>
</template>

<script>
const PAGE_SIZE = 9;

import LayoutDashboard from '@/layouts/Dashboard.vue';
import DashboardTitleRow from '@/components/DashboardTitleRow';
import PageFilter from '@/components/PageFilter';
import PriceListTable from '@/components/Table/PriceList';
import Pagination from '@/components/Pagination';
/**
 * The documents-page component.
 */
export default {
  name: 'DocumentsPage',
  components: {
    LayoutDashboard,
    DashboardTitleRow,
    PageFilter,
    PriceListTable,
    Pagination,
  },
  data() {
    const filter = this.$queryParams.parse(this.$route.query.filter);
    const sort = this.$queryParams.parse(this.$route.query.sort);

    return {
      PAGE_SIZE: PAGE_SIZE,
      loading: true,
      preList: [],
      list: [],
      pageCurrent: parseInt(this.$route.query.page) || 1,
      pageCount: 1,
      filterCurrentCopy: null,
      filterCurrent: {
        deliveryCondition: {
          id: filter.deliveryCondition?.id || null,
        },
        basePriceGroup: {
          id: filter.basePriceGroup?.id || null,
        },
        product: {
          id: filter.product?.id || null,
        },
      },
      latest_price_update: null,
      formKey: 'form_0',
      sort: {
        product: { name: sort?.product?.name || undefined },
        basePriceGroup: { name: sort?.basePriceGroup?.name || undefined },
        deliveryCondition: { name: sort?.deliveryCondition?.name || undefined },
        sellingPrice: sort?.sellingPrice,
        validTo: sort?.validTo,
      },
      fileLink: null,
    };
  },
  computed: {
    filter() {
      const data = {
        deliveryCondition: { },
        basePriceGroup: { },
        product: { },
      };

      for (let i = 0; i < this.preList.length; i++) {
        const item = this.preList[i];
        
        if (
          item.deliveryCondition &&
          !(item.deliveryCondition.id in data.deliveryCondition)
        ) {
          data.deliveryCondition[item.deliveryCondition.id] = {
            id: item.deliveryCondition.id,
            name: item.deliveryCondition.name,
          };
        }
        
        if (
          item.basePriceGroup &&
          !(item.basePriceGroup.id in data.basePriceGroup)
        ) {
          data.basePriceGroup[item.basePriceGroup.id] = {
            id: item.basePriceGroup.id,
            name: item.basePriceGroup.name,
          };
        }
        
        if (
          item.product &&
          !(item.product.id in data.product)
        ) {
          data.product[item.product.id] = {
            id: item.product.id,
            name: item.product.name,
          };
        }
      }

      return {
        deliveryCondition: Object.values(data.deliveryCondition),
        basePriceGroup: Object.values(data.basePriceGroup),
        product: Object.values(data.product),
      };
    },
    filterList() {
      const list = [];

      // deliveryCondition_name
      if (
        this.filterCurrentCopy.deliveryCondition?.id &&
        this.filter.deliveryCondition.length > 0
      ) {
        const item = this.filter.deliveryCondition.find((el) =>
          el.id === this.filterCurrentCopy.deliveryCondition.id
        );
        list.push({
          id: 'deliveryCondition_name',
          type: this.$translate('basis', 'documents'),
          text: item.name,
        });
      }
      // basePriceGroup_name
      if (
        this.filterCurrentCopy.basePriceGroup?.id &&
        this.filter.basePriceGroup.length > 0
      ) {
        const item = this.filter.basePriceGroup.find((el) =>
          el.id === this.filterCurrentCopy.basePriceGroup.id
        );
        list.push({
          id: 'basePriceGroup_name',
          type: this.$translate('type', 'documents'),
          text: item.name,
        });
      }
      // product_name
      if (
        this.filterCurrentCopy.product?.id &&
        this.filter.product.length > 0
      ) {
        const item = this.filter.product.find((el) =>
          el.id === this.filterCurrentCopy.product.id
        );
        list.push({
          id: 'product_name',
          type: this.$translate('product', 'orders'),
          text: item.name,
        });
      }

      return list;
    },
  },
  methods: {
    paginate(page) {
      this.getPriceList(page);
    },
    async getPreList() {
      const { list, success } = await this.$api.getPriceList({
        populate: {
          basePriceGroup: {
            fields: ['name']
          },
          deliveryCondition: {
            fields: ['name']
          },
          product: {
            fields: ['name']
          },
        },
        fields: ['id']
      });
      if (success) {
        this.preList = list;
      }
      this.loading = false;
    },
    async getPriceList(
      page,
      filter = this.filterCurrent,
      sort = this.sort,
      params = {}
    ) {
      const clearFilter = this.$queryParams.getClear(filter, true, true);
      const clearSort = this.$queryParams.getClear(sort);
      if (this.loading) return;
      this.loading = true;
      const { list, pagination, success } = await this.$api.getPriceList({
        pagination: { pageSize: PAGE_SIZE, page: page || this.pageCurrent },
        filters: clearFilter,
        sort: clearSort,
        ...params,
      });
      if (success) {
        this.filterCurrentCopy = this.$copyObject(this.filterCurrent);
        this.list = list;
        this.pageCurrent = pagination.page;
        this.pageCount = pagination.pageCount;

        this.sort = {
          ...this.$queryParams.resetParams(this.sort),
          ...clearSort,
        };

        this.$router.push({
          query: this.$queryParams.getClear({
            page: this.pageCurrent,
            filter: this.$queryParams.stringify(clearFilter),
            sort: this.$queryParams.stringify(clearSort),
          }),
        });
      }
      this.loading = false;
    },

    async sortTable(sortParams) {
      this.$queryParams.reverseParams(sortParams);
      await this.getPriceList(this.pageCurrent, this.filterCurrent, sortParams);
    },

    changeDropdown(key, name) {
      switch (name) {
        case 'deliveryCondition_name':
          this.filterCurrent.deliveryCondition.id = key;
          break;
        case 'basePriceGroup_name':
          this.filterCurrent.basePriceGroup.id = key;
          break;
        case 'product_name':
          this.filterCurrent.product.id = key;
          break;
        default:
          break;
      }
    },
    async submitFilter() {
      await this.getPriceList(1);
    },
    async resetFilter() {
      this.$queryParams.resetParams(this.filterCurrent);
      this.formKey = 'form_' + this.$rnd(0, 100);
      await this.getPriceList(1);
    },
    async removeFilterTag(id) {
      this.changeDropdown(null, id);
      await this.getPriceList();
    },
    async getDownloadLink() {
      try {
        const { data, success } = await this.$api.getPriceListDownloadLink();
        
        if (success) {
          this.fileLink = process.env.VUE_APP_API_DOMAIN + data.url;
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
  async created() {
    this.latest_price_update = this.$moment().format('D.MM.yyyy');
    this.filterCurrentCopy = this.$copyObject(this.filterCurrent);
    await this.getPreList();
    await this.getPriceList();
    await this.getDownloadLink();
  },
  mounted() {
    this.emitter.on('PageFilter:show', () => {
      this.filterCurrent = this.$copyObject(this.filterCurrentCopy);
    });
  },
};
</script>

<style lang="scss" scoped src="./component.scss"></style>
