<template>
  <LayoutLogin>
    <LoginGrid
      :title="$translate('create_new_password', 'auth')"
      :text="$translate('create_new_password_text', 'auth')"
    >
      <h2>{{ $translate("enter_new_password", "auth") }}</h2>
      <div class="formWrap smallMarginBottom">
        <LoadingIcon v-if="loading" :absolute="true" />
        <form
          autocomplete="off"
          novalidate
          @submit.prevent="submit"
          :class="{ loadingElem: loading }"
          :key="formKey"
        >
          <Label
            :title="$translate('access_code', 'auth')"
            name="code"
            :placeholder="$translate('access_code', 'auth')"
            inputType="text"
            @onInput="onInputCode"
            :error="codeError"
            :errorText="codeErrorText"
            value=""
            autocomplete="off"
            ref="code"
          />
          <Label
            :title="$translate('password', 'auth')"
            name="password"
            :placeholder="$translate('password', 'auth')"
            inputType="password"
            @onInput="onInputPassword"
            :error="passwordError"
            :errorText="passwordErrorText"
            autocomplete="off"
            ref="password"
            value=""
          />
          <Label
            :title="$translate('password_again', 'auth')"
            name="passwordConfirmation"
            :placeholder="$translate('password_again', 'auth')"
            inputType="password"
            @onInput="onInputPasswordConfirmation"
            :error="passwordConfirmationError"
            :errorText="passwordConfirmationErrorText"
            autocomplete="off"
            ref="passwordConfirmation"
            value=""
          />
          <MyButton
            class="w100"
            :text="$translate('login_system', 'auth')"
            tag="button"
            :dynamicSize="true"
          />
        </form>
      </div>

      <div class="linkWrap">
        <router-link :to="{ name: 'password-recovery' }">{{
          $translate("forgot_password", "auth")
        }}</router-link>
      </div>
      <!-- <div class="linkWrap">
        <p>{{ $translate("dont_have_account", "auth") }}</p>
        <router-link :to="{ name: 'registration' }">
          {{ $translate("registration") }}
        </router-link>
      </div> -->
    </LoginGrid>
  </LayoutLogin>
</template>

<script>
import LayoutLogin from "@/layouts/Login.vue";
import LoginGrid from "@/components/LoginGrid";
/**
 * The password-recovery-create-page component.
 */
export default {
  name: "PasswordRecoveryCreatePage",
  components: { LayoutLogin, LoginGrid },
  data() {
    return {
      formKey: "initForm",
      loading: false,
      code: "",
      codeError: false,
      codeErrorText: "",
      password: "",
      passwordError: false,
      passwordErrorText: "",
      passwordConfirmation: "",
      passwordConfirmationError: false,
      passwordConfirmationErrorText: "",
    };
  },
  methods: {
    isPasswordsEqual() {
      return this.password === this.passwordConfirmation;
    },
    validatePasswordsEqual() {
      const equals = this.isPasswordsEqual();
      if (!equals) {
        this.passwordError = true;
        this.passwordConfirmationError = true;
        this.passwordErrorText = this.$translate("equalPasswords", "auth");
        this.passwordConfirmationErrorText = this.$translate(
          "equalPasswords",
          "auth"
        );
        this.$refs.passwordConfirmation.$refs.input.focus();
      }
      return equals;
    },
    onInputCode(value = this.code, e, required = false) {
      this.code = value;
      const codeError = this.$simpleInputValidate(value, required, "name");

      this.codeError = codeError.error;
      if (codeError.error) {
        this.codeErrorText = codeError.text;
        this.$refs.code.$refs.input.focus();
      }
      return codeError.error;
    },
    onInputPassword(value = this.password, e, required = false) {
      this.password = value;
      const passwordError = this.$simpleInputValidate(
        value,
        required,
        "password"
      );
      this.passwordError = passwordError.error;
      if (passwordError.error) {
        this.passwordErrorText = passwordError.text;
        this.$refs.password.$refs.input.focus();
      }
      return passwordError.error;
    },
    onInputPasswordConfirmation(
      value = this.passwordConfirmation,
      e,
      required = false
    ) {
      this.passwordConfirmation = value;
      const passwordConfirmationError = this.$simpleInputValidate(
        value,
        required,
        "password"
      );
      this.passwordConfirmationError = passwordConfirmationError.error;
      if (passwordConfirmationError.error) {
        this.passwordConfirmationErrorText = passwordConfirmationError.text;
        this.$refs.passwordConfirmation.$refs.input.focus();
      }
      return passwordConfirmationError.error;
    },
    resetForm() {
      this.loading = false;
      this.code = "";
      this.password = "";
      this.passwordConfirmation = "";
      this.codeError = false;
      this.passwordError = false;
      this.passwordConfirmationError = false;
      this.formKey = "form_" + this.$rnd(0, 100);
    },
    success() {
      this.resetForm();
      this.$router.push({ name: "login" });
    },
    async submit() {
      if (this.loading) return false;

      const error = (() => {
        const errorCode = this.onInputCode(undefined, undefined, true);
        const errorPassword = this.onInputPassword(undefined, undefined, true);
        const errorpasswordConfirmation = this.onInputPasswordConfirmation(
          undefined,
          undefined,
          true
        );
        return (
          errorCode ||
          errorPassword ||
          errorpasswordConfirmation ||
          (!errorCode &&
            !errorPassword &&
            !errorpasswordConfirmation &&
            !this.validatePasswordsEqual())
        );
      })();
      if (!error) {
        this.loading = true;
        const { code, password, passwordConfirmation } = this;
        const { success, message, name } = await this.$api.auth.resetPassword({
          code,
          password,
          passwordConfirmation,
        });
        this.loading = false;
        if (success) {
          this.success();
        } else {
          this.codeError = true;
          if (name === "ValidationError") {
            this.codeErrorText = this.$translate("code_error", "auth");
          } else {
            this.codeErrorText = message;
          }
          this.$refs.code.$refs.input.focus();
        }
      }
    },
  },
  created() {
    if (this.$route.query.code) {
      this.code = this.$route.query.code;
    }
    if (localStorage.getItem("canPasswordRecovery") !== "true") {
      return this.$router.push({ name: "password-recovery" });
    }
  },
};
</script>
