<template>
  <div ref="tooltip" class="tippy Notice">
    <Icon class="InfoIcon" />
  </div>
</template>
<script>
import tippy from 'tippy.js';
import Icon from '@assets/img/tippyTooltip.svg';
import 'tippy.js/dist/tippy.css'; // optional for styling
export default {
  name: 'TippyTooltip',
  components: {
    Icon,
  },
  props: {
    text: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    tooltip: null,
  }),
  mounted() {
    let tippyOption = {
      arrow: false,
      zIndex: 9,
    };
    if (this.text) {
      tippyOption.content = this.text;
    }
    this.tooltip = tippy(this.$refs.tooltip, tippyOption);
  },
  beforeUnmount() {
    if (this.tooltip) {
      this.tooltip.destroy();
      this.tooltip = null;
    }
  },
};
</script>
<style lang="scss">
.tippy {
  display: inline-flex;
  width: toRem(13);
  height: toRem(13);
  justify-content: center;
  align-items: center;
  .InfoIcon {
    width: 100%;
    height: 100%;
  }
}
.tippy-box {
  background: #5c6779;
  box-shadow: 0px 3px 9px 0px rgba(28, 52, 84, 0.12);
  border-radius: toRem(24);
  padding: toRem(20);
  @media (max-width: $tablet2) {
    padding: toRem(10);
  }
}
</style>
