<template>
  <router-view v-if="isChildrenPage"></router-view>
  <LayoutDashboard
    name="order-category"
    v-else
    :customClass="{
      'order-category': $props.orderCategory,
      'order-specials': $props.orderSpecials,
    }"
  >
    <template v-if="$props.orderCategory">
      <DashboardTitleRow
        class="OrderCategoryPage"
        :title="currentCategory?.name || ' '"
        titleTagClass="h4"
        :goBack="{
          name: 'order',
        }"
      >
        <template v-slot:title>
          <DisplayViewType @change="changeViewType" :activeType="viewType" />
        </template>
      </DashboardTitleRow>
    </template>
    <template v-else-if="$props.orderSpecials">
      <DashboardTitleRow
        class="OrderCategoryPage"
        :title="currentSpecials?.name || ' '"
        titleTagClass="h4"
        :goBack="{
          name: 'order',
        }"
      >
      </DashboardTitleRow>
      <SpecialDescription :special="currentSpecials" />
    </template>

    <LoadingIcon v-if="loading && !list.length" />
    <p class="no_data" v-else-if="!loading && !list.length">
      {{ $translate("no_data") }}
    </p>
    <template v-else>
      <div class="gridRowWrap" :class="viewType">
        <LoadingIcon v-if="loading" :absolute="true" />
        <div
          class="gridRow row"
          :class="[
            viewType,
            {
              disabledElem: loading,
            },
          ]"
        >
          <ul v-if="viewType == 'list'" class="listName">
            <li>{{ $translate("product_name", "orders") }}</li>
            <li>{{ $translate("product_description", "orders") }}</li>
            <li>{{ $translate("packaging", "orders") }}</li>
            <li>{{ $translate("order", "dashboard") }}</li>
            <li>{{ $translate("view_details", "orders") }}</li>
          </ul>
          <ProductCard
            v-for="item in list"
            :key="item.id"
            :data="item"
            :class="[viewType === 'grid' ? 'w33 tablet2w100 small' : 'w100']"
            :viewType="viewType"
            :link="getLink(item)"
            :buttonText="$translate('product_page', 'orders')"
            :active="isActiveCard"
            :currentSpecials="currentSpecials"
            :currentCategory="currentCategory"
          />
        </div>
      </div>

      <Pagination
        @paginate="paginate"
        :pageCurrent="pageCurrent"
        :pageCount="pageCount"
        :perPage="PAGE_SIZE"
        :class="{ disabledElem: loading }"
      />
    </template>
  </LayoutDashboard>
</template>

<script>
const PAGE_SIZE = 6;

// import list from "./list.json";
import special from "./special.json";
import LayoutDashboard from "@/layouts/Dashboard.vue";
import DashboardTitleRow from "@/components/DashboardTitleRow";
import ProductCard from "@/components/ProductCard";
import DisplayViewType from "@/components/DisplayViewType";
import SpecialDescription from "@/components/SpecialDescription";
import Pagination from "@/components/Pagination";
/**
 * The order-category-page component.
 */
export default {
  name: "OrderCategoryPage",
  components: {
    LayoutDashboard,
    DashboardTitleRow,
    ProductCard,
    DisplayViewType,
    SpecialDescription,
    Pagination,
  },
  props: {
    orderCategory: { default: false, type: Boolean },
    orderSpecials: { default: false, type: Boolean },
  },
  data() {
    return {
      PAGE_SIZE: PAGE_SIZE,
      currentCategory: null,
      productsCategories: [],
      currentSpecials: null,
      productsSpecials: [],
      pageCurrent: parseInt(this.$route.query.page) || 1,
      pageCount: 1,
      special,
      title: this.$translate("my_orders", "orders"),
      loading: true,
      list: [],
      viewType: "grid",
    };
  },
  computed: {
    isChildrenPage() {
      return (
        this.$route.name !== "order-category" &&
        this.$route.name !== "order-specials"
      );
    },
    isActiveCard() {
      if (this.$props.orderSpecials) {
        return this.currentSpecials?.active;
      } else {
        return this.currentCategory?.active;
      }
    },
  },
  methods: {
    paginate(page) {
      this.getProducts(page);
    },
    changeViewType(type) {
      this.viewType = type;
      localStorage.setItem("OrderCategoryViewType", type);
    },
    getLink(item) {
      if (!item.slug) return false;
      return {
        name: "order-product",
        params: { product: item.slug },
      };
    },
    async getProducts(page, params = {}) {
      if (this.loading && this.list.length) return false;
      this.loading = true;
      const filters = (() => {
        if (this.$props.orderSpecials) {
          return {
            marketingProducts: {
              id: {
                $in: [this.currentSpecials.id],
              },
            },
          };
        }
        return { category: this.currentCategory.id };
      })();

      const { list, pagination, success } =
        await this.$api.product.getProductsByCategory({
          pagination: { pageSize: PAGE_SIZE, page: page || this.pageCurrent },
          filters,
          ...params,
        });
      if (success) {
        console.log("pagination", pagination);
        this.list = list;
        this.pageCurrent = pagination.page;
        this.pageCount = pagination.pageCount;

        this.$router.push({
          query: this.$queryParams.getClear({
            page: this.pageCurrent,
          }),
        });
      }
      this.loading = false;
    },
    async getCurrentCategory() {
      const { list: productsCategories, success: successCategories } =
        await this.$api.product.getProductsCategories();
      if (successCategories) {
        this.productsCategories = productsCategories;

        this.currentCategory = this.productsCategories.find(
          ({ slug }) => slug === this.$route.params.category
        );

        if (this.currentCategory) {
          await this.getProducts();
        } else {
          // error
        }
      }
    },
    async getMarketingProducts() {
      const { success: successMarketing, list: productsSpecials } =
        await this.$api.product.getMarketingProducts();

      if (successMarketing) {
        this.productsSpecials = productsSpecials;

        this.currentSpecials = this.productsSpecials.find(
          ({ slug }) => slug === this.$route.params.category
        );

        if (this.currentSpecials) {
          await this.getProducts();
        } else {
          // error
        }
      }
    },
  },
  async created() {
    this.viewType = localStorage.getItem("OrderCategoryViewType") || "grid";
    if (this.$props.orderSpecials) this.viewType = "grid";

    if (this.$props.orderSpecials) {
      await this.getMarketingProducts();
    } else {
      await this.getCurrentCategory();
    }
  },
};
</script>

<style lang="scss" scoped src="./component.scss"></style>
