<template>
  <tr class="notifications-table-row">
    <td>
      <span class="date" v-if="currentDate">{{ currentDate }}</span>
      <span class="time" v-if="currentTime">{{ currentTime }}</span>
    </td>
    <td>
      <span
        v-if="!row.read"
        class="status"
        :style="{
          background: '#56AF40',
        }"
      ></span>
      <span class="theme" :class="{ bold: !row.read }" v-if="row?.name">{{
        row.name
      }}</span>
    </td>
    <td>
      <Tag
        v-if="row?.notificationType"
        :text="$translate(row.notificationType)"
        :type="row.notificationType === 'notification' ? 'active' : 'secondary'"
      />
    </td>
    <td>
      <Dropdown class="dropdown" :contentClass="'padding--min'">
        <router-link
          :to="{ name: 'chat', params: { id: row?.id } }"
          class="dropdown-item">
          {{ $translate("review") }}
        </router-link>
      </Dropdown>
    </td>
  </tr>
</template>

<script>
import Tag from "@/components/Table/PageTable/Tag";
import Dropdown from "@/components/Table/PageTable/Dropdown";

/**
 * The DocumentsTableItem component.
 */
export default {
  name: "DocumentsTableItem",
  components: { Tag, Dropdown },
  props: {
    row: { default: () => {}, type: Object },
  },
  computed: {
    hasLink() {
      return this.row?.document?.link != undefined;
    },
    currentDate() {
      if (!this.row.createdAt) return false;
      return this.$moment(this.row.createdAt).format("DD.MM.YY");
    },
    currentTime() {
      if (!this.row.createdAt) return false;
      return this.$moment(this.row.createdAt).format("HH:mm");
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped src="./component.scss"></style>
