export default {
    namespaced: true,
    state: {
        token: null,
        user: null,
        logined: false,
    },
    getters: {
        GET_User: (state) => state.user,
        GET_Token: (state) => state.token,
        GET_IsLogined: (state) => state.logined,
    },
    mutations: {
        SET_User(state, user) {
            state.user = user;
        },
        SET_Token(state, token) {
            state.token = token;
        },
        SET_IsLogined(state, logined) {
            state.logined = logined;
        },
    },
    actions: {
        SET_User({ commit }, { jwt, user, success = false }) {
            commit('SET_IsLogined', success);
            commit('SET_Token', jwt)
            commit('SET_User', user)
        }
    }
}