<template>
  <MetaTags>
    <title>{{ title }}</title>
  </MetaTags>
  <router-view v-if="isChildrenPage"></router-view>
  <LayoutDashboard name="home" v-else>
    <!-- <LoadingIcon v-if="loading" /> -->
    <template v-if="noUserData">
      <div class="noUserData">
        <DashboardTitleRow :title="$translate('my_office', 'dashboard')" />
        <div class="row">
          <DashboardCard
            class="w100"
            :title="$translate('welcome_personal_account', 'dashboard')"
            :text="$translate('welcome_personal_account_text', 'dashboard')"
            :close="true"
            v-if="!this.hideWelcomeAccountCard"
            @close="closeWelcomeAccountCard"
          >
            <template v-slot:icon>
              <HelloSVG class="iconWelcome" />
            </template>
            <MyButton
              class="w210"
              @click="showDashboardTour"
              :text="$translate('possibilities_of_account', 'dashboard')"
            />
          </DashboardCard>
          <DashboardCard
            class="w50 mobilew100"
            :title="$translate('create_order', 'dashboard')"
            :text="$translate('create_order_text', 'dashboard')"
          >
            <MyButton
              class="w210"
              :text="$translate('order', 'dashboard')"
              :link="{ name: 'order' }"
              tag="router-link"
            />
          </DashboardCard>
          <DashboardCard
            class="w50 mobilew100"
            :title="$translate('add_counterparty', 'dashboard')"
            :text="$translate('add_counterparty_text', 'dashboard')"
          >
            <MyButton
              class="w210"
              :text="$translate('add_a_company', 'dashboard')"
            />
          </DashboardCard>
        </div>
      </div>
    </template>
    <!-- The user has a confirmed counterparty -->
    <template v-else>
      <DashboardTitleRow
        :title="$translate('my_office', 'dashboard')"
        :notice="$translate('dashboard_title', 'notice')"
        :text="
          $translate('data_as_of', 'dashboard') +
          ' ' +
          $moment().format('D.MM.yyyy HH:mm')
        "
      >
        <template v-slot:title>
          <div class="btnWrap">
            <MyButton
              class="width-auto"
              iconAfter="add_document"
              :text="$translate('add_schedule', 'dashboard')"
              size="s"
              @click="click"
            />
          </div>
        </template>
      </DashboardTitleRow>
      <PageFilter
        :loading="loading"
        :list="filterList"
        @submit="submitFilter"
        @reset="resetFilter"
        @remove="removeFilterTag"
      >
        <LabelDropdown
          name="company"
          :title="$translate('counterparty', 'orders')"
          :list="companiesListComputed"
          :active="filterCurrent.company"
          @change="changeDropdown"
        />
      </PageFilter>
      <Transition mode="out-in">
        <LoadingIcon v-if="loading" />
        <MainTemplate
          v-else-if="!loading && GET_Graphics && GET_Graphics.length"
          :data="GET_Graphics"
        />
        <p v-else class="empty">{{ $translate('no_data', 'general') }}</p>
      </Transition>
    </template>
  </LayoutDashboard>
</template>

<script>
import LayoutDashboard from '@/layouts/Dashboard.vue';
import DashboardTitleRow from '@/components/DashboardTitleRow';
import DashboardCard from '@/components/DashboardCard';
import HelloSVG from '@/assets/img/hello.svg';
import PageFilter from '@/components/PageFilter';
import MainTemplate from '@/components/Dashboard/MainTemplate';
import { mapActions, mapGetters } from 'vuex';
/**
 * The home-page component.
 */
export default {
  name: 'DashboardPage',
  components: {
    LayoutDashboard,
    DashboardTitleRow,
    DashboardCard,
    HelloSVG,
    PageFilter,
    MainTemplate,
  },
  data() {
    const filter = this.$queryParams.parse(this.$route.query.filter);
    return {
      title: 'Dashboard | Home page',
      hideWelcomeAccountCard: false,
      loading: false,
      graphics: null,
      filterCurrentCopy: null,
      filterCurrent: {
        company: {
          id: filter.company?.id || null,
          name: filter.company?.name || null,
        },
      },
    };
  },
  computed: {
    ...mapGetters(['GET_Graphics', 'GET_Сompanies', 'GET_SidebarContacts']),
    noUserData() {
      return !this.$auth.user?.companies?.find((company) => company.verified);
    },
    isChildrenPage() {
      return this.$route.name !== 'dashboard';
    },
    filterList() {
      const list = [];

      // company
      if (this.filterCurrentCopy.company?.name) {
        list.push({
          id: 'company',
          text: this.filterCurrentCopy.company.name,
        });
      }

      return list;
    },
    companiesListComputed() {
      return this.GET_Сompanies.map((company) => ({
        id: company.id,
        name: company.name,
      }));
    },
  },
  methods: {
    ...mapActions(['GET_GraphicsRequest', 'INIT_SidebarContacts']),
    showDashboardTour() {
      this.$store.dispatch('SHOW_Onboarding', this.$route.name);
    },
    closeWelcomeAccountCard() {
      this.hideWelcomeAccountCard = true;
      sessionStorage.setItem('hideWelcomeAccountCard', true);
    },
    changeDropdown(key, name, item) {
      switch (name) {
        case 'company':
          this.filterCurrent.company = item;
          break;
        default:
          break;
      }
    },
    async submitFilter() {
      await this.getGraphics();
    },
    async resetFilter() {
      this.$queryParams.resetParams(this.filterCurrent);
      this.formKey = 'form_' + this.$rnd(0, 100);
      await this.getGraphics();
    },
    async removeFilterTag(id) {
      this.changeDropdown(null, id);
      await this.getGraphics();
    },
    async getGraphics(params = {}) {
      const clearFilter = this.$queryParams.getClear(
        this.filterCurrent,
        true,
        true
      );
      if (this.loading) return;
      this.loading = true;

      const { success } = await this.GET_GraphicsRequest({
        filters: clearFilter,
        ...params,
      });
      if (success) {
        this.filterCurrentCopy = this.$copyObject(this.filterCurrent);
        this.$router.push({
          query: this.$queryParams.getClear({
            filter: this.$queryParams.stringify(clearFilter),
          }),
        });
      }
      this.loading = false;
    },
    click() {
    },
  },
  watch: {
    async isChildrenPage(val) {
      if (!val) {
        this.loading = true;
        await this.$auth.getMe();
        this.loading = false;
      }
    },
  },
  async created() {
    this.hideWelcomeAccountCard =
      sessionStorage.getItem('hideWelcomeAccountCard') === 'true';
    // if (!this.hideWelcomeAccountCard) {
    //   await this.$store.dispatch("GET_Onboarding", this.$route.name);
    // }
    this.filterCurrentCopy = this.$copyObject(this.filterCurrent);
    if (!this.isChildrenPage) {
      if (!this.GET_Graphics) await this.getGraphics();
    }

    if (this.GET_SidebarContacts == null) {
      await this.INIT_SidebarContacts();
    }
  },
  async beforeCreate() {
    try {
      await this.$auth.getMe();
    } catch (error) {
      console.error(error);
    }
  },
  async mounted() {
    this.emitter.on('PageFilter:show', () => {
      this.filterCurrent = this.$copyObject(this.filterCurrentCopy);
      this.formKey = 'form_' + this.$rnd(0, 100);
    });
  },
  beforeUnmount() {
    this.emitter.off('PageFilter:show');
  },
};
</script>
<style lang="scss" scoped src="./component.scss"></style>
