<template>
  <Layout id="DeliverySchedule" v-if="GET_Checkout">
    <div class="titleRow">
      <h4>
        {{ $translate("supply", "orders") + " " + GET_Checkout.product.name }}
      </h4>
      <p>
        {{ GET_Checkout.basis }}/{{ GET_Checkout.shipmentType?.name }},
        {{ GET_Checkout?.order?.data?.quantity + " " + reserved.unit }} ,

        {{ $price(GET_Checkout?.order?.data?.amount, undefined, true) }}
      </p>
    </div>
    <div class="calendarTitleRow">
      <div class="controll">
        <MyButton
          class="arrowButton arrowLeft"
          type="circle"
          icon="arrow_left"
          @click="slideCalendar('prev')"
          :size="arrowButtonSize"
          :disabled="disabledPrevArrow"
        />
        <span class="currentDate">{{ currentDate }}</span>
        <MyButton
          class="arrowButton arrowRight"
          type="circle"
          icon="arrow_left"
          @click="slideCalendar('next')"
          :size="arrowButtonSize"
          :disabled="disabledNextArrow"
        />
      </div>
      <!-- <div class="quota">
        <span
          >{{ $translate("quota_per_month", "orders") }} - 780 т (10
          вагонів)</span
        >
        <MyButton
          :text="$translate('refresh', 'orders')"
          class="no-padding min-height refresh"
          type="transparent"
          icon="refresh"
        />
      </div> -->
    </div>
    <DeliveryCalendar
      @setCalendar="setCalendar"
      :quotas="GET_Checkout?.quotas"
      :reserved="GET_Checkout?.order?.data?.quotas"
      :totalQuantityAvailable="totalQuantityAvailable"
    />
    <div
      class="controllRow row margin0 between"
      :class="{
        alignStart: !distributeSupplyEdit,
        alignEnd: distributeSupplyEdit,
      }"
    >
      <div class="column leftColumn">
        <div class="reserve">
          <h6>{{ $translate("reserve", "orders") }}:</h6>
          <div class="reserveContent">
            <div class="total" v-if="reserved">
              {{ reserved.quantity + " " + reserved.unit }}
              <span v-if="reserved?.type?.typeVolume"
                >({{
                  reserved.type?.genitiveName?.toLowerCase() +
                  ": " +
                  reserved.type.typeVolume
                }})</span
              >
            </div>
            <div class="remainder">
              <span>{{ $translate("remainder", "orders") }}</span> -
              <span> {{ totalQuantityAvailable + " " + reserved.unit }}</span>
            </div>
          </div>
          <div
            class="distributeSupplyWrap"
            :class="{
              edit: distributeSupplyEdit,
              disabledElem: GET_LoadingOrder,
            }"
          >
            <template v-if="distributeSupplyEdit">
              <Label
                :title="$translate('delivery_start_date', 'orders')"
                name="deliveryStartDate"
                inputType="datepicker"
                @onInput="onInputDeliveryStartDate"
                ref="deliveryStartDate"
                :upperLimit="false"
                :lowerLimit="deliveryStartDateLowerLimit"
                calendarStartingView="days"
                :key="deliveryStartDateKey"
                :error="deliveryStartDateError"
                size="l"
              />
              <MyButton
                class="no-padding submitBtn"
                type="transparent"
                tag="div"
                @click="distributeSupply"
              >
                <SuccessSVG
              /></MyButton>
            </template>
            <MyButton
              v-else
              class="no-padding min-height distribute_supply"
              :text="$translate('distribute_supply', 'orders')"
              tag="div"
              type="transparent-primary"
              :disabled="GET_LoadingOrder"
              @click.prevent="distributeSupplyEdit = true"
            />
          </div>
        </div>
      </div>
      <div
        class="column rightColumn"
        :class="{ disabledElem: GET_LoadingOrder }"
      >
        <MyButton
          class="min-width go_back"
          :text="$translate('clear_delivery_schedule', 'orders')"
          tag="button"
          type="transparent"
          @click="resetQuotas"
          :disabled="disabledResetQuotas"
        />
        <MyButton
          class="w288 save"
          :text="$translate('save', 'profile')"
          tag="button"
          @click="saveData"
        />
      </div>
    </div>
  </Layout>
</template>

<script>
import { mapGetters } from "vuex";
import Layout from "../../PopupLayout";
import DeliveryCalendar from "@/components/Checkout/DeliveryCalendar";
import SuccessSVG from "@/assets/img/success2.svg";
/**
 * The DeliveryScheduleModal component.
 */
export default {
  name: "DeliveryScheduleModal",
  components: {
    Layout,
    DeliveryCalendar,
    SuccessSVG,
  },
  data() {
    return {
      calendar: null,
      currentDate: null,
      weekDays: this.$translate("week_days", "orders").split(","),
      months: this.$translate("months", "orders").split(","),
      distributeSupplyEdit: false,
      deliveryStartDate: null,
      deliveryStartDateKey: 0,
      deliveryStartDateError: false,
    };
  },
  computed: {
    ...mapGetters(["IS_Notebook", "GET_CurrentPopup"]),
    ...mapGetters("checkout", ["GET_Checkout", "GET_LoadingOrder"]),
    arrowButtonSize() {
      return this.IS_Notebook ? "m" : "l";
    },
    disabledPrevArrow() {
      if (!this.calendar) return true;
      return (
        this.calendar.year <= this.calendar.current.year &&
        this.calendar.month <= this.calendar.current.month
      );
    },
    disabledNextArrow() {
      return false;
    },
    reserved() {
      const quantity =
        this.GET_Checkout?.order?.data?.quotas.reduce(
          (acc, { quantity }) => acc + quantity,
          0
        ) || 0;
      const type = { ...this.GET_Checkout.calcQuantity.type };
      const unit = this.GET_Checkout.product?.unit?.name || "т";

      type.typeVolume = Math.ceil(quantity / type.wagonNorm);

      return { quantity, type, unit };
    },
    totalQuantityAvailable() {
      return this.GET_Checkout.order.data.quantity - this.reserved.quantity;
    },
    disabledResetQuotas() {
      return this.reserved.quantity === 0;
    },
    deliveryStartDateLowerLimit() {
      const nextDay = this.$moment(
        this.$moment().add(3, "day").format("DD.MM.YYYY"),
        "DD.MM.YYYY"
      );
      return nextDay._d;
    },
  },
  methods: {
    setCalendar(calendar, currentDate) {
      this.calendar = calendar;
      this.currentDate = currentDate;
    },
    slideCalendar(direction) {
      this.emitter.emit("slideDeliveryCalendar", direction);
    },
    resetQuotas() {
      this.emitter.emit("updateOrderQuotas", []);
      this.resetDeliveryStartDate();
    },
    saveData() {
      this.emitter.emit("editDeliveryCell", { edit: true });
      this.$popup.hide("DeliverySchedule");
      this.resetDeliveryStartDate();
    },
    distributeSupply() {
      if (this.deliveryStartDate) {
        this.emitter.emit("distributeSupply", this.deliveryStartDate);
        this.resetDeliveryStartDate();
      } else {
        this.deliveryStartDateError = true;
      }
    },
    onInputDeliveryStartDate(date) {
      this.deliveryStartDate = date;
    },
    resetDeliveryStartDate() {
      this.distributeSupplyEdit = false;
      this.deliveryStartDate = null;
      this.deliveryStartDateKey++;
      this.deliveryStartDateError = false;
    },
  },
};
</script>
<style lang="scss" src="./component.scss" scoped></style>
