export default ($axios) => {
    return {
        async getRequisites(params = {}) {
            return await $axios({
                url: '/requisites/',
                method: 'get', params: { ...params }
            }).then(({ data }) => {
                return { success: true, list: data.data, pagination: data.meta.pagination }
            }).catch(e => e)
        },
        async getRequisite(id) {
            return await $axios({
                url: '/requisites/' + id,
                method: 'get',
            }).then(({ data }) => {
                return { success: true, data: data.data }
            }).catch(e => e)
        },
        async getCompanyManagers(params = {}) {
            return await $axios({
                url: '/company-managers/',
                method: 'get', params: { ...params }
            }).then(({ data }) => {
                return { success: true, list: data.data, pagination: data.meta.pagination }
            }).catch(e => e)
        },
        async removeRequisite(id) {
            return $axios({
                url: "/requisites/" + id,
                method: "delete",
            })
                .then(({ data }) => {
                    return { success: true, data };
                })
                .catch((e) => e);
        },
        async removeCompanyManager(id) {
            return $axios({
                url: "/company-managers/" + id,
                method: "delete",
            })
                .then(({ data }) => {
                    return { success: true, data };
                })
                .catch((e) => e);
        },
        async removeDeliveryAddress(id) {
            return $axios({
                url: "/destination/" + id,
                method: "delete",
            })
                .then(({ data }) => {
                    return { success: true, data };
                })
                .catch((e) => e);
        },
        async addCompanyBills(data) {
            return $axios({
                url: "/requisites",
                method: "post",
                data: { data },
            })
                .then(({ data }) => {
                    return { success: true, data };
                })
                .catch((e) => e);
        },
        async editCompanyBills(data, id) {
            return $axios({
                url: "/requisites/" + id,
                method: "put",
                data: { data },
            })
                .then(({ data }) => {
                    return { success: true, data };
                })
                .catch((e) => e);
        },

        async addDeliveryAddress(data) {
            return $axios({
                url: "/destination",
                method: "post",
                data: { data },
            })
                .then(({ data }) => {
                    return { success: true, data };
                })
                .catch((e) => e);
        },
        async editDeliveryAddress(data, id) {
            return $axios({
                url: "/destination/" + id,
                method: "put",
                data: { data },
            })
                .then(({ data }) => {
                    return { success: true, data };
                })
                .catch((e) => e);
        },
        async addCompanyContactPerson(data) {
            return $axios({
                url: "/company-managers",
                method: "post",
                data: { data },
            })
                .then(({ data }) => {
                    return { success: true, data };
                })
                .catch((e) => e);
        },
        async editCompanyContactPerson(data, id) {
            return $axios({
                url: "/company-managers/" + id,
                method: "put",
                data: { data },
            })
                .then(({ data }) => {
                    return { success: true, data };
                })
                .catch((e) => e);
        },
        async getCompanyContactPerson(id) {
            return $axios({
                url: "/company-managers/" + id,
                method: "get",
            })
                .then(({ data }) => {
                    return { success: true, data };
                })
                .catch((e) => e);
        },
        async getCompany(id) {
            return $axios({
                url: "/company/" + id,
                method: "get",
            })
                .then(({ data }) => {
                    return { success: true, data };
                })
                .catch((e) => e);
        },
        async getDestinations(params = {}) {
            return $axios({
                url: "/destinations/",
                method: "get",
                params: { ...params }
            })
                .then(({ data }) => {
                    return { success: true, list: data.data, pagination: data.meta.pagination };
                })
                .catch((e) => e);
        },

    }
}