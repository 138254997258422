<template>
  <div class="DashboardTitleRow" :class="{ goBack }">
    <MyButton
      v-if="goBack"
      :text="goBackText || $translate('go_back', 'dashboard')"
      :to="goBack"
      icon="arrow_left2"
      class="goBack"
      type="transparent"
      tag="router-link"
    />
    <div class="row margin0 topRow alignCenter">
      <component
        :is="titleTag"
        :class="[titleTagClass, 'title']"
        v-if="title"
        v-html="title"
      ></component>
      <slot name="title"></slot>
    </div>
    <div class="row margin0 bottomRow" v-if="text">
      <p v-if="text" v-html="text"></p>
      <Tippy v-if="notice" :text="notice" />
    </div>
  </div>
</template>

<script>
/**
 * The DashboardTitleRow component.
 *
 */
import Tippy from '@/components/Tippy';

export default {
  name: 'DashboardTitleRow',
  components: { Tippy },
  props: {
    title: { default: null, type: String },
    titleTag: { default: 'h1', type: String },
    titleTagClass: { default: 'h1', type: String },
    text: { default: null, type: String },
    goBack: { default: null, type: Object },
    goBackText: { default: null, type: String },
    notice: { type: String, default: '' },
  },
  data() {
    return {};
  },
  computed: {
    // ...mapGetters(['IS_Tablet']),
  },
  methods: {
    // ...mapActions(['GET_PaymentInfo']),
  },
};
</script>

<style lang="scss" scoped src="./component.scss"></style>
