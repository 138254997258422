<template>
  <LayoutDashboard name="add-company">
    <DashboardTitleRow
      :title="
        $translate(
          editPage ? 'edit_delivery_address' : 'creation_delivery_address',
          'companies'
        )
      "
      :titleTag="'h1'"
      :titleTagClass="'h1'"
      :goBack="{
        name: 'delivery-address',
        params: { id: $route.params.id },
        query: { page: 1, sort: 'name=asc' },
      }"
    />
    <template v-if="editPage && !item">
      <LoadingIcon />
    </template>
    <DashboardCard class="p40 overflow w100per company-add" v-else>
      <div class="formWrap marg--0">
        <LoadingIcon v-if="loading" :absolute="true" />
        <form
          novalidate
          @submit.prevent="submit"
          :class="{ loadingElem: loading }"
          :key="formKey"
        >
          <div class="row margin47 topRow">
            <Label
              :title="$translate('address_name')"
              :placeholder="$translate('address_name')"
              name="name"
              inputType="text"
              @onInput="onInputField"
              :error="inputError['name']"
              ref="name"
              class="w50 mobilew100"
              :value="name"
            />
            <LabelSearchSelect
              :getData="addressAutocomplete"
              :getDataParams="createAddressSearchParams"
              :value="address"
              :title="$translate('address')"
              :placeholder="$translate('address')"
              :error="inputError['address']"
              :sortResults="false"
              @change="changeDropdown"
              class="w50 mobilew100 no-marg address-search"
              displayProperty="description"
              name="address"
            />
            <LabelSearchSelect
              :getData="getCities"
              :getDataParams="createCitiesSearchParams"
              :placeholder="$translate('city', 'companies')"
              :title="$translate('city', 'companies')"
              :value="city"
              @change="changeDropdown"
              class="w50 mobilew100 darkPlaceholder marginBottom0"
              displayProperty="name"
              name="city"
            />
            <Label
              :title="$translate('region', 'orders')"
              :placeholder="$translate('region', 'orders')"
              name="region"
              inputType="text"
              :key="regionKey"
              @onInput="onInputField"
              :error="inputError['region']"
              ref="region"
              class="w50 mobilew100"
              :value="region"
            />
            <Label
              :title="$translate('index')"
              :placeholder="$translate('index')"
              name="index"
              inputType="text"
              @onInput="onInputField"
              :error="inputError['index']"
              ref="index"
              :key="indexKey"
              class="w50 mobilew100"
              :numbersOnly="true"
              :value="index"
            />
          </div>
          <div class="row margin0 between bottomRow">
            <MyButton
              :text="$translate('cancel')"
              tag="button"
              tagType="reset"
              type="cancel"
              :dynamicSize="true"
              @click="resetForm"
            />
            <MyButton
              class="w288"
              :text="
                $translate(
                  editPage ? 'refresh' : 'create',
                  editPage ? 'orders' : 'companies'
                )
              "
              tag="button"
              tagType="submit"
              :dynamicSize="true"
            />
          </div>
        </form>
      </div>
    </DashboardCard>
  </LayoutDashboard>
</template>
<script>
import LayoutDashboard from "@/layouts/Dashboard.vue";
import DashboardTitleRow from "@/components/DashboardTitleRow";
import DashboardCard from "@/components/DashboardCard";
import LabelSearchSelect from "@/components/LabelSearchSelect";
import throttle from "lodash/throttle";
import uniqueId from 'lodash/uniqueId';

/**
 * @typedef {object} Matchedsubstring
 * @property {number} length
 * @property {number} offset
 *
 * @typedef {object} Structuredformatting
 * @property {string} main_text
 * @property {Matchedsubstring[]} main_text_matched_substrings
 * @property {string} secondary_text
 *
 * @typedef {object} AutocompleteEntry
 * @property {string} description
 * @property {Matchedsubstring[]} matched_substrings
 * @property {string} place_id
 * @property {string} reference
 * @property {Structuredformatting} structured_formatting
 * @property {{ offset: number; value: string }[]} terms
 * @property {string[]} types
 */

export default {
  name: "AddCompanyDeliveryAddress",
  components: {
    LayoutDashboard,
    DashboardTitleRow,
    DashboardCard,
    LabelSearchSelect,
  },
  data() {
    return {
      formKey: "form_",
      id: this.$route.params.id,
      loading: false,
      inputError: {},
      name: "",
      city: "",
      region: "",
      regionKey: uniqueId('region_'),
      address: "",
      index: "",
      indexKey: uniqueId('index_'),
      editPage: this.$route.name == "edit-delivery-address",
      item: null,
    };
  },
  computed: {},
  methods: {
    async getCities(params = {}, abortSignal) {
      const { success, list } = await this.$api.getCities(params, abortSignal);

      return success ? list : [];
    },
    createCitiesSearchParams(value) {
      const pagination = {
        pageSize: 100,
        withCount: false,
      }
      const sort = {
        name: 'asc',
      }
      const filters = value != null 
        ? { name: { $containsi: value } }
        : {}

      return {
        filters,
        sort,
        pagination,
      }
    },
    onInputField(value, $event, name, required = false, validateType = "name") {
      if (!name) throw new Error("onInputField: name is undefined");
      if (value != undefined) this[name] = value;
      const validateError = this.$simpleInputValidate(
        value || this[name],
        required,
        validateType
      );
      this.inputError[name] = validateError;
      if (validateError.error) {
        this.$refs[name].$refs.input.focus();
      }
      return validateError.error;
    },
    async submit() {
      if (this.loading) return false;

      const error = (() => {
        const nameError = this.onInputField(undefined, undefined, "name", true);
        const cityError = this.onInputField(
          undefined,
          undefined,
          "city",
          true,
          'select'
        );
        const regionError = this.onInputField(
          undefined,
          undefined,
          "region",
          true
        );
        const addressError = this.onInputField(
          undefined,
          undefined,
          "address",
          true,
          'select',
        );
        const indexError = this.onInputField(
          undefined,
          undefined,
          "index",
          true
        );
        return (
          nameError || cityError || regionError || addressError || indexError
        );
      })();
      if (!error) {
        this.loading = true;

        const payload = {
          name: this.name,
          city: this.city,
          region: this.region,
          address: this.address.description,
          addressPlaceId: this.address.place_id,
          index: this.index,
          company: this.id,
        };
        let { success } = await this.$api.company[
          this.editPage ? "editDeliveryAddress" : "addDeliveryAddress"
        ](
          payload,
          this.item?.id
        );
        this.loading = false;
        if (success) {
          this.$router.push({
            name: "delivery-address",
            params: { id: this.id },
          });
          setTimeout(() => {
            this.$notice(
              this.$translate(
                this.editPage
                  ? "delivery_address_updated"
                  : "delivery_address_created",
                "companies"
              )
            );
          }, 500);
        } else {
          this.$notice(this.$translate("request_error"), undefined, {
            type: "error",
          });
        }
      } else console.log("submit", { error });
    },
    resetForm() {
      this.loading = false;
      this.name = this.editPage ? this.item.name : "";
      this.city = this.editPage ? this.item.city : "";
      this.region = this.editPage ? this.item.region : "";
      this.address = this.editPage ? this.item.address : "";
      this.index = this.editPage ? this.item.index : "";
      this.inputError = {};
      this.formKey = "form_" + this.$rnd(0, 100);
    },
    async getItem() {
      const { list, success } = await this.$api.company.getDestinations({
        filters: {
          company: this.$route.params.id,
          id: this.$route.params.itemId,
        },
      });
      this.item = list[0];
      if (!success || !this.item) {
        return this.$store.dispatch("SET_ErrorPage", {
          code: 404,
          message: this.$translate("page_not_found"),
        });
      }
      this.name = this.item.name;
      this.city = this.item.city;
      this.region = this.item.region;
      this.address = this.item.address;
      this.index = this.item.index;
    },
    /**
     * @param {AutocompleteEntry} item
     */
    async maybeMapAddressToFormFields(item) {
      if (item.terms.length < 3) return;

      const containstZipCode = /[0-9]{5}/.test(item.terms.at(-1).value)
      const terms = containstZipCode ? item.terms.slice(0, -1) : item.terms;
      const zipCode = containstZipCode ? item.terms.at(-1).value : null;
      const [
        /* country */,
        { value: region },
        { value: city }
      ] = terms.reverse();
      const regionRaw = region.replace('область', '').trim();
      const pagination = {
        pageSize: 1,
        withCount: false,
      }
      this.loading = true;
      try {
        const [{ list: cmsCities }, { list: cmsRegions }] = await Promise.all([
          this.$api
            .getCities({
              filters: {
                name: {
                  $containsi: `${city}, ${regionRaw}`,
                }
              },
              pagination,
            })
            .catch(() => ({ success: false, list: [] })),
          this.$api
            .getRegions({
              filters: {
                name: {
                  $containsi: regionRaw,
                }
              },
              pagination,
            })
            .catch(() => ({ success: false, list: [] })),
        ]);

        const [cityItem] = cmsCities;
        const [regionItem] = cmsRegions;

        this.loading = false;

        if (cityItem != null) {
          this.city = cityItem;
        }

        if (regionItem != null) {
          this.region = regionItem.name;
          this.regionKey = uniqueId('region_');
        }

        if (zipCode != null) {
          this.index = zipCode;
          this.indexKey = uniqueId('index_');
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    changeDropdown: throttle(function (key, name, item) {
      if (name && item) {
        this[name] = item;
      }
      if (name === 'address') {
        this.maybeMapAddressToFormFields(item);
      }
    }, 100),
    createAddressSearchParams(value) {
      return { value };
    },
    async addressAutocomplete(params = {}) {
      if (params.value == null || params.value?.trim().length === 0) {
        return [];
      }

      const res = await this.$placesSearch.search(params.value);

      return res.map(({ place_id, ...item }) => ({
        ...item,
        place_id,
        id: place_id
      }));
    },
  },
  async created() {
    if (!this.$placesSearch.ready) {
      this.$placesSearch.init();
    }
    if (this.editPage) await this.getItem();
  },
};
</script>
<style lang="scss" src="../../style.scss" scoped></style>
