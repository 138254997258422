<template>
  <LayoutDashboard name="create-area">
    <DashboardTitleRow
      :title="
        $translate(isEdit ? 'update_of_a_land_plot' : 'creation_of_a_land_plot', 'companies')
      "
      :goBack="{ name: 'companies' }"
    />
    <Transition mode="out-in">
      <DashboardCard class="p40 createArea overflow">
        <div class="formWrap">
          <LoadingIcon v-if="loading" :absolute="true" />
          <form
            novalidate
            @submit.prevent="submit"
            :class="{ loadingElem: loading }"
            :key="formKey"
          >
            <div class="row margin47">
              <div class="column w50 mobilew100">
                <Label
                  :error="inputError['name']"
                  :placeholder="$translate('name', 'orders')"
                  :title="$translate('name', 'orders')"
                  :value="name"
                  @onInput="(value, event, name) => onInputField(value, event, name, 'name', true)"
                  @updateValueFunc="updateName = $event"
                  inputType="text"
                  name="name"
                  ref="name"
                />
              </div>
              <div class="column w50 mobilew100">
                <LabelDropdown
                  id="input_counterparty"
                  :active="counterparty"
                  :disabled="counterpartyListLoading"
                  :error="inputError['counterparty']?.error"
                  :errorText="inputError['counterparty']?.text"
                  :loading="counterpartyListLoading"
                  :list="counterpartyList"
                  :title="$translate('counterparty', 'orders')"
                  @change="changeDropdown"
                  name="counterparty"
                />
              </div>
            </div>
            <div class="row margin47">
              <div class="column w50 mobilew100">
                <Label
                  :error="inputError['area']"
                  :key="areaKey"
                  :placeholder="$translate('sown_areaGa', 'companies')"
                  :title="$translate('sown_areaGa', 'companies')"
                  :value="area"
                  @onInput="(value, event, name) => onInputField(value, event, name, 'cropArea', true)"
                  @updateValueFunc="updateArea = $event"
                  inputType="text"
                  mask-tokens="0:\d:multiple|0:\d:optional"
                  mask="0000000.00"
                  name="area"
                  ref="area"
                />
              </div>
              <div class="column w50 mobilew100">
                <Label
                  :error="inputError['culture']"
                  :placeholder="$translate('culture_for_sowing', 'companies')"
                  :title="$translate('culture_for_sowing', 'companies')"
                  :value="culture"
                  @onInput="(value, event, name) => onInputField(value, event, name, 'name', true)"
                  @updateValueFunc="updateCulture = $event"
                  inputType="text"
                  name="culture"
                  ref="culture"
                />
              </div>
            </div>
            <div class="row margin47">
              <div class="column w50 mobilew100">
                <LabelDropdown
                  id="input_region"
                  :active="region"
                  :disabled="regionsListLoading"
                  :error="inputError['region']?.error"
                  :errorText="inputError['region']?.text"
                  :loading="regionsListLoading"
                  :list="regionList"
                  :title="$translate('area_of_the_site', 'companies')"
                  @change="changeDropdown"
                  name="region"
                />
              </div>
            </div>
            <div class="row margin0 between bottomRow">
              <MyButton
                v-if="isEdit"
                :dynamicSize="true"
                :text="$translate('cancel')"
                @click="deleteCropArea"
                tag="button"
                tagType="button"
                type="cancel"
              />
              <MyButton
                v-else
                :dynamicSize="true"
                :text="$translate('cancel')"
                @click="resetForm"
                tag="button"
                tagType="reset"
                type="cancel"
              />
              <MyButton
                :dynamicSize="true"
                :text="$translate('save', 'profile')"
                class="w288"
                tag="button"
                tagType="submit"
              />
            </div>
          </form>
        </div>
      </DashboardCard>
    </Transition>
  </LayoutDashboard>
</template>

<script>
import LayoutDashboard from "@/layouts/Dashboard.vue";
import DashboardTitleRow from "@/components/DashboardTitleRow";
import DashboardCard from "@/components/DashboardCard";
/**
 * The companies-upsert-area-page  component.
 */
export default {
  name: "companies-upsert-area",
  components: {
    LayoutDashboard,
    DashboardTitleRow,
    DashboardCard,
  },
  data() {
    return {
      formKey: "upsertAreaForm",
      loading: false,
      inputError: {
        name: { error: false },
        counterparty: { error: false },
        area: { error: false },
        culture: { error: false },
        region: { error: false },
      },
      name: "",
      counterparty: null,
      counterpartyList: [],
      counterpartyListLoading: false,
      area: "",
      areaKey: 0,
      culture: "",
      region: null,
      regionList: [],
      regionsListLoading: false,
      updateName: null,
      updateArea: null,
      updateCulture: null,
    };
  },
  computed: {
    currentCropAreaId() {
      return parseInt(this.$route.params.id);
    },
    isEdit() {
      return !Number.isNaN(this.currentCropAreaId);
    },
  },
  methods: {
    setFormFields(data) {
      this.updateName instanceof Function && this.updateName(data.name);
      this.updateArea instanceof Function && this.updateArea(data.cropArea);
      this.updateCulture instanceof Function && this.updateCulture(data.culture);
      
      {
        const { id, name } = data.company;
        this.counterparty = {
          id,
          name,
        };
      }
      {
        const { id, name } = data.region;
        this.region = {
          id,
          name,
        };
      }
    },
    hydrateFromNavigation() {
      try {
        const dataFromNavigation = JSON.parse(
          localStorage.getItem('routeTransition:companies-upsert-area')
        );

        if (dataFromNavigation) {
          this.setFormFields(dataFromNavigation);
          return true;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async setCropArea() {
      try {
        this.loading = true;
        let dataVal = {
          culture: this.culture,
          region: this.region.id,
          cropArea: parseFloat(this.area),
          company: this.counterparty.id,
          name: this.name,
        };
        let { success = false } = await this.isEdit 
          ? this.$api.cropArea.update(this.currentCropAreaId, dataVal)
          : this.$api.cropArea.setCropArea({ data: dataVal });

        if (success) {
          this.$router.push({ name: "companies" });
          setTimeout(() => {
            this.$notice(this.$translate("crop_area_success_created"));
          }, 500);
        } else {
          throw new Error('request error')
        }
        this.loading = false;
      } catch (e) {
        console.error(e);
        this.$notice(
          this.$translate("crop_area_failure_created"),
          undefined,
          { type: "error" }
        );
      } finally {
        this.loading = false;
      }
    },
    async getСompanies() {
      this.counterpartyListLoading = true;
      const data = await this.$api.getСompanies();
      this.counterpartyList = data.list;
      this.counterpartyListLoading = false;
    },
    async getRegions() {
      this.regionsListLoading = true;
      const { list = [] } = await this.$api.checkout.getRegions({
        sort: {
          name: "asc",
        },
        pagination: {
          withCount: false,
          pageSize: 50,
          page: 1,
        }
      });
      this.regionList = list;
      this.regionsListLoading = false;
    },
    async deleteCropArea() {
      try {
        await this.$api.cropArea.delete({
          id: this.currentCropAreaId,
        });
        this.$notice(this.$translate("deleted"));
        this.$router.push({ name: "companies" });
      } catch (error) {
        console.error(error);
        this.$notice(this.$translate("request_error"), undefined, {
          type: "error",
        });
      }
    },
    changeDropdown(key, name, item) {
      switch (name) {
        case "counterparty":
          this.counterparty = item;
          this.onInputField(item, undefined, 'counterparty', true, 'select');
          break;
        case "region":
          this.region = item;
          this.onInputField(item, undefined, 'region', true, 'select');
          break;
        default:
          break;
      }
    },
    onInputField(
      value,
      event,
      name,
      required = false,
      validateType = "name"
    ) {
      if (!name) throw new Error("onInputField: name is undefined");
      if (value != null) this[name] = value;

      const validateError = this.$simpleInputValidate(
        value || this[name],
        required,
        validateType
      );

      this.inputError[name] = validateError || null;

      if (
        validateError.error &&
        this.$refs[name] != null &&
        this.$refs[name].$refs?.input instanceof HTMLElement
      ) {
        this.$refs[name].$refs.input.focus();
      }

      return validateError.error;
    },
    async submit() {
      if (this.loading) return false;

      const fields = [
        ['name', 'name'],
        ['area', 'cropArea'],
        ['culture', 'name'],
        ['counterparty', 'select'],
        ['region', 'select'],
      ];
      const error = fields.some(([field, validateType]) =>
        this.onInputField(undefined, undefined, field, true, validateType)
      );

      if (error) {
        return
      }
      
      this.setCropArea();
    },
    resetForm() {
      this.successCreated = false;
      this.errorCreated = false;

      this.loading = false;
      this.name = "";
      this.counterparty = null;
      this.area = "";
      this.culture = "";
      this.region = null;

      this.inputError = {
        name: { error: false },
        counterparty: { error: false },
        area: { error: false },
        culture: { error: false },
        region: { error: false },
      };
      this.formKey = "form_" + this.$rnd(0, 100);
    },
  },
  async mounted() {
    if (this.isEdit) {
      const isHydrated = this.hydrateFromNavigation();

      if (!isHydrated) {
        try {
          const { data } = await this.$api.cropArea.getCropAreaById(
            this.currentCropAreaId,
            {
              populate: {
                company: {
                  fields: ['name']
                },
                region: {
                  fields: ['name']
                },
              }
            }
          );
          this.setFormFields(data);
        } catch (error) {
          console.error(error);
          this.$notice(this.$translate("request_error"), undefined, {
            type: "error",
          });
          this.$router.push({ name: "companies" });
          return;
        }
      }
    } // isEdit

    localStorage.removeItem('routeTransition:companies-upsert-area');
  },
  async created() {
    await Promise.all([
      this.getСompanies().catch(err => {
        console.error(err);
        this.$notice(this.$translate("request_error"), undefined, {
          type: "error",
        });
      }),
      this.getRegions().catch(err => {
        console.error(err);
        this.$notice(this.$translate("request_error"), undefined, {
          type: "error",
        });
      })
    ]);
  }
};
</script>

<style lang="scss" scoped src="./component.scss"></style>
