<template>
  <div class="Notice" :class="{ hasText: text }">
    <span class="text" v-if="text">{{ text }}</span>
    <InfoIcon class="InfoIcon" v-if="!disableIcon" />
    <div class="content">
      <h5 v-if="title">{{ title }}</h5>
      <slot />
    </div>
  </div>
</template>

<script>
import InfoIcon from "@/assets/img/info.svg";

/**
 * The Notice component.
 */
export default {
  name: "NoticeComponent",
  components: { InfoIcon },
  props: {
    title: { default: null, type: String },
    text: { default: null, type: String },
    disableIcon: { default: false, type: Boolean },
  },
  data() {
    return {};
  },
  computed: {
    // ...mapGetters(['IS_Tablet']),
  },
  methods: {
    // ...mapActions(['GET_PaymentInfo']),
  },
};
</script>

<style lang="scss" scoped src="./component.scss"></style>
