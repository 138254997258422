<template>
  <div class="OrderAccordionWrap">
    <div class="OrderAccordion">
      <div class="topRow" @click="click()">
        <div class="icon" :class="{ iconOpen: open }">
          <ArrowBottomSVG />
        </div>
        <div class="title column">{{ order.number }}</div>
        <template v-if="balanceType === 'trade'">
          <div class="price column" v-if="order.amount">
            <span class="name">{{ $translate("amount", "orders") }}</span>
            <span class="value">{{ $price(order.amount) }}</span>
          </div>
          <div class="price column amount" v-if="finalSaldo">
            <span class="name">{{ $translate("balance", "companies") }}</span>
            <span class="value">{{ finalSaldo }}</span>
          </div>
        </template>
        <template v-else-if="balanceType === 'volume'">
          <div class="price column" v-if="order.quantity">
            <span class="name">{{ $translate("volume", "orders") }}</span>
            <span class="value">{{ order.quantity + " т" }}</span>
          </div>
          <div class="price column amount" v-if="finalSaldo">
            <span class="name">{{ $translate("remainder", "orders") }}</span>
            <span class="value">{{ finalSaldo + " т" }}</span>
          </div>
        </template>
      </div>
      <div class="content" ref="content">
        <OrderDocuments
          :list="listWithSaldo"
          v-if="listWithSaldo.length"
          :loading="loading"
          :orderId="order.id"
          :balanceType="balanceType"
        />
        <p class="no_data" v-else>{{ $translate("no_data") }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import ArrowBottomSVG from "@/assets/img/arrow_bottom.svg";
import OrderDocuments from "@/components/Table/OrderDocuments";

/**
 * The OrderAccordion component.
 */
export default {
  name: "OrderAccordion",
  components: { ArrowBottomSVG, OrderDocuments },
  props: {
    order: { default: null, type: Object },
    balanceType: { default: null, type: String },
  },
  data() {
    return {
      disabled: false,
      open: false,
      loading: false,
    };
  },
  computed: {
    list() {
      if (!this.order?.documents) return [];
      return this.getAllRelatedDocuments(this.order.documents);
    },
    listWithSaldo() {
      if (this.balanceType === "trade" && this.order.amount) {
        let amount = this.order.amount;
        return this.list.map((element) => {
          if (element.amount) amount -= element.amount;
          return { ...element, saldo: amount };
        });
      } else if (this.balanceType === "volume" && this.order.quantity) {
        let quantity = this.order.quantity;
        return this.list.map((element) => {
          if (element.quantity) quantity -= element.quantity;
          return { ...element, saldo: quantity };
        });
      }
      return this.list;
    },
    finalSaldo() {
      if (this.balanceType === "trade" && this.order.amount) {
        return this.$price(
          this.listWithSaldo[this.listWithSaldo.length - 1]?.saldo
        );
      } else if (this.balanceType === "volume" && this.order.quantity) {
        return this.listWithSaldo[this.listWithSaldo.length - 1]?.saldo || null;
      }
      return null;
    },
  },
  methods: {
    getAllRelatedDocuments(arr, level = 0) {
      let result = [];
      for (const item of arr) {
        result.push({ ...item, level });
        if (item.relatedDocuments && item.relatedDocuments.length > 0) {
          item.relatedDocuments.forEach((element) => {
            element.parentId = item.id;
          });
          const nestedDocuments = this.getAllRelatedDocuments(
            item.relatedDocuments,
            level + 1
          );
          result = [...result, ...nestedDocuments];
        }
      }
      // level 1
      result
        .filter(({ level }) => level === 1)
        .forEach((el, index, arr) => {
          el.last =
            !el.relatedDocuments ||
            !el.relatedDocuments.length ||
            index === arr.length - 1;
          if (el.relatedDocuments && el.relatedDocuments.length) {
            const id = el.relatedDocuments[el.relatedDocuments.length - 1].id;
            result.find((el) => el.id === id).last = true;
          }
        });
      // level 0
      result
        .filter(({ level }) => level === 0)
        .forEach((el) => {
          if (el.relatedDocuments && el.relatedDocuments.length) {
            const id = el.relatedDocuments[el.relatedDocuments.length - 1].id;
            result.find((el) => el.id === id).last = true;
          }
        });
      return result;
    },
    click(time = 0.25) {
      if (this.disabled) return;
      this.disabled = true;
      this.open = !this.open;
      this.$slideAccordion(
        this.$refs.content,
        this.open,
        () => {
          this.disabled = false;
        },
        time,
        () => {
          this.emitter.emit("openOrderAccordion", this.orderId);
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped src="./component.scss"></style>
