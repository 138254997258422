<template>
  <tr @click="click">
    <td>
      <span v-if="row?.name"> {{ row.name }}</span>
    </td>

    <td>
      <span v-if="row?.region?.name">{{ row.region.name }}</span>
    </td>
    <td>
      <span v-if="row?.cropArea"
        >{{ row.cropArea }} {{ $translate("GA") }}</span
      >
    </td>
    <td>
      <span v-if="row?.company?.name">{{ row.company.name }}</span>
    </td>
    <td>
      <Dropdown
        ref="dropdown" 
        class="menu-dropdown"
        :contentClass="'padding--min'">
        <button @click="navigateToEdit" class="dropdown-btn" type="button" aria-label="">
          <span>{{ $translate("edit") }}</span>
        </button>
        <button @click="$emit('remove', row?.id)" class="dropdown-btn" type="button" aria-label="">
          <span>{{ $translate("delete") }}</span>
        </button>
      </Dropdown>
    </td>
  </tr>
</template>

<script>
import Dropdown from "@/components/Table/PageTable/Dropdown";

/**
 * The DocumentsTableItem component.
 */
export default {
  name: "DocumentsTableItem",
  components: {
    Dropdown,
  },
  props: {
    row: { default: () => {}, type: Object },
  },
  computed: {
  },
  methods: {
    click(e) {
      try {
        const cancelClass = this.$refs.dropdown
          ? this.$refs.dropdown.$el.classList.toString()
          : ".Dropdown";
        let cancelTarget = e.target.closest(`.${cancelClass}`);
        if (cancelTarget) return null;
      } catch (e) {
        console.log(e);
      }
    },
    navigateToEdit() {
      localStorage.setItem('routeTransition:companies-upsert-area', JSON.stringify(this.row))
      return this.$router.push({
        name: "area-edit",
        params: {
          id: this.row?.id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped src="./component.scss"></style>
