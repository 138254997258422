export default ($axios) => {
  return {
    async setCropArea(data) {
      console.log('setCropArea', data);
      return await $axios({
        url: '/crop-area',
        method: 'post',
        data,
      })
        .then(({ data }) => {
          return { success: true, data: data.data };
        })
        .catch((e) => {
          return { success: false, error: e, list: [] };
        });
    },
    async update(id, data) {
      return await $axios({
        url: '/crop-area/' + id,
        method: 'put',
        data,
      })
        .then(({ data }) => {
          return { success: true, data: data.data };
        })
        .catch((e) => {
          return { success: false, error: e };
        });
    },
    async delete(id) {
      return await $axios({
        url: '/crop-area/' + id,
        method: 'delete',
      })
        .then(({ data }) => {
          return { success: true, data: data.data };
        })
        .catch((e) => {
          return { success: false, error: e };
        });
    },
    async getCropArea(params = {}) {
      return $axios({
        url: '/crop-areas',
        method: 'get',
        params: { ...params },
      })
        .then(({ data }) => {
          return {
            success: true,
            list: data.data,
            pagination: data.meta.pagination,
          };
        })
        .catch((e) => e);
    },
    async getCropAreaById(id, params = {}) {
      return $axios({
        url: '/crop-area/' + id,
        method: 'get',
        params: { ...params },
      })
        .then(({ data }) => {
          return {
            success: true,
            data: data.data,
          };
        })
        .catch((e) => e);
    },
    async getRecommenationCropArea(params = {}) {
      return $axios({
        url: '/crop-areas/recommendations',
        method: 'get',
        params: { ...params },
      })
        .then(({ data }) => {
          return {
            success: true,
            data: data.data,
          };
        })
        .catch((e) => e);
    },
  };
};
