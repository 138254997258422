<template>
  <LayoutDashboard name="profile">
    <DashboardTitleRow :title="$translate('profile', 'dashboard')" />
    <div class="mainRow row alignStart">
      <DashboardCard class="p40 w33 overflow column">
        <h2 class="h4">{{ $translate("basic_information", "profile") }}</h2>
        <div class="formWrap">
          <LoadingIcon v-if="loadingBasicInformation" :absolute="true" />
          <form
            novalidate
            @submit.prevent="submitBasicInformation"
            :class="{ loadingElem: loadingBasicInformation }"
            :key="formBasicInformationKey"
          >
            <Label
              :title="$translate('first_last_name', 'auth') + '*'"
              name="name"
              :placeholder="$translate('first_last_name_example', 'auth')"
              inputType="text"
              @onInput="onInputName"
              :error="nameError"
              :errorText="nameErrorText"
              ref="name"
              autocomplete="name"
              :value="name"
              :editable="true"
            />
            <Label
              :title="$translate('birth_date', 'auth')"
              name="birthDate"
              inputType="datepicker"
              @onInput="onInputBirthDate"
              ref="birthDate"
              :value="birthDate"
              :editable="true"
            />
            <Label
              :title="$translate('email', 'auth') + '*'"
              name="email"
              :placeholder="$translate('email_example', 'auth')"
              inputType="email"
              @onInput="onInputEmail"
              :error="emailError"
              :errorText="emailErrorText"
              ref="email"
              :value="email"
              :editable="true"
            />
            <Label
              :title="$translate('phone_number', 'auth') + '*'"
              name="phone"
              placeholder="+38 093 000 00 00"
              inputType="tel"
              @onInput="onInputPhone"
              :error="phoneError"
              :errorText="phoneErrorText"
              ref="phone"
              autocomplete="tel"
              mask="+38 ### ### ## ##"
              :value="phone"
              :editable="true"
            />
            <MyButton
              class="w240"
              :text="$translate('save', 'profile')"
              tag="button"
              :size="buttonSize"
            />
          </form>
        </div>
      </DashboardCard>
      <div class="column w66">
        <div class="row alignStart">
          <DashboardCard class="p40 w50">
            <h2 class="h4">{{ $translate("password_change", "profile") }}</h2>
            <div class="formWrap">
              <LoadingIcon v-if="loadingEditPassword" :absolute="true" />
              <form
                novalidate
                @submit.prevent="submitEditPassword"
                :class="{ loadingElem: loadingEditPassword }"
                :key="formEditPasswordKey"
              >
                <Label
                  :title="$translate('enter_password', 'auth') + '*'"
                  name="password"
                  :placeholder="$translate('enter_password', 'auth')"
                  inputType="password"
                  @onInput="onInputpassword"
                  :error="passwordError"
                  :errorText="passwordErrorText"
                  ref="password"
                />
                <Label
                  :title="$translate('password_again', 'auth') + '*'"
                  name="passwordConfirmation"
                  :placeholder="$translate('password_again', 'auth')"
                  inputType="password"
                  @onInput="onInputPasswordConfirmation"
                  :error="passwordConfirmationError"
                  :errorText="passwordConfirmationErrorText"
                  ref="passwordConfirmation"
                />
                <MyButton
                  class="w240"
                  :text="$translate('save', 'profile')"
                  tag="button"
                  :size="buttonSize"
                />
              </form>
            </div>
          </DashboardCard>
          <DashboardCard class="p40 w50">
            <h2 class="h4">
              {{ $translate("receiving_notifications", "profile") }}
            </h2>
            <div class="formWrap w100">
              <LoadingIcon v-if="loadingNotifications" :absolute="true" />
              <form
                novalidate
                :class="{ loadingElem: loadingNotifications }"
                :key="formNotificationsKey"
              >
                <CheckBox
                  name="receiving_notifications"
                  :text="
                    $translate(
                      'receive_marketing_subscription_notifications',
                      'profile'
                    )
                  "
                  :checked="receiving_notifications"
                  :error="receiving_notificationsError"
                  @onInput="onInputReceiving_notifications"
                  ref="receiving_notifications"
                />
              </form>
            </div>
          </DashboardCard>
        </div>
      </div>
    </div>
  </LayoutDashboard>
</template>

<script>
import { mapGetters } from "vuex";
import LayoutDashboard from "@/layouts/Dashboard.vue";
import DashboardTitleRow from "@/components/DashboardTitleRow";
import DashboardCard from "@/components/DashboardCard";
import CheckBox from "@/components/CheckBox";
/**
 * The profile-page component.
 */
export default {
  name: "ProfilePage",
  components: {
    LayoutDashboard,
    DashboardTitleRow,
    DashboardCard,
    CheckBox,
  },
  data() {
    return {
      name: this.$auth.user.fullName,
      nameError: false,
      nameErrorText: "",
      birthDate: (() =>
        this.$auth.user.birthDate
          ? this.$moment(this.$auth.user.birthDate, "YYYY-MM-DD").format(
              "DD/MM/YYYY"
            )
          : null)(),
      email: this.$auth.user.email,
      emailError: false,
      emailErrorText: "",
      phone: this.$auth.user.phone || "",
      phoneError: false,
      phoneErrorText: "",
      loadingBasicInformation: false,
      formBasicInformationKey: 0,
      password: "",
      passwordError: false,
      passwordErrorText: "",
      passwordConfirmation: "",
      passwordConfirmationError: false,
      passwordConfirmationErrorText: "",
      formEditPasswordKey: 0,
      loadingEditPassword: false,
      formNotificationsKey: 0,
      loadingNotifications: false,
      receiving_notifications: this.$auth.user.emailNotifications,
      receiving_notificationsError: false,
      receiving_notificationsErrorText: "",
    };
  },
  computed: {
    ...mapGetters(["IS_Mobile"]),
    buttonSize() {
      return this.IS_Mobile ? "m" : "l";
    },
  },
  methods: {
    onInputName(value = this.name, e, required = false) {
      this.name = value;
      const nameError = this.$simpleInputValidate(
        value,
        required,
        "name_surname"
      );
      this.nameError = nameError.error;
      if (nameError.error) {
        this.nameErrorText = nameError.text;
        this.$refs.name.$refs.input.focus();
      }
      return nameError.error;
    },
    onInputBirthDate(value = this.birthDate) {
      this.birthDate = value;
    },
    onInputEmail(value = this.email, e, required = false) {
      this.email = value;
      const emailError = this.$simpleInputValidate(value, required, "email");
      this.emailError = emailError.error;
      if (emailError.error) {
        this.emailErrorText = emailError.text;
        this.$refs.email.$refs.input.focus();
      }
      return emailError.error;
    },
    onInputPhone(value = this.phone, e, required = false) {
      this.phone = value;
      const phoneError = this.$simpleInputValidate(value, required, "tel");
      this.phoneError = phoneError.error;
      if (phoneError.error) {
        this.phoneErrorText = phoneError.text;
        this.$refs.phone.$refs.input.focus();
      }
      return phoneError.error;
    },
    async submitBasicInformation() {
      if (this.loadingBasicInformation) return false;
      const error = (() => {
        const errorName = this.onInputName(undefined, undefined, true);
        const errorEmail = this.onInputEmail(undefined, undefined, true);
        const errorPhone = this.onInputPhone(undefined, undefined, true);
        return errorName || errorEmail || errorPhone;
      })();

      if (!error) {
        this.loadingBasicInformation = true;

        const { success, message } = await this.$auth.update({
          email: this.email,
          fullName: this.name,
          birthDate: (() =>
            this.birthDate
              ? this.$moment(this.birthDate, "DD/MM/YYYY").format("YYYY-MM-DD")
              : null)(),
          phone: this.phone.replace(/ /g, ""),
        });
        this.loadingBasicInformation = false;
        if (success) {
          this.resetBasicInformationForm();
        } else {
          if (message.includes("Email")) {
            this.emailError = true;
            this.emailErrorText = this.$translate("email_exists", "auth");
            this.$refs.email.$refs.input.focus();
          }
        }
      }
    },
    async submitEditPassword() {
      if (this.loadingEditPassword) return false;
      const error = (() => {
        const errorPassword = this.onInputpassword(undefined, undefined, true);
        const errorpasswordConfirmation = this.onInputPasswordConfirmation(
          undefined,
          undefined,
          true
        );
        return (
          errorPassword ||
          errorpasswordConfirmation ||
          (!errorPassword &&
            !errorpasswordConfirmation &&
            !this.validatePasswordsEqual())
        );
      })();
      if (!error) {
        this.loadingEditPassword = true;
        const { success } = await this.$auth.changePassword({
          password: this.password,
          passwordConfirmation: this.passwordConfirmation,
        });
        if (success) {
          this.resetPasswordForm();
        } else {
          this.passwordError = true;
          this.passwordConfirmationError = true;
          this.passwordErrorText = this.$translate(
            "password_change_error",
            "auth"
          );
          this.passwordConfirmationErrorText = this.$translate(
            "password_change_error",
            "auth"
          );
          this.$refs.passwordConfirmation.$refs.input.focus();
        }
        this.loadingEditPassword = false;
      }
    },
    isPasswordsEqual() {
      return this.password === this.passwordConfirmation;
    },
    validatePasswordsEqual() {
      const equals = this.isPasswordsEqual();
      if (!equals) {
        this.passwordError = true;
        this.passwordConfirmationError = true;
        this.passwordErrorText = this.$translate("equalPasswords", "auth");
        this.passwordConfirmationErrorText = this.$translate(
          "equalPasswords",
          "auth"
        );
        this.$refs.passwordConfirmation.$refs.input.focus();
      }
      return equals;
    },
    onInputpassword(value = this.password, e, required = false) {
      this.password = value;
      const passwordError = this.$simpleInputValidate(
        value,
        required,
        "password"
      );
      this.passwordError = passwordError.error;
      if (passwordError.error) {
        this.passwordErrorText = passwordError.text;
        this.$refs.password.$refs.input.focus();
      }
      return passwordError.error;
    },
    onInputPasswordConfirmation(
      value = this.passwordConfirmation,
      e,
      required = false
    ) {
      this.passwordConfirmation = value;
      const passwordConfirmationError = this.$simpleInputValidate(
        value,
        required,
        "password"
      );
      this.passwordConfirmationError = passwordConfirmationError.error;
      if (passwordConfirmationError.error) {
        this.passwordConfirmationErrorText = passwordConfirmationError.text;
        this.$refs.passwordConfirmation.$refs.input.focus();
      }
      return passwordConfirmationError.error;
    },
    resetBasicInformationForm() {
      this.loadingBasicInformation = false;
      this.formBasicInformationKey = "form_" + this.$rnd(0, 100);
    },
    resetPasswordForm() {
      this.loadingEditPassword = false;
      this.password = "";
      this.passwordConfirmation = "";
      this.codeError = false;
      this.passwordError = false;
      this.passwordConfirmationError = false;
      this.formEditPasswordKey = "form_" + this.$rnd(0, 100);
    },
    async onInputReceiving_notifications(value) {
      this.loadingNotifications = true;
      const { success } = await this.$auth.update({
        emailNotifications: value,
      });
      this.receiving_notificationsError = !success;
      if (success) this.receiving_notifications = value;
      this.loadingNotifications = false;
    },
  },
};
</script>

<style lang="scss" scoped src="./component.scss"></style>
