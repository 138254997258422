import store from "@/store";

const translate = (name, key = 'general') => {
    try {
        const val = store.getters['GET_Translations'][key][name];
        if (typeof val == 'undefined') return 'Field not found'
        return val.replace(/\n/g, ' <br> ');
    } catch (error) {
        return 'Key not found'
    }
}

export default translate
