<template>
  <div class="PageTableMainWrap">
    <LoadingIcon v-if="loading" :absolute="true" />
    <div class="PageTableSecondWrap" :class="{ disabledElem: loading }">
      <div class="PageTableWrap">
        <table class="PageTable">
          <thead>
            <tr>
              <slot name="thead"></slot>
            </tr>
          </thead>
          <tbody>
            <slot></slot>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * The PageTable component.
 */
export default {
  name: "PageTable",
  components: {},
  props: {
    loading: { default: false, type: Boolean },
  },
};
</script>

<style lang="scss" scoped src="./component.scss"></style>
