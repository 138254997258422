<template>
  <div ref="dropdown" @click.stop="switchShow" class="Dropdown">
    <div class="top">
      <DotsSVG />
    </div>
    <div
      ref="content"
      id="dropdown-content"
      v-show="show"
      class="content"
      :class="({ disabled: absolute && disabled }, contentClass)"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
import DotsSVG from '@/assets/img/dots.svg';

/**
 * The DocumentsTableDropdown component.
 */
export default {
  name: 'DocumentsTableDropdown',
  components: { DotsSVG },
  data() {
    return {
      show: false,
      disabled: true,
      customEventName: 'cleandropdown',
    };
  },
  props: {
    absolute: {
      type: Boolean,
      default: true,
    },
    contentClass: {
      type: String,
      default: '',
    },
  },
  methods: {
    resetState() {
      if (!this.show) return null;
      this.show = false;
      this.destroyAbsoluteContent();
    },
    initAbsoluteContent() {
      try {
        if (!this.absolute) return null;
        let content = this.$refs.content;
        document.body.appendChild(content);
        requestAnimationFrame(this.setAbsolutePosition);
      } catch (e) {
        console.log(e);
      }
    },
    calculatePos() {
      if (!this.show) return null;
      try {
        let ref = this.$refs.dropdown;
        let content = this.$refs.content;
        //screen size x,y
        let screenW = Math.min(
          document.documentElement.clientWidth,
          document.documentElement.offsetWidth
        );
        let screenH = Math.min(
          document.documentElement.clientHeight,
          document.documentElement.offsetHeight
        );
        //content size
        let contentW = Math.max(content.clientWidth, content.offsetWidth);
        let contentH = Math.max(content.clientHeight, content.offsetHeight);
        //ref pos
        let refPosition = ref.getBoundingClientRect();
        let left = refPosition.left;
        let bottom = refPosition.bottom;
        //corrected metrics
        let leftIsCorrect = screenW > left + contentW;
        if (leftIsCorrect) {
          content.style.left = left + 'px';
        } else {
          let right = refPosition.right;
          content.style.left = right - contentW + 'px';
        }
        let bottomIsCorrect = screenH > bottom + contentH;
        if (bottomIsCorrect) {
          content.style.top = bottom + window.scrollY + 'px';
        } else {
          let top = refPosition.top + window.scrollY;
          content.style.top = top - contentH + 'px';
        }
        this.disabled = false;
      } catch (e) {
        console.log(e);
      }
    },
    setAbsolutePosition() {
      this.calculatePos();
    },
    destroyAbsoluteContent() {
      try {
        if (!this.absolute) return null;
        this.disabled = true;
        let parent = this.$refs.dropdown;
        let content = this.$refs.content;
        parent.appendChild(content);
        content.removeAttribute('style');
      } catch (e) {
        console.log(e);
      }
    },
    switchShow() {
      this.show = !this.show;
      if (this.show) this.initAbsoluteContent();
      else this.destroyAbsoluteContent();
      document.dispatchEvent(
        new CustomEvent(this.customEventName, { detail: this.$refs.dropdown })
      );
    },
    hideContent(e) {
      if (!this.show) return null;
      try {
        let targ = e.target.closest('.Dropdown');
        if (targ == this.$refs.dropdown) return null;
        this.show = false;
        return this.destroyAbsoluteContent();
      } catch (e) {
        console.log(e);
      }
    },
    cleanAll({ detail }) {
      if (this.$refs.dropdown == detail || !this.show) return null;
      this.show = false;
      this.destroyAbsoluteContent();
    },
  },
  mounted() {
    document.addEventListener('click', this.hideContent);
    window.addEventListener('resize', this.resetState);
    window.addEventListener('scroll', this.calculatePos);
    document.addEventListener(this.customEventName, this.cleanAll);
  },
  beforeUnmount() {
    this.resetState();
    document.removeEventListener('click', this.hideContent);
    window.removeEventListener('resize', this.resetState);
    window.removeEventListener('scroll', this.calculatePos);
    document.removeEventListener(this.customEventName, this.cleanAll);
  },
};
</script>

<style lang="scss" src="./component.scss"></style>
