import gsap from 'gsap';
import store from '../store';
import hold_scroll_page from './functions/holdScroll';

// let ts;
// const touchstart = (e) => ts = e.touches[0].clientY

window.hold_scroll_page = hold_scroll_page;
const popup = {
  hide_popup_timer: undefined,
  shift: 100,
  openModal: false,
  timeAnimation: 0.5,
  ease: 'Power3.easeInOut',
  easeFast: 'Power4.easeInOut',
  show: function (target_block, data) {
    store.dispatch('SHOW_Popup', { name: target_block, data });
    gsap.to('.popup_bg', {
      display: 'block',
      opacity: 1,
      duration: this.timeAnimation,
    });
    const block =
      document.querySelector('div.popup_block.' + target_block) ||
      document.getElementById(target_block);
    // block.find(".popupContent").scrollTop(0);
    let set_time = 0;
    if (this.openModal) {
      let visibleBlock = Array.prototype.slice
        .call(document.querySelectorAll('.popup_block'))
        .filter((el) => this.isVisible(el));
      gsap.fromTo(
        visibleBlock,
        {},
        {
          y: this.shift / 5,
          opacity: 0,
          ease: this.easeFast,
          duration: this.timeAnimation / 2,
        }
      );
      // если есть видимая модалка то даем ей плавно скрыться
      set_time = (this.timeAnimation * 1000) / 2;
    }
    //
    this.openCallBack(target_block, block);
    //
    setTimeout(() => {
      hold_scroll_page(true);
      document.querySelectorAll('.popup_block').forEach((el) => {
        el.removeAttribute('style');
      });
      clearTimeout(this.hide_popup_timer);
      block.style.height = '';
      // block.find(".content_wrap_inner").scrollTop(0);
      gsap.set(block, { display: 'block' });
      gsap.set('div.popup_block.' + target_block + ' .popupContent', {
        scrollTop: 0,
      });

      this.hideScrollBar(true, block);
      gsap.fromTo(
        block,
        {
          opacity: 0,
          y: this.shift,
        },
        {
          opacity: 1,
          y: 0,
          ease: this.ease,
          force3D: true,
          duration: this.timeAnimation,
          onComplete: () => {
            this.openModal = true;
            // block.find(".content_wrap_inner, .popupWrap").scrollTop(0);
            block.style.transform = '';
          },
        }
      );
    }, set_time);
  },
  hide: function (target_block, callback = () => {}) {
    // window.scrollTo(0, 0) // fix for mobile safari
    const visibleElems = Array.prototype.slice
      .call(document.querySelectorAll('.popup_block'))
      .filter((el) => this.isVisible(el));
    let target_blockDiv = document.querySelectorAll(
      'div.popup_block.' + target_block
    );
    const block = target_blockDiv.length ? target_blockDiv : visibleElems;
    const hasOpenUserMenu =
      document.querySelector('#menuPopup.show') !== null ? true : false;
    if (this.openModal || visibleElems.length) {
      setTimeout(() => {
        gsap.to('.popup_bg', {
          display: 'none',
          opacity: 0,
          duration: this.timeAnimation,
        });
        if (!hasOpenUserMenu) this.hideScrollBar(false, block);
      }, (this.timeAnimation / 2) * 1000);
      gsap.fromTo(
        block,
        {},
        {
          opacity: 0,
          y: this.shift,
          ease: this.ease,
          duration: this.timeAnimation,
          force3D: true,
          onComplete: () => {
            // block.find(".content_wrap_inner, .popupWrap").scrollTop(0);
            gsap.set('.popup_block', { display: '' });
            Array.prototype.slice
              .call(document.querySelectorAll('.popup_block'))
              .forEach((el) => {
                el.style.display = 'none';
                el.style.opacity = '';
              });
            if (!hasOpenUserMenu) hold_scroll_page(false);
            this.openModal = false;
            gsap.set('.popup_bg, header', { 'z-index': '' });
            this.closeCallBack(block, callback);
            document.dispatchEvent(new CustomEvent('popup-hide'));
          },
        }
      );
    }
  },
  detectHide: function (e) {
    console.log(e);
  },
  openCallBack: function (target_block) {
    switch (target_block) {
      case 'Basket':
        break;
      default:
        break;
    }
  },
  closeCallBack: function (target_block, callback) {
    store.dispatch('HIDE_Popup', target_block);
    callback();
    switch (target_block) {
      case 'suggestResources':
        break;
      default:
        break;
    }
  },
  hideScrollBar: function (hide = true, target_block) {
    if (hide && document.documentElement.scrollHeight > window.innerHeight) {
      const ProductTabsTopRow = target_block.querySelector('.popupContent');
      document.querySelectorAll('html').forEach((element) => {
        element.style.overflow = 'hidden';
      });
      if (ProductTabsTopRow != null)
        ProductTabsTopRow.style.right = this.getScrollbarWidth() + 'px';
      document.querySelectorAll('html, header').forEach((element) => {
        if (element.tagName !== 'HEADER') {
          element.style.paddingRight = this.getScrollbarWidth() + 'px';
        }
      });
    } else {
      document
        .querySelectorAll('.popup_block .popupContent')
        .forEach((element) => {
          element.style.right = '';
        });
      document.querySelectorAll('body, html, header').forEach((element) => {
        element.style.overflow = '';
        element.style.paddingRight = '';
      });
    }
  },
  isVisible(el) {
    var style = window.getComputedStyle(el);
    return style.display !== 'none';
  },
  scrollbarWidth: null,
  getScrollbarWidth() {
    if (!this.scrollbarWidth) {
      // Creating invisible container
      const outer = document.createElement('div');
      outer.style.visibility = 'hidden';
      outer.style.overflow = 'scroll'; // forcing scrollbar to appear
      outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
      document.body.appendChild(outer);
      // Creating inner element and placing it in the container
      const inner = document.createElement('div');
      outer.appendChild(inner);
      // Calculating difference between container's full width and the child width
      this.scrollbarWidth = outer.offsetWidth - inner.offsetWidth;
      // Removing temporary elements from the DOM
      outer.parentNode.removeChild(outer);
    }
    return this.scrollbarWidth;
  },
};

export default popup;
