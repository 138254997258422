<template>
  <LayoutDashboard name="packages-schedule">
    <DashboardTitleRow :title="$translate('my_packages')" />
    <PageTabs :list="pageTabsList" />
    <PageFilter
      :loading="loading"
      :list="filterList"
      @submit="submitFilter"
      @reset="resetFilter"
      @remove="removeFilterTag"
    >
      <LabelDropdown
        name="number"
        :title="$translate('transport_instructions', 'orders')"
        :list="filter.number"
        :active="filterCurrent.number"
        @change="changeDropdown"
      />
      <LabelDropdown
        name="order_number"
        :title="$translate('order', 'orders')"
        :list="filter.order.number"
        :active="filterCurrent.order?.number"
        @change="changeDropdown"
      />
      <LabelDropdown
        name="consignee_name"
        :title="$translate('consignee', 'orders')"
        :list="filter.consignee.name"
        :active="filterCurrent.consignee?.name"
        @change="changeDropdown"
      />
    </PageFilter>
    <Schedule
      :orderList="listFiltered"
      :dates="scheduleBreakpointDates"
      v-if="listFiltered.length"
      :loading="loading"
      enableOrderLinks
      type="packages"
    />
    <LoadingIcon v-if="loading && !list.length" />
    <template v-if="!loading && !list.length">
      <p class="no_data">{{ $translate("no_data") }}</p>
    </template>
    <Pagination
      @paginate="paginate"
      :pageCurrent="pageCurrent"
      :pageCount="pageCount"
      :perPage="PAGE_SIZE"
      :class="{ disabledElem: loading }"
    />
  </LayoutDashboard>
</template>

<script>
const PAGE_SIZE = 2000;

import LayoutDashboard from "@/layouts/Dashboard.vue";
import DashboardTitleRow from "@/components/DashboardTitleRow";
import PageTabs from "@/components/PageTabs";
import PageFilter from "@/components/PageFilter";
import Schedule from "@/components/Schedule";
import Pagination from "@/components/Pagination";
/**
 * The packages-schedule-page component.
 */
export default {
  name: "PackagesSchedulePage",
  components: {
    LayoutDashboard,
    DashboardTitleRow,
    PageTabs,
    PageFilter,
    Schedule,
    Pagination,
  },
  data() {
    const filter = this.$queryParams.parse(this.$route.query.filter);
    const sort = this.$queryParams.parse(this.$route.query.sort);
    return {
      PAGE_SIZE: PAGE_SIZE,
      loading: true,
      preList: [],
      list: [],
      pageCurrent: parseInt(this.$route.query.page) || 1,
      pageCount: 1,
      sort: {
        number: sort?.number || undefined,
        consignee: sort?.consignee ? { name: sort?.consignee?.name || undefined } : undefined,
      },
      filterCurrentCopy: null,
      filterCurrent: {
        number: filter.number || null,
        order: {
          number: filter.order?.number || null,
        },
        consignee: {
          name: filter.consignee?.name || null,
        },
      },
      pageTabsList: [
        {
          id: 1,
          text: this.$translate("final_balance", "orders"),
          to: { name: "packages" },
          active: false,
        },
        {
          id: 2,
          text: this.$translate("cargo_map", "orders"),
          to: { name: "packages-map" },
          active: false,
        },
        {
          id: 3,
          text: this.$translate("shipment_schedule", "orders"),
          to: { name: "packages-schedule" },
          active: true,
        },
      ],
      formKey: "form_0",
    };
  },
  computed: {
    filter() {
      const data = {
        number: [],
        order: {
          number: [],
        },
        consignee: {
          name: [],
        },
      };

      this.preList.forEach((item) => {
        data.number.push(item.number);
        data.order.number.push(item.order?.number);
        data.consignee.name.push(item.consignee?.name);
      });
      // get unique values of array
      data.number = [...new Set(data.number)].filter((el) => el);
      data.order.number = [...new Set(data.order.number)].filter((el) => el);
      data.consignee.name = [...new Set(data.consignee.name)].filter(
        (el) => el
      );

      return data;
    },
    filterList() {
      const list = [];

      // number
      if (this.filterCurrentCopy.number) {
        list.push({
          id: "number",
          type: this.$translate("transport_instructions", "orders"),
          text: this.filterCurrentCopy.number,
        });
      }
      // order_number
      if (this.filterCurrentCopy.order?.number) {
        list.push({
          id: "order_number",
          type: this.$translate("order", "orders"),
          text: this.filterCurrentCopy.order.number,
        });
      }
      // consignee_name
      if (this.filterCurrentCopy.consignee?.name) {
        list.push({
          id: "consignee_name",
          type: this.$translate("consignee", "orders"),
          text: this.filterCurrentCopy.consignee.name,
        });
      }

      return list;
    },
    listFiltered() {
      const payload = this.list.map((item) => {
        const { id, number, quotas, quantity, order, deliveryCondition } = item;
        return {
          name: number?.trim(),
          number: number?.trim(),
          id,
          list: quotas,
          quantity,
          deliveryCondition: {
            name: deliveryCondition?.name,
          },
          order: {
            id,
            number,
            product: {
              id: order?.product?.id,
              name: order?.product?.name,
            }
          },
          routerLinkParams: order != null
            ? {
              name: 'my-order-transport-instruction',
              params: { id: order?.number, tiID: id },
            }
            : null,
        }
      });
      return payload;
    },
    scheduleBreakpointDates() {
      const dates = this.listFiltered.map((item) => {
        return (item.list || []).map((el) =>
          this.$moment(el.date).valueOf()
        );
      }).flat();
      const result = Array.from(new Set(dates)).sort((a, b) => a - b);
      return result;
    }
  },
  methods: {
    paginate(page) {
      this.getPackages(page);
    },
    async getPreList() {
      const { list, success } = await this.$api.getPackages({
        populate: ["number", "order.number", "consignee.name"],
      });
      if (success) {
        this.preList = list;
      }
      this.loading = false;
    },
    async getPackages(
      page,
      sort = this.sort,
      filter = this.filterCurrent,
      params = {}
    ) {
      const clearSort = this.$queryParams.getClear(sort);
      const clearFilter = this.$queryParams.getClear(filter, true);
      if (this.loading) return;
      this.loading = true;
      const { list, pagination, success } = await this.$api.getPackages({
        pagination: { pageSize: PAGE_SIZE, page: page || this.pageCurrent },
        sort: clearSort,
        filters: clearFilter,
        ...params,
      });

      if (success) {
        this.filterCurrentCopy = this.$copyObject(this.filterCurrent);
        this.list = list;
        this.pageCurrent = pagination.page;
        this.pageCount = pagination.pageCount;

        this.sort = {
          ...this.$queryParams.resetParams(this.sort),
          ...clearSort,
        };

        this.$router.push({
          query: this.$queryParams.getClear({
            page: this.pageCurrent,
            sort: this.$queryParams.stringify(clearSort),
            filter: this.$queryParams.stringify(clearFilter),
          }),
        });
      }
      this.loading = false;
    },
    async sortTable(params) {
      this.$queryParams.reverseParams(params);
      await this.getPackages(this.pageCurrent, params);
    },
    changeDropdown(key, name) {
      switch (name) {
        case "number":
          this.filterCurrent.number = key;
          break;
        case "order_number":
          this.filterCurrent.order.number = key;
          break;
        case "consignee_name":
          this.filterCurrent.consignee.name = key;
          break;
        default:
          break;
      }
    },
    async submitFilter() {
      await this.getPackages(1);
    },
    async resetFilter() {
      this.$queryParams.resetParams(this.filterCurrent);
      this.formKey = "form_" + this.$rnd(0, 100);
      await this.getPackages(1);
    },
    async removeFilterTag(id) {
      this.changeDropdown(null, id);
      await this.getPackages();
    },
  },
  async created() {
    this.filterCurrentCopy = this.$copyObject(this.filterCurrent);
    await this.getPreList();
    await this.getPackages();
  },
  mounted() {
    this.emitter.on("PageFilter:show", () => {
      this.filterCurrent = this.$copyObject(this.filterCurrentCopy);
    });
  },
};
</script>

<style lang="scss" scoped src="./component.scss"></style>
