<template>
  <PageTable :loading="loading">
    <template v-slot:thead>
      <th>
        <span
          class="controll"
          :class="[
            date,
            { active: currentSort.date },
          ]"
          @click="$emit('sort', { date: date })"
        >
          {{ $translate("date", "documents") }}
          <ArrowSVG />
        </span>
      </th>
      <th>
        <span 
          class="controll"
          :class="[
            documentType.name,
            { active: currentSort.documentType?.name }
          ]"
          @click="$emit('sort', {
            documentType: documentType.name ? documentType : { name: 'asc' }
          })"
        >
          {{ $translate("document_type", "documents") }}
          <ArrowSVG />
        </span>
      </th>
      <th>
        <span 
          class="controll"
          :class="[
            documentNumber,
            { active: currentSort.documentNumber }
          ]"
          @click="$emit('sort', { documentNumber: documentNumber })">
          {{ $translate("document", "documents") }}
          <ArrowSVG />
        </span>
      </th>
      <th>
        <span 
          class="controll"
          :class="[
            order?.number,
            { active: currentSort.order?.number }
          ]"
          @click="$emit('sort', {
            order: order.number ? order : { number: 'asc' }
          })"
        >
          {{ $translate("related_order", "documents") }}
          <ArrowSVG />
        </span>
      </th>
      <th></th>
    </template>
    <template v-slot:default>
      <RowItem v-for="item in list" :key="item.id" :row="item" />
    </template>
  </PageTable>
</template>

<script>
import RowItem from './Item';
import PageTable from '../PageTable';
import ArrowSVG from '@/assets/img/arrow_top.svg';
/**
 * The DocumentsTable component.
 */
export default {
  name: "DocumentsTable",
  components: { RowItem, ArrowSVG, PageTable },
  props: {
    list: { default: () => [], type: Array },
    loading: { default: false, type: Boolean },
    date: { default: 'asc', type: String },
    documentNumber: { default: 'asc', type: String },
    documentType: {
      default: () => ({ name: 'asc' }),
      type: Object,
    },
    order: { 
      default: () => ({ number: 'asc' }),
      type: Object,
    },
  },
  computed: {
    currentSort() {
      return this.$queryParams.parse(this.$route.query.sort)
    },
  }
};
</script>

<style lang="scss" scoped src="./component.scss"></style>
