<template>
  <ul class="transport-info">
    <li class="w20" v-if="transportNumber">
      <span class="name">{{
        $translate('vehicle_registration_number', 'documents')
      }}</span>
      <span class="val">{{ transportNumber }}</span>
    </li>
    <li class="w40" v-if="driverInfo">
      <span class="name">{{ $translate('driver', 'documents') }}</span>
      <span class="val max-w--215">{{ driverInfo }}</span>
      <span v-if="ttnInfo && ttnInfo.date && ttnInfo.num" class="optional"
        >{{ $translate('power_attorney', 'documents') }}: {{ ttnInfo.num }} до
        {{ $moment(new Date(ttnInfo.date)).format('DD.MM.YYYY') }}</span
      >
    </li>
    <li class="w30" v-if="total">
      <span class="name">{{ $translate('load_rate', 'documents') }}</span>
      <span class="val">{{ total }} {{ $translate('t', 'orders') }}</span>
    </li>
    <li>
      <Dropdown v-if="editable">
        <ul>
          <li>
            <button @click="edit" aria-label="edit" type="button">
              <PenSVG />
              <span>{{ $translate('edit', 'general') }}</span>
            </button>
          </li>
          <li>
            <button @click="del" aria-label="delete" type="button">
              <TrashSVG />
              <span>{{ $translate('remove', 'general') }}</span>
            </button>
          </li>
        </ul>
      </Dropdown>
    </li>
  </ul>
</template>
<script>
import Dropdown from '@/components/Table/PageTable/Dropdown';
import PenSVG from '@/assets/img/Pen.svg';
import TrashSVG from '@/assets/img/TrashBacket.svg';

export default {
  name: 'TransportInfo',
  components: { Dropdown, PenSVG, TrashSVG },
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
    editable: {
      type: Boolean,
      default: false,
    },
    info: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    edit() {
      return this.$popup.show('AddVehicle', {
        action: 'edit',
        id: this.data?.id,
        ...this.info,
      });
    },
    del() {
      return this.$popup.show('DeleteCarPopUp', {
        action: 'remove',
        id: this.data?.id,
        ...this.info,
      });
    },
  },
  computed: {
    transportNumber() {
      return this.data?.transportNumber;
    },
    driverInfo() {
      return this.data?.driver;
    },
    ttnInfo() {
      const date = this.data?.ttnDate;
      const num = this.data?.ttnNumber;
      return {
        date,
        num,
      };
    },
    total() {
      return this.data?.beatingCapacity;
    },
  },
};
</script>
<style scoped src="./style.scss" lang="scss"></style>
