<template>
  <article class="location-popover">
    <header class="location-popover__header">
      <button
        @click="$emit('close')"
        type="button"
        class="location-popover__close-btn">
        <CloseWhite class="location-popover__close-img" />
      </button>
    </header>
    <div v-if="data.length > 0" class="location-popover__content">
      <div class="location-popover__data">
        <span>{{ $translate('electronic-document-short') }}:</span>
        <strong>{{ currentDocument?.number }}</strong>
        <span>{{ $translate('TI_number_short', 'documents') }}:</span>
        <span>
          <span
            v-for="(item, index) of currentTIs"
            :key="item.id">
            <router-link 
              :to="item.routerLink"
              class="location-popover__link">
              {{ item.label }}
            </router-link>
            <br v-if="currentTIs.length > 1 && index < currentTIs.length - 1" />
          </span>
        </span>
        <span>{{ $translate('status_ti', 'shipments') }}:</span>
        <span>{{ currentDocument?.status?.name }}</span>
        <span>{{ $translate('order', 'orders') }}:</span>
        <span>
          <span
            v-for="(item, index) of currentOrders"
            :key="item.id">
            <router-link 
              :to="item.routerLink"
              class="location-popover__link">
              {{ item.label }}
            </router-link>
            <br v-if="currentOrders.length > 1 && index < currentOrders.length - 1" />
          </span>
        </span>
      </div>
      <hr class="location-popover__data-divider">
      <div class="location-popover__data">
        <span>{{ $translate('departure_station') }}:</span>
        <span>{{ currentDocument?.departureStation?.name }}</span>
        <span>{{ $translate('current_station') }}:</span>
        <span>{{ currentDocument?.currentStation?.name }}</span>
        <span>{{ $translate('destination_station') }}:</span>
        <span>{{ currentDocument?.destinationStation?.name }}</span>
        <span>{{ $translate('update_date', 'shipments') }}:</span>
        <span>{{ $moment(currentDocument?.updatedAt).format('DD.MM.YY HH:mm') }}</span>
      </div>
    </div>
    <footer 
      v-if="data.length > 1"
      class="location-popover__footer">
      <button
        @click="footerNavPrev"
        :disabled="activeIndex === 0"
        class="location-popover__footer-nav-btn"
        type="button">
        <ArrowRightLight class="location-popover__footer-nav-icon" />
      </button>
      <span class="location-popover__footer-nav-text">
        {{ activeIndex + 1 }} / {{ data.length }}
      </span>
      <button
        @click="footerNavNext"
        :disabled="activeIndex >= data?.transportInstructions?.length - 1"
        class="location-popover__footer-nav-btn"
        type="button">
        <ArrowLeftLight class="location-popover__footer-nav-icon" />
      </button>
    </footer>
  </article>
</template>

<script>
import CloseWhite from '@/assets/img/CloseWhite.svg';
import ArrowRightLight from '@/assets/img/arrow_right_light.svg';
import ArrowLeftLight from '@/assets/img/arrow_left_light.svg';

/**
 * The PackagesMapLocationPopover component.
 */
export default {
  name: "PackagesMapLocationPopover",
  components: {
    CloseWhite,
    ArrowRightLight,
    ArrowLeftLight,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      activeIndex: 0,
    };
  },
  computed: {
    currentDocument() {
      return this.data[this.activeIndex];
    },
    currentTIs() {
      if (!Array.isArray(this.currentDocument?.transportInstructions)) return [];

      return this.currentDocument.transportInstructions.map(ti => ({
        id: `ti-${ti.order.number}-${ti.id}-${ti.number}`,
        label: ti.number,
        routerLink: {
          name: 'my-order-transport-instruction',
          params: {
            id: ti.order.number,
            tiID: ti.id,
          },
        },
      }));
    },
    currentOrders() {
      if (!Array.isArray(this.currentDocument?.transportInstructions)) return [];

      return this.currentDocument.transportInstructions.map(ti => ({
        id: `order-${ti.order.id}-${ti.order.number}`,
        label: ti.order.number,
        routerLink: {
          name: 'my-order',
          params: {
            id: ti.order.number,
          },
        },
      }));
    },
  },
  methods: {
    footerNavNext() {
      this.activeIndex = Math.min(
        this.activeIndex + 1,
        this.data?.length - 1
      );
      this.$emit('activeItemChange', this.data[this.activeIndex])
    },
    footerNavPrev() {
      this.activeIndex = Math.max(this.activeIndex - 1, 0);
      this.$emit('activeItemChange', this.data[this.activeIndex])
    }, 
  },
}
</script>

<style lang="scss" scoped src="./component.scss"></style>