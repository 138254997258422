<template>
  <Layout id="TIUploadAttachment">
    <h4>{{ $translate('ti_upload_file_model_heading', 'documents') }}</h4>
    <div class="formWrap">
      <LoadingIcon v-if="loading" :absolute="true" />
      <form
        novalidate
        @submit.prevent="submit"
        :class="{ loadingElem: loading }"
        :key="formKey"
      >
        <LabelUploadFile
          :title="$translate('ti_upload_file_attachment', 'documents')"
          name="files"
          @onInput="onInputFile"
          :error="inputError['files']"
          :value="files"
        />
        <Label
          :title="$translate('ti_upload_attachment_note', 'documents')"
          :placeholder="
            $translate('ti_upload_attachment_note_placeholder', 'documents')
          "
          name="comment"
          inputType="textarea"
          @onInput="onInputField"
          :error="inputError['comment']"
          ref="comment"
          :value="comment"
        />
        <MyButton
          class="w100"
          :text="$translate('save', 'profile')"
          tag="button"
          tagType="submit"
        />
      </form>
      <MyButton
        :text="$translate('go_back')"
        tag="button"
        type="cancel w100"
        @click="closeModal"
        :disabled="loading"
      />
    </div>
  </Layout>
</template>

<script>
import { mapGetters } from "vuex";
import Layout from "../../PopupLayout";
import LabelUploadFile from "@/components/LabelUploadFile";
/**
 * The TIUploadAttachment component.
 */
export default {
  name: "TIUploadAttachment",
  components: {
    Layout,
    LabelUploadFile,
  },
  data() {
    return {
      formKey: "faqForm_0",
      loading: false,
      inputError: {},
      files: [],
      comment: "",
    };
  },
  computed: {
    ...mapGetters(['IS_Mobile', 'GET_CurrentPopup', 'GET_PopupData']),
    gData() {
      return this.GET_PopupData?.[this.GET_CurrentPopup];
    },
    tiId() {
      return this.gData?.id;
    },
  },
  methods: {
    onInputFile(files) {
      this.files = files;
      this.onInputField(undefined, undefined, "files", false, "files");
    },
    onInputField(value, $event, name, required = false, validateType = "name") {
      if (!name) throw new Error("onInputField: name is undefined");
      if (value != undefined) this[name] = value;
      const validateError = this.$simpleInputValidate(
        value || this[name],
        required,
        validateType
      );
      this.inputError[name] = validateError;
      if (validateError.error && this.$refs[name]?.$refs?.input) {
        this.$refs[name].$refs.input.focus();
      }
      console.log("onInputField", value, name);
      return validateError.error;
    },
    async submit() {
      if (this.loading) return false;
      const error = (() => {
        const errorFile = this.onInputField(
          undefined,
          undefined,
          "files",
          true,
          "files"
        );
        const errorComment = this.onInputField(
          undefined,
          undefined,
          "comment",
          false,
          "name"
        );
        return errorFile || errorComment;
      })();
      if (!error) {
        this.loading = true;
        
        const { success, data: uploadedFiles } = await this.$api.file.upload(this.files);
        const [file] = uploadedFiles;

        if (success) {
          this.emitter.emit("attachmentFileUploaded", {
            file: file,
            comment: this.comment,
          });
        }
      }

      this.loading = false;
    },
    resetForm() {
      this.files = [];
      this.comment = "";
      this.formKey = "faqForm_" + this.$rnd(0, 100);
    },
    closeModal() {
      this.$popup.hide("TIUploadAttachment", () => this.resetForm());
    },
  },
  created() {
    document.addEventListener('popup-hide', this.resetForm);
  },
  beforeUnmount() {
    document.removeEventListener('popup-hide', this.resetForm);
  }
};
</script>
<style lang="scss" src="./component.scss"></style>
