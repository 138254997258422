import gsap from "gsap";
import store from '../store'
import hold_scroll_page from './functions/holdScroll'

export default class PageFilter {
    timeAnimation = 0.5;
    ease = 'Power3.easeInOut'
    disabled = false;
    constructor({ emitter }) {
        this.emitter = emitter
    }
    show() {
        if (this.disabled) return;
        hold_scroll_page(true)
        this.showHide(true)
        this.emitter.emit('PageFilter:show')
    }
    hide() {
        if (this.disabled) return;
        hold_scroll_page(false)
        this.showHide(false)
        this.emitter.emit('PageFilter:hide')
    }
    showHide(show = false) {
        this.disabled = true;
        const IS_Mobile = store.getters.IS_Mobile
        gsap.to('#PageFilterBG', {
            display: show ? "flex" : 'none',
            opacity: show ? 1 : 0,
            duration: this.timeAnimation
        })
        gsap.fromTo('#PageFilterPopup',
            {
                opacity: show ? 0 : 1,
                x: IS_Mobile ? 0 : show ? '100%' : '0%',
                y: IS_Mobile ? show ? '25%' : '0%' : 0
            },
            {
                display: show ? "flex" : 'none',
                opacity: show ? 1 : 0,
                x: IS_Mobile ? 0 : show ? '0%' : '100%',
                y: IS_Mobile ? show ? '0%' : '25%' : 0,
                ease: this.ease,
                duration: this.timeAnimation,
                onComplete: () => {
                    this.disabled = false;
                }
            })
    }
}