<template>
  <div class="LabelUploadFile" :class="{ error: errorNow }">
    <span class="titleRow" v-if="title">
      <span class="title">{{ title }}</span>
    </span>
    <label>
      <input type="file" @change="previewFiles" ref="input" multiple />

      <span class="uploadFile" v-if="!files || !files.length">
        <span>{{ $translate("upload_file") }}</span>
        <FileSVG />
      </span>
    </label>
    <span class="uploadedFiles" v-if="files && files.length">
      <span>{{ $translate("selected_files") }}:</span>
      <ul class="filesList">
        <li v-for="(file, index) in files" :key="index">
          <span>{{ file.name }}</span>
          <span class="remove" @click="remove(index)">
            <CloseSVG class="removeIcon" />
          </span>
        </li>
      </ul>
    </span>
    <span
      class="errorText"
      v-if="(error && errorText) || (error?.error && error?.text)"
      >{{ errorText || error?.text }}</span
    >
  </div>
</template>

<script>
import FileSVG from "@/assets/img/file.svg";
import CloseSVG from "@/assets/img/close.svg";
/**
 * The LabelUploadFile component.
 */
export default {
  name: "LabelUploadFile",
  components: { FileSVG, CloseSVG },
  props: {
    title: { default: null, type: String },
    name: { default: null, type: String },
    /**
     * Error state
     */
    error: { default: false, type: [Boolean, Object] },
    /**
     * Error text
     */
    errorText: { default: null, type: String },
    multiple: { default: false, type: Boolean },
    /**
     * Init value in v-model
     */
    value: { default: null, type: Array },
  },
  data() {
    return {
      files: [],
    };
  },
  computed: {
    errorNow() {
      return typeof this.error === "object" ? this.error.error : this.error;
    },
  },
  methods: {
    previewFiles(event) {
      this.files = [...event.target.files];
      this.onInput(event);
    },
    remove(index) {
      this.files.splice(index, 1);
      this.onInput(null);
    },
    onInput(event) {
      this.$emit("onInput", this.files, event, this.name);
    },
  },
  created() {
    if (this.value) {
      this.files = this.value;
    }
  },
};
</script>

<style lang="scss" scoped src="./component.scss"></style>
