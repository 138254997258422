<template>
  <router-view :key="$route.fullPath" />
</template>

<script>
export default {
  name: 'App',
  components: {},
};
</script>

<style lang="scss" src="@/assets/css/scss/main.scss"></style>
