<template>
  <div class="SpecialSlider">
    <swiper
      class="swiper simpleImage"
      slides-per-view="1"
      :observer="true"
      :observeParents="true"
      :watchSlidesProgress="true"
      :watchSlidesVisibility="true"
      :watchOverflow="true"
      ref="swiper"
      @swiper="swiperInit"
      @slide-change="swiperSlideChange"
    >
      <swiper-slide class="swiper-slide" v-for="item in list" :key="item.id">
        <SpecialSliderItem :item="item" />
      </swiper-slide>
    </swiper>
    <SwiperPagination
      v-if="swiperList > 1"
      :index="activeIndex"
      :total="swiperList"
      @change="paginationClick"
    />
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css";
import SpecialSliderItem from "./Item";
import SwiperPagination from "@/components/SwiperPagination";
/**
 * The SpecialSlider component.
 */
export default {
  name: "SpecialSlider",
  components: { Swiper, SwiperSlide, SpecialSliderItem, SwiperPagination },
  props: {
    list: { default: () => [], type: Array },
  },
  data() {
    return {
      swiper: null,
      swiperList: 0,
      activeIndex: 0,
      timer: null,
    };
  },
  computed: {
    // ...mapGetters(['IS_Tablet']),
  },
  methods: {
    swiperSlideChange() {
      this.activeIndex = this.swiper.activeIndex;
      this.initSwiperLoop();
    },
    swiperInit(swiper) {
      this.swiper = swiper;
      this.swiperList = this.list.length;
      this.swiperSlideChange();
    },
    paginationClick(index) {
      this.swiper.slideTo(index);
    },
    initSwiperLoop() {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        if (this.activeIndex + 1 === this.swiperList) {
          this.swiper.slideTo(0);
        } else {
          this.swiper.slideNext();
        }
      }, 5000);
    },
  },
  beforeUnmount() {
    clearTimeout(this.timer);
  },
};
</script>

<style lang="scss" scoped src="./component.scss"></style>
