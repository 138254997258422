<template>
  <div class="open-instruction shadowX-box">
    <ul class="list">
      <li v-if="numberTI" class="w32">
        <span class="name">{{ $translate('TI_number', 'documents') }}</span>
        <span class="Ti-name">{{ numberTI }}</span>
        <a
          target="_blank"
          v-if="downloadTi && downloadTi.url"
          class="TI-download simpleLink"
          :href="downloadTi.url"
          :download="downloadTi.name"
          ><span>{{ $translate('download_TI', 'documents') }}</span>
          <DownloadSvg
        /></a>
      </li>
      <li v-if="statusTi && statusTi.name" class="w68">
        <span class="name">{{ $translate('status', 'companies') }}</span>
        <span
          :style="
            statusTi.labelColor ? `background:${statusTi.labelColor}` : ''
          "
          class="notice"
          >{{ statusTi.name }}</span
        >
      </li>
      <!-- delivery_details -->
      <!-- <li class="w32 marg-bot--15">
        <span class="name">{{
          $translate('delivery_details', 'documents')
        }}</span>
        <span  class="val"></span>
      </li> -->
      <!-- consignee -->
      <li v-if="consignee" class="w36 marg-bot--15">
        <span class="name">{{ $translate('consignee', 'orders') }}</span>
        <span class="val">{{ consignee }}</span>
      </li>
      <!-- delType -->
      <li v-if="delType" class="w32 w32--33 marg-bot--15">
        <span class="name">{{
          $translate('type_transportation', 'orders')
        }}</span>
        <span class="val">{{ delType }}</span>
      </li>

      <li v-if="consigneeRailCode" class="w100">
        <span class="name">{{
          $translate('recipients_ID_code', 'documents')
        }}</span>
        <span class="val">{{ consigneeRailCode }}</span>
      </li>

      <li v-if="railGraph15" class="w50">
        <span class="name">{{
          $translate('column_15_for_the_railway', 'orders')
        }}</span>
        <span class="val">{{ railGraph15 }}</span>
      </li>

      <li v-if="railGraph" class="w50">
        <span class="name">{{
          $translate('marks_for_the_railway', 'orders')
        }}</span>
        <span class="val">{{ railGraph }}</span>
      </li>
    </ul>

    <div v-if="actions && actions.showDrivers" class="car-list">
      <div class="flex alignCenter auto-name black marg-bot--16">
        <span class="title">{{ $translate('list_of_cars', 'documents') }}</span>
        <Tippy
          :text="$translate('paymentData_OpenInstructionshowDrivers', 'orders')"
        />
      </div>
      <ul v-if="carInfo && carInfo.length" class="auto-list">
        <li :key="i" v-for="(item, i) in carInfo">
          <TransportInfo
            :data="item"
            :editable="actions && actions.editDrivers"
            :info="vehiclePopupInfo"
          />
        </li>
      </ul>
      <MyButton
        v-if="actions && actions.editDrivers"
        class="width-auto"
        type="outline-green"
        tag="button"
        :text="$translate('add_car', 'documents')"
        :dynamicSize="true"
        @click="
          $popup.show('AddVehicle', { action: 'add', ...this.vehiclePopupInfo })
        "
      />
    </div>
    <!-- epd -->
    <template v-if="getEPD && getEPD.length">
      <div class="flex alignCenter">
        <span class="name big">{{
          $translate('related_EPDs', 'documents')
        }}</span>
        <Tippy
          :text="$translate('paymentData_OpenInstructiongetEPD', 'orders')"
        />
      </div>
      <ul class="epd-list">
        <li class="epd" :key="i" v-for="(item, i) in getEPD">
          <span class="name" v-if="item?.number">{{ item?.number }}</span>
          <Tag
            class="w160"
            :text="item.status.name"
            v-if="item?.status?.name"
          />
          <template v-if="item.action">
            <MyButton
              v-if="item.action == 'map'"
              type="outline-yellow"
              tag="button"
              :text="$translate('view_on_map', 'documents')"
              :dynamicSize="true"
              icon="mapMarker"
              @click="lookMap(item.id)"
            />
            <MyButton
              v-if="item.action == 'credit'"
              type="outline-yellow"
              tag="button"
              :text="$translate('discredit', 'general')"
              :dynamicSize="true"
              icon="print"
              @click="creditAction(item.id)"
            />
            <MyButton
              v-if="item.action == 'approve'"
              type="outline-yellow"
              tag="button"
              :text="$translate('Accept', 'general')"
              :dynamicSize="true"
              icon="star"
              @click="approveAction(item.id)"
            />
          </template>
        </li>
      </ul>
    </template>

    <div v-if="attachments.length > 0" class="attachments black marg-bot--16">
      <h4 class="title">{{ $translate('ti_attachments', 'orders') }}</h4>

      <ul class="attachments-list">
        <li
          v-for="(item) in attachments"
          :key="item.id"
          class="flex alignCenter attachments-list-item">
          <a
            target="_blank"
            :href="item?.url"
            :download="item?.name"
            class="simpleLink attachments-list-link"
          >
            <span class="attachments-list-name">
              {{ $translate('download_ti_attachment', 'orders') }} "{{ item?.name }}"
            </span>
          </a>
          <DownloadSvg /> 
        </li>
      </ul>
    </div>

    <div class="flex alignCenter action marg-top--16">
      <MyButton
        v-if="canUpdate"
        tag="button"
        :text="$translate('edit_TI', 'documents')"
        :dynamicSize="true"
        @click="$popup.show('TIEdit', this.data)"
      />

      <MyButton
        class="width-auto"
        type="outline-yellow"
        tag="button"
        :text="$translate('ask_a_question', 'documents')"
        :dynamicSize="true"
        @click="
          $popup.show('AskQuestionProfile', {
            id: data?.id,
            type: 'transport-instructions',
          })
        "
      />

      <MyButton
        v-if="canUploadAttachment"
        class="width-auto"
        type="outline-yellow"
        tag="button"
        :text="$translate('upload_file')"
        :dynamicSize="true"
        @click="
          $popup.show('TIUploadAttachment', {
            id: data?.id,
            type: 'ti',
          })
          "
      />
    </div>
  </div>
</template>
<script>
import DownloadSvg from '@/assets/img/downloadArrow.svg';
import TransportInfo from './TransportInfo';
import Tag from '@/components/Table/PageTable/Tag';
import Tippy from '@/components/Tippy';

export default {
  name: 'OpenInstruction',
  components: { TransportInfo, Tag, DownloadSvg, Tippy },
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
    orderData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    numberTI() {
      return this.data?.number;
    },
    downloadTi() {
      const domain = process.env.VUE_APP_API_DOMAIN;
      const url = this.data?.file?.url;
      const name = this.data?.file?.name;
      return {
        url: url ? domain + url : null,
        name,
      };
    },
    statusTi() {
      const name = this.data?.status?.name;
      const labelColor = this.data?.status?.labelColor;
      return {
        name,
        labelColor,
      };
    },
    consignee() {
      return [
        this.data?.consignee?.name,
        this.$translate('EDRPOY', 'general'),
        this.data?.consignee?.edrpou,
      ]
        .filter((item) => item)
        .join(' ');
    },
    delType() {
      return [
        this.orderData?.deliveryType?.name,
        this.data?.shipmentType?.name,
        this.data?.deliveryCondition?.name,
      ]
        .filter((item) => item)
        .join(', ');
    },
    consigneeRailCode() {
      return this.data?.consignee?.railCode;
    },
    railGraph15() {
      return this.data?.railroadNote15;
    },
    railGraph() {
      return this.data?.railroadNote;
    },
    actions() {
      return this.data?.actions;
    },
    carInfo() {
      return this.data?.carInfo;
    },
    getEPD() {
      return this.data?.electronicDocuments;
    },
    totalVolume() {
      return [this.orderData?.product?.name, this.data?.order?.quantity]
        .filter((item) => item)
        .join(', ');
    },
    vehiclePopupInfo() {
      return {
        cars: this.carInfo,
        TIID: this.data?.id,
        deliveryType: this.delType,
        totalValue: this.totalVolume,
      };
    },
    canUpdate() {
      return this.actions?.update;
    },
    canUploadAttachment() {
      return this.actions?.uploadFile;
    },
    attachments() {
      if (Array.isArray(this.data?.attachments)) {
        return this.data.attachments.map(({ id, name, url }) => {
          return {
            id,
            name,
            url: url ? process.env.VUE_APP_API_DOMAIN + url : null,
          };
        });
      }
      return [];
    }
  },
  methods: {
    lookMap(id) {
      return this.$router.push({
        name: 'packages-map',
        query: {
          electronicDocument_id: id,
        },
      });
    },
    async creditAction(id) {
      if (!id) throw Error(`id not found`);
      try {
        const { success = false } =
          await this.$api.document.electronicDocumentsCredit(id);
        if (success) {
          document.dispatchEvent(new CustomEvent('update-instruction'));
        }
      } catch (e) {
        console.log(e);
      }
    },
    async approveAction(id) {
      if (!id) throw Error(`id not found`);
      try {
        const { success = false } =
          await this.$api.document.electronicDocumentsApprove(id);
        if (success) {
          document.dispatchEvent(new CustomEvent('update-instruction'));
        }
      } catch (e) {
        console.log(e);
      }
    },
    async handleAttachmentUpload({ file, comment }) {
      const { success } = await this.$api.document.uploadTIAttachment(this.data.id, {
        file: file.id,
        comment: comment,
      });

      if (success) {
        this.$notice(this.$translate('ti_attachment_uploaded_notice', 'orders'));
        this.$popup.hide('TIUploadAttachment')
      } else {
        this.$notice(this.$translate('request_error'), undefined, {
          type: 'error',
        });
      }
    },
  },
  created() {
    this.emitter.on('attachmentFileUploaded', this.handleAttachmentUpload);
  },
  beforeUnmount() {
    this.emitter.off('attachmentFileUploaded', this.handleAttachmentUpload);
  },
};
</script>
<style scoped src="./style.scss" lang="scss"></style>
