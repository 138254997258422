<template>
  <LayoutLogin>
    <LoginGrid
      :title="$translate('thank_registering', 'auth')"
      :text="$translate('thank_registering_text', 'auth')"
      :doubleColumn="false"
    >
      <template v-slot:columnLeft>
        <MyButton
          class="DashboardButton"
          :text="$translate('login_personal_account')"
          tag="router-link"
          :link="{ name: 'dashboard' }"
          :dynamicSize="true"
        />
      </template>
    </LoginGrid>
  </LayoutLogin>
</template>

<script>
import LayoutLogin from "@/layouts/Login.vue";
import LoginGrid from "@/components/LoginGrid";
/**
 * The registration-success-page component.
 */
export default {
  name: "RegistrationPage",
  components: { LayoutLogin, LoginGrid },
  created() {
    if (localStorage.getItem("registrationSuccess") !== "true") {
      return this.$router.push({ name: "registration" });
    }
    localStorage.removeItem("registrationSuccess");
  },
};
</script>

<style lang="scss" scoped>
.DashboardButton {
  width: toRem(370);
  margin-top: toRem(40);
  @media (max-width: $notebook) {
    width: toRem(306);
  }
  @media (max-width: $mobile) {
    margin-top: toRem(20);
  }
}
</style>