<template>
  <DashboardCard class="CheckoutOrderDetails overflow">
    <h5 class="title">{{ $translate("order_details", "orders") + ":" }}</h5>
    <table>
      <tbody>
        <tr>
          <td>
            {{ $translate("order_number", "orders") }}
          </td>
          <td>
            <b>{{ order?.number }}</b>
          </td>
        </tr>
        <tr>
          <td>
            {{ $translate("delivery_type", "orders") }}
          </td>
          <td>
            <div class="basis" v-if="basis">
              <b class="basisName">{{ basis.name }}</b>
              <Notice
                v-if="basis.tooltip"
                :title="basis.tooltip"
                class="gray left"
              />
              <b v-if="shippingWarehouse" class="shippingWarehouse">{{
                shippingWarehouse.name
              }}</b>
            </div>
            <span class="noData" v-else-if="showEmptyData">—</span>
          </td>
        </tr>
        <tr>
          <td>
            {{ $translate("volume", "orders") }}
          </td>
          <td>
            <b
              >{{ order?.quantity }} {{ unit?.name || "т" }} ,
              {{ packagingName }}</b
            >
          </td>
        </tr>
        <tr>
          <td>
            {{ $translate("payment_details", "orders") }}
          </td>
          <td>
            <b v-if="paymentType">{{ paymentType.name }}</b>
            <span v-else-if="showEmptyData" class="noData">—</span>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="priceRow" v-if="price">
      <p class="rowTitle">
        {{ $translate("order_amount", "orders") }}
        <Notice
          class="gray left"
          :title="$getNotice('order_amount_notice')"
          v-if="$getNotice('order_amount_notice')"
          :class="{
            left_center: IS_Tablet,
          }"
        />
      </p>
      <div class="price">
        {{ $price(price.total, undefined, true) }}
      </div>

      <table class="priceDetailsTable">
        <tbody>
          <tr v-if="price.perTon">
            <td>{{ $translate("price_per_ton", "orders") }}:</td>
            <td>
              <span class="priceDetailsTableHighlight">
                {{ $price(price.perTon, undefined, true) }}
              </span>
            </td>
          </tr>
          <tr v-if="price.delivery">
            <td>{{ $translate("delivery_price", "orders") }}:</td>
            <td>
              <span class="priceDetailsTableHighlight">
                {{ $price(price.delivery, undefined, true) }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>

    </div>

    <MyButton
      class="w100 submit"
      :text="$translate('order', 'dashboard')"
      :disabled="disabled"
      :size="submitSize"
      @click="$emit('submit')"
    />

    <span class="notice">{{
      $translate("order_details_notice", "orders")
    }}</span>

    <MyButton
      :text="$translate('save_orders_to_drafts', 'orders')"
      class="w100 saveDrafts"
      type="transparent-primary"
      @click="emitter.emit('saveDrafts')"
    />

    <div
      class="priceRow marketingProducts"
      v-if="order?.marketingProductsConfig && order?.marketingProductsConfig.length"
    >
      <p class="rowTitle">
        {{ $translate("marketing_products", "orders") }}:
        <Notice
          class="gray left"
          :title="$getNotice('marketing_products_notice')"
          v-if="$getNotice('marketing_products_notice')"
        />
      </p>
      <div class="list">
        <MarketingProduct
          v-for="item in order.marketingProductsConfig.slice(0, 2)"
          :key="item.id"
          :item="item"
          :currentMarketingProduct="getCurrentMarketingProduct(item.id)"
        />
        <div v-show="marketingProductsListIsExpanded">
          <MarketingProduct
            v-for="item in order.marketingProductsConfig.slice(2)"
            :key="item.id"
            :item="item"
            :currentMarketingProduct="getCurrentMarketingProduct(item.id)"
          />
        </div>
        <MyButton
          v-if="order.marketingProductsConfig.length > 2"
          :text="marketingProductsListExpandButtonLabel"
          class="w100 marketingProductsExpandButton"
          size="s"
          type="transparent"
          @click="marketingProductsListIsExpanded = !marketingProductsListIsExpanded"
        />
      </div>
    </div>
  </DashboardCard>
</template>

<script>
import { mapGetters } from "vuex";
import DashboardCard from "@/components/DashboardCard";
import Notice from "@/components/Notice";
import MarketingProduct from "@/components/Checkout/MarketingProduct";
/**
 * The CheckoutOrderDetails component.
 */
export default {
  name: "CheckoutOrderDetails",
  components: { DashboardCard, Notice, MarketingProduct },
  props: {
    /**
     * disabled state on form errors
     */
    showEmptyData: { default: true, type: Boolean },
    disabled: { default: true, type: Boolean },
    packagingName: { default: null, type: String },
    paymentType: { default: null, type: Object },
    unit: { default: null, type: Object },
    basis: { default: null, type: Object },
    shippingWarehouse: { default: null, type: Object },
    order: { default: null, type: Object },
  },
  data() {
    return {
      marketingProductsListIsExpanded: false,
    };
  },
  computed: {
    ...mapGetters(["IS_Notebook", "IS_Tablet"]),
    price() {
      return {
        total: this.order?.amount,
        perTon: this.order?.price,
        delivery: this.order?.deliveryPrice,
      };
    },
    submitSize() {
      if (this.IS_Notebook) return "m";
      return "l";
    },
    marketingProductsListExpandButtonLabel() {
      const key = this.marketingProductsListIsExpanded
        ? "marketing_list_collapse"
        : "marketing_list_expand";

      return this.$translate(key, "orders");
    },
  },
  methods: {
    // ...mapActions(['GET_PaymentInfo']),
    getCurrentMarketingProduct(id) {
      return this.order?.marketingProducts?.find(
        (item) => item?.marketingProduct?.id === id
      );
    },
  },
};
</script>

<style lang="scss" scoped src="./component.scss"></style>
