<template>
  <div class="ScheduleMainWrap" :class="{ autoHeight }">
    <LoadingIcon v-if="loading" :absolute="true" />
    <div class="ScheduleSecondWrap" :class="{ disabledElem: loading }">
      <div class="ScheduleWrap">
        <div class="ScheduleFixed">
          <table>
            <thead>
              <tr>
                <th>{{ tableTitle }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in orderList"
                :key="item?.order?.id || item.id"
                ref="fixedRow"
              >
                <td>
                  <router-link
                    v-if="enableOrderLinks && item.routerLinkParams"
                    class="simpleLink"
                    :to="item.routerLinkParams"
                  >
                    <b>{{ getOrderTitle(item) }}</b>
                  </router-link>
                  <b v-else>{{ getOrderTitle(item) }}</b>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="ScheduleSwiperWrap">
          <MyButton
            class="arrowButton arrowLeft shadow"
            type="circle"
            icon="arrow_left"
            @click="slidePrev"
            size="m"
            :disabled="disabledPrevButton"
          />
          <MyButton
            class="arrowButton arrowRight shadow"
            type="circle"
            icon="arrow_left"
            @click="slideNext"
            size="m"
            :disabled="disabledNextButton"
          />
          <swiper
            class="swiper Schedule"
            :class="{ 'swiper-no-swiping': IS_Mobile }"
            ref="swiper"
            :slides-per-view="slidesPerView"
            @swiper="swiperInit"
            @slide-change="swiperSlideChange"
            :no-swiping="true"
            no-swiping-class="swiper-no-swiping"
          >
            <swiper-slide
              class="swiper-slide ScheduleColumn"
              v-for="column in scheduleList"
              :key="column.date"
              ref="slide"
            >
              <table>
                <thead>
                  <tr>
                    <th>
                      <span class="date">{{ column.date }}</span>
                      <b v-if="column.totalValue">{{
                        getTotalValue(column.totalValue)
                      }}</b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="row in column.ordersList" :key="row.order.id">
                    <td>
                      <ul>
                        <li v-for="tag in row.list" :key="tag.id">
                          <Tag
                            :text="getTagText(tag, row)"
                            :class="['small', getTagClass(tag)]"
                          >
                            <template v-if="row?.order?.product?.name != null">&nbsp;<Tippy :text="row.order.product.name" /></template>
                          </Tag>
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Tag from "@/components/Table/PageTable/Tag";
import Tippy from '@/components/Tippy';
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css";

const DATE_FORMAT = "DD.MM.YY";
/**
 * The Schedule component.
 */
export default {
  name: "ScheduleComponent",
  components: { Tag, Tippy, Swiper, SwiperSlide },
  props: {
    orderList: { default: () => [], type: Array },
    primaryTagClass: { default: true, type: Boolean },
    /**
     * The type element.
     * @values packages, payments
     */
    type: { default: "payments", type: String },
    loading: { default: false, type: Boolean },
    enableOrderLinks: { default: false, type: Boolean },
    /** @type {null|number[]} */
    dates: { default: () => [], type: Array },
  },
  data() {
    return {
      swiper: null,
      activeIndex: 0,
      autoHeight: false,
    };
  },
  computed: {
    ...mapGetters(["IS_Mobile", "IS_Notebook", "IS_Tablet", "IS_Tablet2"]),
    creationDate() {
      return this.$moment().format(DATE_FORMAT);
    },
    tableTitle() {
      if (this.type == "payments") return this.$translate("order", "orders");
      if (this.type == "packages")
        return this.$translate("transport_instructions", "orders");
      return "";
    },
    scheduleList() {
      const list = [];
      const startDate = this.$moment().subtract(14, "days");
      const endDate = this.$moment().add(14, "days");

      if (this.dates && this.dates.length) {
        return this.dates.map((date) => ({
          date: this.$moment(date).format(DATE_FORMAT),
          ...this.createScheduleListEntryFromDate(date),
        }));
      }

      for (let date = startDate; date <= endDate; date = date.add(1, "day")) {
        const formattedDate = date.format(DATE_FORMAT);
        list.push({
          date: formattedDate,
          ...this.createScheduleListEntryFromDate(date.valueOf()),
        });
      }

      return list;
    },
    slidesPerView() {
      if (this.IS_Mobile) return 5;
      if (this.IS_Tablet2) return 3;
      if (this.IS_Tablet) return 7;
      if (this.IS_Notebook) return 5;
      return 7;
    },
    disabledPrevButton() {
      return this.activeIndex == 0;
    },
    disabledNextButton() {
      return this.swiper?.isEnd;
    },
  },
  methods: {
    /**
     * @param {number} date
     */
    createScheduleListEntryFromDateForPackages(date) {
      let totalValue = 0;
      const ordersList = this.orderList.map((item) => {3
        if (item == null) {
          return item
        }

        const list = item.list.filter((quota) => {
          const packageDate = this.$moment(quota.date);
          return packageDate.isSame(date, "day");
        });

        totalValue += list.reduce((acc, item) => acc + (item.quantity || 0), 0);

        return {
          ...item,
          list
        };
      });
      return { totalValue, ordersList };
    },
    /**
     * @param {number} date
     */
    createScheduleListEntryFromDateForPayments(date) {
      let totalValue = 0;
      const ordersList = this.orderList.map((item) => {
        const orderId = item.order.id;
        const matchingItemByOrder = this.orderList.find(
          (el) => el.order.id === orderId
        );
        if (matchingItemByOrder == null) {
          return item
        }

        const list = matchingItemByOrder.list.filter((payment) => {
          const paymentDate = this.$moment(payment.documentValidToDate);
          return paymentDate.isSame(date, "day");
        });

        totalValue += list.reduce((acc, item) => acc + item.suAmountVat, 0);

        item.list = list;

        return item;
      });
      return { totalValue, ordersList };
    },
    /**
     * @param {number} date
     */
    createScheduleListEntryFromDate(date) {
      return this.type === "payments"
        ? this.createScheduleListEntryFromDateForPayments(date)
        : this.createScheduleListEntryFromDateForPackages(date);
    },
    getTimeToday() {
      return this.$moment()
        .set("hour", 0)
        .set("minute", 0)
        .set("second", 0)
        .set("millisecond", 0);
    },
    getOrderTitle(item) {
      if (this.type == "payments") return item.order?.number;
      if (this.type == "packages") return item.name;
      return "";
    },
    getTotalValue(totalValue) {
      if (this.type == "payments") return this.$price(totalValue);
      if (this.type == "packages") return totalValue + " T";
      return totalValue;
    },
    getTagText(tag, item) {
      if (this.type == "payments") return this.$price(tag.suAmountVat);
      if (this.type == "packages") {
        return [
          item?.deliveryCondition?.name || undefined,
          tag.quantity ? tag.quantity + " T" : undefined,
        ]
          .filter(Boolean)
          .join(", ");
      }
      return tag.suAmountVat;
    },
    getTagClass(tag) {
      if (this.primaryTagClass) return "primary";
      const tagDate = this.$moment(tag.documentValidToDate).format(DATE_FORMAT);
      const diff = this.$moment(this.creationDate, DATE_FORMAT).diff(
        this.$moment(tagDate, DATE_FORMAT),
        "days",
        true
      );
      if (tagDate === this.creationDate || diff > 0) {
        return "error";
      }
      if (diff < -4) {
        return "success";
      }
      return "primary";
    },
    swiperInit(swiper) {
      this.swiper = swiper;
      this.swiper.slideTo(14, 0);
    },
    swiperSlideChange() {
      this.activeIndex = this.swiper.activeIndex;
    },
    slidePrev() {
      this.swiper.slidePrev();
    },
    slideNext() {
      this.swiper.slideNext();
    },
    paginationClick(index) {
      this.swiper.slideTo(index);
    },
    calcRowSize() {
      this.autoHeight = true;
      for (let index = 0; index < this.orderList.length; index++) {
        let max = 0;
        const rowList = [];
        this.$refs.slide.forEach((column) => {
          const td = column.$el
            .querySelectorAll("tbody tr")
            .item(index)
            .querySelector("td");
          const height = td.clientHeight;
          max = Math.max(max, height);
          rowList.push(td);
        });

        // set max height
        this.$refs.fixedRow[index].cells.item(0).style.height = `${max}px`;
        rowList.forEach((el) => (el.style.height = `${max}px`));
        this.autoHeight = false;
      }
    },
  },
  mounted() {
    this.calcRowSize();
    window.addEventListener("resize", this.calcRowSize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.calcRowSize);
  },
};
</script>

<style lang="scss" scoped src="./component.scss"></style>
