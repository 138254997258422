export default async function auth({ next, store, router }) {
    const $app = window.$app
    const apiToken = localStorage.getItem('api_token');

    if (apiToken) {
        if (localStorage.getItem('api_logined') == 'true' && !store.getters['auth/GET_User']) {
            store.commit('auth/SET_Token', apiToken);
            await $app.config.globalProperties.$auth.getMe();
            console.log('store');
            
            $app.config.globalProperties.$api.io.connect({ authToken: apiToken })
                .then(() => {
                    store.dispatch('EFFECT_WatchNotifications');
                })
                .catch(err => console.error(err));
        } else if (localStorage.getItem('api_logined') != 'true') {
            await $app.config.globalProperties.$auth.logOut()
            return router.push({ name: 'login' });
        }
    } else {
        await $app.config.globalProperties.$auth.logOut()
        return router.push({ name: 'login' });
    }

    return next();
}