<template>
  <Layout id="SectionIntro">
    <template v-if="showPopup">
      <swiper
        class="swiper imageSlider"
        ref="swiper"
        @swiper="swiperInit"
        @slide-change="swiperSlideChange"
      >
        <swiper-slide
          class="swiper-slide"
          v-for="item in list"
          :key="item.id"
          ref="slide"
        >
          <div class="imageSlideImage">
            <template v-if="item.media?.mime?.includes('image')">
              <img
                :src="getMediaUrl(item.media)"
                :alt="item.media.alternativeText"
              />
            </template>
            <template v-else-if="item.media?.mime?.includes('video')">
              <video :src="getMediaUrl(item.media)" loop playsinline></video>
            </template>
          </div>
          <div class="imageSliderContent">
            <h5 class="h4" v-html="item.title"></h5>
            <p v-html="item.description"></p>
          </div>
        </swiper-slide>
      </swiper>

      <SwiperPagination
        v-if="swiperList > 1"
        :index="activeIndex"
        :total="swiperList"
        @change="paginationClick"
      />
      <template v-if="swiperList > 1">
        <MyButton
          class="arrowButton arrowLeft shadow"
          type="circle"
          icon="arrow_left"
          @click="slidePrev"
          :size="arrowButtonSize"
        />
        <MyButton
          class="arrowButton arrowRight shadow"
          type="circle"
          icon="arrow_left"
          @click="slideNext"
          :size="arrowButtonSize"
        />
      </template>

      <div class="controllRow row margin0 between">
        <MyButton
          :text="$translate('go_back', 'dashboard')"
          type="transparent"
          icon="arrow_left"
          class="width-auto arrow arrowLeft"
          @click="slidePrev"
        />
        <MyButton
          :text="$translate('to_complete', 'dashboard')"
          class="w210"
          @click="$popup.hide()"
        />
        <MyButton
          :text="$translate('go_next', 'dashboard')"
          type="transparent"
          iconAfter="arrow_left"
          class="width-auto arrow arrowRight"
          @click="slideNext"
        />
      </div>
    </template>
  </Layout>
</template>

<script>
import { mapGetters } from "vuex";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css";
import Layout from "../../PopupLayout";
import SwiperPagination from "@/components/SwiperPagination";
/**
 * The SectionIntroModal component.
 */
export default {
  name: "SectionIntroModal",
  components: {
    Layout,
    Swiper,
    SwiperSlide,
    SwiperPagination,
  },
  data() {
    return {
      swiper: null,
      swiperList: 0,
      activeIndex: 0,
    };
  },
  computed: {
    ...mapGetters([
      "IS_Mobile",
      "GET_Onboarding",
      "GET_CurrentOnboarding",
      "GET_CurrentPopup",
    ]),
    showPopup() {
      return this.GET_CurrentPopup &&
        this.GET_Onboarding[this.GET_CurrentOnboarding]
        ? true
        : false;
    },
    list() {
      return this.GET_Onboarding[this.GET_CurrentOnboarding] || [];
    },
    arrowButtonSize() {
      return this.IS_Mobile ? "m" : "l";
    },
  },
  methods: {
    swiperInit(swiper) {
      this.swiper = swiper;
      this.swiperList = this.list.length;
      this.swiperSlideChange();
    },
    swiperSlideChange() {
      this.activeIndex = this.swiper.activeIndex;
      this.stopAllVideos();
      if (this.list[this.activeIndex].media?.mime?.includes("video")) {
        this.playSlideVideo(this.activeIndex);
      }
    },
    stopAllVideos() {
      this.$refs.slide.forEach((slide) => {
        const video = slide.$el.querySelector("video");
        if (video) video.pause();
      });
    },
    playSlideVideo(index = 0) {
      this.$refs.slide[index].$el.querySelector("video").play();
    },
    slidePrev() {
      this.swiper.slidePrev();
    },
    slideNext() {
      this.swiper.slideNext();
    },
    paginationClick(index) {
      this.swiper.slideTo(index);
    },
    getMediaUrl(media) {
      return process.env.VUE_APP_API_DOMAIN + media.url;
    },
  },
};
</script>
<style lang="scss" src="./component.scss"></style>
