import { createStore } from 'vuex';
import windowSize from './modules/windowSize';
import auth from './modules/auth';
import i18ns from './modules/i18ns';
import checkout from './modules/checkout';
import notifications from './modules/notifications';
import graphics from './modules/graphics';

// Create a new store instance.
const store = createStore({
  state() {
    return {
      isMainPage: false,
      minimizedSidebar: false,
      currentPopup: null,
      onboarding: {},
      currentOnboarding: null,
      popupData: {},
      errorPage: null,
      companies: {},
      removeCompanyData: null,
      sidebarContacts: null,
    };
  },
  getters: {
    GET_IsMainPage: (state) => state.isMainPage,
    GET_MinimizedSidebar: (state) => state.minimizedSidebar,
    GET_CurrentPopup: (state) => state.currentPopup,
    GET_Onboarding: (state) => state.onboarding,
    GET_CurrentOnboarding: (state) => state.currentOnboarding,
    GET_PopupData: (state) => state.popupData,
    GET_ErrorPage: (state) => state.errorPage,
    GET_Companies: (state) => state.companies,
    GET_RemoveCompanyData: (state) => state.removeCompanyData,
    GET_SidebarContacts: (state) => state.sidebarContacts,
  },
  mutations: {
    SET_MinimizedSidebar(state, payload) {
      state.minimizedSidebar = payload;
    },
    SET_CurrentPopup: (state, payload) => {
      state.currentPopup = payload;
    },
    SET_Onboarding: (state, { uid, data }) => {
      state.onboarding[uid] = data;
    },
    SET_CurrentOnboarding: (state, uid) => {
      state.currentOnboarding = uid;
    },
    SET_PopupData: (state, { name, data }) => {
      state.popupData[name] = data;
    },
    SET_ErrorPage: (state, payload) => {
      state.errorPage = payload;
    },
    SET_Company: (state, { id, data }) => {
      state.companies[id] = data;
    },
    SET_RemoveCompanyData: (state, data) => {
      state.removeCompanyData = data;
    },
    SET_SidebarContacts: (state, data) => {
      state.sidebarContacts = {
        ...state.sidebarContacts,
        ...data,
      };
    },
  },
  actions: {
    SET_MinimizedSidebar({ commit }, payload) {
      commit('SET_MinimizedSidebar', payload);
      localStorage.setItem('minimizedDashboardLayout', payload);
    },
    DETECT_MinimizedSidebar({ commit }) {
      const minimized = localStorage.getItem('minimizedDashboardLayout') === 'true';
      console.log('DETECT_MinimizedSidebar', { minimized });
      commit('SET_MinimizedSidebar', minimized);
    },
    SHOW_Popup({ commit }, { name, data }) {
      commit('SET_CurrentPopup', name);
      commit('SET_PopupData', { name, data });
      console.log('SHOW_Popup', name, data);
    },
    HIDE_Popup({ commit }) {
      commit('SET_CurrentPopup', null);
    },
    async SHOW_Onboarding({ commit, getters }, uid) {
      let success = true;
      if (!getters.GET_Onboarding[uid]) {
        const response = await window.$app.config.globalProperties.$api.getOnboarding(uid);
        if (response.success) commit('SET_Onboarding', { uid, data: response.slider });
        success = response.success;
      }
      console.log('SHOW_Onboarding', { uid, success });
      if (success) {
        commit('SET_CurrentOnboarding', uid);
        window.$app.config.globalProperties.$popup.show('SectionIntro');
      }
    },
    SET_ErrorPage({ commit }, payload) {
      commit('SET_ErrorPage', payload);
    },
    SET_Company({ commit }, payload) {
      commit('SET_Company', payload);
    },
    RemoveCompanyData({ commit }, payload) {
      commit('SET_RemoveCompanyData', payload);
      window.$app.config.globalProperties.$popup.show('RemoveCompanyItem');
    },
    async INIT_SidebarContacts({ commit }) {
      try {
        const { data } = await window.$app.config.globalProperties.$api.getFrontendConfig({});
        const { supportEmail, supportPhone, policy, terms } = data;

        commit('SET_SidebarContacts', {
          supportEmail,
          supportPhone,
          policy,
          terms,
        });
      } catch (error) {
        console.error(error);
      }
    },
  },

  modules: {
    windowSize,
    auth,
    i18ns,
    checkout,
    notifications,
    graphics,
  },
});

store.dispatch('SET_WindowSize');
window.addEventListener('resize', () => {
  store.dispatch('SET_WindowSize');
});

export default store;
