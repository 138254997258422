<template>
  <Layout class="TI-cancellation" id="TICancellation">
    <h4 class="title">
      {{ $translate('cancellation', 'general') }} <br />
      {{ $translate('TI', 'documents') }}
    </h4>
    <p class="description">
      {{ $translate('TICancellation_description', 'documents') }}
    </p>
    <div class="flex column">
      <ul class="block">
        <li v-if="totalVolume">
          <span class="name">
            {{ $translate('total_volume_by_TI', 'general') }}
          </span>
          <span class="val"
            >{{ totalVolume }} {{ $translate('t', 'orders') }}</span
          >
        </li>
      </ul>
      <ul class="block">
        <li>
          <transition>
            <LabelDropdown
              name="TI"
              :title="$translate('choose_new_TI', 'documents')"
              :list="listNewTI"
              :active="selected"
              @change="changeDropdown"
              :disabled="loadingList || !listNewTI || !listNewTI.length"
            />
          </transition>
        </li>
      </ul>
    </div>
    <div class="formWrap" :class="{ loading: loading }">
      <LoadingIcon v-if="loading" :absolute="true" />
      <MyButton
        @click="submit"
        class="w100"
        :text="$translate('delete_TI', 'documents')"
        tag="button"
        :dynamicSize="true"
        :disabled="!selected"
      />
      <MyButton
        @click="goBack"
        class="w100"
        :text="$translate('go_back', 'general')"
        tag="button"
        tagType="reset"
        type="cancel"
        :dynamicSize="true"
      />
    </div>
  </Layout>
</template>

<script>
import { mapGetters } from 'vuex';
import Layout from '../../PopupLayout';
const popupName = 'TICancellation';
/**
 * The OrderCancellationRequest component.
 */
export default {
  name: popupName,
  components: {
    Layout,
  },
  data() {
    return {
      popupName: popupName,
      loading: false,
      listFull: [],
      listNewTI: [],
      selected: null,
      loadingList: false,
    };
  },
  computed: {
    ...mapGetters(['IS_Mobile', 'GET_CurrentPopup', 'GET_PopupData']),
    gData() {
      return this.GET_PopupData?.[this.GET_CurrentPopup];
    },
    totalVolume() {
      return [this.gData?.order?.product?.name, this.gData?.order?.quantity]
        .filter((item) => item)
        .join(', ');
    },
    getId() {
      return this.gData?.order?.id;
    },
    currentInstructionId() {
      return this.gData?.id;
    },
  },
  methods: {
    async submit() {
      try {
        if (this.loading) return false;
        this.loading = true;
        const transferToTiId = this.listFull.find(
          ({ number }) => number === this.selected
        )?.id;
        if (!transferToTiId) throw new Error('transferToTiId is null');
        const PUT = {
          transferToTiId,
        };
        const { success = false } = await this.$api.document.putInstruction(
          this.currentInstructionId,
          PUT
        );
        if (success) {
          this.$notice(this.$translate('canceled'));
          document.dispatchEvent(new CustomEvent('update-instruction'));
          document.dispatchEvent(new CustomEvent('update-instructions'));
        } else {
          this.$notice(this.$translate('request_error'), undefined, {
            type: 'error',
          });
        }
        this.loading = false;
        this.$popup.hide(undefined, () => this.resetForm());
      } catch (e) {
        this.loading = false;
        this.$popup.hide(undefined, () => this.resetForm());
        this.$notice(e, undefined, {
          type: 'error',
        });
      }
    },
    goBack() {
      this.$popup.hide();
    },
    resetForm() {
      this.loading = false;
      this.listFull = [];
      this.listNewTI = [];
      this.selected = null;
      this.loadingList = false;
    },
    log(evt) {
      console.log(evt);
    },
    changeDropdown(key, name, item) {
      switch (name) {
        case 'TI':
          this.selected = item;
          break;
        default:
          break;
      }
    },
    async request() {
      try {
        this.loadingList = true;
        const params = {
          filters: {
            order: { id: this.getId },
          },
        };
        const { list = [], success = false } =
          await this.$api.document.getInstructions(params);
        if (success && list.length) {
          this.listFull = list.filter(
            ({ id }) => id !== this.currentInstructionId
          );
          this.listNewTI = this.listFull.map(({ number }) => number);
          this.selected = this.listNewTI[0];
        }
        this.loadingList = false;
      } catch (e) {
        console.log(e);
        this.loadingList = false;
      }
    },
  },
  watch: {
    GET_CurrentPopup: function (currentPopupName) {
      if (currentPopupName === this.popupName) this.request();
    },
  },
};
</script>
<style lang="scss" scoped src="./style.scss"></style>
