<template>
  <PageTable :loading="loading">
    <template v-slot:thead>
      <th>
        <span
          class="controll"
          :class="[
            date,
            { active: $queryParams.parse(this.$route.query.sort).date },
          ]"
          @click="$emit('sort', { date: date || 'asc' })"
        >
          {{ $translate("date", "documents") }}
          <ArrowSVG />
        </span>
      </th>
      <th>
        {{ $translate("message_subject") }}
      </th>
      <th>
        <span
          class="controll"
          :class="[
            notificationType,
            { active: $queryParams.parse(this.$route.query.sort).notificationType },
          ]"
          @click="$emit('sort', { notificationType: notificationType || 'asc' })"
        >
          {{ $translate("notification_type") }}
          <ArrowSVG />
        </span>
      </th>
      <th></th>
    </template>
    <template v-slot:default>
      <RowItem 
        v-for="item in list" 
        :key="item.id" 
        :row="item" 
        @click="$emit('rowClick', item)" />
    </template>
  </PageTable>
</template>

<script>
import RowItem from "./Item";
import PageTable from "../PageTable";
import ArrowSVG from "@/assets/img/arrow_top.svg";
/**
 * The DocumentsTable component.
 */
export default {
  name: "DocumentsTable",
  components: { RowItem, ArrowSVG, PageTable },
  props: {
    list: { default: () => [], type: Array },
    loading: { default: false, type: Boolean },
    date: { default: "asc", type: String },
    notificationType: { default: "asc", type: String },
  },
};
</script>

<style lang="scss" scoped src="./component.scss"></style>
