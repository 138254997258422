<template>
  <LayoutDashboard name="packages-map">
    <DashboardTitleRow :title="$translate('my_packages')" />
    <PageTabs :list="pageTabsList" />
    <div class="packages-map__content">
    <MapView
      ref="mapView"
      :items="mapItems"
      :icons="mapIcons"
      :iconSize="mapIconsSize"
      @markerClick="handleMarkerClick"
      @mapClick="handleMapClick" />
      <PackagesMapLocationPopover
        v-if="activeStationId != null"
        :key="activeStationId"
        @close="activeStationId = null"
        @activeItemChange="handleItemDetailsDisplayUpdate"
        :data="activeItemData"
        class="map-location-popover" />
    </div>
  </LayoutDashboard>
</template>
<script>
import LayoutDashboard from '@/layouts/Dashboard.vue';
import DashboardTitleRow from '@/components/DashboardTitleRow';
import PageTabs from '@/components/PageTabs';
import MapView from '@/components/MapView';
import PackagesMapLocationPopover from '@/components/PackagesMapLocationPopover';

const MAP_ICONS = {
  "in-transit": "/assets/img/map_icon_cargo_cart.svg",
  "arrived": "/assets/img/map_icon_destination.svg",
  "warehouse": "/assets/img/map_icon_factory.svg",
  "liquid_cargo_cart": "/assets/img/map_icon_liquid_cargo_cart.svg",
  "train": "/assets/img/map_icon_train.svg",
  "warehouse2": "/assets/img/map_icon_warehouse.svg",
}

export default {
  name: 'PackagesMapPage',
  components: {
    LayoutDashboard,
    DashboardTitleRow,
    PageTabs,
    MapView,
    PackagesMapLocationPopover,
  },
  data() {
    return {
      pageTabsList: [
        {
          id: 1,
          text: this.$translate('final_balance', 'orders'),
          to: { name: 'packages' },
          active: false,
        },
        {
          id: 2,
          text: this.$translate('cargo_map', 'orders'),
          to: { name: 'packages-map' },
          active: true,
        },
        {
          id: 3,
          text: this.$translate('shipment_schedule', 'orders'),
          to: { name: 'packages-schedule' },
          active: false,
        },
      ],
      electronicDocs: [],
      mapIcons: { ...MAP_ICONS },
      mapIconsSize: {
        'default': [60, 60]
      },
      activeStationId: null,
    };
  },
  computed: {
    activeExternalEDId() {
      return parseInt(this.$route.query.electronicDocument_id)
    },
    activeItemData() {
      if (!Number.isNaN(this.activeExternalEDId)) {
        return [
          this.electronicDocs.find(({ id }) => id === this.activeExternalEDId)
        ]
      }

      return this.electronicDocs.filter(({ currentStation, destinationStation, departureStation }) => {
        return [
          currentStation || {},
          destinationStation || {},
          departureStation || {},
        ].some(({ id }) => id === this.activeStationId)
      })
    },
    mapItems() {
      return this.electronicDocs.map(({
        id,
        transportInstructions,
        currentStation,
        departureStation,
        destinationStation,
      }) => {
        return [
          currentStation == null ? null : {
            parentId: id,
            id: currentStation.id,
            type: 'currentStation',
            lng: currentStation.longitude,
            lat: currentStation.latitude,
            icon: 'liquid_cargo_cart',
            title: currentStation.name,
          },
          destinationStation == null ? null : {
            parentId: id,
            id: destinationStation.id,
            type: 'destinationStation',
            lng: destinationStation.longitude,
            lat: destinationStation.latitude,
            icon: 'arrived',
            title: destinationStation.name,
          },
          departureStation == null ? null : {
            parentId: id,
            id: departureStation.id,
            type: 'departureStation',
            lng: departureStation.longitude,
            lat: departureStation.latitude,
            icon: 'warehouse',
            title: departureStation.name,
            counter: transportInstructions.length,
          }
        ].filter(Boolean)
      }).flat();
    }
  },
  methods: {
    async loadMap() {
      try {
        const res = await this.$api.document.getElectronicDocumentMap()
        
        if ('data' in res) {
          return res.data
        }

        return []
      } catch (error) {
        console.error(error);
      }
    },
    handleMarkerClick(item) {
      this.activeStationId = item.id
      this.renderDocumentPaths(item.parentId)
    },
    handleItemDetailsDisplayUpdate(item) {
      this.renderDocumentPaths(item.id)
    },
    async renderDocumentPaths(documentId) {
      try {
        const data = await this.$api.document.getElectronicDocumentMapRailwayPath(documentId)
        const features = data.data.map(({ path }) => path).filter(Boolean)

        this.$refs.mapView.$exposeResetGeoJSON()

        if (features.length === 0) {
          return
        }

        this.$refs.mapView.$exposeUpdateGeoJsonData({
          type: 'FeatureCollection',
          features,
        })
      } catch (error) {
        console.error(error);
      }
    },
    handleMapClick() {
      this.activeStationId = null
      this.$refs.mapView.$exposeResetGeoJSON()
    },
  },
  async created() {
    const res = await this.loadMap()

    if (Array.isArray(res) && res.length > 0) {
      this.electronicDocs = res
    }

    if (!Number.isNaN(this.activeExternalEDId)) {
      const items = this.mapItems.filter((item) => item.parentId === this.activeExternalEDId)
      const destinationStation = items.find(({ type }) => type === 'destinationStation')
      const departureStation = items.find(({ type }) => type === 'departureStation')

      if (destinationStation) {
        this.handleMarkerClick(destinationStation)
      } else if (departureStation) {
        this.handleMarkerClick(
          items.find(({ type }) => type === 'departureStation')
        )
      }
    }
  },
  mounted() {
  },
};
</script>
<style scoped lang="scss" src="./component.scss"></style>
