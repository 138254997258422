<template>
  <LayoutDashboard name="add-company">
    <DashboardTitleRow
      :title="$translate('creation_of_counterparty', 'companies')"
      :titleTag="'h1'"
      :titleTagClass="'h1'"
      :goBack="{ name: 'companies' }"
    />
    <DashboardCard class="p40 overflow w100per company-add">
      <div class="formWrap marg--0">
        <LoadingIcon v-if="loading" :absolute="true" />

        <form
          novalidate
          @submit.prevent="submit"
          :class="{ loadingElem: loading }"
          :key="formKey"
        >
          <div class="row margin47 topRow">
            <div class="column w50 mobilew100">
              <Label
                :title="$translate('counterpaties_name', 'companies')"
                :placeholder="$translate('counterpaties_name', 'companies')"
                name="nameCounterparty"
                inputType="text"
                @onInput="onNameCounterparty"
                :error="nameCounterpartyError"
                :errorText="nameCounterpartyErrorText"
                ref="nameCounterparty"
              />
            </div>
            <div class="column w50 mobilew100">
              <Label
                :title="$translate('edrpou_ipn', 'companies')"
                :placeholder="$translate('edrpou_ipn', 'companies')"
                name="TIN_YEDRPOU"
                inputType="text"
                @onInput="onTIN_YEDRPOU"
                :error="TIN_YEDRPOU_Error"
                :errorText="TIN_YEDRPOU_ErrorText"
                ref="TIN_YEDRPOU"
              />
            </div>
          </div>
          <!-- <div class="row margin47">
              <div class="column w50 mobilew100">
                <LabelDropdownMulty
                  name="destination"
                  :title="$translate('destination', 'companies')"
                  :list="destinationList"
                  :active="destination"
                  @change="changeMultyDropdown"
                  :error="destinationError"
                  :errorText="destinationErrorText"
                />
              </div>
            </div>
            <div class="row margin47">
              <div class="column w50 mobilew100">
                <LabelDropdown
                  name="region"
                  :title="$translate('area_of_the_site', 'companies')"
                  :list="regionList"
                  :active="region"
                  @change="changeDropdown"
                  :error="regionError"
                  :errorText="regionErrorText"
                />
              </div>
            </div> -->
          <div class="row margin0 between bottomRow">
            <MyButton
              :text="$translate('cancel')"
              tag="button"
              tagType="reset"
              type="cancel"
              :dynamicSize="true"
              @click="resetForm"
            />
            <MyButton
              class="w288"
              :text="$translate('create', 'companies')"
              tag="button"
              tagType="submit"
              :dynamicSize="true"
            />
          </div>
        </form>
      </div>
    </DashboardCard>
  </LayoutDashboard>
</template>
<script>
import LayoutDashboard from "@/layouts/Dashboard.vue";
import DashboardTitleRow from "@/components/DashboardTitleRow";
import DashboardCard from "@/components/DashboardCard";

export default {
  name: "AddCounterparties",
  data() {
    return {
      formKey: "registrationData",
      loading: false,
      //inputs
      //nameCounterparty
      nameCounterparty: "",
      nameCounterpartyError: false,
      nameCounterpartyErrorText: "",

      ipn: "",
      ipnError: false,
      ipnErrorText: "",
      //TIN_YEDRPOU_company
      TIN_YEDRPOU: "",
      TIN_YEDRPOU_Error: false,
      TIN_YEDRPOU_ErrorText: "",

      destinationList: [],
      destination: [],
      destinationError: false,
      destinationErrorText: "",

      errorMessage: "company_no_created",
    };
  },
  components: {
    LayoutDashboard,
    DashboardTitleRow,
    DashboardCard,
  },
  computed: {},
  methods: {
    async getLists() {
      this.loading = true;
      await this.getDestination();

      this.loading = false;
    },
    async getDestination() {
      const { success = false, list = [] } =
        await this.$api.checkout.deliveryAddress();
      if (success && list.length) return (this.destinationList = list);
    },
    onNameCounterparty(value = this.nameCounterparty, e, required = false) {
      this.nameCounterparty = value;
      const NameCounterparty = this.$simpleInputValidate(
        value,
        required,
        "name"
      );
      this.nameCounterpartyError = NameCounterparty.error;
      if (NameCounterparty.error) {
        this.nameCounterpartyErrorText = NameCounterparty.text;
        this.$refs.nameCounterparty.$refs.input.focus();
      }
      return NameCounterparty.error;
    },
    onTIN_YEDRPOU(value = this.TIN_YEDRPOU, e, required = false) {
      this.TIN_YEDRPOU = value;
      const TIN_YEDRPOU = this.$simpleInputValidate(
        value,
        required,
        "TIN_YEDRPOU"
      );
      this.TIN_YEDRPOU_Error = TIN_YEDRPOU.error;
      if (TIN_YEDRPOU.error) {
        this.TIN_YEDRPOU_ErrorText = TIN_YEDRPOU.text;
        this.$refs.TIN_YEDRPOU.$refs.input.focus();
      }
      return TIN_YEDRPOU.error;
    },
    async setCompany() {
      try {
        this.loading = true;
        const dataVal = {
          name: this.nameCounterparty,
          edrpou: this.TIN_YEDRPOU,
        };
        let { success = false, code = "" } = await this.$api.setСompany({
          data: dataVal,
        });
        if (success) {
          this.$router.push({ name: "companies" });
          setTimeout(() => {
            this.$notice(this.$translate("company_created", "companies"));
          }, 500);
        } else {
          if (code == 422) this.errorMessage = "already_added";
          this.nameCounterpartyError = true;
          this.TIN_YEDRPOU_Error = true;
          this.$notice(
            this.$translate(this.errorMessage, "companies"),
            undefined,
            {
              type: "error",
            }
          );
        }
        this.loading = false;
      } catch (e) {
        this.loading = false;
      }
    },
    async submit() {
      if (this.loading) return false;

      const error = (() => {
        const errorNameCounterparty = this.onNameCounterparty(
          undefined,
          undefined,
          true
        );
        const errorTIN_YEDRPOU = this.onTIN_YEDRPOU(undefined, undefined, true);
        return errorNameCounterparty || errorTIN_YEDRPOU;
      })();
      if (!error) await this.setCompany();
      else console.log("submit", { error });
    },
    resetForm() {
      this.loading = false;

      this.nameCounterparty = "";
      this.nameCounterpartyError = false;
      this.nameCounterpartyErrorText = "";

      this.TIN_YEDRPOU = "";
      this.TIN_YEDRPOU_Error = false;
      this.TIN_YEDRPOU_ErrorText = "";

      this.successCreated = false;
      this.errorMessage = "company_no_created";

      this.formKey = "registrationData_" + this.$rnd(0, 100);
    },
    changeMultyDropdown(key, name, item) {
      switch (name) {
        case "destination":
          if (this.destination.length) {
            const remove = this.destination.findIndex(
              (rem) => JSON.stringify(rem) === JSON.stringify(item)
            );
            console.log("remove--->", remove);
            if (remove !== -1)
              this.destination = [
                ...this.destination.slice(0, remove - 1),
                ...this.destination.slice(
                  remove + 1,
                  this.destination.length - 1
                ),
              ];
          }
          this.destination.push(item);
          this.onInputDropdownDestination(this.destination, undefined, true);
          break;

        default:
          break;
      }
    },
    onInputDropdownDestination() {},
  },
  async created() {
    await this.getLists();
  },
};
</script>
<style lang="scss" src="../Company/style.scss" scoped></style>
