<template>
  <Layout class="addVehicle" id="AddVehicle">
    <h4 v-if="gTitle" class="title">
      {{ gTitle }}
    </h4>
    <span class="section-name">{{
      $translate('order_details', 'orders')
    }}</span>
    <ul class="box--ul --row">
      <li v-if="gData && gData.deliveryType">
        <span class="name">{{
          $translate('type_transportation', 'orders')
        }}</span>
        <span class="val big">{{ gData.deliveryType }}</span>
      </li>

      <li v-if="gData && gData.totalValue">
        <span class="name">{{ $translate('total_volume', 'orders') }}</span>
        <span class="val big"
          >{{ gData.totalValue }} {{ $translate('t', 'orders') }}</span
        >
      </li>
    </ul>

    <span class="section-name">{{
      $translate('vehicle_data', 'documents')
    }}</span>
    <ul class="box--ul --row">
      <li class="w50">
        <Label
          :title="$translate('vehicle_registration_number', 'documents')"
          name="vehicleNum"
          inputType="text"
          @onInput="onInputVehicleNum"
          :error="vehicleNumError"
          :errorText="vehicleNumErrorText"
          :placeholder="$translate('vehicle_registration_number', 'documents')"
          ref="vehicleNum"
          :value="vehicleNum"
        />
      </li>
      <li class="w50">
        <Label
          :title="$translate('load_rate', 'documents')"
          name="trailerNumber"
          inputType="text"
          @onInput="onInputTrailerNumber"
          :error="trailerNumberError"
          :errorText="trailerNumberErrorText"
          :placeholder="$translate('load_rate', 'documents')"
          ref="trailer"
          :value="trailerNumber"
        />
      </li>
      <li class="w100">
        <Label
          :title="$translate('driver_name', 'documents')"
          name="driverName"
          inputType="text"
          @onInput="onInputDriverName"
          :error="driverNameError"
          :errorText="driverNameErrorText"
          :placeholder="$translate('driver_name', 'documents')"
          ref="driverName"
          :value="driverName"
        />
      </li>
      <li>
        <Label
          :title="$translate('power_attorney', 'documents')"
          name="numberTz"
          inputType="text"
          @onInput="onInputNumberTz"
          :error="numberTzError"
          :errorText="numberTzErrorText"
          :placeholder="$translate('power_attorney', 'documents')"
          ref="numberTz"
          :value="numberTz"
        />
      </li>
      <li>
        <Label
          :title="$translate('validity_power_attorney', 'documents')"
          name="filterDate"
          inputType="datepicker"
          @onInput="onInputFilterDate"
          ref="filterDate"
          :value="filterCurrent.date"
          :upperLimit="false"
          calendarStartingView="months"
          :key="formKey"
        />
      </li>
    </ul>

    <div class="formWrap" :class="{ loading: loading }">
      <MyButton
        @click="submit"
        class="w100"
        :text="$translate('create_TI', 'documents')"
        tag="button"
        :dynamicSize="true"
      />
      <MyButton
        @click="goBack"
        class="w100"
        :text="$translate('go_back', 'general')"
        tag="button"
        tagType="reset"
        type="cancel"
        :dynamicSize="true"
      />
    </div>
    <transition>
      <LoadingIcon v-if="loading" :absolute="true" />
    </transition>
  </Layout>
</template>

<script>
import { mapGetters } from 'vuex';
import Layout from '../../PopupLayout';

/**
 * The OrderCancellationRequest component.
 */
export default {
  name: 'TICancellation',
  components: {
    Layout,
  },
  data() {
    return {
      loading: false,
      //inputs
      vehicleNum: null,
      vehicleNumError: false,
      vehicleNumErrorText: '',

      trailerNumber: null,
      trailerNumberError: false,
      trailerNumberErrorText: '',

      driverName: null,
      driverNameError: false,
      driverNameErrorText: '',

      numberTz: null,
      numberTzError: false,
      numberTzErrorText: '',

      formKey: 'form_0',
      filterCurrent: {
        date: this.$moment(new Date()).format('DD/MM/YYYY'),
      },
    };
  },
  computed: {
    ...mapGetters(['IS_Mobile', 'GET_CurrentPopup', 'GET_PopupData']),
    gData() {
      return this.GET_PopupData?.[this.GET_CurrentPopup];
    },
    actionType() {
      return this.gData?.action;
    },
    TIID() {
      return this.gData?.TIID;
    },
    carID() {
      return this.gData?.id;
    },
    carsList() {
      return this.gData?.cars;
    },
    gTitle() {
      switch (this.actionType) {
        case 'add':
          return this.$translate('adding_car', 'documents');
        case 'edit':
          return this.$translate('edit_data_car', 'general');
        case 'remove':
          return this.$translate('adding_car', 'documents');
        default:
          return null;
      }
    },
  },
  methods: {
    async submit() {
      try {
        if (this.loading) return false;
        if (this.TIID == null) throw Error('TIID is null');
        const error = (() => {
          const errorVehicleNum = this.onInputVehicleNum(
            undefined,
            undefined,
            true
          );
          const errorTrailerNumber = this.onInputTrailerNumber(
            undefined,
            undefined,
            true
          );
          const errorDriverName = this.onInputDriverName(
            undefined,
            undefined,
            true
          );
          const errorNumberTz = this.onInputNumberTz(
            undefined,
            undefined,
            true
          );

          return (
            errorVehicleNum ||
            errorTrailerNumber ||
            errorDriverName ||
            errorNumberTz
          );
        })();
        if (!error) {
          this.loading = true;
          const PUT = {};
          const newCar = {
            beatingCapacity: parseFloat(this.trailerNumber),
            transportNumber: this.vehicleNum,
            driver: this.driverName,
            ttnNumber: this.numberTz,
            ttnDate: this.filterCurrent.date.split('/').reverse().join('-'),
          };
          if (this.actionType == 'add') {
            const newCarInfo = [];
            if (this.gData?.cars && this.gData.cars.length)
              newCarInfo.push(...this.gData.cars);
            newCarInfo.push(newCar);
            PUT.carInfo = newCarInfo;
          }
          if (this.actionType == 'edit') {
            if (this.carID == null) throw Error('carID is null');
            const newArray = this.carsList.map((item) => {
              if (item.id == this.carID) {
                return { ...item, ...newCar };
              } else {
                return item;
              }
            });
            PUT.carInfo = newArray;
          }

          const { success = false } = await this.$api.document.putInstruction(
            this.TIID,
            PUT
          );
          if (success) {
            if (this.actionType == 'add')
              this.$notice(this.$translate('car_will_added'));
            if (this.actionType == 'edit')
              this.$notice(this.$translate('redictable'));
            document.dispatchEvent(new CustomEvent('update-instruction'));
          } else {
            this.$notice(this.$translate('request_error'), undefined, {
              type: 'error',
            });
          }
          this.loading = false;
          this.$popup.hide(undefined, () => this.resetForm());
        }
      } catch (e) {
        console.log(e);
      }
    },
    goBack() {
      this.resetForm();
      this.$popup.hide();
    },
    resetForm() {
      this.vehicleNum = null;
      this.vehicleNumError = false;
      this.vehicleNumErrorText = '';

      this.trailerNumber = null;
      this.trailerNumberError = false;
      this.trailerNumberErrorText = '';

      this.driverName = null;
      this.driverNameError = false;
      this.driverNameErrorText = '';

      this.numberTz = null;
      this.numberTzError = false;
      this.numberTzErrorText = '';

      this.filterCurrent.date = this.$moment(new Date()).format('DD/MM/YYYY');

      this.formKey = 'form_' + this.$rnd(0, 100);
    },
    onInputVehicleNum(value = this.vehicleNum, e, required = false) {
      this.vehicleNum = value;
      const validateError = this.$simpleInputValidate(value, required, 'name');
      this.vehicleNumError = validateError.error;
      if (validateError.error) {
        this.vehicleNumErrorText = validateError.text;
        this.$refs.vehicleNum.$refs.input.focus();
      }
      return validateError.error;
    },
    onInputTrailerNumber(value = this.trailerNumber, e, required = false) {
      this.trailerNumber = value;
      const validateError = this.$simpleInputValidate(
        value,
        required,
        'volume'
      );
      this.trailerNumberError = validateError.error;
      if (validateError.error) {
        this.trailerNumberErrorText = validateError.text;
        this.$refs.trailer.$refs.input.focus();
      }
      return validateError.error;
    },
    onInputDriverName(value = this.driverName, e, required = false) {
      this.driverName = value;
      const validateError = this.$simpleInputValidate(value, required, 'name');
      this.driverNameError = validateError.error;
      if (validateError.error) {
        this.driverNameErrorText = validateError.text;
        this.$refs.driverName.$refs.input.focus();
      }
      return validateError.error;
    },
    onInputNumberTz(value = this.numberTz, e, required = false) {
      this.numberTz = value;
      const validateError = this.$simpleInputValidate(value, required, 'name');
      this.numberTzError = validateError.error;
      if (validateError.error) {
        this.numberTzErrorText = validateError.text;
        this.$refs.numberTz.$refs.input.focus();
      }
      return validateError.error;
    },
    onInputFilterDate(value) {
      this.filterCurrent.date = value;
    },
  },
  watch: {
    actionType: function (val) {
      if (val == 'edit' && this.carsList && this.carsList.length) {
        const current =
          this.carsList.find((item) => item.id == this.carID) || null;
        if (current?.beatingCapacity)
          this.trailerNumber = current.beatingCapacity;
        if (current?.transportNumber) this.vehicleNum = current.transportNumber;
        if (current?.driver) this.driverName = current.driver;
        if (current?.ttnNumber) this.numberTz = current.ttnNumber;
        if (current?.ttnDate)
          this.filterCurrent.date = current.ttnDate
            .split('-')
            .reverse()
            .join('/');
      }
    },
  },
  mounted() {
    document.addEventListener('popup-hide', this.resetForm);
  },
  beforeUnmount() {
    document.removeEventListener('popup-hide', this.resetForm);
  },
};
</script>
<style lang="scss" src="./style.scss"></style>
