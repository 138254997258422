<template>
  <div class="LoginGrid">
    <div class="container container_without_padding">
      <div
        class="row margin0 alignCenter nowrap"
        :class="{ singleColumn: !doubleColumn }"
      >
        <div class="column columnLeft">
          <h1 v-if="title" v-html="title"></h1>
          <div class="text" v-html="text" v-if="text"></div>
          <slot name="columnLeft" />
        </div>
        <div class="column columnRight" v-if="doubleColumn">
          <div class="LoginBox">
            <slot />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * The LoginGrid component.
 */
export default {
  name: "LoginGrid",
  props: {
    title: { default: null, type: String },
    text: { default: null, type: String },
    doubleColumn: { default: true, type: Boolean },
  },
};
</script>

<style lang="scss" scoped src="./component.scss"></style>