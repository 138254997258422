<template>
  <PageTable :loading="loading">
    <template v-slot:thead>
      <th>
        <span>
          {{ $translate("name", "orders") }}
        </span>
      </th>
      <th>
        <span
          class="controll"
          :class="[{ active: currentSort.product?.name }, sortByProduct?.name]" 
          @click="onSort('product')">
          {{ $translate("product", "orders") }}
          <ArrowSVG />
        </span>
      </th>
      <th>
        <span
          class="controll"
          :class="[{ active: currentSort.deliveryCondition?.name }, sortByDeliveryCondition?.name]"
            @click="onSort('deliveryCondition')">
          {{ $translate("basis", "documents") }}
          <ArrowSVG />
        </span>
      </th>
      <th>
        <span 
          class="controll"
          :class="[{ active: currentSort.basePriceGroup?.name }, sortByBasePriceGroup?.name]" 
          @click="onSort('basePriceGroup')">
          {{ $translate("type", "documents") }}
          <ArrowSVG />
        </span>
      </th>
      <th>
        <span>
          {{ $translate("storage", "orders") }}
        </span>
      </th>
      <th>
        <span
          class="controll"
          :class="[{ active: currentSort.sellingPrice }, sortBySellingPrice]" 
          @click="onSort('sellingPrice')">
          {{ $translate("price_per_ton", "orders") }}
          <ArrowSVG />
        </span>
      </th>
      <th>
        <span
          class="controll"
          :class="[{ active: currentSort.validTo }, sortByValidTo]" 
          @click="onSort('validTo')">
          {{ $translate("valid_until", "orders") }}
          <ArrowSVG />
        </span>
      </th>
    </template>
    <template v-slot:default>
      <RowItem v-for="item in list" :key="item.id" :row="item" />
    </template>
  </PageTable>
</template>

<script>
import RowItem from "./Item";
import PageTable from "../PageTable";
import ArrowSVG from "@/assets/img/arrow_top.svg";

const SORT_ASCENDING = 'asc';
const SORT_DESCENDING = 'desc';

/**
 * The DocumentsTable component.
 */
export default {
  name: "DocumentsTable",
  components: { RowItem, PageTable, ArrowSVG },
  props: {
    list: { default: () => [], type: Array },
    loading: { default: false, type: Boolean },
    sortByProduct: {
      default: () => ({ name: undefined }),
      type: Object
    },
    sortByBasePriceGroup: {
      default: () => ({ name: undefined }),
      type: Object
    },
    sortByDeliveryCondition: {
      default: () => ({ name: undefined }),
      type: Object
    },
    sortBySellingPrice: { default: SORT_ASCENDING, type: String },
    sortByValidTo: { default: SORT_ASCENDING, type: String },
  },
  computed: {
    currentSort() {
      return this.$queryParams.parse(this.$route.query.sort)
    },
  },
  methods: {
    getSortPayload(key) {
      switch (key) {
        case 'product': {
          return {
            product: this.sortByProduct.name
              ? this.sortByProduct
              : { name: SORT_DESCENDING }
          };
        }
        case 'basePriceGroup': {
          return {
            basePriceGroup: this.sortByBasePriceGroup.name
              ? this.sortByBasePriceGroup
              : { name: SORT_DESCENDING }
          };
        }
        case 'deliveryCondition': {
          return {
            deliveryCondition: this.sortByDeliveryCondition.name
              ? this.sortByDeliveryCondition
              : { name: SORT_ASCENDING }
          };
        }
        case 'sellingPrice': {
          return {
            sellingPrice: this.sortBySellingPrice || SORT_ASCENDING
          }
        }
        case 'validTo': {
          return {
            validTo: this.sortByValidTo || SORT_ASCENDING
          }
        }
        default:
          throw new Error(`Uknown sort key "${JSON.stringify(key)}"`)
      }
    },
    onSort(key) {
      const payload = this.getSortPayload(key)
      this.$emit('sort', payload)
    },
  }
};
</script>

<style lang="scss" scoped src="./component.scss"></style>
