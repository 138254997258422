<template>
  <LayoutDashboard name="order-category">
    <DashboardTitleRow
      :goBack="{ name: 'order-category' }"
      :title="productName"
    />
    <Transition mode="out-in">
      <LoadingIcon v-if="loading" />
      <div class="ptr" v-else-if="!loading && product && hasProduct">
        <div v-if="currentW <= triggerW" class="top">
          <div v-if="product?.name" class="title">
            <h1>{{ product.name }}</h1>
          </div>
          <!-- elements -->
          <ul
            v-if="product?.elements && product.elements.length"
            class="elements"
          >
            <li v-for="item in product.elements" :key="item.id">
              <Tag :text="item.element" class="primary_500 medium" />
              <span>-</span> {{ $prefix(item.amount) }}
            </li>
          </ul>
          <!-- /elements -->
        </div>
        <div class="colums">
          <div class="colum colum--left">
            <div
              class="image"
              :style="{ 'background-color': product.backgroundColor }"
            >
              <img
                :src="getImageUrl(product.image.url)"
                alt="product"
                v-if="product?.image && product?.image?.url"
              />
            </div>
          </div>
          <div class="colum colum--right">
            <div class="box">
              <div v-if="currentW > triggerW" class="top">
                <div v-if="product?.name" class="title">
                  <h1>{{ product.name }}</h1>
                </div>
                <!-- elements -->
                <ul
                  v-if="product?.elements && product.elements.length"
                  class="elements"
                >
                  <li v-for="item in product.elements" :key="item.id">
                    <Tag :text="item.element" class="primary_500 medium" />
                    <span>-</span> {{ $prefix(item.amount) }}
                  </li>
                </ul>
                <!-- /elements -->
              </div>
              <!-- description -->
              <Markdown
                v-once
                v-if="product?.description"
                :source="description"
                class="description"
                :breaks="true"
                :html="true" />
              <!-- /description -->
              <!-- action  -->
              <div class="action">
                <LabelDropdown
                  v-if="packagingOptions"
                  name="packaging"
                  :title="$translate('packaging', 'orders')"
                  :list="packagingOptions"
                  :active="activeOption"
                  @change="changeDropdown"
                />
                <MyButton
                  type="primary"
                  :text="$translate('order', 'dashboard')"
                  class="orderButton"
                  :disabled="!activeOption"
                  @click="goCheckout"
                />
              </div>
              <!-- /action  -->
            </div>

            <!-- Characteristics -->
            <div v-if="characteristics && characteristics.length" class="block">
              <h2>{{ $translate('сharacteristics', 'general') }}</h2>
              <table>
                <thead>
                  <tr>
                    <th>{{ $translate('parameter', 'general') }}</th>
                    <th>{{ $translate('value', 'general') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr :key="item.id" v-for="item in characteristics">
                    <td>{{ item.parameter || '' }}</td>
                    <td>{{ item.value || '' }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- /Characteristics -->

            <!-- Rules of use -->
            <div v-if="requirements && requirements.length" class="block">
              <h2>{{ $translate('rulesofuse', 'general') }}</h2>
              <table>
                <thead>
                  <tr>
                    <th>{{ $translate('parameter', 'general') }}</th>
                    <th>{{ $translate('value', 'general') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr :key="item.id" v-for="item in requirements">
                    <td>{{ item.parameter || '' }}</td>
                    <td>{{ item.value || '' }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- /Rules of use -->
          </div>
        </div>
      </div>
      <p v-else>{{ $translate('data_not_found', 'general') }}</p>
    </Transition>
  </LayoutDashboard>
</template>

<script>
import LayoutDashboard from '@/layouts/Dashboard.vue';
import DashboardTitleRow from '@/components/DashboardTitleRow';
// import DashboardCard from '@/components/DashboardCard';
import Markdown from "vue3-markdown-it";
import Tag from '@/components/Table/PageTable/Tag';

import { mapGetters } from 'vuex';
/**
 * The order-product-page component.
 */
export default {
  name: 'OrderProductPage',
  components: {
    LayoutDashboard,
    DashboardTitleRow,
    Tag,
    Markdown,
    // DashboardCard,
  },
  props: {
    orderCategory: { default: false, type: Boolean },
    orderSpecials: { default: false, type: Boolean },
  },
  data() {
    return {
      title: this.$translate('my_orders', 'orders'),
      loading: false,
      product: null,
      hasProduct: true,
      activeOption: null,
      currentW: 0,
      triggerW: 1481,
    };
  },
  computed: {
    ...mapGetters(['IS_Tablet2']),
    packagingOptions() {
      if (!this.product?.packaging?.length) return null;
      return this.product.packaging.map((item) => {
        const { name, crmId } = item;
        return {
          key: crmId,
          name,
          crmId,
          product: item.product,
        };
      });
    },
    characteristics() {
      return this.product?.characteristics &&
        this.product.characteristics.length
        ? this.product.characteristics.filter(
            ({ parameter, value }) => parameter || value
          )
        : [];
      // return [
      //   // {
      //   //   id: 2,
      //   //   parameter: 'Характеристика 1',
      //   //   value: 'Значення характеристики',
      //   // },
      //   // {
      //   //   id: 3,
      //   //   parameter: 'Характеристика 1',
      //   //   value: 'Значення характеристики',
      //   // },
      //   // {
      //   //   id: 4,
      //   //   parameter: 'Характеристика 1',
      //   //   value: 'Значення характеристики',
      //   // },
      // ];
    },
    requirements() {
      return this.product?.requirements && this.product.requirements.length
        ? this.product.requirements.filter(
            ({ parameter, value }) => parameter || value
          )
        : [];
      // return [
      //   {
      //     id: 2,
      //     parameter: 'Характеристика 1',
      //     value: 'Значення характеристики',
      //   },
      //   {
      //     id: 3,
      //     parameter: 'Характеристика 1',
      //     value: 'Значення характеристики',
      //   },
      //   {
      //     id: 4,
      //     parameter: 'Характеристика 1',
      //     value: 'Значення характеристики',
      //   },
      // ];
    },
    description() {
      const description = this.product?.description;
      if (!description) return null;

      // find all images that aren't staring with https:// and replace them with full url
      const regex = /!\[.*?\]\((.*?)\)/g;
      const updatedDescription = description.replace(regex, (match, imgLink) => {
        if (!imgLink.startsWith('https://')) {
          return `![${match}](${this.getImageUrl(imgLink)})`;
        }
        return match;
      });

      return updatedDescription;
    },
  },
  methods: {
    getImageUrl(url = '') {
      return process.env.VUE_APP_API_DOMAIN + url;
    },
    goCheckout() {
      try {
        const query = this.$queryParams.getClear({
          specials: this.product?.specials?.slug,
          category: this.product?.category?.slug,
          suGoodWithoutPackaging: this.product.crmId,
          packagingId: this.activeOption?.crmId,
          productId: this.activeOption?.product?.crmId,
          id: this.activeOption?.product?.id,
        });
        this.$order.set(query);
        this.$router.push({ name: 'checkout' });
      } catch (e) {
        console.log(e);
      }
    },
    changeDropdown(key, name, item) {
      this.activeOption = item;
    },
    async getProduct() {
      try {
        this.loading = true;
        const {
          params: { product },
        } = this.$route;
        const { success: success1, list } =
          await this.$api.product.getProductsByCategory({
            filters: {
              slug: product,
            },
          });
        if (success1 && list.length) {
          const { id } = list[0];
          const { success: success2, data } =
            await this.$api.product.getProductWithoutPacking(id);
          if (success2 && data) {
            this.product = data;
          } else {
            this.hasProdut = false;
          }
        } else this.hasProdut = false;
        this.loading = false;
      } catch (e) {
        console.log(e);
      }
    },
    setCurrentW() {
      this.currentW = document.documentElement.offsetWidth;
    },
  },
  async created() {
    console.log('created');
    this.getProduct();
  },
  mounted() {
    this.setCurrentW();
    window.addEventListener('resize', this.setCurrentW);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.setCurrentW);
  },
};
</script>

<style lang="scss" scoped src="./component.scss"></style>
