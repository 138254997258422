<template>
  <div class="payable">
    <div class="top">
      <h2 v-if="name" class="title">{{ name }}</h2>
      <span class="totalDocs" v-if="totalDocs">({{ totalDocs }})</span>
      <div v-if="total" class="info">
        <span>{{ $translate('amount', 'orders') }}:</span>
        <span class="big green">{{ this.$price(total, undefined, true) }}</span>
      </div>
      <div v-if="losses" class="info">
        <span>{{ $translate('possible_losses', 'dashboard') }}</span>
        <span class="big red">{{ this.$price(losses, undefined, true) }}</span>
        <Tippy
          class="red"
          :text="$translate('dashboard_possibleLosses', 'notice')"
        />
      </div>
    </div>
    <div v-if="data.data && data.data.length" class="scroll-box">
      <div class="box">
        <table class="table">
          <thead>
            <tr>
              <th>{{ $translate('order', 'orders') }}</th>
              <th>{{ $translate('account', 'general') }}</th>

              <th>{{ $translate('amount', 'orders') }}</th>
              <th>{{ $translate('already_paid', 'dashboard') }}</th>
              <th>{{ $translate('deadline', 'dashboard') }}</th>
              <th>
                {{ $translate('change_in_case_non-payment', 'dashboard') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr :key="i" v-for="(item, i) in data.data">
              <td>
                <router-link
                  :to="{
                    name: 'my-order',
                    params: { id: item.order.number },
                  }"
                  v-if="item.order.number"
                  class="number b link Button"
                >
                  <span class="number b">{{ item.order.number }}</span>
                </router-link>
              </td>
              <td>
                <a
                  class="Button download"
                  v-if="item.file && item.file.url"
                  download
                  :href="getDownloadLink(item)"
                  ><span v-if="item.documentNumber">{{
                    item.documentNumber
                  }}</span>
                  <DownloadIco />
                </a>
              </td>
              <td>
                <span v-if="item.amount" class="b">{{
                  $price(item.amount, undefined, true)
                }}</span>
                <span v-if="item.order && item.order.price"
                  >{{ $price(item.order.price, undefined, true) }} / т</span
                >
              </td>
              <td>
                <span
                  v-if="item.paidAmount"
                  class="b"
                  :class="{ green: item.paidAmount >= item.amount }"
                  >{{ $price(item.paidAmount, undefined, true) }}</span
                >
              </td>
              <td>
                <span
                  :class="{ red: isToday(item.documentValidToDate) }"
                  v-if="item.documentValidToDate"
                  >{{
                    $moment(item.documentValidToDate).format('DD.MM.YYYY')
                  }}</span
                >
                <div v-if="isToday(item.documentValidToDate)" class="red">
                  ({{ $translate('today', 'general') }})
                </div>
              </td>
              <td>
                <p v-if="item.caption">{{ item.caption }}</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="bot">
      <router-link
        class="btn Button"
        :to="{ name: 'payments', href: '/dashboard/payments' }"
      >
        <span>{{ this.$translate('view_all_unpaid_bills', 'dashboard') }}</span>
        <IconSvg />
      </router-link>
    </div>
  </div>
</template>
<script>
import Tippy from '@/components/Tippy';
import IconSvg from '@/assets/img/chartArrowRight.svg';
import DownloadIco from '@/assets/img/downloadGreenIco.svg';

export default {
  name: 'PayableCompoennt',
  components: {
    Tippy,
    IconSvg,
    DownloadIco,
  },
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    name() {
      return this.data?.name;
    },
    totalDocs() {
      return this.data.data ? this.data.data.length : null;
    },
    total() {
      return this.data.data
        ? this.data.data.reduce(
            (accumulator, currentValue) =>
              accumulator + currentValue.amount || 0,
            0
          )
        : null;
    },
    losses() {
      return this.data.data
        ? this.data.data.reduce(
            (accumulator, currentValue) =>
              accumulator + currentValue.cautionAmount,
            0
          )
        : null;
    },
  },
  methods: {
    isToday(date) {
      const today = new Date();
      const providedDate = new Date(date);

      return (
        providedDate.getDate() === today.getDate() &&
        providedDate.getMonth() === today.getMonth() &&
        providedDate.getFullYear() === today.getFullYear()
      );
    },
    getDownloadLink(item) {
      return this.$api.file.getFullUrl(item.file.url);
    },
  },
};
</script>
<style lang="scss" scoped>
.payable {
  border-radius: toRem(20);
  border: toRem(1) solid rgba(255, 255, 255, 0.4);
  box-shadow: 5px 5px 10px 0px rgba(166, 171, 189, 0.3),
    -5px -5px 20px 0px rgba(255, 255, 255, 0.8);
  padding: toRem(15) toRem(30) toRem(30) toRem(30);
  background: $gray_100_purple;
  @media (max-width: $tablet2) {
    padding: toRem(19) toRem(20);
  }
  @media (max-width: $mobile) {
    padding: toRem(15) toRem(20);
  }

  .top {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    color: $gray_800;
    margin-bottom: toRem(58);
    @media (max-width: $mobile) {
      margin-bottom: toRem(24);
    }
    &:last-child {
      margin-bottom: 0;
    }
    .info {
      display: flex;
      align-items: flex-end;
      margin-left: toRem(88);
      font-size: toRem(14);
      line-height: 100%;
      @media (max-width: $notebook) {
        margin-left: toRem(38);
      }
      @media (max-width: $mobile) {
        width: 100%;
        margin-left: 0;
        justify-content: space-between;
        margin-top: toRem(16);
      }
      &:first-child {
        margin-left: 0;
      }
      span {
        font-size: inherit;
        line-height: inherit;
        &.big {
          font-size: toRem(20);
          font-weight: 500;
          line-height: 100%;
        }
        &.green {
          color: $green;
        }
        &.red {
          color: $red;
        }
      }
      ::v-deep(.Notice) {
        margin-left: toRem(3);
        .content {
          left: auto;
          right: 100%;
        }
        &.red {
          .InfoIcon {
            path {
              fill: $red;
            }
          }
        }
      }
    }
    h1.title,
    h2.title,
    h3.title,
    h4.title {
      line-height: 100%;
      font-size: toRem(36);
      color: inherit;
      margin: 0;
      @media (max-width: $mobile) {
        line-height: toRem(32);
        font-size: toRem(32);
      }
    }
    .totalDocs {
      color: $gray_600;
      align-self: flex-end;
      margin-left: toRem(5);
      @media (max-width: $notebook) {
        font-size: toRem(18);
      }
    }
  }
  .scroll-box {
    @media (max-width: $tablet2) {
      margin: 0 toRem(-20);
      overflow: hidden;
      width: calc(100% + toRem(40));
      .box {
        padding: 0 toRem(20);
        overflow: auto;
        width: auto;
      }
    }
  }
  .table {
    width: 100%;
    border-collapse: collapse;
    @media (max-width: $tablet2) {
      width: auto;
    }
    thead {
      th {
        text-align: start;
        font-size: toRem(14);
        font-weight: 450;
        line-height: 100%;
        color: $gray_700;
        border-bottom: toRem(1) solid #dfdfdf;
        padding-bottom: toRem(24);
      }
    }
    thead,
    tbody {
      th,
      td {
        vertical-align: top;
        text-align: left;
        width: auto;
        font-size: toRem(14);
        line-height: 100%;
        @media (max-width: $tablet2) {
          min-width: toRem(144);
        }
        &:nth-child(1) {
          width: 19.6%;
          @media (max-width: $notebook) {
            width: 21%;
          }
          @media (max-width: $tablet2) {
            min-width: toRem(208);
          }
        }
        &:nth-child(2) {
          width: 16.3%;
          @media (max-width: $notebook) {
            width: 16%;
          }
          @media (max-width: $tablet2) {
            min-width: toRem(153);
          }
        }
        &:nth-child(3) {
          width: 18.1%;
          @media (max-width: $notebook) {
            width: 18.4%;
          }
          @media (max-width: $tablet2) {
            min-width: toRem(180);
          }
        }
        &:nth-child(4) {
          width: 18%;
          @media (max-width: $notebook) {
            width: 18.6%;
          }
          @media (max-width: $tablet2) {
            min-width: toRem(180);
          }
        }
        &:nth-child(5) {
          width: 14.3%;
          @media (max-width: $notebook) {
            width: 12.8%;
          }
          @media (max-width: $tablet2) {
            min-width: toRem(144);
          }
        }
        .link {
          font-size: toRem(16);
          color: $gray_900;
          text-decoration: none;
          font-weight: 450;
          span {
            font-size: inherit;
            color: inherit;
          }
        }
        .download {
          display: inline-flex;
          font-size: toRem(14);
          color: $gray_900;
          align-items: center;
          span {
            font-size: inherit;
            color: inherit;
          }
          svg {
            margin-left: toRem(4);
            width: toRem(18);
            height: toRem(18);
          }
        }
        .b {
          font-size: toRem(16);
          font-weight: 450;
          color: $gray_900;
          &.green {
            color: $green;
          }
          &.red {
            color: $red;
          }
        }
        .green {
          color: $green;
        }
        .red {
          color: $red;
        }
        p {
          font-size: toRem(12);
          color: $gray_700;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
    tbody {
      td {
        padding: toRem(20) 0;
      }
    }
  }
  .bot {
    padding-top: toRem(40);
    @media (max-width: $notebook) {
      padding-top: toRem(46);
    }
    @media (max-width: $tablet2) {
      padding-top: toRem(26);
    }

    .btn.Button {
      margin-top: auto;
      font-weight: 450;
      font-size: toRem(14);
      line-height: 100%;
      color: $gray_900;
      display: inline-flex;
      align-items: center;
      text-decoration: none;
      &::before {
        content: none;
      }
      span {
        font-weight: inherit;
        font-size: inherit;
        line-height: inherit;
        text-decoration: none;
      }
      svg {
        width: toRem(16);
        height: toRem(16);
        object-fit: contain;
        margin-left: toRem(5);
      }
    }
  }
}
</style>
