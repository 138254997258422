export default ($axios) => {
  return {
    async getDocuments(params = {}) {
      return $axios({
        url: '/documents',
        method: 'get',
        params: { ...params },
      })
        .then(({ data }) => {
          return {
            success: true,
            list: data.data,
            pagination: data.meta.pagination,
          };
        })
        .catch((e) => e);
    },
    async getByID(id, params = {}) {
      return $axios({
        url: '/document/' + id,
        method: 'get',
        params: { ...params },
      })
        .then(({ data }) => {
          return { success: true, data: data.data };
        })
        .catch((e) => e);
    },
    async getByCRM(id, params = {}) {
      return $axios({
        url: '/document/crm-id/' + id,
        method: 'get',
        params: { ...params },
      })
        .then(({ data }) => {
          return { success: true, data: data };
        })
        .catch((e) => e);
    },
    async getTypes() {
      return await $axios({
        url: '/document-types',
        method: 'get',
      })
        .then(({ data }) => {
          return { success: true, list: data.data };
        })
        .catch((e) => e);
    },
    async askQuestion(data) {
      return await $axios({
        url: '/request/documents',
        method: 'post',
        data: data,
      })
        .then(({ data }) => {
          return { success: true, data: data.data };
        })
        .catch((e) => {
          return { success: false, error: e, list: [] };
        });
    },
    async getInstructions(params = {}) {
      return $axios({
        url: '/transport-instructions',
        method: 'get',
        params: { ...params },
      })
        .then(({ data }) => {
          return {
            success: true,
            list: data.data,
            pagination: data.meta.pagination,
          };
        })
        .catch((e) => e);
    },
    async getInstruction(id, params = {}) {
      return $axios({
        url: '/transport-instruction/' + id,
        method: 'get',
        params: { ...params },
      })
        .then(({ data }) => {
          return {
            success: true,
            data: data,
          };
        })
        .catch((e) => e);
    },
    async putInstruction(id, payload = {}) {
      return $axios({
        url: '/transport-instruction/' + id,
        method: 'put',
        data: {
          data: payload,
        },
      })
        .then(({ data }) => {
          return {
            success: true,
            data: data,
          };
        })
        .catch((e) => e);
    },
    async askQuestionProfile(data, type = 'orders') {
      return await $axios({
        url: `/request/${type}`,
        method: 'post',
        data: data,
      })
        .then(({ data }) => {
          return { success: true, data: data.data };
        })
        .catch((e) => {
          return { success: false, error: e, list: [] };
        });
    },

    async electronicDocumentsCredit(id) {
      return await $axios({
        url: '/electronic-document/credit/' + id,
        method: 'post',
        // data: data,
      })
        .then(({ data }) => {
          return { success: true, data: data.data };
        })
        .catch((e) => {
          return { success: false, error: e, list: [] };
        });
    },

    async electronicDocumentsApprove(id) {
      return await $axios({
        url: '/electronic-document/approve/' + id,
        method: 'post',
      })
        .then(({ data }) => {
          return { success: true, data: data.data };
        })
        .catch((e) => {
          return { success: false, error: e, list: [] };
        });
    },

    async getElectronicDocumentMap(id, params = {}) {
      return $axios({
        url: '/electronic-documents/map',
        method: 'get',
        params: { ...params },
      })
        .then(({ data }) => {
          return {
            success: true,
            data: data,
          };
        })
        .catch((e) => e);
    },

    async getElectronicDocumentMapRailwayPath(id, params = {}) {
      return $axios({
        url: `/electronic-document/${id}/rendered-paths`,
        method: 'get',
        params: { ...params },
      })
        .then(({ data }) => {
          return {
            success: true,
            data: data,
          };
        })
        .catch((e) => e);
    },

    async createTI(data) {
      return await $axios({
        url: `/transport-instruction`,
        method: 'post',
        data: data,
      })
        .then(({ data }) => {
          return { success: true, data: data.data };
        })
        .catch((e) => {
          return { success: false, error: e, list: [] };
        });
    },

    /**
     * @typedef {Object} TIAttachmentPayload
     * @property {string} comment
     * @property {number} file - file id
     *
     * @param {number} id
     * @param {TIAttachmentPayload} data
     * @returns {Promise<{success: boolean, data: any}>
     */
    async uploadTIAttachment(id, data) {
      return await $axios({
        url: `/transport-instruction/${id}/upload-attachment`,
        method: 'post',
        data: data,
      })
        .then(({ data }) => {
          return { success: true, data: data.data };
        })
        .catch((e) => {
          return { success: false, error: e, list: [] };
        });
    },

    /**
     * @typedef {Object} OrderAttachmentPayload
     * @property {string} comment
     * @property {number} file - file id
     *
     * @param {number} id
     * @param {OrderAttachmentPayload} data
     * @returns {Promise<{success: boolean, data: any}>
     */
    async uploadOrderAttachment(id, data) {
      return await $axios({
        url: `/order/${id}/upload-attachment`,
        method: 'post',
        data: data,
      })
        .then(({ data }) => {
          return { success: true, data: data.data };
        })
        .catch((e) => {
          return { success: false, error: e, list: [] };
        });
    },
  };
};
