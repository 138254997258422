<template>
  <div class="PageTabs">
    <swiper
      class="swiper"
      slides-per-view="auto"
      :observer="true"
      :observeParents="true"
      :watchSlidesProgress="true"
      :watchSlidesVisibility="true"
      :watchOverflow="true"
      ref="swiper"
      @swiper="swiperInit"
    >
      <swiper-slide
        class="swiper-slide PageTabs__item"
        v-for="item in list"
        :key="item.id"
      >
        <router-link
          :to="item.to"
          class="PageTabs__link simpleLink"
          :class="{ active: item.active }"
        >
          {{ item.text }}
        </router-link>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css";
/**
 * The PageTabs component.
 */
export default {
  name: "PageTabs",
  components: { Swiper, SwiperSlide },
  props: {
    list: { default: () => [], type: Array },
  },
  data() {
    return { swiper: null };
  },
  computed: {},
  methods: {
    swiperInit(swiper) {
      this.swiper = swiper;
      const activeIndex = this.list.findIndex(({ active }) => active);
      this.swiper.slideTo(activeIndex);
    },
  },
};
</script>

<style lang="scss" scoped src="./component.scss"></style>