<template>
  <td
    class="CellWrap"
    :class="{ disabled: cell.disabled, isToday: cell.isToday, noQuota }"
  >
    <div class="Cell">
      <div class="date">
        <SuccessCircleSVG v-if="cell.isToday" class="SuccessCircle" />
        {{ cell.date }}.
      </div>
      <template v-if="!cell.disabled || data?.reserved">
        <template v-if="editNow">
          <div class="content editNow">
            <span class="quota">
              <span>{{ $translate("available", "orders") }}:</span>

              <!-- <span class="reserved"
              >{{ available + (data.unit?.name || "т") }}
            </span>
            <span class="from">{{ $translate("from") }}</span> -->
              <span class="available">{{
                data.balance + (data.unit?.name || "т")
              }}</span>
            </span>
            <form
              class="order"
              novalidate
              @submit.prevent="submit"
              :key="formKey"
            >
              <span>{{ $translate("order", "dashboard") }}</span>
              <Label
                class="marginBottom0"
                name="quantity"
                :placeholder="data.unit?.name || 'т'"
                inputType="text"
                @onInput="onInputField"
                :error="inputError['quantity']"
                ref="quantity"
                mask="0.99"
                mask-tokens="0:\d:multiple|9:\d"
                :value="quantity"
                size="xs"
                @blur="labelBlur"
                @updateValueFunc="
                  (func) => {
                    updateValueFunc = func;
                  }
                "
              />
              <MyButton
                type="submit"
                class="no-padding min-height"
                tag="button"
                tagType="submit"
              >
                <SuccessSVG2
              /></MyButton>
            </form>
          </div>
        </template>
        <template v-else>
          <div class="wrap">
            <div class="icon">
              <PackageSVG />
              <div class="status" v-if="noQuota || reserved">
                <CloseSVG class="closeSVG" v-if="noQuota" />
                <SuccessSVG2 class="reserved" v-else-if="reserved" />
              </div>
            </div>
            <div class="content">
              <template v-if="noQuota">
                <span>{{ $translate("no_quotas", "orders") }}</span>
              </template>
              <template v-else>
                <span>{{ $translate("available", "orders") }}:</span><br />
                <template v-if="data?.reserved">
                  <span class="quota">
                    <span class="reserved"
                      >{{ data?.reserved.quantity + (data.unit?.name || "т") }}
                    </span>
                    <span class="from">{{ $translate("from") }}</span>
                    <span class="available">{{
                      data.balance + (data.unit?.name || "т")
                    }}</span>
                    <div
                      class="editIcon"
                      @click="changeEditNow(true)"
                      v-if="!isPastDay"
                    >
                      <PenSVG />
                    </div>
                    <div class="editIcon" @click="removeQuota" v-else>
                      <CloseSVG />
                    </div>
                  </span>
                </template>
                <template v-else>
                  <span class="quota"
                    >{{ data.balance + " " + (data.unit?.name || "т") }}
                    <div class="editIcon" @click="changeEditNow(true)">
                      <PenSVG />
                    </div>
                  </span>
                </template>
              </template>
            </div>
          </div>
        </template>
      </template>
    </div>
  </td>
</template>

<script>
import { mapGetters } from "vuex";
import PackageSVG from "@/assets/img/package.svg";
import CloseSVG from "@/assets/img/close.svg";
import SuccessSVG2 from "@/assets/img/success2.svg";
import SuccessCircleSVG from "@/assets/img/success_sircle.svg";
import PenSVG from "@/assets/img/pen2.svg";
/**
 * The DeliveryCalendarCell component.
 */
export default {
  name: "DeliveryCalendarCell",
  components: { PackageSVG, CloseSVG, SuccessSVG2, SuccessCircleSVG, PenSVG },
  props: {
    cell: { default: null, type: Object },
    data: { default: null, type: Object },
  },
  data() {
    return {
      editNow: false,
      quantity: "",
      inputError: {},
      formKey: 0,
      loading: false,
      updateValueFunc: null,
    };
  },
  computed: {
    ...mapGetters("checkout", ["GET_Checkout"]),
    noQuota() {
      return !this.data?.balance;
    },
    reserved() {
      return this.data?.reserved;
    },
    // available() {
    //   return this.data.balance - this.quantity;
    // },
    isPastDay() {
      if (!this.reserved) return false;
      const nextDay = this.$moment(
        this.$moment().format("DD.MM.YYYY"),
        "DD.MM.YYYY"
      );
      const date = this.$moment(this.reserved.date, "YYYY-MM-DD", "DD.MM.YYYY");
      return nextDay.unix() >= date.unix();
    },
    isHoldedDay() {
      if (!this.reserved) return false;
      const nextDay = this.$moment(
        this.$moment().add(2, "day").format("DD.MM.YYYY"),
        "DD.MM.YYYY"
      );
      const date = this.$moment(this.reserved.date, "YYYY-MM-DD", "DD.MM.YYYY");
      return nextDay.unix() >= date.unix();
    },
    wagonNorm() {
      return this.GET_Checkout.wagonTypes?.wagonNorm?.wagonNorm || 1;
    },
    totalQuantityAvailable() {
      return (
        this.GET_Checkout.order.data.quantity -
        this.GET_Checkout.order.data.quotas
          .filter(({ id }) => {
            return id !== this.data?.reserved?.id;
          })
          .reduce((acc, { quantity }) => {
            return acc + quantity;
          }, 0)
      );
    },
  },
  methods: {
    onInputField(value, $event, name, required = false, validateType = "name") {
      if (!name) throw new Error("onInputField: name is undefined");
      if (value != undefined) this[name] = value;
      const validateError = this.$simpleInputValidate(
        value || this[name],
        required,
        validateType
      );
      this.inputError[name] = validateError;
      if (validateError.error) {
        this.$refs[name].$refs.input.focus();
      }
      console.log("onInputField", value, name);
      return validateError.error;
    },
    async submit() {
      if (this.loading) return false;
      this.recalcValue();
      const error = this.onInputField(
        undefined,
        undefined,
        "quantity",
        false,
        "name"
      );
      if (!error) {
        // this.loading = true;
        this.editNow = false;
        this.emitter.emit("updateDeliveryCell", {
          cell: this.cell,
          data: this.data,
          quantity: this.quantity ? parseFloat(this.quantity) : null,
        });
      }
    },
    recalcValue(val = this.quantity) {
      console.log("recalcValue", val);

      if (val) {
        // more than available on quota
        if (val > this.data.balance) {
          val = this.data.balance;
        }
        // more than available on order
        if (val > this.totalQuantityAvailable) {
          val = this.totalQuantityAvailable;
        }

        // round to nearest multiple
        val = this.$roundToNearestMultiple(val, this.wagonNorm);
        // if more than available on quota
        if (val > this.data.balance) val = val - this.wagonNorm;

        this.quantity = val;

        console.log("recalcValue new val", val);

        // update value
        this.updateValueFunc(val);
      } else {
        this.quantity = "";
        // update value
        this.updateValueFunc(this.quantity);
      }
    },
    labelBlur(val) {
      console.log(
        "val",
        val,
        typeof val,
        parseFloat(val),
        this.data.balance,
        parseFloat(val) > this.data.balance
      );

      this.recalcValue(parseFloat(val));
    },
    changeEditNow(val = false) {
      this.editNow = val;
      this.emitter.emit("editDeliveryCell", { edit: val, id: this.data.id });
    },
    distributeSupply() {
      this.editNow = false;
      this.quantity = null;
      this.formKey++;
    },
    removeQuota() {
      this.onInputField(0, undefined, "quantity");
      this.emitter.emit("updateDeliveryCell", {
        cell: this.cell,
        data: this.data,
        quantity: 0,
      });
    },
  },
  watch: {
    editNow(val) {
      if (val) this.$nextTick(() => this.$refs.quantity.$refs.input.focus());
    },
  },
  created() {
    this.quantity = this.data?.reserved?.quantity || "";
    this.emitter.on("editDeliveryCell", ({ edit, id }) => {
      if (edit && this.editNow && this.data && id !== this.data.id) {
        console.log("editDeliveryCell", { edit, id, currentID: this.data.id });
        // this.editNow = false;
        this.submit();
      }
    });
    this.emitter.on("distributeSupply", this.distributeSupply);
  },
  beforeUnmount() {
    this.emitter.off("editDeliveryCell");
    this.emitter.off("distributeSupply", this.distributeSupply);
  },
};
</script>

<style lang="scss" scoped src="./component.scss"></style>
