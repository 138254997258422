<template>
  <div
    class="CustomDropdownWrap"
    v-bind:name="name"
    :class="['size_' + currentSize, { disabled, open, error }]"
  >
    <span v-if="title" class="title">{{ title }}</span>
    <div
      ref="CustomDropdown"
      :class="[
        {
          open: open,
          active: currentItem,
          error,
          errorBorder: error && errorText == '',
        },
        'size_' + currentSize,
        'CustomDropdown',
        className,
      ]"
      :id="'select_' + id"
    >
      <div class="top dropdownTop" v-on:click="toggleDropdown">
        <template v-if="currentItem && currentItem.length">
          <span :key="'span_' + i" v-for="(item, i) in currentItem">{{
            item.name ? item.name : item
          }}</span>
        </template>

        <span v-else-if="title" class="title placeholder">{{
          placeholder || title
        }}</span>
        <div class="arrow">
          <Arrow />
        </div>
      </div>

      <div class="dropdown" ref="dropdown">
        <div
          v-for="item in filteredList"
          :key="item.key || item.id ? item.key || item.id : item"
          class="dropdownItem"
          :class="{ active: isActiveItem(item) }"
          v-on:click="dropdownClick(item)"
        >
          <span>{{ item.name ? item.name : item }}</span>
        </div>
        <div
          class="dropdownItem no_data"
          v-if="!filteredList || !filteredList.length"
        >
          {{ $translate('no_data') }}
        </div>
      </div>
    </div>
    <span class="errorText" v-if="error && errorText">{{ errorText }}</span>
    <slot></slot>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Scrollbar from 'smooth-scrollbar';
import Arrow from '@/assets/img/arrow_left.svg';
export default {
  name: 'CustomDropdownMulti',
  components: { Arrow },
  props: {
    title: { default: null, type: String },
    name: { default: null, type: String },
    placeholder: { default: null, type: String },
    /**
     * Error state
     */
    error: { default: false },
    /**
     * Error text
     */
    errorText: { default: null, type: String },
    className: { default: () => false },
    list: { default: () => [], type: Array },
    active: {
      default() {
        return [];
      },
      type: Array,
    },
    /**
     * The size of the button
     * @values m, l
     */
    size: { default: null, type: String },
    /**
     * Disabled state
     */
    disabled: { default: false, type: Boolean },
  },
  data: function () {
    return {
      inputValue: 0,
      open: false,
      id: null,
      scrollbar: null,
    };
  },
  computed: {
    ...mapGetters(['IS_Notebook']),
    currentSize() {
      if (this.size) return this.size;
      if (!this.IS_Notebook) return 'l';
      return 'm';
    },
    currentItem() {
      return this.active;
    },
    filteredList() {
      return this.list;
      //   return this.list.filter((el) => el != this.currentItem);
    },
  },
  created() {
    this.id = this.$rnd(0, 4096);
  },
  methods: {
    isActiveItem(item) {
      if (!this.currentItem) return false;
      return this.currentItem.find((it) => {
        if (typeof it == 'object' && this.currentItem && it.key && item.key) {
          return item.key === it?.key;
        }
        return item === it;
      });
    },
    initScroll() {
      this.scrollbar = Scrollbar.init(this.$refs.dropdown, {
        alwaysShowTracks: true,
        continuousScrolling: false,
      });
      this.scrollbar.track.xAxis.hide();
    },
    dropdownClick(item) {
      const key = item.key || item.id ? item.key || item.id : item;
      this.$emit('change', key, this.$props.name, item);
      this.closeDropdown();
    },
    documentClick(e) {
      const parent = e.target.closest('.CustomDropdown');
      if (
        (parent == null || parent.getAttribute('id') != 'select_' + this.id) &&
        !e.target.classList.contains('vhd__datepicker__month-button')
      ) {
        this.closeDropdown(e);
      }
    },
    closeDropdown() {
      this.open = false;
      this.id = this.$rnd(0, 4096);
      document.removeEventListener('click', this.documentClick, false);
      document.removeEventListener('tap', this.documentClick, false);
      document.removeEventListener('touchend', this.documentClick, false);
    },
    openDropdown() {
      if (this.disabled) return;
      this.open = true;
      document.addEventListener('click', this.documentClick, false);
      document.addEventListener('tap', this.documentClick, false);
      document.addEventListener('touchend', this.documentClick, false);
    },
    toggleDropdown(e) {
      this.open ? this.closeDropdown(e) : this.openDropdown(e);
    },
  },
  mounted() {
    // if (!this.$props.active) {
    //   this.$emit(
    //     "change",
    //     this.$props.list[0].key,
    //     this.$props.name,
    //     this.$props.list[0]
    //   );
    // }
    this.initScroll();
  },
  beforeUnmount() {
    if (this.scrollbar) this.scrollbar.destroy();
    document.removeEventListener('click', this.documentClick, false);
    document.removeEventListener('tap', this.documentClick, false);
    document.removeEventListener('touchend', this.documentClick, false);
  },
};
</script>

<style lang="scss" scoped src="./component.scss"></style>
