// Notifications
import Notifications from '@kyvg/vue3-notification'


export default (app) => {
    app.use(Notifications)
    return (title = "", text = "", params = {}) => {
        console.log({ title, text, params });
        app.config.globalProperties.$notify({
            type: "success",
            group: "foo",
            title: title,
            text: text,
            duration: 7000,
            ignoreDuplicates: true,
            ...params,
            width: '100%',
        });
    }

}