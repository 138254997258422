<template>
  <div class="Pagination" v-if="pageCount > 1">
    <Paginate
      v-model="page"
      :page-count="pageCount"
      :click-handler="click"
      :prev-text="arrow"
      :next-text="arrow"
      :margin-pages="1"
      :page-range="2"
      :container-class="'className'"
      prev-link-class="prev-link"
      prev-class="page-item buttonWrap prev-btn"
      next-link-class="next-link"
      next-class="page-item buttonWrap next-btn"
      page-link-class="simpleLink"
    >
    </Paginate>
  </div>
</template>

<script>
import Paginate from 'vuejs-paginate-next';
/**
 * The Pagination component.
 */
export default {
  name: 'PaginationComponent',
  components: { Paginate },
  props: {
    pageCurrent: { default: 1, type: [Number, String] },
    pageCount: { default: 1, type: Number },
  },
  data() {
    return {
      arrow:
        '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M8.70711 0.292893C9.09763 0.683417 9.09763 1.31658 8.70711 1.70711L3.41421 7H15C15.5523 7 16 7.44772 16 8C16 8.55228 15.5523 9 15 9H3.41421L8.70711 14.2929C9.09763 14.6834 9.09763 15.3166 8.70711 15.7071C8.31658 16.0976 7.68342 16.0976 7.29289 15.7071L0.292893 8.70711C-0.0976311 8.31658 -0.0976311 7.68342 0.292893 7.29289L7.29289 0.292893C7.68342 -0.0976311 8.31658 -0.0976311 8.70711 0.292893Z" fill="#2B3951"/></svg>',
      page: this.$props.pageCurrent,
    };
  },
  watch: {
    pageCurrent(val) {
      this.page = val;
    },
  },
  methods: {
    click(page) {
      this.$emit('paginate', page);
    },
  },
};
</script>

<style lang="scss" scoped src="./component.scss"></style>
