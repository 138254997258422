<template>
  <LayoutLogin>
    <LoginGrid
      :title="$translate('register_in_office', 'auth')"
      :text="$translate('register_in_office_text', 'auth')"
      class="smallBoxPadding"
    >
      <h2>{{ $translate("phone_confirmation", "auth") }}</h2>
      <p>{{ $translate("phone_confirmation_text", "auth") }}</p>
      <div class="formWrap">
        <LoadingIcon v-if="loading" :absolute="true" />
        <form
          novalidate
          @submit.prevent="submit"
          :class="{ loadingElem: loading }"
          :key="formKey"
        >
          <Label
            :title="$translate('enter_code', 'auth')"
            name="key"
            :placeholder="$translate('code', 'auth')"
            inputType="text"
            @onInput="onInputKey"
            :error="keyError"
            :errorText="keyErrorText"
            ref="key"
          />
          <MyButton
            class="w100"
            :text="$translate('register', 'auth')"
            tag="button"
            :dynamicSize="true"
          />
        </form>
      </div>
    </LoginGrid>
  </LayoutLogin>
</template>

<script>
import LayoutLogin from "@/layouts/Login.vue";
import LoginGrid from "@/components/LoginGrid";
/**
 * The registration-otp-page component.
 */
export default {
  name: "RegistrationSendOTPPage",
  components: { LayoutLogin, LoginGrid },
  data() {
    return {
      formKey: "initForm",
      loading: false,
      key: "",
      keyError: false,
      keyErrorText: "",
      phone: this.$route.query.phone,
    };
  },
  methods: {
    onInputKey(value = this.key, e, required = false) {
      this.key = value;
      const keyError = this.$simpleInputValidate(value, required, "opt_key");
      this.keyError = keyError.error;
      if (keyError.error) {
        this.keyErrorText = keyError.text;
        this.$refs.key.$refs.input.focus();
      }
      return keyError.error;
    },
    resetForm() {
      this.loading = false;
      this.key = "";
      this.keyError = false;
      this.formKey = "form_" + this.$rnd(0, 100);
    },
    success() {
      this.resetForm();
      localStorage.setItem("registrationSuccess", true);
      this.$router.push({ name: "registration-success" });
    },
    async submit() {
      if (this.loading) return false;

      const error = (() => {
        const errorKey = this.onInputKey(this.key, undefined, true);
        return errorKey;
      })();
      if (!error) {
        this.loading = true;
        const { phone, key } = this;
        const { success, message } = await this.$api.auth.confirmOTP({
          phone,
          code: key,
        });
        this.loading = false;
        if (success) {
          this.success();
        } else {
          this.keyError = true;
          this.keyErrorText = message;
          this.$refs.key.$refs.input.focus();
        }
      }
    },
  },
};
</script>
