<template>
  <MetaTags>
    <title>{{ title }}</title>
  </MetaTags>
  <div class="login-layout">
    <Header />
    <slot />
  </div>
</template>

<script>
import Header from "@/components/Header";
/**
 * The login-layout component.
 */
export default {
  name: "login-layout",
  components: { Header },
  props: {
    /**
     * log out on enter route
     */
    logOut: { default: true },
  },
  data() {
    return {
      title: "OSTCHEM RETAIL",
    };
  },
  created() {
    if (this.logOut && this.$auth.loggedIn) this.$auth.logOut();
  },
};
</script>
<style lang="scss">
.login-layout {
  min-height: var(--windowHeight);
  padding-top: toRem(125);
  padding-bottom: toRem(20);
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: $notebook) {
    padding-top: toRem(85);
    padding-bottom: toRem(20);
  }
  @media (max-width: $tablet) {
    padding-bottom: toRem(60);
  }
  @media (max-width: $mobile) {
    padding-top: toRem(115);
  }
}
</style>
